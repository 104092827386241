import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const ModalImage = (props) => {
  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>{props.name}</h4>
              <span>
                <i
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={`${props.img}`} alt="img" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                props.setModal(false);
              }}
              className={props.myStyle[`btn_${props.cliente}`]}
              variant="main"
              disabled={false}
              type="button"
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalImage;
