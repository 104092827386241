import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* Compnents */
import Content from "../../components/validate/Content";
import Login from "../../components/validate/Login";
import Footer from "../../components/footer";

/* Style */
import Style from "../../assets/styles/Login.module.scss";
import Logo from "../../assets/images/loading.gif";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";

import companies from "../config";
import validateCompanies from "../config/functions";

/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
  if (
    window.location.protocol !== "https:" &&
    window.location.host === "app.kantoor.cl"
  ) {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }
}

const Checkin = (props) => {
  const [cliente, setCliente] = useState("");

  let auth = "false";
  if (typeof localStorage !== "undefined") {
    auth = localStorage.getItem("authValidate");
  }

  auth = auth === "true";

  const [login, setLogin] = useState(auth);
  const [done, setDone] = useState(false);

  /*  User Select from redux  */
  const state = useSelector((state) => state.validate);

  useEffect(() => {
    if (done) {
      let auth = "false";
      if (typeof localStorage !== "undefined") {
        auth = localStorage.getItem("authValidate");
      }

      auth = auth === "true";
      setLogin(auth);
    } else {
      setDone(true);
    }
  }, [state.loading, done]);

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  /* Local store */

  if (!done) {
    return (
      <div className={Style.loading_general}>
        <img src={Logo} alt="logo" />
      </div>
    );
  }

  return (
    <>
      {!login ? (
        <Login
          validateCompanies={validateCompanies}
          companies={companies}
          company={props.match.params.id}
          cliente={cliente}
          myStyle={myStyle}
          style={Style}
        />
      ) : (
        <Content
          setLogin={setLogin}
          cliente={cliente}
          myStyle={myStyle}
          style={Style}
          id={props.match.params.data}
        />
      )}
      <Footer />
    </>
  );
};

export default Checkin;
