const initialState = {
  data: {},
  loading: true,
  status: 0,
  dataCredito: {},
  loadingCredito: true,
  statusCredito: 0,
};

export default initialState;
