const initialState = {
  data: false,
  email: false,
  loading: false,

  errorMessage: "",

  dataReserva: false,
  loadingReserva: true,
  statusReserva: 0,

  dataCheck: false,
  loadingCheck: false,
  statusCheck: 0,
};

export default initialState;
