import { combineReducers } from "redux";

import home from "./home";
import login from "./login";
import reservation from "./reservation";
import history from "./history";
import check from "./check";
import validate from "./validate";

const rootReducer = combineReducers({
  home,
  login,
  reservation,
  history,
  check,
  validate,
});

export default rootReducer;
