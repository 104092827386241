import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

/* Component */
import { Form } from "react-bootstrap";

/* Actions */
import {
  getLabels,
  resetLabels,
  getDivision,
  resetDivision,
  getSubDivision,
  resetSubDivision,
  getSubSubDivision,
  resetSubSubDivision,
} from "../../actions/reservation";

/* Components */
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";

const StepDivision = (props) => {
  const state = useSelector((state) => state.reservation);

  const dispatch = useDispatch();

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getLabels({}));
      dispatch(getDivision({ empresa_id: props.form.empresa_id }));
    };
    callsAPI();
  }, [dispatch]);

  /* Set division */
  useEffect(() => {
    const callsAPI = async () => {
      if (state.statusDivision === 200) {
        if (state.dataDivision.datos.length === 1) {
          dispatch(
            getSubDivision({
              id: state.dataDivision.datos[0].id,
              empresa_id: props.form.empresa_id,
            })
          );
          props.handleChange({
            target: {
              name: "ciudad",
              value: state.dataDivision.datos[0].id,
            },
          });
        }
      }
    };
    callsAPI();
  }, [dispatch, state.dataDivision]);

  /* Set sub division */
  useEffect(() => {
    const callsAPI = async () => {
      if (state.statusSubDivision === 200) {
        if (
          state.dataSubDivision.datos.length === 1 &&
          state.dataDivision.datos.length === 1
        ) {
          dispatch(
            getSubSubDivision({
              id: state.dataSubDivision.datos[0].id,
              empresa_id: props.form.empresa_id,
            })
          );
          props.handleChange({
            target: {
              name: "provincia",
              value: state.dataSubDivision.datos[0].id,
            },
          });
        }
      }
    };
    callsAPI();
  }, [dispatch, state.dataSubDivision]);

  /* Set sub sub division */
  useEffect(() => {
    const callsAPI = async () => {
      if (state.statusSubSubDivision === 200) {
        if (
          state.dataSubSubDivision.datos.length === 1 &&
          state.dataSubDivision.datos.length === 1 &&
          state.dataDivision.datos.length === 1
        ) {
          props.handleChange({
            target: {
              name: "comuna",
              value: state.dataSubSubDivision.datos[0].id,
            },
          });
          if (props.direction === "right") {
            props.nextStep();
            props.handleMemory({ name: "step4" });
          } else {
            props.backStep();
          }
        }
      }
    };
    callsAPI();
  }, [dispatch, state.dataSubSubDivision]);

  /* Reset  Data  */
  useEffect(() => {
    return () => {
      dispatch(resetLabels());
      /*dispatch(resetDivision());
      dispatch(resetSubDivision());
      dispatch(resetSubSubDivision());*/
    };
  }, [dispatch]);

  /* Load Subdivision */
  const loadSubDivision = async (value) => {
    dispatch(getSubDivision({ id: value, empresa_id: props.form.empresa_id }));
  };

  /* Load SubSubdivision */
  const loadSubSubDivision = async (value) => {
    dispatch(
      getSubSubDivision({ id: value, empresa_id: props.form.empresa_id })
    );
  };

  /* Next Step */
  const validationAllFile = (value) => {
    if (value !== "") {
      props.handleCount(1);
      props.handleMemory({ name: "step4" });
      props.nextStep();
    }
  };

  if (
    state.loadingLabels ||
    state.loadingDivision ||
    state.loadingSubDivision ||
    state.loadingSubSubDivision
  ) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusLabels === 501 ||
    state.statusDivision === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusLabels === 200 &&
    state.statusDivision === 200
  ) {
    if (!state.dataLabels.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <section className={props.style.container__content}>
      <h2 className={props.style.start}>
        <FormattedMessage id="EnterPlaceOfPreference" />
      </h2>
      <p>
        <FormattedMessage id="SelectThePlaceOfPreference" />.
      </p>
      <div
        className={`${props.style.box__footer} ${props.style.box__footer_alcenter}`}
      >
        <div className={props.style.box__footer__select}>
          <Form.Label>
            {state.dataLabels.datos.label_divisiones_singular}
          </Form.Label>
          <Form.Control
            as="select"
            className={props.myStyle[`select_${props.cliente}`]}
            value={props.form.ciudad}
            onChange={(event) => {
              props.handleChange(event);
              loadSubDivision(event.target.value);
            }}
            name="ciudad"
            data-number="ok"
          >
            <FormattedMessage id="Select">
              {(message) => <option value="">{message}</option>}
            </FormattedMessage>
            {state.dataDivision.datos.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </Form.Control>
        </div>
        <div className={props.style.box__footer__select}>
          <Form.Label>
            {state.dataLabels.datos.label_subdivisiones_singular}
          </Form.Label>
          <Form.Control
            as="select"
            className={props.myStyle[`select_${props.cliente}`]}
            value={props.form.provincia}
            onChange={(event) => {
              props.handleChange(event);
              loadSubSubDivision(event.target.value);
            }}
            name="provincia"
            disabled={props.form.ciudad === "" ? true : false}
            data-number="ok"
          >
            <FormattedMessage id="Select">
              {(message) => <option value="">{message}</option>}
            </FormattedMessage>
            {state.statusSubDivision === 200 &&
              state.dataSubDivision.ejecucion.estado &&
              state.dataSubDivision.datos.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                );
              })}
          </Form.Control>
        </div>
        <div className={props.style.box__footer__select}>
          <Form.Label>
            {state.dataLabels.datos.label_subsubdivisiones_singular}
          </Form.Label>
          <Form.Control
            as="select"
            className={props.myStyle[`select_${props.cliente}`]}
            value={props.form.comuna}
            onChange={(event) => {
              props.handleChange(event);
              validationAllFile(event.target.value);
            }}
            name="comuna"
            data-number="ok"
            disabled={props.form.provincia === "" ? true : false}
          >
            <FormattedMessage id="Select">
              {(message) => <option value="">{message}</option>}
            </FormattedMessage>
            {state.statusSubSubDivision === 200 &&
              state.dataSubSubDivision.ejecucion.estado &&
              state.dataSubSubDivision.datos.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                );
              })}
          </Form.Control>
        </div>
      </div>
    </section>
  );
};

export default StepDivision;
