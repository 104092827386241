import React, { useState, useEffect } from "react";

/* Compnents */
import Form from "../../components/register/Form";
import Footer from "../../components/footer";

/* Style */
import Style from "../../assets/styles/Register.module.scss";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const Register = (props) => {
  const [email, setEmail] = useState(false);
  const [code, setCode] = useState(false);

  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  useEffect(() => {
    const content = props.match.params.code.split("&");
    if (content > 0) {
      props.history.push("/home");
    } else {
      setCode(content[0]);
      setEmail(content[1]);
    }
  });

  if (!code) {
    return <div></div>;
  }

  return (
    <>
      <Form
        cliente={cliente}
        myStyle={myStyle}
        email={email}
        code={code}
        style={Style}
      />
      <Footer />
    </>
  );
};

export default Register;
