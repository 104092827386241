import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import { FormattedMessage } from "react-intl";

/* Actions */
import {
  getReservaAllExternaList,
  resetReservaAllExternaList,
  getOptionsReservation,
  resetAllReservation,
} from "../../actions/reservation";

/* Components */
import Loading from "../common/Loading";
import ErrorComponent from "../common/Error";
import Expired from "../common/Expired";
import Modal from "./Modal";

const List = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(false);

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getReservaAllExternaList({}));
      dispatch(getOptionsReservation({}));
    };
    callsAPI();
  }, [dispatch]);

  /* Reset  Data  */
  useEffect(() => {
    return () => {
      dispatch(resetReservaAllExternaList());
      dispatch(resetAllReservation());
    };
  }, [dispatch]);

  if (state.loadingReservaAllExternaList || state.loadingOptionsReservation) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusReservaAllExternaList === 501 ||
    state.statusOptionsReservation === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusReservaAllExternaList === 200
  ) {
    if (!state.dataReservaAllExternaList.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  } else if (
    /* When there is an error in the back */
    state.statusOptionsReservation === 200
  ) {
    if (!state.dataOptionsReservation.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <section className={`${props.style.container__module}`} variant="dark">
      <Modal
        myStyle={props.myStyle}
        style={props.homeStyle}
        modal={modal}
        id={id}
        setModal={setModal}
        data={state.dataOptionsReservation.datos}
      />
      <h1>
        <FormattedMessage id="AllRequests" />
      </h1>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>#</th>
            <th>
              <FormattedMessage id="Description" />
            </th>
            <th>
              <FormattedMessage id="Request" />
            </th>
            <th>
              <FormattedMessage id="AnswerDate" />
            </th>
            <th>
              <FormattedMessage id="AssignedRoom" />
            </th>
            <th>
              <FormattedMessage id="Update" />
            </th>
          </tr>
        </thead>
        <tbody>
          {state.dataReservaAllExternaList.datos
            .sort(function (a, b) {
              return (
                (a.feedback !== null) - (b.feedback !== null) ||
                -(a > b) ||
                +(a < b)
              );
            })
            .map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.descripcion}</td>

                <td>
                  {item.feedback === null ? (
                    <FormattedMessage id="WaitingForAnswer" />
                  ) : item.feedback === true ? (
                    <FormattedMessage id="RequestAccepted" />
                  ) : (
                    <FormattedMessage id="RequestRejected" />
                  )}
                </td>
                <td>{item.fecha_feedback ? item.fecha_feedback : "-"}</td>
                <td>{item.recurso_asginado ? item.recurso_asginado : "-"}</td>
                <td>
                  <div className={`${props.style.icon_table}`}>
                    <span
                      onClick={() => {
                        setId(item.id);
                        setModal(true);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </section>
  );
};

export default List;
