import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

/* Component */
import { Form, Button } from "react-bootstrap";
import Code from "./Code";

/* Assets */
import Logo from "../../assets/images/logo.png";

let language =
  typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : "es";

if (language !== "es" && language !== "en") {
  language = "en";
}

const FormRegister = (props) => {
  /*  Use  states  */

  const [error, setError] = useState(false);

  const [form, setForm] = useState({
    email: props.email,
    lenguaje: language,
    pais_id: 173,
    password: "",
    uid: null,
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    password_confirmation: "",
  });

  const [step, setStep] = useState(1);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setError(false);
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const nextStep = (e, step) => {
    e.preventDefault();

    if (step === 1) {
      if (form.nombres !== "") {
        setStep(step + 1);
      } else {
        setError("CompleteTheRequiredFields");
      }
    } else if (step === 2) {
      if (form.apellido_paterno !== "") {
        setStep(step + 1);
      } else {
        setError("CompleteTheRequiredFields");
      }
    } else if (step === 3) {
      if (form.password !== "") {
        setStep(step + 1);
      } else {
        setError("CompleteTheRequiredFields");
      }
    }
  };

  const backStep = (e, step) => {
    e.preventDefault();
    setStep(step - 1);
  };

  /*  Code confirmation  */
  if (step === 4) {
    return (
      <Code
        cliente={props.cliente}
        myStyle={props.myStyle}
        form={form}
        code={props.code}
        style={props.style}
      />
    );
  }

  return (
    <>
      <section data-testid="form" className={props.style.container}>
        <section className={props.style.box}>
          {props.cliente === "" ? (
            <div className={props.style.box__img}>
              <img src={Logo} alt="Logo" />
            </div>
          ) : (
            <div
              className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
            >
              <div className={props.myStyle[`logo_${props.cliente}`]}></div>
              <div></div>
            </div>
          )}
          <h2>
            <FormattedMessage id="Register" />
          </h2>
          <Form
            onSubmit={(e) => {
              nextStep(e, step);
            }}
          >
            {step === 1 && (
              <div className={props.style.box__form}>
                <Form.Group>
                  <Form.Label>
                    1 - <FormattedMessage id="WriteYourName" />
                  </Form.Label>
                  <FormattedMessage id="WriteYourName">
                    {(msg) => (
                      <Form.Control
                        value={form.nombres}
                        onChange={(e) => handleChange(e)}
                        name={"nombres"}
                        type="text"
                        placeholder=""
                        className={props.myStyle[`input_${props.cliente}`]}
                      />
                    )}
                  </FormattedMessage>
                </Form.Group>
              </div>
            )}
            {step === 2 && (
              <div className={props.style.box__form}>
                <Form.Group>
                  <Form.Label>
                    2 - <FormattedMessage id="WriteLastName" />
                  </Form.Label>
                  <FormattedMessage id="WriteLastName">
                    {(msg) => (
                      <Form.Control
                        value={form.apellido_paterno}
                        onChange={(e) => handleChange(e)}
                        name={"apellido_paterno"}
                        type="text"
                        placeholder=""
                        className={props.myStyle[`input_${props.cliente}`]}
                      />
                    )}
                  </FormattedMessage>
                </Form.Group>
              </div>
            )}
            {step === 3 && (
              <div className={props.style.box__form}>
                <Form.Group>
                  <Form.Label>
                    3 - <FormattedMessage id="WriteYourPass" />
                  </Form.Label>
                  <FormattedMessage id="WriteYourPass">
                    {(msg) => (
                      <Form.Control
                        autoComplete="on"
                        type="password"
                        value={form.password}
                        onChange={(e) => handleChange(e)}
                        name={"password"}
                        placeholder=""
                        className={props.myStyle[`input_${props.cliente}`]}
                      />
                    )}
                  </FormattedMessage>
                </Form.Group>
              </div>
            )}

            <section className={props.style.box__footer}>
              {error && (
                <span className={props.style.error}>
                  <p>
                    <FormattedMessage id={error} />
                  </p>
                </span>
              )}
              <Button
                variant="main"
                type="submit"
                className={props.myStyle[`btn_${props.cliente}`]}
              >
                <div className={props.style.box__button}>
                  <span>
                    {!true ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Next" />
                    )}
                  </span>
                  <span>
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </span>
                </div>
              </Button>
              <div>
                <p>
                  <FormattedMessage id="PressEnterToContinue" />
                </p>
              </div>
            </section>
          </Form>
        </section>
      </section>
      <section className={props.style.container__arrow}>
        {step > 1 && step < 6 && (
          <span
            onClick={(e) => {
              backStep(e, step);
            }}
          >
            <i className="far fa-arrow-alt-circle-left"></i>
          </span>
        )}
        {step < 7 && (
          <span
            onClick={(e) => {
              nextStep(e, step);
            }}
          >
            <i className="far fa-arrow-alt-circle-right"></i>
          </span>
        )}
      </section>
    </>
  );
};

export default FormRegister;
