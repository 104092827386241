import React, { useEffect, useState } from "react";
import Header from "./Header";
import Tramo from "./Tramo";
import Days from "./Days";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import Empty from "./Empty";
import { FormattedMessage } from "react-intl";

import { getWeek, resetWeek } from "../../../actions/reservation";
import OneComponent from "./Component";

const Schedule = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [day, setDay] = useState([]);

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      const arr = [];
      props.data.datos.fechas_a_llamar.forEach((element, index) => {
        let query = { ...props.query };
        query.fecha_inicio = element;
        query.index = index;
        arr.push({ index, query, inicio: element });
      });
      setDay(arr);
    };

    if (props.query.empresa_id !== undefined) {
      callsAPI();
    }
  }, [dispatch, props.query]);

  useEffect(() => {
    return () => {
      dispatch(resetWeek());
    };
  }, [dispatch]);

  return (
    <>
      <Header
        showType={props.showType}
        fecha_inicio={props.query.fecha_inicio}
        next={props.next}
        back={props.back}
        data={props.data.datos.marco.eje_x}
        style={props.style}
        cliente={props.cliente}
        myStyle={props.myStyle}
      />
      <section
        className={`${props.style.container__schedule__body} ${
          props.showType === "show_day"
            ? props.style.container__schedule__body_day
            : ""
        }`}
      >
        <Tramo data={props.data.datos.marco.eje_y} />
        <>
          {day.map((o, i) => {
            if (
              o.inicio !== props.query.fecha_inicio &&
              props.showType === "show_day"
            ) {
              return null;
            }
            const currentDate = new Date();
            currentDate.setHours(0);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            currentDate.setMilliseconds(0);

            const partes = o.inicio.split("-");
            const dateComparate = new Date(partes[2], partes[1] - 1, partes[0]);

            const diferenciaMs = dateComparate - currentDate;
            const unDiaEnMs = 1000 * 60 * 60 * 24;
            const diferenciaDias = Math.floor(diferenciaMs / unDiaEnMs);

            if ((i === 5 || i === 6) && props.form.showWeekend === false) {
              return (
                <Empty key={i} id={i} data={props.data.datos.marco.eje_y} />
              );
            } else if (dateComparate < currentDate) {
              return (
                <Empty key={i} id={i} data={props.data.datos.marco.eje_y} />
              );
            } else if (
              diferenciaDias >= props.form.daysAllowed &&
              props.form.daysAllowed !== 0
            ) {
              return (
                <Empty
                  text={
                    <span style={{ color: "#f35e2d", textAlign: "center" }}>
                      <FormattedMessage id="YouCannotMakeAReservationForMoreThan" />{" "}
                      {props.form.daysAllowed} <FormattedMessage id="Days" />
                    </span>
                  }
                  key={i}
                  id={i}
                  data={props.data.datos.marco.eje_y}
                />
              );
            }

            return (
              <OneComponent
                key={i}
                message={props.message}
                handleSelect={props.handleSelect}
                data={props.data}
                style={props.style}
                {...o}
              />
            );
          })}
        </>
      </section>
    </>
  );
};

export default Schedule;
