import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import IMG from "../../assets/images/icono-lugar.png";

import {
  getHorarios,
  getHorariosValido,
  resetHorarios,
  resetHorariosValido,
  resetCalendario,
} from "../../actions/reservation";

/* Component */
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";
import Information from "./Information";
import ModalSchedule from "./ModalSchedule";

import { Form, Button } from "react-bootstrap";
//import TimePicker from "rc-time-picker";
import DatePicker from "react-datepicker";

import moment from "moment";
import _ from "lodash";

const Step5 = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState({});
  const [showType, setShowType] = useState(props.form.showType);

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getHorarios({
          empresa_id: props.form.empresa_id,
        })
      );
    };
    callsAPI();
  }, [dispatch]);

  /* Validation quantity select times */
  useEffect(() => {
    if (state.statusHorarios === 200) {
      let arr = [];
      state.dataHorarios.datos.forEach((element) => {
        if (element.usado_por_empresa) {
          arr.push(element);
        }
      });
      if (props.form.formato_tiempo === "") {
        props.handleChange({
          target: {
            name: "formato_tiempo",
            value: arr[0].clave.toString(),
          },
        });
      }
    }
  }, [state.loadingHorarios]);

  /* If the schedule es right yoy pass the next step */
  useEffect(() => {
    if (state.statusHorariosValido === 200) {
      if (state.dataHorariosValido.datos.disponibilidad) {
        props.nextStep();
        props.handleMemory({ name: "step7" });
        props.handleCount(1);
        /* Add image */
        props.handleChangeImage(
          {
            id: "img-123",
            img: IMG,
          },
          props.form.question + 1
        );
      } else {
        setModal(true);
      }
    }
  }, [state.loadingHorariosValido]);

  useEffect(() => {
    return () => {
      dispatch(resetHorariosValido());
      dispatch(resetHorarios());
      dispatch(resetCalendario());
    };
  }, [dispatch]);

  const handleTime = (modal) => {
    let someWrong = false;
    let obj = {};
    if (props.form.formato_tiempo === "") {
      someWrong = true;
      setError("CompleteTheRequiredFields");
    }

    /* minutes */
    if (props.form.formato_tiempo === "minutos") {
      if (
        !props.form.minuto_comienzo !== null &&
        props.form.minuto_comienzo !== null
      ) {
        let timeStart = new Date(
          "01/01/2007 " + props.form.minuto_comienzo.format("H:mm")
        );
        let timeEnd = new Date(
          "01/01/2007 " + props.form.minuto_final.format("H:mm")
        );

        if (
          parseInt(props.form.minuto_comienzo.format("Hmm"), 10) ||
          parseInt(props.form.minuto_final.format("Hmm"), 10)
        ) {
          //debe ser un mayor < en el if
          setError(false);
          let diff = Math.abs(timeStart - timeEnd);
          let minutes = Math.floor(diff / 1000 / 60);

          props.handleChange({
            target: { name: "duracion", value: minutes },
          });

          props.handleChange({
            target: {
              name: "inicio",
              value: `${props.form.minuto_comienzo.format("H:mm")}`,
            },
          });

          obj = {
            empresa_id: props.form.empresa_id,
            division_id: props.form.ciudad,
            subdivision_id: props.form.provincia,
            subsubdivision_id: props.form.comuna,
            arr_alternativa_id: props.form.atributos, // []
            fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
            hora_inicio: `${props.form.minuto_comienzo.format("H:mm")}`,
            clave_granularidad: "minutos",
            duracion: `${minutes}`,
          };
        } else {
          someWrong = true;
          setError("StartTimeMustBeLessThanEndTime");
        }
      } else {
        someWrong = true;
        setError("CompleteTheRequiredFields");
      }
      /* Horas */
    } else if (props.form.formato_tiempo === "horas") {
      if (!props.form.hora_comienzo !== null && props.form.hora_final !== "") {
        props.handleChange({
          target: { name: "duracion", value: props.form.hora_final },
        });

        props.handleChange({
          target: {
            name: "inicio",
            value: `${props.form.hora_comienzo.format("H:mm")}`,
          },
        });

        obj = {
          empresa_id: props.form.empresa_id,
          division_id: props.form.ciudad,
          subdivision_id: props.form.provincia,
          subsubdivision_id: props.form.comuna,
          arr_alternativa_id: props.form.atributos, // []
          fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
          hora_inicio: `${props.form.hora_comienzo.format("H:mm")}`,
          clave_granularidad: "horas",
          duracion: `${props.form.hora_final}`,
        };
        setError(false);
      } else {
        someWrong = true;
        setError("CompleteTheRequiredFields");
      }
    } else if (props.form.formato_tiempo === "dias") {
      const date1 = props.form.fecha_inicio;
      const date2 = props.form.dias_comienzo;

      if (date1 === null || date2 === "") {
        someWrong = true;
        setError("CompleteTheRequiredFields");
      } else {
        props.handleChange({
          target: { name: "duracion", value: date2 },
        });

        props.handleChange({
          target: {
            name: "inicio",
            value: `12:00`,
          },
        });

        obj = {
          empresa_id: props.form.empresa_id,
          division_id: props.form.ciudad,
          subdivision_id: props.form.provincia,
          subsubdivision_id: props.form.comuna,
          arr_alternativa_id: props.form.atributos, // []
          fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
          hora_inicio: `12:00`,
          clave_granularidad: "dias",
          duracion: `${date2}`,
        };
      }
    } else if (props.form.formato_tiempo === "semanas") {
      if (props.form.semana_comienzo) {
        props.handleChange({
          target: { name: "duracion", value: props.form.semana_comienzo },
        });

        props.handleChange({
          target: {
            name: "inicio",
            value: `12:00`,
          },
        });

        obj = {
          empresa_id: props.form.empresa_id,
          division_id: props.form.ciudad,
          subdivision_id: props.form.provincia,
          subsubdivision_id: props.form.comuna,
          arr_alternativa_id: props.form.atributos, // []
          fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
          hora_inicio: `12:00`,
          clave_granularidad: "semanas",
          duracion: `${props.form.semana_comienzo}`,
        };
      } else {
        someWrong = true;
        setError("CompleteTheRequiredFields");
      }
    }

    if (!someWrong) {
      setQuery(obj);
      if (modal) {
        setModal(true);
      } else {
        dispatch(getHorariosValido(obj));
      }
    }
  };

  if (state.loadingHorarios) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusHorarios === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusHorarios === 200
  ) {
    if (!state.dataHorarios.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <>
      <section className={props.style.container__content}>
        <Information
          cliente={props.cliente}
          myStyle={props.myStyle}
          data={props.form.imagenes}
          style={props.style}
        />
        <ModalSchedule
          showType={showType}
          form={props.form}
          cliente={props.cliente}
          myStyle={props.myStyle}
          resetCalendario={resetCalendario}
          query={query}
          style={props.style}
          modal={modal}
          handleChange={props.handleChange}
          setQuery={setQuery}
          getHorariosValido={getHorariosValido}
          setModal={setModal}
        />
        <h2 className={props.style.start}>
          <FormattedMessage id="DayAndTimeOfReservation" />
        </h2>
        <p>
          <FormattedMessage id="SelectTheDayAndTimeYouWantToReserve" />
        </p>
        <div className={`${props.style.box__footer}`}>
          <div className={props.style.box__footer__date}>
            <p>
              <FormattedMessage id="SearchBy" />:
            </p>
            <div className={props.style.box__tabs}>
              <ul>
                <li
                  onClick={() => setShowType("show_day")}
                  className={
                    showType === "show_day"
                      ? `${props.myStyle[`list_${props.cliente}`]}`
                      : `${props.style.box__selbutton__active} ${
                          props.myStyle[`list_active_${props.cliente}`]
                        }`
                  }
                >
                  <FormattedMessage id="Day" />
                </li>
                <li
                  onClick={() => setShowType("show_week")}
                  className={
                    showType === "show_week"
                      ? `${props.myStyle[`list_${props.cliente}`]}`
                      : `${props.style.box__selbutton__active} ${
                          props.myStyle[`list_active_${props.cliente}`]
                        }`
                  }
                >
                  <FormattedMessage id="Week" />
                </li>
              </ul>
            </div>
            <div
              className={`${props.style.box__footer__inputdate} ${
                props.myStyle[`box_${props.cliente}`]
              }`}
            >
              <i className="far fa-calendar-alt"></i>
              <DatePicker
                selected={props.form.fecha_inicio}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  props.handleChange({
                    target: { name: "fecha_inicio", value: date },
                  });
                }}
              />
            </div>
            {_.filter(state.dataHorarios.datos, { usado_por_empresa: true })
              .length > 1 && (
              <div
                className={`${props.style.box__footer__select} ${props.style.box__footer__selecthorary}`}
              >
                <Form.Control
                  as="select"
                  value={props.form.formato_tiempo}
                  name="formato_tiempo"
                  onChange={(event) => {
                    props.handleChange(event);
                    setError(false);
                  }}
                >
                  {state.dataHorarios.datos.map((item, i) => {
                    if (item.usado_por_empresa) {
                      return (
                        <option key={i} value={item.clave}>
                          {item.nombre}
                        </option>
                      );
                    }
                  })}
                </Form.Control>
              </div>
            )}
            {/*         <div className={props.style.box__footer__seeschedule}>
              <span
                onClick={() => {
                  if (!state.loadingHorariosValido) {
                    handleTime(true);
                  }
                }}
              >
                <FormattedMessage id="SeeSchedule" />
              </span>
            </div> */}
            {/*      {props.form.formato_tiempo === "minutos" && (
              <>
                <div className={props.style.box__footer__inputtime}>
                  <i className="far fa-clock"></i>
                  <TimePicker
                    showSecond={false}
                    value={props.form.minuto_comienzo}
                    className="xxx"
                    onChange={(value) => {
                      setError(false);
                      props.handleChange({
                        target: { name: "minuto_comienzo", value },
                      });
                    }}
                    inputReadOnly
                  />
                </div>
                <div className={props.style.box__footer__inputtime}>
                  <i className="far fa-clock"></i>
                  <TimePicker
                    showSecond={false}
                    value={props.form.minuto_final}
                    className="xxx"
                    onChange={(value) => {
                      setError(false);
                      props.handleChange({
                        target: { name: "minuto_final", value },
                      });
                    }}
                    showSecond={false}
                    inputReadOnly
                  />
                </div>
              </>
            )}
            {props.form.formato_tiempo === "horas" && (
              <>
                <div className={props.style.box__footer__inputtime}>
                  <i className="far fa-clock"></i>
                  <TimePicker
                    showSecond={false}
                    value={props.form.hora_comienzo}
                    className="xxx"
                    onChange={(value) => {
                      props.handleChange({
                        target: { name: "hora_comienzo", value },
                      });
                    }}
                    showSecond={false}
                    inputReadOnly
                  />
                </div>
                <div
                  className={`${props.style.box__footer__select} ${props.style.box__footer__selectsmall}`}
                >
                  <Form.Control
                    as="select"
                    value={props.form.hora_final}
                    name="hora_final"
                    onChange={props.handleChange}
                  >
                    <FormattedMessage id="Select">
                      {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <option value="1">1 hr</option>
                    <option value="2">2 hrs</option>
                    <option value="3">3 hrs</option>
                    <option value="4">2 hrs</option>
                    <option value="5">5 hrs</option>
                    <option value="6">6 hrs</option>
                    <option value="7">7 hrs</option>
                    <option value="8">8 hrs</option>
                    <option value="9">9 hrs</option>
                    <option value="10">10 hrs</option>
                    <option value="11">11 hrs</option>
                    <option value="12">12 hrs</option>
                    <option value="12">13 hrs</option>
                    <option value="14">14 hrs</option>
                    <option value="15">15 hrs</option>
                  </Form.Control>
                </div>
              </>
            )}
            {props.form.formato_tiempo === "dias" && (
              <>
                <div
                  className={`${props.style.box__footer__select} ${props.style.box__footer__selecthorary}`}
                >
                  <Form.Control
                    value={props.form.dias_comienzo}
                    name="dias_comienzo"
                    type="number"
                    onChange={props.handleChange}
                  />
                </div>
              </>
            )}
            {props.form.formato_tiempo === "semanas" && (
              <div
                className={`${props.style.box__footer__select} ${props.style.box__footer__selecthorary}`}
              >
                <Form.Control
                  as="select"
                  value={props.form.semana_comienzo}
                  name="semana_comienzo"
                  onChange={props.handleChange}
                >
                  <FormattedMessage id="Select">
                    {(message) => <option value="">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="Week">
                    {(message) => <option value="1">1 {message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="Weeks">
                    {(message) => <option value="2">2 {message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="Weeks">
                    {(message) => <option value="3">3 {message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="Weeks">
                    {(message) => <option value="4">4 {message}</option>}
                  </FormattedMessage>
                </Form.Control>
              </div>
            )} */}
            <div className={props.style.box__footer__buttontime}>
              {error && (
                <span className={props.style.box__footer__buttontimeerror}>
                  <FormattedMessage id={error} />
                </span>
              )}
              {!props.form.formato_tiempo !== "" && (
                <Button
                  variant="main"
                  className={props.myStyle[`btn_${props.cliente}`]}
                  onClick={() => {
                    if (!state.loadingHorariosValido) {
                      handleTime(true);
                    }
                  }}
                >
                  <div className={props.style.box__buttontime}>
                    <span>
                      {state.loadingHorariosValido ? (
                        <FormattedMessage id="Loading" />
                      ) : (
                        <FormattedMessage id="SeeSchedule" />
                      )}
                    </span>
                    <span>
                      <i className="far fa-arrow-alt-circle-right"></i>
                    </span>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className={props.style.box__arrow}>
        <span
          onClick={() => {
            props.backStep();
          }}
        >
          <i className="far fa-arrow-alt-circle-left"></i>
        </span>
        {props.memory[`step${props.step + 1}`] && (
          <span
            onClick={() => {
              handleTime();
            }}
          >
            <i className="far fa-arrow-alt-circle-right"></i>
          </span>
        )}
      </div>
    </>
  );
};

export default Step5;
