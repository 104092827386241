import React from "react";
import { FormattedMessage } from "react-intl";

const Step1 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>Seguridad y Almacenamiento de los Datos Personales</h2>
      <p>
        Kantoor tratará los Datos Personales de sus Usuarios de conformidad a lo
        dispuesto en la Ley Nº 19.628 sobre Protección de la Vida Privada y
        respetando siempre el pleno ejercicio de los derechos fundamentales de
        los Usuarios.
      </p>
      <p>
        Kantoor considera que los Datos Personales y otra Información Personal
        de sus Usuarios como un activo que debe ser protegido de cualquier
        pérdida o acceso no autorizado, por lo que tomará todas las precauciones
        para resguardarla mediante la implementación de mecanismos de seguridad
        informática eficaces, tales como cortafuegos (“firewalls”) y Secure
        Socket Layers (“SSL”).Kantoor se obliga a cumplir con toda la normativa
        aplicable en materia de medidas de seguridad aplicables a los Datos
        Personales, utilizando los estándares de la industria entre materia de
        protección y confidencialidad de su Información Personal. No obstante, y
        considerando que internet es un sistema abierto, de acceso
        público,Kantoor no puede garantizar que terceros no autorizados no
        puedan eventualmente superar las medidas de seguridad y utilizar la
        Información Personal y/o Datos Personales del Usuario en forma indebida.
        Todos los Datos Personales serán almacenados en un archivo o soporte
        automatizado de datos personales.
      </p>
      <p>
        Kantoor no se hace responsable por interceptaciones ilegales o violación
        de sus sistemas o bases de datos por parte de personas no autorizadas.
        Kantoor, tampoco se hace responsable por la indebida utilización de la
        información obtenida por esos medios.
      </p>
      <p>
        Kantoor no venderá, arrendará ni compartirá los Datos Personales u otra
        Información Personal de sus Usuarios, excepto en las formas establecidas
        en estas Políticas de Privacidad.
      </p>
      <p>
        Kantoor conservará los Datos Personales y la Información Personal de los
        Usuarios por un periodo de cinco (5) años, a fin de resguardarse y poder
        resolver posibles disputas o reclamos, detectar problemas o incidencias
        y solucionarlos.
      </p>
    </section>
  );
};

export default Step1;
