import React from "react";

/* Components */
import Content from "../../components/reservation/Content";

/* Style */
import Style from "../../assets/styles/Reservation.module.scss";

const Reservation = (props) => {
  return (
    <>
      <Content
        countDeseable={props.countDeseable}
        deseable={props.deseable}
        style={Style}
      />
    </>
  );
};

export default Reservation;
