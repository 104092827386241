import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Config from "../../server/controllers/config";

/* Actions */
import {
  getCompanyContrato,
  getCompanySinContrato,
  resetCompanyContrato,
  resetCompanySinContrato,
} from "../../actions/reservation";

/* Components */
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";

const StepCompany = (props) => {
  /* If  they are closed companies or open companies */
  const [list, setList] = useState(true);

  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  /* Get companies */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getCompanyContrato({}));
      dispatch(getCompanySinContrato({}));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    if (
      state.statusCompanyContrato === 200 &&
      state.statusCompanySinContrato === 200
    ) {
      const total1 = state.dataCompanyContrato.datos.length;
      const total2 = state.dataCompanySinContrato.datos.length;
      const total = total1 + total2;

      if (total === 1) {
        if (total1 === 1) {
          const texto = state.dataCompanyContrato.datos[0].uid;
          const q_dias_permitidos_a_futuro =
            state.dataCompanyContrato.datos[0].q_dias_permitidos_a_futuro;
          const regex = /\[(.*?)\]/;
          const matches = texto.match(regex); // "[ show-weekend]"

          const regexDay = /--(.*?)--/;
          const matchesDay = texto.match(regexDay); // "--show_day--"

          const regex1 = /\(\((.*?)\)\)/;
          const resultado = regex1.exec(texto); // "((0,3))"

          props.handleChange({
            target: {
              name: "empresa_id",
              value: state.dataCompanyContrato.datos[0].id,
            },
          });

          props.handleChange({
            target: {
              name: "daysAllowed",
              value: q_dias_permitidos_a_futuro,
            },
          });

          if (matchesDay && matchesDay.length > 1) {
            props.handleChange({
              target: {
                name: "showType",
                value: matchesDay[1], // "show-weekend"
              },
            });
          }

          if (matches) {
            props.handleChange({
              target: {
                name: "showWeekend",
                value: matches[1], // "show-weekend"
              },
            });
          }

          if (resultado) {
            props.handleChange({
              target: {
                name: "filterType",
                value: resultado[1], // filteType
              },
            });
          }

          if (props.direction === "right") {
            props.nextStep();
            props.handleMemory({ name: "step3" });
          } else {
            props.backStep();
          }
        } else {
          props.handleChange({
            target: {
              name: "empresa_id",
              value: state.dataCompanySinContrato.datos[0].id,
            },
          });
          if (props.direction === "right") {
            props.nextStep();
            props.handleMemory({ name: "step3" });
          } else {
            props.backStep();
          }
        }
      }
    }
  }, [state]);

  /* Reset  companies */
  useEffect(() => {
    return () => {
      dispatch(resetCompanyContrato());
      dispatch(resetCompanySinContrato());
    };
  }, [dispatch]);

  /* Condition to filter */
  let data;
  if (list) {
    data = state.dataCompanyContrato;
  } else {
    data = state.dataCompanySinContrato;
  }

  if (state.loadingCompanyContrato || state.loadingCompanySinContrato) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusCompanyContrato === 501 ||
    state.statusCompanySinContrato === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusCompanyContrato === 200 ||
    state.statusCompanySinContrato === 200
  ) {
    if (!data.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <section className={props.style.container__content}>
      <h2 className={props.style.start}>
        <FormattedMessage id="SelectCompany" />
      </h2>
      <p>
        <FormattedMessage id="SelectTheCompanyYouWantToRent" />
      </p>
      <div className={`${props.style.box__footer}`}>
        <div className={props.style.box__tabs}>
          <ul>
            <li
              onClick={() => setList(true)}
              className={
                list
                  ? `${props.myStyle[`list_${props.cliente}`]}`
                  : `${props.style.box__selbutton__active} ${
                      props.myStyle[`list_active_${props.cliente}`]
                    }`
              }
            >
              <FormattedMessage id="Contract" />
            </li>
            <li
              onClick={() => setList(false)}
              className={
                !list
                  ? `${props.myStyle[`list_${props.cliente}`]}`
                  : `${props.style.box__selbutton__active} ${
                      props.myStyle[`list_active_${props.cliente}`]
                    }`
              }
            >
              <FormattedMessage id="Opened" />
            </li>
          </ul>
        </div>
        <section className={`${props.style.box__img}`}>
          {data.datos.map((item) => (
            <article
              key={item.id}
              onClick={() => {
                props.handleChange({
                  target: { name: "empresa_id", value: item.id },
                });
                props.handleCount(1);
                props.handleMemory({ name: "step3" });
                props.nextStep();
              }}
            >
              <span
                className={`${props.style.box__imgheart} ${props.style.box__hearthactive}`}
              >
                <i className="fas fa-heart"></i>
              </span>
              <div className={`${props.style.box__imgelement}`}>
                <img src={`${Config.api}${item.avatar3.archivo_imagen}`} />
              </div>
              <h2>{item.nombre}</h2>
            </article>
          ))}
        </section>
      </div>
    </section>
  );
};

export default StepCompany;
