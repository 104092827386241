import {
  GET_LOGIN_CHECK_INIT,
  GET_LOGIN_CHECK_SUCCESS,
  GET_LOGIN_CHECK_FAILURE,
  RESET_LOGIN_CHECK,
  GET_RESERVA_CHECK_INIT,
  GET_RESERVA_CHECK_SUCCESS,
  GET_RESERVA_CHECK_FAILURE,
  RESET_RESERVA_CHECK,
  GET_CHECK_INIT,
  GET_CHECK_SUCCESS,
  GET_CHECK_FAILURE,
  RESET_CHECK,
  GET_COVID_INIT,
  GET_COVID_SUCCESS,
  GET_COVID_FAILURE,
  RESET_COVID,
  GET_COVID_REGISTER_INIT,
  GET_COVID_REGISTER_SUCCESS,
  GET_COVID_REGISTER_FAILURE,
  RESET_COVID_REGISTER,
  GET_COVID_REGISTER_UPDATE_INIT,
  GET_COVID_REGISTER_UPDATE_SUCCESS,
  GET_COVID_REGISTER_UPDATE_FAILURE,
  RESET_COVID_REGISTER_UPDATE,
  GET_JORNADA_INIT,
  GET_JORNADA_SUCCESS,
  GET_JORNADA_FAILURE,
  RESET_JORNADA,
} from "./types";
import API from "./api";

export function resetLoginCheck() {
  return {
    type: RESET_LOGIN_CHECK,
  };
}

function getLoginSuccess(data, info) {
  return {
    type: GET_LOGIN_CHECK_SUCCESS,
    payload: data,
    info,
  };
}

function getLoginFailure(error) {
  console.log(error);
  return {
    type: GET_LOGIN_CHECK_FAILURE,
    payload: error,
  };
}

function getLoginInit() {
  return {
    type: GET_LOGIN_CHECK_INIT,
  };
}

export function getLoginCheck(data) {
  return async (dispatch) => {
    dispatch(getLoginInit());
    try {
      const resp = await API.data.getLogin(data);
      return dispatch(getLoginSuccess(resp, data));
    } catch (error) {
      return dispatch(getLoginFailure(error));
    }
  };
}

/* Get reserva */
export function resetReservaCheck() {
  return {
    type: RESET_RESERVA_CHECK,
  };
}

function getReservaSuccess(data, info) {
  return {
    type: GET_RESERVA_CHECK_SUCCESS,
    payload: data,
    info,
  };
}

function getReservaFailure(error) {
  console.log(error);
  return {
    type: GET_RESERVA_CHECK_FAILURE,
    payload: error,
  };
}

function getReservaInit() {
  return {
    type: GET_RESERVA_CHECK_INIT,
  };
}

export function getReservaCheck(data) {
  return async (dispatch) => {
    dispatch(getReservaInit());
    try {
      const resp = await API.data.getReserva(data);
      return dispatch(getReservaSuccess(resp, data));
    } catch (error) {
      return dispatch(getReservaFailure(error));
    }
  };
}

/* Get post checkin checkout*/
export function resetCheck() {
  return {
    type: RESET_CHECK,
  };
}

function getCheckSuccess(data, info) {
  return {
    type: GET_CHECK_SUCCESS,
    payload: data,
    info,
  };
}

function getCheckFailure(error) {
  console.log(error);
  return {
    type: GET_CHECK_FAILURE,
    payload: error,
  };
}

function getCheckInit() {
  return {
    type: GET_CHECK_INIT,
  };
}

export function getCheck(data) {
  return async (dispatch) => {
    dispatch(getCheckInit());
    try {
      const resp = await API.data.getCheck(data);
      return dispatch(getCheckSuccess(resp, data));
    } catch (error) {
      return dispatch(getCheckFailure(error));
    }
  };
}

/* Get covid */
export function resetCovid() {
  return {
    type: RESET_COVID,
  };
}

function getCovidSuccess(data, info) {
  return {
    type: GET_COVID_SUCCESS,
    payload: data,
    info,
  };
}

function getCovidFailure(error) {
  console.log(error);
  return {
    type: GET_COVID_FAILURE,
    payload: error,
  };
}

function getCovidInit() {
  return {
    type: GET_COVID_INIT,
  };
}

export function getCovid(data) {
  return async (dispatch) => {
    dispatch(getCovidInit());
    try {
      const resp = await API.data.getCovid(data);
      return dispatch(getCovidSuccess(resp, data));
    } catch (error) {
      return dispatch(getCovidFailure(error));
    }
  };
}

/* Get covid register */
export function resetCovidRegister() {
  return {
    type: RESET_COVID_REGISTER,
  };
}

function getCovidRegisterSuccess(data, info) {
  return {
    type: GET_COVID_REGISTER_SUCCESS,
    payload: data,
    info,
  };
}

function getCovidRegisterFailure(error) {
  console.log(error);
  return {
    type: GET_COVID_REGISTER_FAILURE,
    payload: error,
  };
}

function getCovidRegisterInit() {
  return {
    type: GET_COVID_REGISTER_INIT,
  };
}

export function getCovidRegister(data) {
  return async (dispatch) => {
    dispatch(getCovidRegisterInit());
    try {
      const resp = await API.data.getCovidRegister(data);
      return dispatch(getCovidRegisterSuccess(resp, data));
    } catch (error) {
      return dispatch(getCovidRegisterFailure(error));
    }
  };
}

/* Get covid register update */
export function resetCovidRegisterUpdate() {
  return {
    type: RESET_COVID_REGISTER_UPDATE,
  };
}

function getCovidRegisterUpdateSuccess(data, info) {
  return {
    type: GET_COVID_REGISTER_UPDATE_SUCCESS,
    payload: data,
    info,
  };
}

function getCovidRegisterUpdateFailure(error) {
  console.log(error);
  return {
    type: GET_COVID_REGISTER_UPDATE_FAILURE,
    payload: error,
  };
}

function getCovidRegisterUpdateInit() {
  return {
    type: GET_COVID_REGISTER_UPDATE_INIT,
  };
}

export function getCovidRegisterUpdate(data) {
  return async (dispatch) => {
    dispatch(getCovidRegisterUpdateInit());
    try {
      const resp = await API.data.getCovidRegisterUpdate(data);
      return dispatch(getCovidRegisterUpdateSuccess(resp, data));
    } catch (error) {
      return dispatch(getCovidRegisterUpdateFailure(error));
    }
  };
}

/* Get jornada */
export function resetJornada() {
  return {
    type: RESET_JORNADA,
  };
}

function getJornadaSuccess(data, info) {
  return {
    type: GET_JORNADA_SUCCESS,
    payload: data,
    info,
  };
}

function getJornadaFailure(error) {
  console.log(error);
  return {
    type: GET_JORNADA_FAILURE,
    payload: error,
  };
}

function getJornadaInit() {
  return {
    type: GET_JORNADA_INIT,
  };
}

export function getJornada(data) {
  return async (dispatch) => {
    dispatch(getJornadaInit());
    try {
      const resp = await API.data.getJornada(data);
      return dispatch(getJornadaSuccess(resp, data));
    } catch (error) {
      return dispatch(getJornadaFailure(error));
    }
  };
}
