import {
  GET_HISTORY_INIT,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  RESET_HISTORY,
  GET_ANULAR_INIT,
  GET_ANULAR_SUCCESS,
  GET_ANULAR_FAILURE,
  RESET_ANULAR,
  GET_NIVELES_INIT,
  GET_NIVELES_SUCCESS,
  GET_NIVELES_FAILURE,
  RESET_NIVELES,
  GET_NOTA_INIT,
  GET_NOTA_SUCCESS,
  GET_NOTA_FAILURE,
  RESET_NOTA,
  GET_CALENDARTWO_INIT,
  GET_CALENDARTWO_SUCCESS,
  GET_CALENDARTWO_FAILURE,
  RESET_CALENDARTWO,
} from "./types";
import API from "./api";

/* Resets */

export function resetHistory() {
  return {
    type: RESET_HISTORY,
  };
}

/* Get history (Async) */

function getHistorySuccess(data) {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: data,
  };
}

function getHistoryFailure(error) {
  console.log(error);
  return {
    type: GET_HISTORY_FAILURE,
    payload: error,
  };
}

function getHistoryInit(data) {
  return {
    type: GET_HISTORY_INIT,
    payload: data.loading,
  };
}

export function getHistory(data) {
  return async (dispatch) => {
    dispatch(getHistoryInit(data));
    try {
      const resp = await API.data.getHistory(data);
      return dispatch(getHistorySuccess(resp));
    } catch (error) {
      return dispatch(getHistoryFailure(error));
    }
  };
}

export function resetAnular() {
  return {
    type: RESET_ANULAR,
  };
}

/* Get history anular (Async) */

function getAnularSuccess(data) {
  return {
    type: GET_ANULAR_SUCCESS,
    payload: data,
  };
}

function getAnularFailure(error) {
  console.log(error);
  return {
    type: GET_ANULAR_FAILURE,
    payload: error,
  };
}

function getAnularInit(data) {
  return {
    type: GET_ANULAR_INIT,
    payload: data.loading,
  };
}

export function getAnular(data) {
  return async (dispatch) => {
    dispatch(getAnularInit(data));
    try {
      const resp = await API.data.getAnular(data);
      return dispatch(getAnularSuccess(resp));
    } catch (error) {
      return dispatch(getAnularFailure(error));
    }
  };
}

export function resetNiveles() {
  return {
    type: RESET_NIVELES,
  };
}

/* Get Niveles(Async) */

function getNivelesSuccess(data) {
  return {
    type: GET_NIVELES_SUCCESS,
    payload: data,
  };
}

function getNivelesFailure(error) {
  console.log(error);
  return {
    type: GET_NIVELES_FAILURE,
    payload: error,
  };
}

function getNivelesInit(data) {
  return {
    type: GET_NIVELES_INIT,
    payload: data.loading,
  };
}

export function getNiveles(data) {
  return async (dispatch) => {
    dispatch(getNivelesInit(data));
    try {
      const resp = await API.data.getNiveles(data);
      return dispatch(getNivelesSuccess(resp));
    } catch (error) {
      return dispatch(getNivelesFailure(error));
    }
  };
}

export function resetNota() {
  return {
    type: RESET_NOTA,
  };
}

/* Get Nota(Async) */

function getNotaSuccess(data) {
  return {
    type: GET_NOTA_SUCCESS,
    payload: data,
  };
}

function getNotaFailure(error) {
  console.log(error);
  return {
    type: GET_NOTA_FAILURE,
    payload: error,
  };
}

function getNotaInit(data) {
  return {
    type: GET_NOTA_INIT,
    payload: data.loading,
  };
}

export function getNota(data) {
  return async (dispatch) => {
    dispatch(getNotaInit(data));
    try {
      const resp = await API.data.getNota(data);
      return dispatch(getNotaSuccess(resp));
    } catch (error) {
      return dispatch(getNotaFailure(error));
    }
  };
}

export function resetCalendarTwo() {
  return {
    type: RESET_CALENDARTWO,
  };
}

/* Get CalendarTwo(Async) */

function getCalendarTwoSuccess(data) {
  return {
    type: GET_CALENDARTWO_SUCCESS,
    payload: data,
  };
}

function getCalendarTwoFailure(error) {
  console.log(error);
  return {
    type: GET_CALENDARTWO_FAILURE,
    payload: error,
  };
}

function getCalendarTwoInit(data) {
  return {
    type: GET_CALENDARTWO_INIT,
    payload: data.loading,
  };
}

export function getCalendarTwo(data) {
  return async (dispatch) => {
    dispatch(getCalendarTwoInit(data));
    try {
      const resp = await API.data.getCalendarTwo(data);
      return dispatch(getCalendarTwoSuccess(resp));
    } catch (error) {
      return dispatch(getCalendarTwoFailure(error));
    }
  };
}
