import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import Config from "../../server/controllers/config";

/* Actions */
import { getReserva, resetReserva } from "../../actions/reservation";

import companies from "../../containers/config";
import validateCompanies from "../../containers/config/functions";

/* Component */
import Loading from "./Loading";
import Information from "./Information";

const StepSummary = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [code, setCode] = useState(0);
  const [type, setType] = useState("1");
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (state.statusReserva === 200) {
      if (state.dataReserva.ejecucion.estado) {
        props.nextStep();
        props.setFinalBooking([
          state.dataReserva.datos.reservas_relizadas,
          state.dataReserva.datos.reservas_no_permitida,
        ]);
        props.handleMemory({ name: "step11" });
        props.handleCount(1);
      } else {
        if (state.dataReserva.ejecucion.codigo === 111) {
          setError(true);
          setCode(111);
        }
        if (state.dataReserva.ejecucion.codigo === 177) {
          setError(true);
          setCode(117);
        }
      }
    }
  }, [state.loadingReserva]);

  const updateData = (arreglo) => {
    let primerBooking = arreglo[0].booking;

    for (let i = 0; i < arreglo.length; i++) {
      if (!arreglo[i].email) {
        arreglo[i].email = `invitado.${i + 1}@kantoorl.cl`;
        arreglo[i].guest = `invitado ${i + 1}`;
      }

      if (!arreglo[i].booking) {
        arreglo[i].booking = primerBooking;
      }
    }
    return arreglo;
  };

  useEffect(() => {
    props.handleChange({
      target: { name: "emails", value: updateData(props.form.emails) },
    });
  }, []);

  const filterNumbersList = useMemo(() => {
    const firstMatch = props.form.nodos_final.find((item) => {
      const regex = /\(\(\(\(([^\)]+)\)\)\)\)/;
      const match = item.label_corto.match(regex);
      return match && match[1];
    });

    if (firstMatch) {
      const regex = /\(\(\(\(([^\)]+)\)\)\)\)/;
      const match = firstMatch.label_corto.match(regex);
      if (match && match[1]) {
        return match[1].split(",").map(Number);
      }
    }

    return [];
  }, [props.form.nodos_final]);

  const filteredComponents = useMemo(() => {
    const filterTypeSet = new Set(props.form.filterType);

    if (filterNumbersList.length === 0) {
      // Renderizar todos los componentes si filterNumbersList está vacío
      return [1, 2, 3, 4, 5].map((num) => {
        switch (num) {
          case 1:
            return filterTypeSet.has("1") ? (
              <FormattedMessage key={1} id="DoesNotRepeat">
                {(msg) => <option value="1">{msg}</option>}
              </FormattedMessage>
            ) : null;
          case 2:
            return filterTypeSet.has("2") ? (
              <FormattedMessage key={2} id="SameDayWeekly">
                {(msg) => <option value="2">{msg}</option>}
              </FormattedMessage>
            ) : null;
          case 3:
            return filterTypeSet.has("3") ? (
              <FormattedMessage key={3} id="EveryWeekday">
                {(msg) => <option value="3">{msg}</option>}
              </FormattedMessage>
            ) : null;
          case 4:
            return filterTypeSet.has("4") ? (
              <FormattedMessage key={4} id="EveryMonthday">
                {(msg) => <option value="4">{msg}</option>}
              </FormattedMessage>
            ) : null;
          case 5:
            return filterTypeSet.has("5") ? (
              <FormattedMessage key={5} id="Personalized">
                {(msg) => <option value="5">{msg}</option>}
              </FormattedMessage>
            ) : null;
          default:
            return null;
        }
      });
    }

    return filterNumbersList.map((num) => {
      switch (num) {
        case 1:
          return filterTypeSet.has("1") ? (
            <FormattedMessage key={1} id="DoesNotRepeat">
              {(msg) => <option value="1">{msg}</option>}
            </FormattedMessage>
          ) : null;
        case 2:
          return filterTypeSet.has("2") ? (
            <FormattedMessage key={2} id="SameDayWeekly">
              {(msg) => <option value="2">{msg}</option>}
            </FormattedMessage>
          ) : null;
        case 3:
          return filterTypeSet.has("3") ? (
            <FormattedMessage key={3} id="EveryWeekday">
              {(msg) => <option value="3">{msg}</option>}
            </FormattedMessage>
          ) : null;
        case 4:
          return filterTypeSet.has("4") ? (
            <FormattedMessage key={4} id="EveryMonthday">
              {(msg) => <option value="4">{msg}</option>}
            </FormattedMessage>
          ) : null;
        case 5:
          return filterTypeSet.has("5") ? (
            <FormattedMessage key={5} id="Personalized">
              {(msg) => <option value="5">{msg}</option>}
            </FormattedMessage>
          ) : null;
        default:
          return null;
      }
    });
  }, [filterNumbersList, props.form.filterType]);

  const handleSubmit = () => {
    const reservas = [];
    const editables = [];

    props.form.nodos_final.forEach((element) => {
      let obj = {
        empresa_id: props.form.empresa_id,
        edi_division_id: null,
        clave_granularidad: props.form.formato_tiempo,
        fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
        hora_inicio: props.form.inicio,
        duracion: parseInt(props.form.duracion, 10),
        arr_recursos_escasos: [],
        arr_invitados: [],
        tipo_iteracion: parseInt(type, 10),
      };

      if (props.form.formato_tiempo === "horas") {
        obj.duracion = props.form.duracion * 60;
        obj.clave_granularidad = "minutos";
      }

      if (type === "5") {
        obj.tipo_iteracion = 2;
        obj.arr_numero_dias_semana = days;
      }

      obj.edi_division_id = element.id;

      let index = props.form.emails.filter(
        (x) => x.booking.toString() === element.id.toString()
      );

      index.forEach((x) => {
        obj.arr_invitados.push({ email: x.email });

        let result = [];
        for (var i in x) {
          if (i.includes("recurso")) result.push([i, x[i]]);
        }

        result.forEach((x1) => {
          let recursoNum = x1[0].split("-");
          if (x1[1] !== "" && parseInt(x1[1], 10) > 0) {
            obj.arr_recursos_escasos.push({
              recurso_id: parseInt(recursoNum[1], 10),
              cantidad_solicitada: parseInt(x1[1], 10),
            });
          }
        });

        /* Campo editable */
        for (var i in x) {
          if (i.includes("edititem")) {
            if (x.editable !== "") {
              x[i].nombre = x.editable;
              editables.push(x[i]);
            }
          }
        }
      });
      reservas.push(obj);
    });

    dispatch(
      getReserva({
        reservas: reservas,
        editables: editables,
        disponibility: true /* Just in casa edit reservation (defautl true) */,
      })
    );
  };

  /* Reset  recursos */
  /*   useEffect(() => {
    return () => {
      dispatch(resetReserva());
    };
  }, [dispatch]); */

  let company = false;
  if (typeof localStorage !== "undefined") {
    company = localStorage.getItem("login_company");
  }

  const handleDays = (id) => {
    const arr = [...days];
    const index = arr.indexOf(id);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }

    setDays(arr);
  };

  if (state.loadingReserva) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  }

  return (
    <section className={props.style.container__content}>
      <Information
        cliente={props.cliente}
        myStyle={props.myStyle}
        data={props.form.imagenes}
        style={props.style}
      />
      {!error && (
        <React.Fragment>
          <h2 className={props.style.start}>
            <FormattedMessage id="Summary" />
          </h2>

          <section className={props.style.filter__type}>
            <div>
              <FormattedMessage id="RepeatOptions" />
            </div>
            <div>
              <Form.Control
                value={type}
                onChange={(e) => setType(e.target.value)}
                as="select"
              >
                {filteredComponents}
              </Form.Control>
            </div>
          </section>
          {type === "5" && (
            <section className={props.style.filter__day}>
              <span>
                <FormattedMessage id="MessagePersonalized" />
              </span>
              <div
                onClick={() => handleDays(1)}
                className={days.includes(1) ? props.style.yellow : ""}
              >
                <FormattedMessage id="Day1" />
              </div>
              <div
                onClick={() => handleDays(2)}
                className={days.includes(2) ? props.style.yellow : ""}
              >
                <FormattedMessage id="Day2" />
              </div>
              <div
                onClick={() => handleDays(3)}
                className={days.includes(3) ? props.style.yellow : ""}
              >
                <FormattedMessage id="Day3" />
              </div>
              <div
                onClick={() => handleDays(4)}
                className={days.includes(4) ? props.style.yellow : ""}
              >
                <FormattedMessage id="Day4" />
              </div>
              <div
                onClick={() => handleDays(5)}
                className={days.includes(5) ? props.style.yellow : ""}
              >
                <FormattedMessage id="Day5" />
              </div>
            </section>
          )}

          <div className={`${props.style.box__footer}`}>
            <section className={`${props.style.box__img}`}>
              {props.form.emails.map((item) => {
                let index = props.form.nodos_final.findIndex(
                  (x) => x.id.toString() === item.booking
                );

                let img = false;
                if (index >= 0) {
                  if (props.form.nodos_final[index].vista_general) {
                    if (
                      props.form.nodos_final[index].vista_general.vista_general
                    ) {
                      img = true;
                    }
                  }
                }

                if (index >= 0) {
                  return (
                    <article key={item.index}>
                      <div className={`${props.style.box__imgelement}`}>
                        {img ? (
                          <img
                            src={`${Config.api}${props.form.nodos_final[index].vista_general.vista_general.archivo_imagen}`}
                          />
                        ) : (
                          <div
                            className={`${props.style.box__imgelement__text}`}
                          >
                            <h1>{props.form.nodos_final[index].label}</h1>
                          </div>
                        )}
                      </div>
                      <h2>{item.guest ? item.guest : item.email}</h2>
                      {img && (
                        <span>{props.form.nodos_final[index].label}</span>
                      )}
                    </article>
                  );
                }
              })}

              <article className={props.style.box__full}>
                <div>
                  <Button
                    variant="main"
                    className={props.myStyle[`btn_${props.cliente}`]}
                    onClick={() => {
                      if (!state.loadingReserva) {
                        handleSubmit();
                      }
                    }}
                  >
                    <div className={props.style.box__button}>
                      <span>
                        {state.loadingReserva ? (
                          <FormattedMessage id="Loading" />
                        ) : (
                          <FormattedMessage id="Next" />
                        )}
                      </span>
                      <span>
                        <i className="far fa-arrow-alt-circle-right"></i>
                      </span>
                    </div>
                  </Button>
                </div>
              </article>
            </section>
          </div>
        </React.Fragment>
      )}
      {error && (
        <React.Fragment>
          <h2 className={props.style.start}>
            {code === 111 && <FormattedMessage id="Summary2" />}
            {code === 117 && <FormattedMessage id="Summary3" />}
            <i className="fas fa-frown"></i>
          </h2>

          <div className={`${props.style.box__footer}`}>
            <Button
              variant="main"
              className={props.myStyle[`btn_${props.cliente}`]}
              onClick={() => {
                dispatch(resetReserva());
                props.backStepSpecific(9);
              }}
            >
              <div className={props.style.box__button}>
                <span>
                  <FormattedMessage id="GoBack" />
                </span>
                <span>
                  <i className="far fa-arrow-alt-circle-left"></i>
                </span>
              </div>
            </Button>
          </div>
        </React.Fragment>
      )}
    </section>
  );
};

export default StepSummary;
