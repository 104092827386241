import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Form } from "react-bootstrap";
import Config from "../../server/controllers/config";

/* Actions */
import { getReserva, resetReserva } from "../../actions/reservation";

import Loading from "./Loading";

const StepSummary = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [type, setType] = useState("1");
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (state.statusReserva === 200) {
      if (state.dataReserva.ejecucion.estado) {
        props.nextStep();
        props.setFinalBooking([
          state.dataReserva.datos.reservas_relizadas,
          state.dataReserva.datos.reservas_no_permitida,
        ]);
        props.handleMemory({ name: "step11" });
        props.handleCount(1);
      }
    }
  }, [state.loadingReserva]);

  const handleSubmit = () => {
    const reservas = [];
    const editables = [];

    props.form.nodos_final.forEach((element) => {
      let obj = {
        empresa_id: props.form.empresa_id,
        edi_division_id: null,
        clave_granularidad: props.form.formato_tiempo,
        fecha_inicio: props.form.fecha_inicio,
        hora_inicio: props.form.inicio,
        duracion: parseInt(props.form.duracion, 10),
        arr_recursos_escasos: [],
        arr_invitados: [],
        tipo_iteracion: parseInt(type, 10),
      };

      if (props.form.formato_tiempo === "horas") {
        obj.duracion = props.form.duracion * 60;
        obj.clave_granularidad = "minutos";
      }

      if (type === "5") {
        obj.tipo_iteracion = 2;
        obj.arr_numero_dias_semana = days;
      }

      obj.edi_division_id = element.id;

      let index = props.form.emails.filter(
        (x) => x.booking.toString() === element.id.toString()
      );

      index.forEach((x) => {
        obj.arr_invitados.push({ email: x.email });

        let result = [];
        for (var i in x) {
          if (i.includes("recurso")) result.push([i, x[i]]);
        }

        result.forEach((x1) => {
          let recursoNum = x1[0].split("-");
          if (x1[1] !== "" && parseInt(x1[1], 10) > 0) {
            obj.arr_recursos_escasos.push({
              recurso_id: parseInt(recursoNum[1], 10),
              cantidad_solicitada: parseInt(x1[1], 10),
            });
          }
        });
        /* Campo editable */
        for (var i in x) {
          if (i.includes("edititem")) {
            if (x.editable !== "") {
              x[i].nombre = x.editable;
              editables.push(x[i]);
            }
          }
        }
      });

      reservas.push(obj);
    });
    dispatch(
      getReserva({
        reservas: reservas,
        editables: editables,
        reserva: props.form.reserva_id,
        disponibility: props.form.disponibility,
      })
    );
  };

  const updateData = (arreglo) => {
    let primerBooking = arreglo[0].booking;

    for (let i = 0; i < arreglo.length; i++) {
      if (!arreglo[i].email) {
        arreglo[i].email = `invitado.${i + 1}@kantoorl.cl`;
        arreglo[i].guest = `invitado ${i + 1}`;
      }

      if (!arreglo[i].booking) {
        arreglo[i].booking = primerBooking;
      }
    }
    return arreglo;
  };

  useEffect(() => {
    props.handleChange({
      target: { name: "emails", value: updateData(props.form.emails) },
    });
  }, []);

  /* Reset  recursos */
  /*   useEffect(() => {
    return () => {
      dispatch(resetReserva());
    };
  }, [dispatch]); */

  const handleDays = (id) => {
    const arr = [...days];
    const index = arr.indexOf(id);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }

    setDays(arr);
  };

  if (state.loadingReserva) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  }

  return (
    <section className={props.style.container__content}>
      <h2 className={props.style.start}>
        <FormattedMessage id="Summary" />
      </h2>

      <section className={props.style.filter__type}>
        <div>
          <FormattedMessage id="RepeatOptions" />
        </div>
        <div>
          <Form.Control
            value={type}
            onChange={(e) => setType(e.target.value)}
            as="select"
          >
            {props.filters.includes("1") && (
              <FormattedMessage id="DoesNotRepeat">
                {(msg) => <option value="1">{msg}</option>}
              </FormattedMessage>
            )}
            {props.filters.includes("2") && (
              <FormattedMessage id="SameDayWeekly">
                {(msg) => <option value="2">{msg}</option>}
              </FormattedMessage>
            )}
            {props.filters.includes("3") && (
              <FormattedMessage id="EveryWeekday">
                {(msg) => <option value="3">{msg}</option>}
              </FormattedMessage>
            )}
            {props.filters.includes("4") && (
              <FormattedMessage id="EveryMonthday">
                {(msg) => <option value="4">{msg}</option>}
              </FormattedMessage>
            )}
            {props.filters.includes("5") && (
              <FormattedMessage id="Personalized">
                {(msg) => <option value="5">{msg}</option>}
              </FormattedMessage>
            )}
          </Form.Control>
        </div>
      </section>
      {type === "5" && (
        <section className={props.style.filter__day}>
          <span>
            <FormattedMessage id="MessagePersonalized" />
          </span>
          <div
            onClick={() => handleDays(1)}
            className={days.includes(1) ? props.style.yellow : ""}
          >
            <FormattedMessage id="Day1" />
          </div>
          <div
            onClick={() => handleDays(2)}
            className={days.includes(2) ? props.style.yellow : ""}
          >
            <FormattedMessage id="Day2" />
          </div>
          <div
            onClick={() => handleDays(3)}
            className={days.includes(3) ? props.style.yellow : ""}
          >
            <FormattedMessage id="Day3" />
          </div>
          <div
            onClick={() => handleDays(4)}
            className={days.includes(4) ? props.style.yellow : ""}
          >
            <FormattedMessage id="Day4" />
          </div>
          <div
            onClick={() => handleDays(5)}
            className={days.includes(5) ? props.style.yellow : ""}
          >
            <FormattedMessage id="Day5" />
          </div>
        </section>
      )}

      <div className={`${props.style.box__footer}`}>
        <section className={`${props.style.box__img}`}>
          {props.form.emails.map((item) => {
            let index = props.form.nodos_final.findIndex(
              (x) => x.id.toString() === item.booking.toString()
            );

            let img = false;
            if (props.form?.nodos_final[index]?.vista_general) {
              if (props.form.nodos_final[index].vista_general.vista_general) {
                img = true;
              }
            }

            if (index >= 0) {
              return (
                <article key={item.index}>
                  <div className={`${props.style.box__imgelement}`}>
                    {img ? (
                      <img
                        src={`${Config.api}${props.form.nodos_final[index].vista_general.vista_general.archivo_imagen}`}
                      />
                    ) : (
                      <div className={`${props.style.box__imgelement__text}`}>
                        <h1>{props.form.nodos_final[index].label}</h1>
                      </div>
                    )}
                  </div>
                  <h2>{item.guest ? item.guest : item.email}</h2>
                  {img && <span>{props.form.nodos_final[index].label}</span>}
                </article>
              );
            }
          })}

          <article className={props.style.box__full}>
            <div>
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                onClick={() => {
                  if (!state.loadingReserva) {
                    handleSubmit();
                  }
                }}
              >
                <div className={props.style.box__button}>
                  <span>
                    {state.loadingReserva ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Next" />
                    )}
                  </span>
                  <span>
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </span>
                </div>
              </Button>
            </div>
          </article>
        </section>
      </div>
    </section>
  );
};

export default StepSummary;
