import React, { useState } from "react";
import Info from "./Info";
import Edit from "./Edit";

const List = (props) => {
  const [edit, setEdit] = useState(false);
  return (
    <section className={props.style.container}>
      <h1>Perfil</h1>
      <section>
        <article className={props.style.box__user}>
          <div>
            <img src="https://via.placeholder.com/150" alt="user" />
            <h2>Crstóbal Maturana</h2>
            <h3>
              <i className="fas fa-map-marker-alt"></i> Santiago, Chile
            </h3>
            <button onClick={() => setEdit(!edit)}>
              <i className="fas fa-user-edit"></i> Editar perfil
            </button>
          </div>
        </article>
        {!edit ? <Info style={props.style} /> : <Edit style={props.style} />}
      </section>
    </section>
  );
};

export default List;
