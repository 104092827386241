import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Config from "../../server/controllers/config";

/* Components */
import Filter from "./Filter";
import User from "./User";
import ModalSearch from "./ModalSearch";
import CalendarMenu from "./CalendarMenu/CalendarMenu";

const Menu = (props) => {
  const state = useSelector((state) => state.reservation);

  const [expand, setExpand] = useState(false);
  const [select, setSelect] = useState("");
  const [modal, setModal] = useState(false);

  let menu = "";
  let modalFilter = "";
  if (state.statusDeseable === 200) {
    if (state.dataDeseable.ejecucion.estado) {
      if (state.dataDeseable.datos.length > 0) {
        menu = (
          <li className={select === "filter" ? props.style.active : ""}>
            <i
              onClick={() => {
                setExpand(true);
                setSelect("filter");
              }}
              className="fas fa-filter"
            ></i>
          </li>
        );
        modalFilter = (
          <Filter
            setCount={props.setCount}
            setDeseable={props.setDeseable}
            data={state.dataDeseable.datos}
            setSelect={setSelect}
            setExpand={setExpand}
            expand={expand}
            select={select}
            style={props.style}
            cliente={props.cliente}
            myStyle={props.myStyle}
          />
        );
      }
    }
  }
  let logo = "";
  if (typeof localStorage !== "undefined") {
    logo = localStorage.getItem("logo");
  }

  return (
    <section className={props.style.container__menu}>
      <nav>
        <div>
          {logo && <img alt="logo client" src={`${Config.api}${logo}`} />}
        </div>
        <ul>
          {menu}
          <li className={select === "user" ? props.style.active : ""}>
            <i
              onClick={() => {
                setExpand(true);
                setSelect("user");
              }}
              className="far fa-user-circle"
            ></i>
          </li>
          <li>
            <Link to="/home/preference">
              <i className="far fa-star"></i>
            </Link>
          </li>
          <li
            onClick={() => {
              setExpand(false);
              setModal(true);
              setSelect("search");
            }}
          >
            <i className="fas fa-search"></i>
          </li>
          <li className={select === "calendar" ? props.style.active : ""}>
            <i
              onClick={() => {
                setExpand(true);
                setSelect("calendar");
              }}
              className="fas fa-calendar-alt"
            ></i>
          </li>
        </ul>
        {modalFilter}
        <User
          homeState={props.homeState}
          cliente={props.cliente}
          myStyle={props.myStyle}
          setSelect={setSelect}
          setExpand={setExpand}
          expand={expand}
          select={select}
          style={props.style}
        />

        <CalendarMenu
          myStyle={props.myStyle}
          setSelect={setSelect}
          setExpand={setExpand}
          expand={expand}
          select={select}
          style={props.style}
        />
      </nav>
      <ModalSearch
        cliente={props.cliente}
        myStyle={props.myStyle}
        style={props.style}
        modal={modal}
        setModal={setModal}
      />
    </section>
  );
};

export default Menu;
