import axios from "axios";

const API = {
  data: {
    async getCompanyContrato(obj) {
      const response = await axios.get(`/api/companyContrato`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getCompanySinContrato(obj) {
      const response = await axios.get(`/api/companySinContrato`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getLabels(obj) {
      const response = await axios.get(`/api/labels`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getDivision(obj) {
      const response = await axios.get(
        `/api/divisiones/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getSubDivision(obj) {
      const response = await axios.get(
        `/api/subdivisiones/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getSubSubDivision(obj) {
      const response = await axios.get(
        `/api/subsubdivisiones/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getBolsas(obj) {
      const response = await axios.get(
        `/api/bolsas/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getPreguntas(obj) {
      const response = await axios.get(
        `/api/preguntas/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;
      return {
        data,
        status,
      };
    },
    async getAlternativas(obj) {
      const response = await axios.get(
        `/api/alternativas/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getHorarios(obj) {
      const response = await axios.get(
        `/api/horarios/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getHorariosValido(obj) {
      const response = await axios.get(
        `/api/horariosValido/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getCalendario(obj) {
      axios.defaults.timeout = 60 * 20 * 1000;
      const response = await axios({
        method: "get",
        url: `/api/calendario/${encodeURIComponent(JSON.stringify(obj))}`,
        timeout: 60 * 20 * 1000, // Let's say you want to wait at least 4 mins
        data: obj,
      });

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getWeek(obj) {
      axios.defaults.timeout = 60 * 20 * 1000;
      const response = await axios({
        method: "get",
        url: `/api/calendario/week/${encodeURIComponent(JSON.stringify(obj))}`,
        timeout: 60 * 20 * 1000, // Let's say you want to wait at least 4 mins
        data: obj,
      });

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getMarco(obj) {
      axios.defaults.timeout = 60 * 20 * 1000;
      const response = await axios({
        method: "get",
        url: `/api/calendario/marco/${encodeURIComponent(JSON.stringify(obj))}`,
        timeout: 60 * 20 * 1000, // Let's say you want to wait at least 4 mins
        data: obj,
      });

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getEdificio(obj) {
      const response = await axios.get(
        `/api/edificio/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getDeseable(obj) {
      const response = await axios.get(
        `/api/deseable/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getNodo1(obj) {
      const response = await axios.get(
        `/api/nodo1/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getNodoHijo(obj) {
      const response = await axios.get(
        `/api/nodoHijo/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getNodoFinal(obj) {
      const response = await axios.get(
        `/api/nodoFinal/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getImagen(obj) {
      const response = await axios.get(
        `/api/imagen/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getRecursos(obj) {
      const response = await axios.get(
        `/api/recursos/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getReserva(obj) {
      const response = await axios.get(
        `/api/reserva/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getReservaExterna(obj) {
      const response = await axios.get(
        `/api/reserva/externa/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getAcceptReservaExterna(obj) {
      const response = await axios.get(
        `/api/reserva/acceptExterna/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getReservaExternaList(obj) {
      const response = await axios.get(
        `/api/reserva/externaList/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getReservaAllExternaList(obj) {
      const response = await axios.get(
        `/api/reserva/allExternaList/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getOptionsReservation(obj) {
      const response = await axios.get(
        `/api/reserva/optionsReservation/${encodeURIComponent(
          JSON.stringify(obj)
        )}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
