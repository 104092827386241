import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const Edit = (props) => {
  return (
    <article className={props.style.box__input}>
      <div>
        <h4>Email </h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>País</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>Holding</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>Rut</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>Ubicación</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>Empresa</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>Teléfono</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
      <div>
        <h4>Sitio Web</h4>
        <FormattedMessage id="WriteYourEmail">
          {(msg) => <Form.Control name={"email"} type="text" placeholder="" />}
        </FormattedMessage>
      </div>
    </article>
  );
};

export default Edit;
