import React from "react";
import { FormattedMessage } from "react-intl";

const Step2 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>Uso de los Datos Personales y de la Información Personal</h2>
      <p>
        A través de la aceptación de las Políticas de Privacidad, el Usuario
        declara que:
      </p>
      <p>
        Autoriza a FIFO a recopilar, procesar, utilizar y compartir sus Datos
        Personales, de acuerdo a estas Políticas de Privacidad y a la Ley Nº
        19.628 de Protección de la Vida Privada.
      </p>
      <p>
        Autoriza a FIFO a compilar y procesar sus Datos Personales e Información
        Personal con la finalidad de desarrollar estudios internos sobre los
        intereses, comportamientos y demografía de los Usuarios de la
        Plataforma.
      </p>
      <p>
        Autoriza a FIFO a enviarle información o avisos a su e-mail o mensajes
        de texto (SMS) a su teléfono celular sobre publicidad y/o promociones
        que puedan ser de interés para los Usuarios, o información sobre los
        servicios de FIFO en general, que podrá incluir claves personales para
        el uso de la Plataforma, recordatorios de vencimientos de pagos y otras
        obligaciones adquiridas por el uso de la Plataforma. El Usuario siempre
        podrá solicitar que lo excluyan de las listas para el envío de
        información promocional y/o publicitaria, mediante un correo electrónico
        dirigido a servicioalcliente@fifoapp.cl.
      </p>
      <p>
        Reconoce y acepta que FIFO revele, comparta o transfiera, total o
        parcialmente, sus Datos Personales e Información Personal con su matriz,
        personas relacionadas o empresas afiliadas o relacionadas con FIFO, a
        cualquier título y en el momento, forma y condiciones que estime
        pertinente. En estos casos, FIFO velará porque se cumplan ciertos
        estándares, mediante la firma de acuerdos o convenios cuyo objeto sea la
        privacidad de tales Datos Personales y de la Información Personal.
      </p>
      <p>
        Autoriza expresamente a FIFO para que almacene, guarde y procese sus
        Datos Personales o los transfiera o transmita a sus Socios Comerciales,
        de conformidad a la ley Nº 19.628 y a la Política de Privacidad de Datos
        de FIFO.
      </p>
      <p>
        Reconoce que, en algunos casos, proveedores “outsourcing” de servicios
        podrán recoger información directamente del Usuario, por ejemplo si se
        realizan encuestas o estudios. En tales casos, el Usuario podrá recibir
        una notificación acerca de la participación de un proveedor de servicios
        en tales actividades y quedará, a su discreción, el acceder o no a
        proporcionarle sus Datos Personales u otra Información Personal que le
        sea solicitada, así como autorizar a dichos proveedores a utilizar o
        compartir esa información. En caso que el Usuario facilite,
        voluntariamente y por propia iniciativa, sus Datos Personales u otra
        información adicional a tales proveedores, estos últimos usarán esa
        información conforme a sus propias políticas de privacidad, de forma que
        el Usuario acepta y reconoce que FIFO no se hace responsable por el uso
        indebido de sus Datos Personales y/u otra Información Personal que hagan
        los proveedores o compañías o sitios de Internet que actúen por cuenta
        propia. De conformidad a la ley, el proveedor que requiera recolectar
        datos personales de los Usuarios deberá siempre informar acerca del
        carácter facultativo de las respuestas y el propósito para el cual se
        está solicitando la información.
      </p>
      <p>
        Acepta que las llamadas que realice al servicio de soporte técnico de la
        Plataforma puedan ser grabadas con la finalidad de supervisar la
        prestación del Servicio por parte del equipo; se puedan remitir sus
        consultas, sugerencias o reclamos a quienes corresponda; y, se
        investiguen a profundidad situaciones graves que puedan ser denunciadas.
      </p>
      <p>
        Autoriza a FIFO para comunicar su Información Personal y Datos
        Personales con la finalidad de cumplir la normativa aplicable y cooperar
        con las autoridades judiciales y/o gubernamentales, en la medida en que
        FIFO discrecionalmente lo entienda necesario y adecuado en relación con
        cualquier investigación de un ilícito o un fraude, infracción de
        derechos de propiedad industrial o intelectual, u otra actividad que sea
        ilegal o que pueda exponer a FIFO o a los Usuarios a cualquier
        responsabilidad legal. Este derecho podrá ser ejercido por FIFO a
        efectos de cooperar con el cumplimiento y ejecución de la ley,
        independientemente que no exista una orden judicial o administrativa al
        efecto.
      </p>
      <p>
        Reconoce que será el único responsable de todos los actos que tengan
        lugar mediante el uso de su Cuenta, lo que incluye hacerse cargo del
        pago de las tarifas que eventualmente se devenguen y de los perjuicios
        que puedan sufrir otros Usuarios y/o terceros por tal motivo.
      </p>
      <p>
        FIFO recopilará, utilizará y compartirá sus Datos Personales e
        Información Personal a fin de proporcionar seguridad a los Usuarios y
        que los Servicios sean prestados correctamente. De esta forma, FIFO
        podrá utilizar la información recopilada para:
      </p>
      <ul>
        <li>Crear y actualizar la Cuenta del Usuario.</li>
        <li>Verificar la identidad del Usuario.</li>
        <li>Comprobar la veracidad de los Datos Personales de los Usuarios.</li>
        <li>Procesar y/o facilitar el pago de los Servicios.</li>
        <li>
          Ubicar al Usuario, teniendo acceso a su ubicación en tiempo real, para
          entregar el Servicio.
        </li>
        <li>
          Permitir que el servicio de soporte técnico de la Plataforma pueda
          contactar al Usuario.
        </li>
        <li>
          Realizar pruebas, investigaciones y análisis respecto a la prestación
          de los Servicios.
        </li>
        <li>
          Enviar a los Usuarios información respecto a promociones y/o
          publicidad.
        </li>
      </ul>
    </section>
  );
};

export default Step2;
