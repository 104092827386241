import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorCompnent = (props) => {
  return (
    <section className={props.style.container}>
      <h2>
        Nosotros tuvimos un problema <i className="fas fa-sad-tear"></i>
      </h2>
    </section>
  );
};

export default ErrorCompnent;
