import React from "react";
import Header from "./Header";
import Tramo from "./Tramo";
import Days from "./Days";
import { FormattedMessage } from "react-intl";

const Schedule = (props) => {
  const largo = props.data.datos.marco.eje_y.length;
  let data1 = props.data.datos.casillas.slice(0, largo);
  let data2 = props.data.datos.casillas.slice(largo * 1, largo * 1 + largo);
  let data3 = props.data.datos.casillas.slice(largo * 2, largo * 2 + largo);
  let data4 = props.data.datos.casillas.slice(largo * 3, largo * 3 + largo);
  let data5 = props.data.datos.casillas.slice(largo * 4, largo * 4 + largo);
  let data6 = props.data.datos.casillas.slice(largo * 5, largo * 5 + largo);
  let data7 = props.data.datos.casillas.slice(largo * 6, largo * 6 + largo);

  console.log("info", props.infoReserva);

  return (
    <>
      <Header
        next={props.next}
        back={props.back}
        cliente={props.cliente}
        myStyle={props.myStyle}
        data={props.data.datos.marco.eje_x}
        style={props.style}
      />
      <section className={props.style.container__schedule__body}>
        <Tramo data={props.data.datos.marco.eje_y} />
        <Days
          id="id1"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data1}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
        <Days
          id="id2"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data2}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
        <Days
          id="id3"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data3}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
        <Days
          id="id4"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data4}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
        <Days
          id="id5"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data5}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
        <Days
          id="id6"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data6}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
        <Days
          id="id7"
          handleSelect={props.handleSelect}
          style={props.style}
          message={props.message}
          data={data7}
          disponibility={props.disponibility}
          infoReserva={props.infoReserva}
        />
      </section>
    </>
  );
};

export default Schedule;
