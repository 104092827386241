import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { getNota, getHistory, resetNota } from "../../actions/history";

const ModalStart = (props) => {
  const [error, setError] = useState(false);
  const state = useSelector((state) => state.history);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.statusNota === 200) {
      if (state.dataNota.ejecucion.estado) {
        props.setModal(false);
        dispatch(getHistory({}));
        dispatch(resetNota());
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [state.loadingNota]);

  const handleNota = (nota) => {
    dispatch(getNota({ reserva_id: props.data, nivel_id: nota }));
  };

  const drawElement = (id) => {
    let element = "";
    switch (id) {
      case 28:
        element = <i className="fas fa-frown"></i>;
        break;
      case 29:
        element = <i className="fas fa-smile-beam"></i>;
        break;
      case 30:
        element = <i className="fas fa-laugh-beam"></i>;
        break;
    }

    return element;
  };

  return (
    <>
      <Modal
        onHide={() => {
          setError(false);
          props.setModal(false);
        }}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="Favorite" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="ModalFavoriteText" />
            </p>
            <div className="modal__button__icon">
              <ul>
                {props.niveles.datos.map((item) => {
                  return (
                    <li key={item.id} onClick={() => handleNota(item.id)}>
                      {drawElement(item.id)}
                      <span>{item.glosa}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default ModalStart;
