import React from "react";

const Empty = (props) => {
  return (
    <section>
      {props.data.map((item, i) => {
        return (
          <div key={`empty-${i}${props.id}`}>
            {props.text && i === 0 && props.text}
            <div data-father={"ok"} id={`empty-${i}`} className={`empty-${i}`}>
              <h4 data-ok={"ok"} id={`message-${props.id}${item.id}`}></h4>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Empty;
