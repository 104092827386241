import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Config from "../../server/controllers/config";

/* Actions */
import {
  getDeseable,
  resetDeseable,
  getNodo1,
  resetNodo1,
  getNodoHijo,
  resetNodoHijo,
} from "../../actions/reservation";

import moment from "moment";

/* Components */
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";
import Information from "./Information";
import { Button } from "react-bootstrap";

const StepBlocks = (props) => {
  const [nodosCount, setNodosCount] = useState(0);
  const [nodos, setNodos] = useState([]);

  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  /* Get companies */
  useEffect(() => {
    const callsAPI = async () => {
      let memoriaRecurso = "";
      let memoriaRecursoObj = localStorage.getItem("memoriaRecurso");
      if (memoriaRecursoObj) {
        memoriaRecursoObj = JSON.parse(memoriaRecursoObj);
        memoriaRecurso = memoriaRecursoObj.value;
      }

      props.handleChange({
        target: {
          name: "nodos_final",
          value: [],
        },
      });
      props.handleChange({
        target: {
          name: "emails",
          value: [
            {
              index: "index0",
              email: props.myEmail,
              booking: "",
              editable: memoriaRecurso,
            },
          ],
        },
      });
      const obj = {
        empresa_id: props.form.empresa_id,
        edificacion_id: props.form.edificio_id,
        division_id: props.form.ciudad,
        subdivision_id: props.form.provincia,
        subsubdivision_id: props.form.comuna,
        arr_alternativa_id: props.form.atributos, // []
        clave_granularidad: props.form.formato_tiempo,
        fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
        hora_inicio: props.form.inicio,
        duracion: props.form.duracion,
      };

      dispatch(getDeseable(obj));

      dispatch(
        getNodo1({
          empresa_id: props.form.empresa_id,
          edificacion_id: props.form.edificio_id,
          division_id: props.form.ciudad,
          subdivision_id: props.form.provincia,
          subsubdivision_id: props.form.comuna,
          arr_alternativa_id: props.form.atributos, // []
          clave_granularidad: props.form.formato_tiempo,
          fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
          hora_inicio: props.form.inicio,
          duracion: props.form.duracion,
        })
      );
    };
    callsAPI();
  }, [dispatch]);

  const handleNodo1 = (obj, direction) => {
    props.setDirection(direction);
    const arr = [...nodos];
    let id = "";
    let index = arr.indexOf(obj.id);
    if (arr[nodosCount]) {
      id = arr[nodosCount];
    } else if (index > -1) {
      id = arr[index];
    } else {
      id = obj.id;
      arr.push(obj.id);
      setNodos(arr);
      props.handleChange({
        target: { name: "nodos", value: arr },
      });
    }

    props.handleChange({
      target: { name: "nodosCount", value: nodosCount + 1 },
    });

    const max = arr.length;

    setNodosCount(nodosCount + 1 <= max ? nodosCount + 1 : max);

    /* Llamada a la api */

    const objH = {
      empresa_id: props.form.empresa_id,
      edificacion_id: props.form.edificio_id,
      division_id: props.form.ciudad,
      subdivision_id: props.form.provincia,
      subsubdivision_id: props.form.comuna,
      arr_alternativa_id: props.form.atributos, // []
      clave_granularidad: props.form.formato_tiempo,
      fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
      hora_inicio: props.form.inicio,
      duracion: props.form.duracion,
      nodo_id: id,
    };

    dispatch(getNodoHijo(objH));
  };

  useEffect(() => {
    if (state.statusNodo1 === 200) {
      if (state.dataNodo1.ejecucion.estado) {
        if (state.dataNodo1.datos.nodos.length === 0) {
          if (props.direction === "right") {
            props.handleMemory({ name: "step9" });
            props.nextStep();
            if (props.form.nodosCount === props.form.nodos.length) {
              props.handleChange({
                target: { name: "nodosCount", value: nodosCount - 1 },
              });
            }
          } else {
            props.backStep();
          }
        } else if (
          state.dataNodo1.datos.nodos.length === 1 &&
          nodosCount <= nodos.length
        ) {
          if (props.direction === "right") {
            handleNodo1({ id: state.dataNodo1.datos.nodos[0].id }, "right");
          }
        }
      }
    }
  }, [state.loadingNodo1]);

  useEffect(() => {
    if (state.statusNodoHijo === 200 && !state.loadingNodoHijo) {
      if (state.dataNodoHijo.ejecucion.estado) {
        if (state.dataNodoHijo.datos.nodos.length === 0) {
          props.handleMemory({ name: "step9" });
          props.nextStep();
          props.handleCount(1);
          if (props.form.nodosCount === props.form.nodos.length) {
            props.handleChange({
              target: { name: "nodosCount", value: nodosCount - 1 },
            });
          }
        } else if (
          state.dataNodoHijo.datos.nodos.length === 1 &&
          nodosCount > 0
        ) {
          if (props.direction === "right") {
            handleNodo1(
              {
                id: state.dataNodoHijo.datos.nodos[0].id,
              },
              "right"
            );
          }
        }
      }
    }
  }, [state.loadingNodoHijo]);

  /* Reset  companies */
  useEffect(() => {
    return () => {
      dispatch(resetNodo1());
      dispatch(resetNodoHijo());
    };
  }, [dispatch]);

  if (state.loadingNodo1 || state.loadingNodoHijo) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusNodo1 === 501 ||
    state.statusNodoHijo === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusNodo1 === 200
  ) {
    if (!state.dataNodo1.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  } else if (
    /* When there is an error in the back */
    state.statusNodoHijo === 200
  ) {
    if (!state.dataNodoHijo.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <>
      <section className={props.style.container__content}>
        <Information
          cliente={props.cliente}
          myStyle={props.myStyle}
          data={props.form.imagenes}
          style={props.style}
        />
        <h2 className={props.style.start}>
          <FormattedMessage id="SelectThePlaceOfPreference" />
        </h2>
        <p>
          <FormattedMessage id="SelectYourPreference" />
        </p>
        {nodosCount === 0 && (
          <div className={`${props.style.box__footer}`}>
            <section className={`${props.style.box__img}`}>
              {state.dataNodo1.datos.nodos.map((item) => {
                return (
                  <article
                    key={item.id}
                    onClick={() => {
                      handleNodo1({ id: item.id }, "right");
                    }}
                  >
                    <span
                      className={`${props.style.box__imgheart} ${props.style.box__hearthactive}`}
                    >
                      <i className="fas fa-heart"></i>
                    </span>
                    <div className={`${props.style.box__imgelement}`}>
                      {item.vista_general.vista_general ? (
                        <img
                          src={`${Config.api}${item.vista_general.vista_general.archivo_imagen}`}
                        />
                      ) : (
                        <div className={`${props.style.box__imgelement__text}`}>
                          <h1>{item.nombre}</h1>
                        </div>
                      )}
                    </div>
                    {item.vista_general.vista_general && <h2>{item.nombre}</h2>}
                  </article>
                );
              })}
            </section>
          </div>
        )}
        {nodosCount > 0 && state.statusNodoHijo === 200 && (
          <div className={`${props.style.box__footer}`}>
            <section className={`${props.style.box__img}`}>
              {state.dataNodoHijo.datos.nodos.map((item) => {
                return (
                  <article
                    key={item.id}
                    onClick={() => {
                      handleNodo1({ id: item.id }, "right");
                    }}
                  >
                    <span
                      className={`${props.style.box__imgheart} ${props.style.box__hearthactive}`}
                    >
                      <i className="fas fa-heart"></i>
                    </span>
                    <div className={`${props.style.box__imgelement}`}>
                      {item.vista_general.vista_general ? (
                        <img
                          src={`${Config.api}${item.vista_general.vista_general.archivo_imagen}`}
                        />
                      ) : (
                        <div className={`${props.style.box__imgelement__text}`}>
                          <h1>{item.nombre}</h1>
                        </div>
                      )}
                    </div>
                    {item.vista_general.vista_general && <h2>{item.nombre}</h2>}
                  </article>
                );
              })}
            </section>
          </div>
        )}
      </section>
    </>
  );
};

export default StepBlocks;
