import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Config from "../../server/controllers/config";
import moment from "moment";
import _ from "lodash";

/* Actions */
import {
  getImagen,
  resetImagen,
  getNodoFinal,
  resetNodoFinal,
} from "../../actions/reservation";

import { GlassMagnifier } from "react-image-magnifiers";

/* Components */

import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";
import { Button } from "react-bootstrap";
import Modal from "./ModalImage";
import Message from "./Message";

import { getHistory, resetHistory } from "../../actions/history";

const StepFinal = (props) => {
  const [select, setSelect] = useState(props.form.nodos_final);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [img, setImg] = useState(false);
  const [name, setName] = useState(false);

  const state = useSelector((state) => state.reservation);
  const stateHistory = useSelector((state) => state.history);

  const dispatch = useDispatch();

  /* Get data*/
  useEffect(() => {
    const callsAPI = async () => {
      const obj = {
        empresa_id: props.form.empresa_id,
        edi_division_id: props.form.nodos[props.form.nodos.length - 1],
        arr_alternativa_id: props.form.atributos, // []
        clave_granularidad: props.form.formato_tiempo,
        fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
        hora_inicio: props.form.inicio,
        duracion: props.form.duracion,
        arr_deseable_id: props.deseable,
      };

      dispatch(getHistory({ filtrar_evaluadas: false }));

      dispatch(getNodoFinal(obj));
      dispatch(
        getImagen({
          edi_division_id: props.form.nodos[props.form.nodos.length - 1],
        })
      );
    };
    callsAPI();
  }, [dispatch, props.countDeseable]);

  /* Reset  */
  useEffect(() => {
    return () => {
      dispatch(resetImagen());
      dispatch(resetNodoFinal());
      dispatch(resetHistory());
    };
  }, [dispatch]);

  const handleFilter = (obj) => {
    setError(false);
    let arr = [];
    let index = -1;

    if (parseInt(props.form.guests, 10) <= 1) {
      arr = [];
      index = -1;
    } else {
      arr = [...select];
      index = arr.findIndex((x) => x.id === obj.id);
    }

    if (index < 0) {
      arr.push(obj);
    } else {
      arr.splice(index, 1);
    }

    if (
      parseInt(props.form.guests, 10) >= arr.length ||
      parseInt(props.form.guests, 10) <= 1
    ) {
      props.handleChange({
        target: { name: "nodos_final", value: arr },
      });
      setSelect(arr);
    }
  };

  const handleSubmit = (event) => {
    if (select.length > 0) {
      props.handleCount(1);
      props.handleMemory({ name: "step10" });
      props.nextStep();
      setError(false);
    } else {
      setError(true);
    }
  };

  if (
    state.loadingImagen ||
    state.loadingNodoFinal ||
    stateHistory.loadingHistory
  ) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusImagen === 501 ||
    state.statusNodoFinal === 501 ||
    stateHistory.statusHistory === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusImagen === 200 ||
    state.statusNodoFinal === 200 ||
    stateHistory.statusHistory === 200
  ) {
    if (!state.dataImagen.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    } else if (!state.dataNodoFinal.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  //console.log(state.dataNodoFinal.datos);

  const filterSpecial = (arr) => {
    const filterNormal = arr.filter(
      (o) => o.label_corto !== "reserva_convenio"
    );

    const filterSpecial = arr.filter(
      (o) => o.label_corto === "reserva_convenio"
    );

    if (filterNormal.length > 0) {
      return filterNormal;
    } else if (filterSpecial.length > 0) {
      return filterSpecial;
    }

    return filterNormal;
  };

  const filterHistory = (arr) => {
    let dataResult = null;
    if (Array.isArray(arr)) {
      const filterHistoryDate = arr.filter((o) => {
        return (
          o.reserva.desde.includes(
            moment(props.form.fecha_inicio).format("DD-MM-YYYY")
          ) &&
          o.reserva.vigente &&
          o.reserva.vencida === false &&
          o.edi_division_reservada.label_corto.match(/([^[]+(?=]))/g)
        );
      });

      if (filterHistoryDate.length > 0) {
        let objData =
          filterHistoryDate[0].edi_division_reservada.label_corto.match(
            /([^[]+(?=]))/g
          );
        const objDataFinal = objData[0].split("---");

        dataResult = {
          tipoReservable: objDataFinal[0],
          regla: objDataFinal[1],
          nombreCompleto: objData[0],
          reserva: filterHistoryDate[0].reserva,
        };
      }
    }

    return dataResult;
  };

  const validateSobreReserva = (arr, info) => {
    const filterSobreReserva = arr.filter((o) => {
      return o.label_corto.includes(info.nombreCompleto);
    });

    let pass = false;
    if (props.form.formato_tiempo === "horas") {
      const inicioReserva = info.reserva.desde.split(" ");
      const finalReserva = info.reserva.hasta.split(" ");
      const inicioReservaFinal = parseInt(inicioReserva[1], 10);
      const finalReservaFinal = parseInt(finalReserva[1], 10);

      const hours = props.form.inicio.split(":");
      const inicio = parseInt(props.form.inicio, 10);
      const final = parseInt(hours[0], 10) + parseInt(props.form.duracion, 10);

      for (let i = inicio; i <= final; i++) {
        if (i >= inicioReservaFinal && i <= finalReservaFinal && !pass) {
          pass = true;
        }
      }

      if (final === inicioReservaFinal) {
        pass = false;
      } else if (inicio === finalReservaFinal) {
        pass = false;
      }
    } else if (props.form.formato_tiempo === "minutos") {
      const inicioReserva = info.reserva.desde.split(" ");
      const finalReserva = info.reserva.hasta.split(" ");

      const one = inicioReserva[1].split(":");
      const two = finalReserva[1].split(":");

      const inicioReservaFinal = parseInt(`${one[0]}${one[1]}`, 10);
      const finalReservaFinal = parseInt(`${two[0]}${two[1]}`, 10);

      const hours = props.form.inicio.split(":");
      const inicio = parseInt(`${hours[0]}${hours[1]}`, 10);
      const final =
        parseInt(`${hours[0]}${hours[1]}`, 10) +
        parseInt(props.form.duracion, 10);

      for (let i = inicio; i <= final; i = i + 30) {
        if (i >= inicioReservaFinal && i <= finalReservaFinal && !pass) {
          pass = true;
        }
      }

      if (final === inicioReservaFinal) {
        pass = false;
      } else if (inicio === finalReservaFinal) {
        pass = false;
      }
    }

    if (filterSobreReserva.length > 0 && pass) {
      return true;
    } else {
      return false;
    }
  };

  if (filterHistory(stateHistory.dataHistory.datos)) {
    if (
      validateSobreReserva(
        state.dataNodoFinal.datos,
        filterHistory(stateHistory.dataHistory.datos)
      )
    ) {
      return (
        <Message
          backStep={props.backStepSpecific}
          myStyle={props.myStyle}
          client={props.cliente}
          text={"OverlapsReservationMessage"}
          style={props.style}
        />
      );
    }
  }

  return (
    <>
      <section className={props.style.container__content}>
        <Modal
          img={img}
          name={name}
          modal={modal}
          cliente={props.cliente}
          setModal={setModal}
          myStyle={props.myStyle}
        />
        <h2 className={props.style.start}>
          <FormattedMessage id="SelectYourPreference" />
        </h2>
        <div className={`${props.style.box__footer}`}>
          <div className={`${props.style.box__select}`}>
            <section className={`${props.style.box__selectimg}`}>
              <div className={`${props.style.box__selectimg__web}`}>
                <GlassMagnifier
                  imageSrc={`${Config.api}${state.dataImagen.datos.imagen_layout.vista_layout.archivo_imagen}`}
                  imageAlt="Example"
                  square={true}
                  magnifierBackgroundColor="rgba(225,225,225,1)"
                />
              </div>
              <div
                onLoad={() => {
                  const element = document.getElementById(
                    "box__selectimg__mobile"
                  );
                  if (element) {
                    element.scrollLeft =
                      (element.scrollWidth - element.clientWidth) / 2;
                  }
                }}
                id="box__selectimg__mobile"
                className={`${props.style.box__selectimg__mobile}`}
              >
                <img
                  src={`${Config.api}${state.dataImagen.datos.imagen_layout.vista_layout.archivo_imagen}`}
                  alt="img"
                />
              </div>
            </section>
            <section className={`${props.style.box__selectchoose}`}>
              <menu>
                <ul>
                  {_.orderBy(
                    filterSpecial(state.dataNodoFinal.datos),
                    [
                      (item) => {
                        const match = item.label.match(/\d+/);
                        return match ? parseInt(match[0]) : Infinity;
                      },
                      "id",
                    ],
                    ["asc", "asc"]
                  ).map((item) => {
                    let index = select.findIndex((x) => x.id === item.id);

                    return (
                      <li
                        key={item.id}
                        onClick={(event) => {
                          event.preventDefault();
                          if (event.target.dataset.click !== "yes") {
                            handleFilter(item);
                          }
                        }}
                        className={index > -1 ? props.style.active : ""}
                      >
                        <div>
                          {item.vista_general &&
                            item.vista_general.vista_general && (
                              <span
                                onClick={(event) => {
                                  setImg(
                                    `${Config.api}${item.vista_general.vista_general.archivo_imagen}`
                                  );
                                  setName(item.label);
                                  setModal(true);
                                }}
                              >
                                <i
                                  data-click={"yes"}
                                  className={"fas fa-eye"}
                                ></i>
                              </span>
                            )}
                          {item.label}
                        </div>
                        <div>
                          {index > -1 && <i className={"fas fa-check"}></i>}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </menu>
              <div>
                {error && (
                  <span className={props.style.error}>
                    <p>
                      <FormattedMessage id="CompleteTheRequiredFields" />
                    </p>
                  </span>
                )}
                <Button
                  variant="main"
                  onClick={() => handleSubmit()}
                  className={props.myStyle[`btn_${props.cliente}`]}
                >
                  <div className={props.style.box__button}>
                    <span>
                      <FormattedMessage id="Next" />
                    </span>
                    <span>
                      <i className="far fa-arrow-alt-circle-right"></i>
                    </span>
                  </div>
                </Button>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepFinal;
