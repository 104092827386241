/* Companies with contract */

export const GET_COMPANY_CONTRATO_INIT = "GET_COMPANY_CONTRATO_INIT";
export const GET_COMPANY_CONTRATO_SUCCESS = "GET_COMPANY_CONTRATO_SUCCESS";
export const GET_COMPANY_CONTRATO_FAILURE = "GET_COMPANY_CONTRATO_FAILURE";

export const RESET_COMPANY_CONTRATO = "RESET_COMPANY_CONTRATO";

/* Companies without contract */
export const GET_COMPANY_SIN_CONTRATO_INIT = "GET_COMPANY_SIN_CONTRATO_INIT";
export const GET_COMPANY_SIN_CONTRATO_SUCCESS =
  "GET_COMPANY_SIN_CONTRATO_SUCCESS";
export const GET_COMPANY_SIN_CONTRATO_FAILURE =
  "GET_COMPANY_SIN_CONTRATO_FAILURE";

export const RESET_COMPANY_SIN_CONTRATO = "RESET_COMPANY_SIN_CONTRATO";

/* Labels */
export const GET_LABELS_INIT = "GET_LABELS_INIT";
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS";
export const GET_LABELS_FAILURE = "GET_LABELS_FAILURE";

export const RESET_LABELS = "RESET_LABELS";

/* Division*/
export const GET_DIVISION_INIT = "GET_DIVISION_INIT";
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS";
export const GET_DIVISION_FAILURE = "GET_DIVISION_FAILURE";

export const RESET_DIVISION = "RESET_DIVISION";

/* Sub Division*/
export const GET_SUBDIVISION_INIT = "GET_SUBDIVISION_INIT";
export const GET_SUBDIVISION_SUCCESS = "GET_SUBDIVISION_SUCCESS";
export const GET_SUBDIVISION_FAILURE = "GET_SUBDIVISION_FAILURE";

export const RESET_SUBDIVISION = "RESET_SUBDIVISION";

/* Sub sub Division */
export const GET_SUBSUBDIVISION_INIT = "GET_SUBSUBDIVISION_INIT";
export const GET_SUBSUBDIVISION_SUCCESS = "GET_SUBSUBDIVISION_SUCCESS";
export const GET_SUBSUBDIVISION_FAILURE = "GET_SUBSUBDIVISION_FAILURE";

export const RESET_SUBSUBDIVISION = "RESET_SUBSUBDIVISION";

/* Sub sub Division */
export const GET_BOLSAS_INIT = "GET_BOLSAS_INIT";
export const GET_BOLSAS_SUCCESS = "GET_BOLSAS_SUCCESS";
export const GET_BOLSAS_FAILURE = "GET_BOLSAS_FAILURE";

export const RESET_BOLSAS = "RESET_BOLSAS";

/* Preguntas */
export const GET_PREGUNTAS_INIT = "GET_PREGUNTAS_INIT";
export const GET_PREGUNTAS_SUCCESS = "GET_PREGUNTAS_SUCCESS";
export const GET_PREGUNTAS_FAILURE = "GET_PREGUNTAS_FAILURE";

export const RESET_PREGUNTAS = "RESET_PREGUNTAS";

/* Alternativas */
export const GET_ALTERNATIVAS_INIT = "GET_ALTERNATIVAS_INIT";
export const GET_ALTERNATIVAS_SUCCESS = "GET_ALTERNATIVAS_SUCCESS";
export const GET_ALTERNATIVAS_FAILURE = "GET_ALTERNATIVAS_FAILURE";

export const RESET_ALTERNATIVAS = "RESET_ALTERNATIVAS";

/* Horarios */
export const GET_HORARIOS_INIT = "GET_HORARIOS_INIT";
export const GET_HORARIOS_SUCCESS = "GET_HORARIOS_SUCCESS";
export const GET_HORARIOS_FAILURE = "GET_HORARIOS_FAILURE";

export const RESET_HORARIOS = "RESET_HORARIOS";

/* Horarios */
export const GET_HORARIOS_VALIDO_INIT = "GET_HORARIOS_VALIDO_INIT";
export const GET_HORARIOS_VALIDO_SUCCESS = "GET_HORARIOS_VALIDO_SUCCESS";
export const GET_HORARIOS_VALIDO_FAILURE = "GET_HORARIOS_VALIDO_FAILURE";

export const RESET_HORARIOS_VALIDO = "RESET_HORARIOS_VALIDO";

/* Calendario */
export const GET_CALENDARIO_INIT = "GET_CALENDARIO_INIT";
export const GET_CALENDARIO_SUCCESS = "GET_CALENDARIO_SUCCESS";
export const GET_CALENDARIO_FAILURE = "GET_CALENDARIO_FAILURE";

export const RESET_CALENDARIO = "RESET_CALENDARIO";

/* Marco*/
export const GET_MARCO_INIT = "GET_MARCO_INIT";
export const GET_MARCO_SUCCESS = "GET_MARCO_SUCCESS";
export const GET_MARCO_FAILURE = "GET_MARCO_FAILURE";

export const RESET_MARCO = "RESET_MARCO";

/* Week */
export const GET_WEEK_INIT = "GET_WEEK_INIT";
export const GET_WEEK_SUCCESS = "GET_WEEK_SUCCESS";
export const GET_WEEK_FAILURE = "GET_WEEK_FAILURE";

export const RESET_WEEK = "RESET_WEEK";

/* Edificio */
export const GET_EDIFICIO_INIT = "GET_EDIFICIO_INIT";
export const GET_EDIFICIO_SUCCESS = "GET_EDIFICIO_SUCCESS";
export const GET_EDIFICIO_FAILURE = "GET_EDIFICIO_FAILURE";

export const RESET_EDIFICIO = "RESET_EDIFICIO";

/* Deseable */
export const GET_DESEABLE_INIT = "GET_DESEABLE_INIT";
export const GET_DESEABLE_SUCCESS = "GET_DESEABLE_SUCCESS";
export const GET_DESEABLE_FAILURE = "GET_DESEABLE_FAILURE";

export const RESET_DESEABLE = "RESET_DESEABLE";

/* Nodo 1 */
export const GET_NODO1_INIT = "GET_NODO1_INIT";
export const GET_NODO1_SUCCESS = "GET_NODO1_SUCCESS";
export const GET_NODO1_FAILURE = "GET_NODO1_FAILURE";

export const RESET_NODO1 = "RESET_NODO1";

/* Nodo hijo */
export const GET_NODO_HIJO_INIT = "GET_NODO_HIJO_INIT";
export const GET_NODO_HIJO_SUCCESS = "GET_NODO_HIJO_SUCCESS";
export const GET_NODO_HIJO_FAILURE = "GET_NODO_HIJO_FAILURE";

export const RESET_NODO_HIJO = "RESET_NODO_HIJO";

/* Nodo Final */
export const GET_NODO_FINAL_INIT = "GET_NODO_FINAL_INIT";
export const GET_NODO_FINAL_SUCCESS = "GET_NODO_FINAL_SUCCESS";
export const GET_NODO_FINAL_FAILURE = "GET_NODO_FINAL_FAILURE";

export const RESET_NODO_FINAL = "RESET_NODO_FINAL";

/* Nodo imagen */
export const GET_IMAGEN_INIT = "GET_IMAGEN_INIT";
export const GET_IMAGEN_SUCCESS = "GET_IMAGEN_SUCCESS";
export const GET_IMAGEN_FAILURE = "GET_IMAGEN_FAILURE";

export const RESET_IMAGEN = "RESET_IMAGEN";

/* Recursos */
export const GET_RECURSOS_INIT = "GET_RECURSOS_INIT";
export const GET_RECURSOS_SUCCESS = "GET_RECURSOS_SUCCESS";
export const GET_RECURSOS_FAILURE = "GET_RECURSOS_FAILURE";

export const RESET_RECURSOS = "RESET_RECURSOS";

/* Reserva */
export const GET_RESERVA_INIT = "GET_RESERVA_INIT";
export const GET_RESERVA_SUCCESS = "GET_RESERVA_SUCCESS";
export const GET_RESERVA_FAILURE = "GET_RESERVA_FAILURE";

export const RESET_RESERVA = "RESET_RESERVA";

/* Reserva  externa*/
export const GET_RESERVA_EXTERNA_INIT = "GET_RESERVA_EXTERNA_INIT";
export const GET_RESERVA_EXTERNA_SUCCESS = "GET_RESERVA_EXTERNA_SUCCESS";
export const GET_RESERVA_EXTERNA_FAILURE = "GET_RESERVA_EXTERNA_FAILURE";

export const RESET_RESERVA_EXTERNA = "RESET_RESERVA_EXTERNA";

/* Aceptar reserva  externa*/
export const GET_ACCEPT_RESERVA_EXTERNA_INIT =
  "GET_ACCEPT_RESERVA_EXTERNA_INIT";
export const GET_ACCEPT_RESERVA_EXTERNA_SUCCESS =
  "GET_ACCEPT_RESERVA_EXTERNA_SUCCESS";
export const GET_ACCEPT_RESERVA_EXTERNA_FAILURE =
  "GET_ACCEPT_RESERVA_EXTERNA_FAILURE";

export const RESET_ACCEPT_RESERVA_EXTERNA = "RESET_ACCEPT_RESERVA_EXTERNA";

/* Reserva  externa list*/
export const GET_RESERVA_EXTERNA_LIST_INIT = "GET_RESERVA_EXTERNA_LIST_INIT";
export const GET_RESERVA_EXTERNA_LIST_SUCCESS =
  "GET_RESERVA_EXTERNA_LIST_SUCCESS";
export const GET_RESERVA_EXTERNA_LIST_FAILURE =
  "GET_RESERVA_EXTERNA_LIST_FAILURE";

export const RESET_RESERVA_EXTERNA_LIST = "RESET_RESERVA_EXTERNA_LIST";

/* Reserva all externa list*/
export const GET_RESERVA_ALL_EXTERNA_LIST_INIT =
  "GET_RESERVA_ALL_EXTERNA_LIST_INIT";
export const GET_RESERVA_ALL_EXTERNA_LIST_SUCCESS =
  "GET_RESERVA_ALL_EXTERNA_LIST_SUCCESS";
export const GET_RESERVA_ALL_EXTERNA_LIST_FAILURE =
  "GET_RESERVA_ALL_EXTERNA_LIST_FAILURE";

export const RESET_RESERVA_ALL_EXTERNA_LIST = "RESET_RESERVA_ALL_EXTERNA_LIST";

/* Reserva options reservation */
export const GET_OPTIONS_RESERVATION_INIT = "GET_OPTIONS_RESERVATION_INIT";
export const GET_OPTIONS_RESERVATION_SUCCESS =
  "GET_OPTIONS_RESERVATION_SUCCESS";
export const GET_OPTIONS_RESERVATION_FAILURE =
  "GET_RESERVA_ALL_EXTERNA_LIST_FAILURE";

export const RESET_OPTIONS_RESERVATION = "RESET_OPTIONS_RESERVATION";

/* All */

export const RESET_ALL_RESERVATION = "RESET_ALL_RESERVATION";
