import React, { useEffect, useState } from "react";

/* Components */
import List from "../../components/request/List";

/* Style */
import Style from "../../assets/styles/Request.module.scss";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const RequestComponent = (props) => {
  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);
  return (
    <>
      <List cliente={cliente} myStyle={myStyle} style={Style} />
    </>
  );
};

export default RequestComponent;
