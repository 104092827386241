import React, { useEffect, useState } from "react";

/* Compnents */
import Form from "../../components/recover/Form";
import Footer from "../../components/footer";

/* Style */
import Style from "../../assets/styles/Recover.module.scss";
import companies from "../config";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const Recover = (props) => {
  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  let goBack = "/";

  if (companies[props.match.params.id]) {
    if (companies[props.match.params.id].recover) {
      goBack = `/company/${props.match.params.id}`;
    }
  }

  return (
    <>
      <Form goBack={goBack} cliente={cliente} myStyle={myStyle} style={Style} />
      <Footer />
    </>
  );
};

export default Recover;
