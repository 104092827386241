import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
/* Compnents */
import Form from "../../components/register_company/Form";
import Footer from "../../components/footer";

/* Style */
import Style from "../../assets/styles/Register.module.scss";
import companies from "../config";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const Register = (props) => {
  const [email, setEmail] = useState(false);

  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  return (
    <>
      {companies[props.match.params.id] ? (
        <Form
          cliente={cliente}
          myStyle={myStyle}
          email={email}
          nameCompany={props.match.params.id}
          company={companies[props.match.params.id].id}
          style={Style}
        />
      ) : (
        <div className={Style.container}>
          <h2>
            <FormattedMessage id="CompanyDoesNotHaveARegistrationSystem" />
          </h2>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Register;
