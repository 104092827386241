import React, { useState } from "react";

const Info = (props) => {
  return (
    <article className={props.style.box__input}>
      <div>
        <h4>Email</h4>
        <h5>cri.maturana@gmail.com</h5>
      </div>
      <div>
        <h4>País</h4>
        <h5>Chile</h5>
      </div>
      <div>
        <h4>Holding</h4>
        <h5>Grupo Toro</h5>
      </div>
      <div>
        <h4>Rut</h4>
        <h5>17.797.075-1</h5>
      </div>
      <div>
        <h4>Ubicación</h4>
        <h5>Santiago, (RM)</h5>
      </div>
      <div>
        <h4>Empresa</h4>
        <h5>Rocha S.A.</h5>
      </div>
      <div>
        <h4>Teléfono</h4>
        <h5>+56 9 8981 76 21</h5>
      </div>
      <div>
        <h4>Sitio Web</h4>
        <h5>www.rocha.cl</h5>
      </div>
    </article>
  );
};

export default Info;
