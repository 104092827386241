import React from "react";

/* Components */
import Content from "../../components/about/Content";

/* Style */
import Style from "../../assets/styles/About.module.scss";

const About = (props) => {
  return (
    <>
      <Content style={Style} />
    </>
  );
};

export default About;
