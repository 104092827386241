import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

/* Component */
import { Form, Button, Alert } from "react-bootstrap";

/* Assets */
import Logo from "../../assets/images/logo.png";

const Code = (props) => {
  let history = useHistory();

  /*  Use  states  */
  const [form, setForm] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  });

  const [step, setStep] = useState(1);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.name === "code1") {
      document.getElementById("code2").focus();
    } else if (event.target.name === "code2") {
      document.getElementById("code3").focus();
    } else if (event.target.name === "code3") {
      document.getElementById("code4").focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      setStep(2);
    } else {
      history.push("/");
    }
  };

  return (
    <section data-testid="form" className={props.style.container__code}>
      <section className={props.style.box}>
        <div className={props.style.box__img}>
          <img src={Logo} alt="Logo" />
        </div>
        <h2>
          <FormattedMessage id="Register" />
        </h2>
        {step === 1 && (
          <>
            <h3>
              <FormattedMessage id="WeSendAVerificationCodeToYourEmail" />
            </h3>
            <h3>
              <FormattedMessage id="ContinueTheProcessFromThere" />
            </h3>
          </>
        )}
      </section>
    </section>
  );
};

export default Code;
