const messages = {
  Select: "Seleccione", // General
  Cancel: "Cancelar", // General
  ThereWasAnError: "Un error ha ocurrido", // General
  No: "No", // General
  Yes: "Si", // General
  Email: "Email", // General
  Password: "Contraseña", // General
  and: "y", // General
  Ok: "Aceptar", // General
  Loginwith: "Entrar con", // General
  Loading: "Cargando", // General
  Next: "Siguiente", // General
  ViewMore: "Ver más", // General
  Edit: "Editar", // General
  Search: "Buscar", // General
  Home: "Inicio", // Home -> Menu Left
  History: "Historial", // Home -> Menu Left
  About: "Sobre nosotros", // Home -> Menu Left
  Profile: "Perfil", // Home -> Menu Left
  Setting: "Ajustes", // Home -> Menu Left
  LogOut: "Cerrar sesión", // Home -> Menu Left
  WelcomeToKantoor: "Bienvenido a Kantoor", // Login - > Welcome
  WelcomeTo: "Bienvenido a", // Login - > Welcome
  YourOwnSpaceYourOwnDeskWith: "Tu propio espacio, tu propio escritorio con ", // Login - > Welcome
  reserveYourDesk: "reserva tu escritorio", // Login - > Welcome
  Start: "Iniciar", // Login - > Welcome
  VisitOurNetworks: "Visita nuestras redes", // Footer - > Index
  TermsAndConditions: "Terminos y condiciones",
  WriteYourEmail: "Escribe tu email", // Login - > Form
  WriteYourNewEmail: "Escribe tu nuevo email", // Login - > Form
  WriteYourPass: "Escribe tu password", // Login - > Form
  WriteYourNewPass: "Escribe tu nuevo password", // Login - > Form
  WriteLastName: "Escribe tu apellido", // Login - > Form
  SelectYourArea: "Selecciona tu área", // Login - > Form
  IDoNotBelongToAnyArea: "No pertenezco a ninguna área", // Login - > Form
  LogIn: "Iniciar sesión", // Login - > Form
  WrongUsernameOrPassword: "password o user incorrecto", // Login - > Form
  CreateAccount: "Crear cuenta", // Login - > Form
  AccessWith: "Acceder con", // Login - > Form
  RecoverPassword: "Recuperar contraseña", // Login - > Form
  Register: "Registro", // Register - > Form
  WriteYourName: "Escribe tu nombre", // Register - > Form
  WriteYourRut: "Escribe tu rut", // Register - > Form
  WriteYourPhone: "Escribe tu teléfono", // Register - > Form
  ConfirmYourEmail: "Confirma tu contraseña", // Register - > Form
  PressEnterToContinue: "Presione enter para continuar", // Register - > Form
  WeSendAVerificationCodeToYourEmail:
    "Enviamos un código de verificación a tu correo", // Register - > Code
  ThanksForSigningUpFor: "Gracias por registrarse en", // Register - > Code
  RememberToCompleteYourProfile: "Recuerda completar tu perfil", // Register - > Code
  RecoverPass: "Recuperar contraseña", // Recover - > Form
  PasswordRecoveredCheckYourEmail: "Contraseña recuperada revisa tu email", // Recover - > Message
  Reservation: "Reserva", // Reservation - > Step1
  DoYouWantToReserveASpace: "¿Quieres reservar un espacio?", // Reservation - > Step1
  Step1Text1:
    "Comienza para reservar un puesto, sucursal y oficina de tu gusto, el espacio de tu preferencia y comienza a trabajar, sigue el formulario.", // Reservation - > Step1
  PressNextToContinue: "Presione siguente para continuar",
  SelectTheCompanyYouWantToRent: "Selecciona la empresa que quieres arrendar", // Reservation - > Step company
  SelectCompany: "Selecciona empresa", // Reservation - > Step company
  Contract: "Contrato", // Reservation - > Step company
  Opened: "Abierto", // Reservation - > Step company
  EnterPlaceOfPreference: "Ingrese lugar de preferencia", // Reservation - > Step division
  SelectThePlaceOfPreference: "Selecciona el lugar de preferencia", // Reservation - > Step division
  WhatAreYouLookingFor: "¿Que está buscando?", // Reservation - > Step bags
  SelectYourPreference: "Seleccione su preferencia", // Reservation - > Step bags
  DayAndTimeOfReservation: "Día y hora de la reserva", // Reservation - > Step reservation
  SelectTheDayAndTimeYouWantToReserve:
    "Selecciona el día y hora que quieres reservar", // Reservation - > Step time
  CompleteTheRequiredFields: "Complete los campos requeridos", // Reservation - > Step time
  StartTimeMustBeLessThanEndTime:
    "Hora de inicio debe ser menor que hora final", // Reservation - > Step time
  StartDateMustBeLessThanEndDate:
    "Fecha de inicio debe ser menor que fecha final", // Reservation - > Step time
  Schedule: "Agenda", // Reservation - > Step time
  Week: "Semana", // Reservation - > Step time
  Weeks: "Semanas", // Reservation - > Step time
  Day: "Día",
  Days: "Días", // Reservation - > Step time
  SeeSchedule: "Ver agenda", // Reservation - > Step time
  SelectBuilding: "Selecciona edificio", // Reservation - > Step building
  SelectTheBuildingWhereYouWantToRent:
    "Selecciona el edificio donde quieres arrendar", // Reservation - > Step building
  Guests: "Invitados", // Reservation - > Step Attr
  HowManyGuests: "Cuantos invitados?",
  SendTheInvitation: "Envíe la invitación", // Reservation - > Step guests
  AnEmailWithTheReservationWillArriveToAllUsers:
    "Les llegara un correo con la reserva a todos los usuarios", // Reservation - > Step guests
  WriteTheGuestsEmail: `Escribe el mail del invitado`, // Reservation - > Step guests
  Booking: "Reserva", // Reservation - > Step guests
  WriteYourPreferences: "Escribe tus preferencias", //Reservation -> Step guests
  WriteEmailAndPreferencesOfTheGuests:
    "Escribe email y preferencias de los invitados", //Reservation -> Step guests
  Total: "Total", //Reservation -> Step guests
  IamAnOrganizer: "Organizo, pero no participaré", //Reservation -> Step guests
  NoStock: "No stock", //Reservation -> Step guests
  YouHaveToCompleteEmailAndReservation: "Debes completar email y reserva", //Reservation -> Step guests
  YouMustTheRequiredFields: "Debes completar los campos requeridos", //Reservation -> Step guests
  WriteYour: "Escribe tu",
  Summary: "Resumen", //Reservation -> Step summary
  AllBookingsMustBeSelected: "Todos las reservas deben ser seleccionada", //Reservation -> Step summary
  BookingReady: "Reserva lista", //Reservation -> Step summary
  Summary1:
    "Ya te guardamos la reserva. Recuerda revisar o modificar tu reserva en el historial del menú principal",
  Summary2: "Otro usuario tomo la reserva",
  Summary3: "Has ocupado todos tus creditos",
  TheEmailsShouldNotBeTheSame: "Los emails no debe ser repetidos",
  AreYouSureToCancelTheReservation: "¿Estas seguro de anular la reserva?", // History
  Reservation: "Reserva", // History
  Creater: "Creador", // History
  ExpiredReservation: "Reserva expirada", // History
  Evaluation: "Evaluación", // History
  Favorite: "Favorito",
  TellUsYourOpinion: "Comentanos tu opinión", // History
  ModalFavoriteText: "Antes de guardar como favorito danos tu opinión",
  MakeReservation: "Realizar reserva", // History
  ChangePassword: "Cambiar contraseña", // Menu Profile
  ChangeLanguage: "Cambiar Idioma", // Menu Profile
  PasswordsDoNotMatch: "Las contraseña no coinciden", // Menu Profile
  PasswordUpdatedSuccessfully: "Contraseña actualizada con éxito", // Menu Profile
  Languages: "Idiomas", // Menu langauges
  Spanish: "Español", // Menu langauges
  English: "Ingles", // Menu langauges
  LanguageUpdatedSuccessfully: "Idioma actualizada con éxito", // Menu Language
  ThereIsCurrentlyNoDataInTheHistory: "Actualmente no hay datos en la lista",
  ThereIsCurrentlyNoDataInTheFavorite: "Actualmente no hay datos en favoritos",
  ContinueTheProcessFromThere: "continua el proceso desde ahí", //Validation
  ThisEmailAlreadyExists: "Este mail ya existe",
  PleaseConfirmYourEntryOrExitOfTheReservation:
    "Por favor confirma tu entrada o salida de la reserva", //CheckIn
  MessageCheckIn: "Gracias por confrimar tu llegada", //CheckIn
  MessageCheckOut: "Gracias por confirmar tu salida", //CheckIn
  GoBack: "Volver", //CheckIn
  SuccessfulAction: "Acción realizado con exito", //CheckIn
  ThereAreNotData: "No hay datos", //CheckIn
  From: "Desde", //CheckIn
  To: "Hasta", //CheckIn
  Guests: "Invitados", //CheckIn
  HowManyReservationsDoYouWantToMake: "¿Cuantas reserva desea realizar?",
  Resources: "Recursos",
  List: "Listado",
  Map: "Mapa",
  Error500: "Solo puede ser anulada por el creador de la reserva",
  Close: "Cerrar",
  Cannotbook: "No puedes validar tu reserva si presentas sintomas",
  ExternalReservationRequest: "Solicitud sala cowork",
  Date: "Fecha",
  StartTime: "Hora inicio",
  EndTime: "Hora termino",
  Request: "Solicitud",
  People: "Personas",
  RequestSentSuccessfully: "Solicitud enviada con éxito",
  RequestCouldNotBeSent: "Solicitud no pudo se enviada",
  TryAgain: "Intenta nuevamente",
  MyRequests: "Mis solicitudes sala cowork",
  AllRequests: "Administración salas cowork",
  Description: "Descripción",
  WaitingForAnswer: "En espera de respuesta",
  Accepted: "Aceptada",
  Update: "Actualizar",
  AcceptRequest: "Aceptar solicitud",
  RequestUpdated: "Solicitud actualizada",
  RequestRejected: "Solicitud rechazada",
  RequestAccepted: "Solicitud aceptada",
  AssignedDivision: "División asignada",
  AssignedRoom: "Sala asignada",
  AnswerDate: "Fecha de respuesta",
  SelectATime: "Seleccione un horario",
  MakeABooking: "Hacer un reserva",
  ReservationMadeSuccessfully: "Reserva realizada con éxito",
  ReservationCouldNotBeMade: "Reserva no pudo ser realizada",
  YouCanSeeIfYourCheckInIsAvailable:
    "Puedes ver si tu check-in esta disponible",
  MessageSeeCheckin: "Estara habilitado una hora antes de tu reserva",
  YouDoNotHaveCheckInAvailable: "No tienes check-in disponible",
  DoesNotRepeat: "No repetir",
  SameDayWeekly: "Repetir mismo día todas las semanas",
  EveryWeekday: "Todos los días de la semana en curso",
  EveryMonthday: "Todos los días del mes",
  BookingType: "Tipo de reserva",
  BookingsMade: "Reservas realizadas",
  BookingsNotMadeDue: "Reservas no realizadas por no disponibilidad",
  Schedule1: "Horario",
  Day0: "Domingo",
  Day1: "Lunes",
  Day2: "Martes",
  Day3: "Miercoles",
  Day4: "Jueves",
  Day5: "Viernes",
  Day6: "Sabado",
  RepeatOptions: "Opciones de repetición",
  Personalized: "Personalizado",
  MessagePersonalized: "Días que deseas reservar (considera un mes calendario)",
  MyBookings: "Mis reservas",
  DeterminingAvailability: "Determinando disponibilidad",
  CompanyDoesNotHaveARegistrationSystem:
    "Esta empresa no tienen permite el registro.",
  ErrorRegisterMessage: "Problemas con el registros, favor revisar tus datos.",
  GoToLogin: "Ir al iniciar sesión",
  MeesageCredit: "Le informamos que ha ocupado todos sus créditos asignados",
  TryAgain: "Volver a intentar",
  TotalCredits: "Total créditos",
  CreditsUsed: "Créditos usados",
  NonCurrentReservation: "Reserva anulada",
  "sobrepasa-saldo-no-permitido": "Sobrepasa el límite de créditos",
  ValidateAllReservations: "Validar todas las reservas",
  Validate: "Validar",
  Validated: "Validado",
  Reception: "Recepción",
  MessageReceptionNoReservation: "El usuario no tiene reservas para hoy",
  MustCompleteThepatente: "Debe completar la patente",
  DoesNotHaveNecessaryPermissions: "No tiene permisos necesarios",
  YouCannotMakeAReservationForMoreThan: "No puede realizar una reserva mayor a",
  Available: "Disponible",
  OverlapsReservationMessage:
    "Tienes una reserva de este tipo de espacio que se traslapa con otra. Selecciona otra fecha o distintos horarios",
  SearchBy: "Búsqueda por",
  Current: "Vigente", //CalendarMenu
  Expired: "Expirado", //CalendarMenu
  AddOrRemoveGuests: "Agregar o quitar invitados",
  YouCannotAddMoreUsersThanTheAllowedCapacity:
    "No puede agregar más usuarios que el aforo permitido",
  Filter: "Filtro",
  ThereIsNoDataWithThisFilter: "No se encontraron datos con este filtro",
};

export default messages;
