import React, { useEffect, useState } from "react";

/* Components */
import List from "../../components/history/List";

/* Style */
import Style from "../../assets/styles/History.module.scss";
import ReservationStyle from "../../assets/styles/Reservation.module.scss";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const HistoryComponent = (props) => {
  const [search, setSearch] = useState(false);
  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (props.location.state) {
      setSearch(props.location.state.search);
    } else {
      setSearch(false);
    }
  }, [props.location.state]);

  return (
    <>
      <List
        disabled={props.disabled}
        title="History"
        cliente={cliente}
        myStyle={myStyle}
        search={search}
        evaluada={false}
        reservationStyle={ReservationStyle}
        style={Style}
      />
    </>
  );
};

export default HistoryComponent;
