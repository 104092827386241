import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./microsoftConfig";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import axios from "axios";

/* Component */
import { Form, Button, Alert } from "react-bootstrap";

/* Assets */
import Logo from "../../assets/images/logo.png";

/* Actions  */
import { getLogin, resetLogin } from "../../actions/login";

const FormLogin = (props) => {
  /*  Use  states  */
  const { instance } = useMsal();
  const [form, setForm] = useState({
    user: "",
    pass: "",
  });

  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  /* Reset status error */
  useEffect(() => {
    return () => {
      if (state.status === 401 || state.status === 501) {
        dispatch(resetLogin());
      }
    };
  }, [dispatch]);

  /*  Function for inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /*  Post  Login  */
  const postLogin = (event) => {
    event.preventDefault();

    const obj = {
      provider: "kantoor",
      loginCompany: props.company,
      google: { uid: "", name: "", token: "" },
      facebook: { uid: "", name: "", token: "" },
      office365: { uid: "", name: "", token: "" },
      kantoor: {
        email: form.user.toLowerCase(),
        password: form.pass,
      },
    };
    dispatch(getLogin(obj));
  };

  /* Login microsoft */
  const handleLoginMicrosoft = async (instance) => {
    instance
      .loginPopup(loginRequest)
      .then((resp) => {
        const obj = {
          provider: "office365",
          loginCompany: props.company,
          google: { uid: "", name: "", token: "" },
          office365: {
            uid: resp.account.homeAccountId,
            name: resp.account.name,
            email: resp.account.username,
            token: resp.accessToken,
            img: "test",
          },
          facebook: { uid: "" },
          kantoor: { email: "", password: "" },
        };

        dispatch(getLogin(obj));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  /* Login with facebook */
  const responseFacebook = async (response) => {
    const response1 = await axios.get(
      `https://graph.facebook.com/v10.0/${response.id}/picture?redirect=0&height=250&width=250&type=normal&access_token=${response.accessToken}`
    );
    let info = await response1.data;
    const obj = {
      provider: "facebook",
      loginCompany: props.company,
      facebook: {
        uid: response.id,
        name: response.name,
        email: response.email,
        token: response.accessToken,
        img: info.data.url,
      },

      kantoor: { email: "", password: "" },
    };

    dispatch(getLogin(obj));
  };

  const responseGoogle = (response) => {
    console.log(response);
    if (response.accessToken) {
      const obj = {
        provider: "google",
        loginCompany: props.company,
        facebook: { uid: "", name: "", token: "" },
        office365: { uid: "", name: "", token: "" },
        kantoor: { uid: "", name: "", token: "" },
        google: {
          uid: response.profileObj.googleId,
          name: response.profileObj.name,
          usename: response.profileObj.email,
          token_access: response.accessToken,
        },
      };
      dispatch(getLogin(obj));
    }
  };

  /* Local store */
  let auth = "false";
  if (typeof localStorage !== "undefined") {
    auth = localStorage.getItem("auth");
  }

  let ruta = "/home";
  auth = auth === "true";

  return auth ? (
    <div data-testid="form" className={props.style.container__login}>
      <Redirect to={ruta} />
    </div>
  ) : (
    <section data-testid="form" className={props.style.container__login}>
      <div className={props.style.box}>
        {props.cliente === "" ? (
          <div className={props.style.box__img}>
            <img src={Logo} alt="Logo" />
          </div>
        ) : (
          <div className={props.myStyle.box__logomix}>
            <div className={props.myStyle[`logo_${props.cliente}`]}></div>
            <div></div>
          </div>
        )}

        <Form onSubmit={!state.loading ? postLogin : null}>
          <div id="testupload" className={props.style.box__form}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <FormattedMessage id="WriteYourEmail">
                {(msg) => (
                  <Form.Control
                    value={form.user}
                    onChange={(e) => handleChange(e)}
                    name={"user"}
                    autoComplete="username"
                    type="text"
                    placeholder={msg}
                    className={props.myStyle[`input_${props.cliente}`]}
                  />
                )}
              </FormattedMessage>
            </Form.Group>
          </div>
          <div className={props.style.box__form}>
            <Form.Group controlId="pass">
              <Form.Label>
                <FormattedMessage id="Password" />
              </Form.Label>
              <FormattedMessage id="WriteYourPass">
                {(msg) => (
                  <Form.Control
                    value={form.pass}
                    onChange={(e) => handleChange(e)}
                    name={"pass"}
                    autoComplete="current-password"
                    type="password"
                    placeholder={msg}
                    className={props.myStyle[`input_${props.cliente}`]}
                  />
                )}
              </FormattedMessage>
            </Form.Group>
          </div>

          <div className={props.style.box__form}>
            <div className={props.style.box__form__link}>
              {props.validateCompanies(
                props.company,
                props.companies,
                "recover"
              ) ? (
                <Link to={`/recover-company/${props.company}`}>
                  <FormattedMessage id="RecoverPassword" />
                </Link>
              ) : (
                <Link to="/recover">
                  <FormattedMessage id="RecoverPassword" />
                </Link>
              )}
            </div>
          </div>
          <div className={props.style.box__form}>
            <Button
              variant="main"
              disabled={state.loading}
              type="submit"
              className={props.myStyle[`btn_${props.cliente}`]}
            >
              <div className={props.style.box__button}>
                <span>
                  {state.loading ? (
                    <FormattedMessage id="Loading" />
                  ) : (
                    <FormattedMessage id="LogIn" />
                  )}
                </span>
                <span>
                  <i className="far fa-arrow-alt-circle-right"></i>
                </span>
              </div>
            </Button>
          </div>

          {state.status === 401 && (
            <div className={props.style.box__error}>
              <Alert variant={"danger"} className="top center">
                <span>
                  <FormattedMessage id="WrongUsernameOrPassword" />
                </span>
              </Alert>
            </div>
          )}
          {state.status === 501 && (
            <div className={props.style.box__error}>
              <Alert variant={"danger"} className="top center">
                <span>
                  <FormattedMessage id="WrongUsernameOrPassword" />
                </span>
              </Alert>
            </div>
          )}

          <div className={props.style.box__footer}>
            <div>
              {/*     <Link to="/validation">
                <FormattedMessage id="CreateAccount" />
              </Link> */}
              {props.validateCompanies(
                props.company,
                props.companies,
                "createAccount"
              ) && (
                <Link to={`/register-company/${props.company}`}>
                  <FormattedMessage id="CreateAccount" />
                </Link>
              )}
            </div>
            <div className={props.style.box__footer__icon}>
              {props.validateCompanies(
                props.company,
                props.companies,
                "textLoginWith",
                false
              ) && (
                <span>
                  <FormattedMessage id="AccessWith" />
                </span>
              )}
              {props.validateCompanies(
                props.company,
                props.companies,
                "googleLogin",
                false
              ) && (
                <GoogleLogin
                  clientId="674973170963-vpqj81chgbd5lhg3bmbjqpr7qts648c7.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <span
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <i className="fab fa-google"></i>
                    </span>
                  )}
                />
              )}
              {/*      <FacebookLogin
                appId="280982213691285"
                fields="name,email,picture"
                // onClick={componentClicked}
                callback={responseFacebook}
                render={(renderProps) => (
                  <a onClick={renderProps.onClick}>
                    <i className="fab fa-facebook-square"></i>
                  </a>
                )}
              /> */}
              {props.validateCompanies(
                props.company,
                props.companies,
                "microsoftLogin",
                false
              ) && (
                <span onClick={() => handleLoginMicrosoft(instance)}>
                  <i className="fab fa-microsoft"></i>
                </span>
              )}
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
};

FormLogin.propTypes = {
  state: PropTypes.object,
};

export default FormLogin;
