import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Config from "../../server/controllers/config";

import moment from "moment";

/* Actions */
import { getEdificio, resetEdificio } from "../../actions/reservation";

/* Components */
import Information from "./Information";
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";

const StepEdificio = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  /* Get companies */
  useEffect(() => {
    const callsAPI = async () => {
      const obj = {
        empresa_id: props.form.empresa_id,
        division_id: props.form.ciudad,
        subdivision_id: props.form.provincia,
        subsubdivision_id: props.form.comuna,
        arr_alternativa_id: props.form.atributos, // []
        clave_granularidad: props.form.formato_tiempo,
        fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
        hora_inicio: props.form.inicio,
        duracion: props.form.duracion,
      };

      dispatch(getEdificio(obj));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    if (state.statusEdificio === 200) {
      const total = state.dataEdificio.datos.length;
      if (total === 1) {
        props.handleChange({
          target: {
            name: "edificio_id",
            value: state.dataEdificio.datos[0].id,
          },
        });
        if (props.direction === "right") {
          props.handleMemory({ name: "step8" });
          props.nextStep();
        } else {
          props.backStep();
        }
      }
    }
  }, [state]);

  /* Reset  companies */
  useEffect(() => {
    return () => {
      dispatch(resetEdificio());
    };
  }, [dispatch]);

  if (state.loadingEdificio) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusEdificio === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusEdificio === 200
  ) {
    if (!state.dataEdificio.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <section className={props.style.container__content}>
      <Information
        cliente={props.cliente}
        myStyle={props.myStyle}
        data={props.form.imagenes}
        style={props.style}
      />
      <h2 className={props.style.start}>
        <FormattedMessage id="SelectBuilding" />
      </h2>
      <p>
        <FormattedMessage id="SelectTheBuildingWhereYouWantToRent" />
      </p>
      <div className={`${props.style.box__footer}`}>
        <section className={`${props.style.box__img}`}>
          {state.dataEdificio.datos.map((item) => (
            <article
              key={item.id}
              onClick={() => {
                props.handleChange({
                  target: { name: "edificio_id", value: item.id },
                });
                props.handleChange({
                  target: { name: "nodosCount", value: 0 },
                });
                props.handleChange({
                  target: { name: "nodos", value: [] },
                });
                props.handleCount(1);
                props.handleMemory({ name: "step8" });
                props.nextStep();
              }}
            >
              <span
                className={`${props.style.box__imgheart} ${props.style.box__hearthactive}`}
              >
                <i className="fas fa-heart"></i>
              </span>
              <div className={`${props.style.box__imgelement}`}>
                <img src={`${Config.api}${item.avatar3.archivo_imagen}`} />
              </div>
              <h2>{item.nombre}</h2>
            </article>
          ))}
        </section>
      </div>
    </section>
  );
};

export default StepEdificio;
