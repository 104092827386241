import React, { useState } from "react";

/* Component */
import Step1 from "./StepOne";
import Step2 from "./StepTwo";

const Content = (props) => {
  const [step, setStep] = useState(1);

  /* Form  */
  const [form, setForm] = useState({
    ciudad: "",
  });

  const backStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  let container;

  switch (step) {
    case 2:
      container = (
        <Step2 handleChange={handleChange} form={form} style={props.style} />
      );
      break;
    default:
      container = <Step1 nextStep={nextStep} style={props.style} />;
      break;
  }

  return (
    <section className={props.style.container}>
      {container}
      <div className={props.style.box__arrow}>
        <span onClick={backStep}>
          <i className="far fa-arrow-alt-circle-left"></i>
        </span>
        <span onClick={nextStep}>
          <i className="far fa-arrow-alt-circle-right"></i>
        </span>
      </div>
    </section>
  );
};

export default Content;
