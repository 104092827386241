import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const MessageCompnent = (props) => {
  return (
    <section className={props.style.container__content__message}>
      <h2>
        <FormattedMessage id={props.text} />.
      </h2>
      <div>
        <Button
          variant="main"
          onClick={() => props.backStep(6)}
          className={props.myStyle[`btn_${props.cliente}`]}
        >
          <div className={props.style.box__button}>
            <span>
              <FormattedMessage id="GoBack" />
            </span>
            <span>
              <i className="far fa-arrow-alt-circle-left"></i>
            </span>
          </div>
        </Button>
      </div>
    </section>
  );
};

export default MessageCompnent;
