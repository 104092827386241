const messages = {
  Select: "Select", // General
  Cancel: "Cancel", // General
  ThereWasAnError: "There was an error", // General
  No: "No", // General
  Yes: "Yes", // General
  Email: "Email", // General
  Password: "Password", // General
  and: "and", // General
  Ok: "Ok", // General
  Loginwith: "Login with", // General
  Loading: "Loading", // General
  Next: "Next", // General
  ViewMore: "View more", // General
  Edit: "Edit", // General
  Search: "Search", // General
  Home: "Home", // Home -> Menu Left
  History: "History", // Home -> Menu Left
  About: "About", // Home -> Menu Left
  Profile: "Profile", // Home -> Menu Left
  Setting: "Setting", // Home -> Menu Left
  LogOut: "Log out", // Home -> Menu Left
  WelcomeToKantoor: "Welcome to Kantoor", // Login - > Welcome
  WelcomeTo: "Welcome to", // Login - > Welcome
  YourOwnSpaceYourOwnDeskWith: "Your own space, your own desk with", // Login - > Welcome
  reserveYourDesk: "reserve your desk", // Login - > Welcome
  Start: "Start", // Login - > Welcome
  VisitOurNetworks: "Visit our networks", // Footer - > Index
  TermsAndConditions: "Terms and Conditions", // Login - > Form
  WriteYourEmail: "Write your email", // Login - > Form
  WriteYourNewEmail: "Write your new email", // Login - > Form
  WriteYourPass: "Write your password", // Login - > Form
  WriteYourNewPass: "Write your new password", // Login - > Form
  WriteLastName: "Write your last name", // Login - > Form
  SelectYourArea: "Select your area", // Login - > Form
  IDoNotBelongToAnyArea: "I do not belong to any area", // Login - > Form
  LogIn: "Log in", // Login - > Form
  WrongUsernameOrPassword: "Wrong username or password", // Login - > Form
  CreateAccount: "Create Account", // Login - > Form
  AccessWith: "Access with", // Login - > Form
  RecoverPassword: "Recover password", // Login - > Form
  Register: "Register", // Register - > Form
  WriteYourName: "Write your name", // Register - > Form
  WriteYourRut: "Write your rut", // Register - > Form
  WriteYourPhone: "Write your phone", // Register - > Form
  ConfirmYourEmail: "Confirm your password", // Register - > Form
  PressEnterToContinue: "Press enter to continue", // Register - > Form
  WeSendAVerificationCodeToYourEmail:
    "We send a verification code to your email", // Register - > Code
  ThanksForSigningUpFor: "Thanks for signing up for",
  RememberToCompleteYourProfile: "Remember to complete your profile", // Register - > Code
  RecoverPass: "Recover password", // Recover - > Form
  PasswordRecoveredCheckYourEmail: "Password recovered check your email", // Recover - > Message
  Reservation: "Reservation", // Reservation - > Step1
  DoYouWantToReserveASpace: "Do you want to reserve a space?", // Reservation - > Step1
  Step1Text1:
    "Start by reserving a position, branch and office of your liking, the space of your preference and start working, follow the form", // Reservation - > Step1
  PressNextToContinue: "Press next to continue", // Reservation - > Step1
  SelectTheCompanyYouWantToRent: "Select the company you want to rent", // Reservation - > Step company
  SelectCompany: "Select company", // Reservation - > Step company
  Contract: "Contract", // Reservation - > Step company
  Opened: "Opened", // Reservation - > Step company
  EnterPlaceOfPreference: "Enter place of preference", // Reservation - > Step division
  SelectThePlaceOfPreference: "Select the place of preference", // Reservation - > Step division
  WhatAreYouLookingFor: "What are you looking for", // Reservation - > Step bags
  SelectYourPreference: "Select your preference", // Reservation - > Step bags
  DayAndTimeOfReservation: "Day and time of reservation", // Reservation - > Step time
  SelectTheDayAndTimeYouWantToReserve:
    "Select the day and time you want to reserve", // Reservation - > Step time
  CompleteTheRequiredFields: "Complete the required fields", // Reservation - > Step time
  StartTimeMustBeLessThanEndTime: "Start time must be less than end time", // Reservation - > Step time
  StartDateMustBeLessThanEndDate: "Start date must be less than end date", // Reservation - > Step time
  Schedule: "Schedule", // Reservation - > Step time
  Week: "Week", // Reservation - > Step time
  Weeks: "Weeks", // Reservation - > Step time
  Day: "Day", // Reservation - > Step time
  Days: "Days", // Reservation - > Step time
  SeeSchedule: "See schedule", // Reservation - > Step time
  SelectBuilding: "Select building", // Reservation - > Step building
  SelectTheBuildingWhereYouWantToRent:
    "Select the building where you want to rent", // Reservation - > Step building
  Guests: "Guests", // Reservation - > Step Attr
  HowManyGuests: "How many guests?",
  SendTheInvitation: "Send the invitation", // Reservation - > Step guests
  AnEmailWithTheReservationWillArriveToAllUsers:
    "An email with the reservation will arrive to all users", // Reservation - > Step guests
  WriteTheGuestsEmail: `Write the guest's email`, // Reservation - > Step guests
  Booking: "Booking", // Reservation - > Step guests
  WriteYourPreferences: "Write your preferences", //Reservation -> Step guests
  WriteEmailAndPreferencesOfTheGuests:
    "Write email and preferences of the guests", //Reservation -> Step guests
  Total: "Total", //Reservation -> Step guests
  IamAnOrganizer: "I organize, but will not participate", //Reservation -> Step guests
  NoStock: "No stock", //Reservation -> Step guests
  YouHaveToCompleteEmailAndReservation:
    "You have to complete email and reservation", //Reservation -> Step guests
  YouMustTheRequiredFields: "You must complete the required fields", //Reservation -> Step guests
  WriteYour: "Write your",
  Summary: "Summary", //Reservation -> Step summary
  AllReservationsMustBeSelected: "All reservations must be selected",
  BookingReady: "Booking ready",
  Summary1:
    "We already save the reservation for you. Remember to review or modify your reservation in the main menu history",
  Summary2: "Another user took the reservation",
  Summary3: "You have used all your credits",
  TheEmailsShouldNotBeTheSame: "The emails should not be the same",
  AreYouSureToCancelTheReservation: "Are you sure to cancel the reservation", // History
  Reservation: "Reservation", // History
  Creater: "Creater", // History
  ExpiredReservation: "Expired reservation", // History
  Evaluation: "Evaluation", // History
  Favorite: "Favorite",
  TellUsYourOpinion: "Tell us your opinion", // History
  ModalFavoriteText: "Before saving as a favorite, give us your opinion", // History\
  MakeReservation: "Make reservation", // History
  ChangePassword: "Change password", // Menu Profile
  ChangeLanguage: "Change Language", // Menu Profile
  PasswordsDoNotMatch: "Passwords do not match", // Menu Profile
  PasswordUpdatedSuccessfully: "Password updated successfully", // Menu Profile
  Languages: "Languages", // Menu langauges
  Spanish: "Spanish", // Menu langauges
  English: "English", // Menu langauges
  LanguageUpdatedSuccessfully: "Language updated successfully", // Menu Profile
  ThereIsCurrentlyNoDataInTheHistory: "There is currently no data in the list",
  ThereIsCurrentlyNoDataInTheFavorite:
    "There is currently no data in the favorite",
  ContinueTheProcessFromThere: "continue the process from there", //Validation
  ThisEmailAlreadyExists: "This email already exists", //Validation
  PleaseConfirmYourEntryOrExitOfTheReservation:
    "Please confirm your entry or exit of the reservation", //CheckIn
  MessageCheckIn: "Thank you for confirming your arrival", //CheckIn
  MessageCheckOut: "Thank you for confirming your exit", //CheckIn
  GoBack: "Go back", //CheckIn
  SuccessfulAction: "Successful action", //CheckIn
  ThereAreNotData: "There are not data", //CheckIn
  From: "From", //CheckIn
  To: "To", //CheckIn
  Guests: "Guests", //CheckIn
  HowManyReservationsDoYouWantToMake:
    "How many reservations do you want to make?",
  Resources: "Resources",
  List: "List",
  Map: "Map",
  Error500: "Can only be canceled by the creator of the reservation",
  Close: "Close",
  Cannotbook: "Can not book",
  ExternalReservationRequest: "Cowork room request",
  Date: "Date",
  StartTime: "Start time",
  EndTime: "End time",
  Request: "Request",
  People: "People",
  RequestSentSuccessfully: "Request sent successfully",
  RequestCouldNotBeSent: "Request could not be sent",
  TryAgain: "Try again",
  MyRequests: "My requests cowork room",
  AllRequests: "Cowork rooms administration",
  Description: "Description",
  WaitingForAnswer: "Waiting for answer",
  Accepted: "Accepted",
  Update: "Update",
  AcceptRequest: "Accept request",
  RequestUpdated: "Request updated",
  RequestRejected: "Request rejected",
  RequestAccepted: "Request accepted",
  AssignedDivision: "Assigned division",
  AssignedRoom: "Assigned room",
  AnswerDate: "Answer date",
  SelectATime: "Seleccione un horario",
  MakeABooking: "Make a booking",
  ReservationMadeSuccessfully: "Reservation made successfully",
  ReservationCouldNotBeMade: "Reservation could not be made",
  YouCanSeeIfYourCheckInIsAvailable:
    "You can see if your check-in is available",
  MessageSeeCheckin:
    "Remember your check-in is available one hour before your reservation",
  YouDoNotHaveCheckInAvailable: "You do not have check-in available",
  DoesNotRepeat: "Does not repeat",
  SameDayWeekly: "Same day Weekly",
  EveryWeekday: "Every weekday",
  EveryMonthday: "Every Monthday",
  BookingType: "Booking type",
  BookingsMade: "Bookings made",
  BookingsNotMadeDue: "Bookings not made due to lack of availability",
  Schedule1: "schedule",
  Day0: "Sunday",
  Day1: "Monday",
  Day2: "Tuesday",
  Day3: "Wednesday",
  Day4: "Thursday",
  Day5: "Friday",
  Day6: "Saturday",
  RepeatOptions: "Repeat options",
  Personalized: "Personalized",
  MessagePersonalized: "Days you want to reserve (consider a calendar month)",
  MyBookings: "My bookings",
  DeterminingAvailability: "Determining availability",
  CompanyDoesNotHaveARegistrationSystem:
    "Company does not have a registration system.",
  ErrorRegisterMessage: "Problem logging in, please check your details.",
  GoToLogin: "Go to login",
  MeesageCredit: "We inform you that you have used all your assigned credits",
  TryAgain: "try again",
  TotalCredits: "Total credits",
  CreditsUsed: "Credits used",
  NonCurrentReservation: "Booking canceled",
  "sobrepasa-saldo-no-permitido": "Exceed the credit limit",
  ValidateAllReservations: "Validate all reservations",
  Validate: "Validate",
  Validated: "Validated",
  Reception: "Reception",
  MessageReceptionNoReservation: "The user has no reservations for today",
  MustCompleteThepatent: "Must complete the patent",
  DoesNotHaveNecessaryPermissions: "Does not have necessary permissions",
  YouCannotMakeAReservationForMoreThan:
    "You cannot make a reservation for more than",
  Available: "available",
  OverlapsReservationMessage:
    "You have a reservation of this type of space that overlaps with another. Select another date or different times.",
  SearchBy: "Search by",
  Current: "Current", //CalendarMenu
  Expired: "Expired", //CalendarMenu
  AddOrRemoveGuests: "Add or remove guests",
  YouCannotAddMoreUsersThanTheAllowedCapacity:
    "You cannot add more users than the allowed capacity",
  Filter: "Filter",
  ThereIsNoDataWithThisFilter: "There is currently no data with this filter",
};

export default messages;
