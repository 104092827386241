import initialState from "./initialState";
import {
  GET_COMPANY_CONTRATO_INIT,
  GET_COMPANY_CONTRATO_SUCCESS,
  GET_COMPANY_CONTRATO_FAILURE,
  GET_COMPANY_SIN_CONTRATO_INIT,
  GET_COMPANY_SIN_CONTRATO_SUCCESS,
  GET_COMPANY_SIN_CONTRATO_FAILURE,
  GET_LABELS_INIT,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  GET_DIVISION_INIT,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAILURE,
  GET_SUBDIVISION_INIT,
  GET_SUBDIVISION_SUCCESS,
  GET_SUBDIVISION_FAILURE,
  GET_SUBSUBDIVISION_INIT,
  GET_SUBSUBDIVISION_SUCCESS,
  GET_SUBSUBDIVISION_FAILURE,
  GET_BOLSAS_INIT,
  GET_BOLSAS_SUCCESS,
  GET_BOLSAS_FAILURE,
  GET_PREGUNTAS_INIT,
  GET_PREGUNTAS_SUCCESS,
  GET_PREGUNTAS_FAILURE,
  GET_ALTERNATIVAS_INIT,
  GET_ALTERNATIVAS_SUCCESS,
  GET_ALTERNATIVAS_FAILURE,
  GET_HORARIOS_INIT,
  GET_HORARIOS_SUCCESS,
  GET_HORARIOS_FAILURE,
  GET_HORARIOS_VALIDO_INIT,
  GET_HORARIOS_VALIDO_SUCCESS,
  GET_HORARIOS_VALIDO_FAILURE,
  GET_CALENDARIO_INIT,
  GET_CALENDARIO_SUCCESS,
  GET_CALENDARIO_FAILURE,
  GET_MARCO_INIT,
  GET_MARCO_SUCCESS,
  GET_MARCO_FAILURE,
  GET_WEEK_INIT,
  GET_WEEK_SUCCESS,
  GET_WEEK_FAILURE,
  GET_EDIFICIO_INIT,
  GET_EDIFICIO_SUCCESS,
  GET_EDIFICIO_FAILURE,
  GET_DESEABLE_INIT,
  GET_DESEABLE_SUCCESS,
  GET_DESEABLE_FAILURE,
  GET_NODO1_INIT,
  GET_NODO1_SUCCESS,
  GET_NODO1_FAILURE,
  GET_NODO_HIJO_INIT,
  GET_NODO_HIJO_SUCCESS,
  GET_NODO_HIJO_FAILURE,
  GET_NODO_FINAL_INIT,
  GET_NODO_FINAL_SUCCESS,
  GET_NODO_FINAL_FAILURE,
  GET_IMAGEN_INIT,
  GET_IMAGEN_SUCCESS,
  GET_IMAGEN_FAILURE,
  GET_RECURSOS_INIT,
  GET_RECURSOS_SUCCESS,
  GET_RECURSOS_FAILURE,
  GET_RESERVA_INIT,
  GET_RESERVA_SUCCESS,
  GET_RESERVA_FAILURE,
  GET_RESERVA_EXTERNA_INIT,
  GET_RESERVA_EXTERNA_SUCCESS,
  GET_RESERVA_EXTERNA_FAILURE,
  GET_ACCEPT_RESERVA_EXTERNA_INIT,
  GET_ACCEPT_RESERVA_EXTERNA_SUCCESS,
  GET_ACCEPT_RESERVA_EXTERNA_FAILURE,
  GET_RESERVA_EXTERNA_LIST_INIT,
  GET_RESERVA_EXTERNA_LIST_SUCCESS,
  GET_RESERVA_EXTERNA_LIST_FAILURE,
  GET_RESERVA_ALL_EXTERNA_LIST_INIT,
  GET_RESERVA_ALL_EXTERNA_LIST_SUCCESS,
  GET_RESERVA_ALL_EXTERNA_LIST_FAILURE,
  GET_OPTIONS_RESERVATION_INIT,
  GET_OPTIONS_RESERVATION_SUCCESS,
  GET_OPTIONS_RESERVATION_FAILURE,
  RESET_COMPANY_CONTRATO,
  RESET_COMPANY_SIN_CONTRATO,
  RESET_LABELS,
  RESET_DIVISION,
  RESET_SUBDIVISION,
  RESET_SUBSUBDIVISION,
  RESET_BOLSAS,
  RESET_PREGUNTAS,
  RESET_ALTERNATIVAS,
  RESET_HORARIOS,
  RESET_HORARIOS_VALIDO,
  RESET_CALENDARIO,
  RESET_MARCO,
  RESET_WEEK,
  RESET_EDIFICIO,
  RESET_DESEABLE,
  RESET_NODO1,
  RESET_NODO_HIJO,
  RESET_NODO_FINAL,
  RESET_IMAGEN,
  RESET_RECURSOS,
  RESET_RESERVA,
  RESET_RESERVA_EXTERNA,
  RESET_ACCEPT_RESERVA_EXTERNA,
  RESET_RESERVA_EXTERNA_LIST,
  RESET_RESERVA_ALL_EXTERNA_LIST,
  RESET_OPTIONS_RESERVATION,
  RESET_ALL_RESERVATION,
} from "../../actions/reservation/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_COMPANY_CONTRATO:
      return {
        ...state,
        dataCompanyContrato: {},
        loadingCompanyContrato: true,
        statusCompanyContrato: 0,
      };
    case GET_COMPANY_CONTRATO_INIT:
      return {
        ...state,
        loadingCompanyContrato: true,
      };
    case GET_COMPANY_CONTRATO_SUCCESS:
      return {
        ...state,
        dataCompanyContrato: action.payload.data,
        loadingCompanyContrato: false,
        statusCompanyContrato: action.payload.status,
      };
    case GET_COMPANY_CONTRATO_FAILURE:
      return {
        ...state,
        dataCompanyContrato: {},
        loadingCompanyContrato: false,
        statusCompanyContrato: 501,
      };
    case RESET_COMPANY_SIN_CONTRATO:
      return {
        ...state,
        dataCompanySinContrato: {},
        loadingCompanySinContrato: true,
        statusCompanySinContrato: 0,
      };
    case GET_COMPANY_SIN_CONTRATO_INIT:
      return {
        ...state,
        loadingCompanySinContrato: true,
      };
    case GET_COMPANY_SIN_CONTRATO_SUCCESS:
      return {
        ...state,
        dataCompanySinContrato: action.payload.data,
        loadingCompanySinContrato: false,
        statusCompanySinContrato: action.payload.status,
      };
    case GET_COMPANY_SIN_CONTRATO_FAILURE:
      return {
        ...state,
        dataCompanySinContrato: {},
        loadingCompanySinContrato: false,
        statusCompanySinContrato: 501,
      };
    case RESET_LABELS:
      return {
        ...state,
        dataLabels: {},
        loadingLabels: true,
        statusLabels: 0,
      };
    case GET_LABELS_INIT:
      return {
        ...state,
        loadingLabels: true,
      };
    case GET_LABELS_SUCCESS:
      return {
        ...state,
        dataLabels: action.payload.data,
        loadingLabels: false,
        statusLabels: action.payload.status,
      };
    case GET_LABELS_FAILURE:
      return {
        ...state,
        dataLabels: {},
        loadingLabels: false,
        statusLabels: 501,
      };
    case RESET_DIVISION:
      return {
        ...state,
        dataDivision: {},
        loadingDivision: true,
        statusDivision: 0,
      };
    case GET_DIVISION_INIT:
      return {
        ...state,
        loadingDivision: true,
      };
    case GET_DIVISION_SUCCESS:
      return {
        ...state,
        dataDivision: action.payload.data,
        loadingDivision: false,
        statusDivision: action.payload.status,
      };
    case GET_DIVISION_FAILURE:
      return {
        ...state,
        dataDivision: {},
        loadingDivision: false,
        statusDivision: 501,
      };
    case RESET_SUBDIVISION:
      return {
        ...state,
        dataSubDivision: {},
        loadingSubDivision: true,
        statusSubDivision: 0,
      };
    case GET_SUBDIVISION_INIT:
      return {
        ...state,
        loadingSubDivision: true,
      };
    case GET_SUBDIVISION_SUCCESS:
      return {
        ...state,
        dataSubDivision: action.payload.data,
        loadingSubDivision: false,
        statusSubDivision: action.payload.status,
      };
    case GET_SUBDIVISION_FAILURE:
      return {
        ...state,
        dataSubDivision: {},
        loadingSubDivision: false,
        statusSubDivision: 501,
      };
    case RESET_SUBSUBDIVISION:
      return {
        ...state,
        dataSubSubDivision: {},
        loadingSubSubDivision: true,
        statusSubSubDivision: 0,
      };
    case GET_SUBSUBDIVISION_INIT:
      return {
        ...state,
        loadingSubSubDivision: true,
      };
    case GET_SUBSUBDIVISION_SUCCESS:
      return {
        ...state,
        dataSubSubDivision: action.payload.data,
        loadingSubSubDivision: false,
        statusSubSubDivision: action.payload.status,
      };
    case GET_SUBSUBDIVISION_FAILURE:
      return {
        ...state,
        dataSubSubDivision: {},
        loadingSubSubDivision: false,
        statusSubSubDivision: 501,
      };
    case RESET_BOLSAS:
      return {
        ...state,
        dataBolsas: {},
        loadingBolsas: true,
        statusBolsas: 0,
      };
    case GET_BOLSAS_INIT:
      return {
        ...state,
        loadingBolsas: true,
      };
    case GET_BOLSAS_SUCCESS:
      return {
        ...state,
        dataBolsas: action.payload.data,
        loadingBolsas: false,
        statusBolsas: action.payload.status,
      };
    case GET_BOLSAS_FAILURE:
      return {
        ...state,
        dataBolsas: {},
        loadingBolsas: false,
        statusBolsas: 501,
      };
    case RESET_PREGUNTAS:
      return {
        ...state,
        dataPreguntas: {},
        loadingPreguntas: true,
        statusPreguntas: 0,
      };
    case GET_PREGUNTAS_INIT:
      return {
        ...state,
        loadingPreguntas: true,
      };
    case GET_PREGUNTAS_SUCCESS:
      return {
        ...state,
        dataPreguntas: action.payload.data,
        loadingPreguntas: false,
        statusPreguntas: action.payload.status,
      };
    case GET_PREGUNTAS_FAILURE:
      return {
        ...state,
        dataPreguntas: {},
        loadingPreguntas: false,
        statusPreguntas: 501,
      };
    case RESET_ALTERNATIVAS:
      return {
        ...state,
        dataAlternativas: {},
        loadingAlternativas: true,
        statusAlternativas: 0,
      };
    case GET_ALTERNATIVAS_INIT:
      return {
        ...state,
        loadingAlternativas: true,
      };
    case GET_ALTERNATIVAS_SUCCESS:
      return {
        ...state,
        dataAlternativas: action.payload.data,
        loadingAlternativas: false,
        statusAlternativas: action.payload.status,
      };
    case GET_ALTERNATIVAS_FAILURE:
      return {
        ...state,
        dataAlternativas: {},
        loadingAlternativas: false,
        statusAlternativas: 501,
      };
    case RESET_HORARIOS:
      return {
        ...state,
        dataHorarios: {},
        loadingHorarios: true,
        statusHorarios: 0,
      };
    case GET_HORARIOS_INIT:
      return {
        ...state,
        loadingHorarios: true,
      };
    case GET_HORARIOS_SUCCESS:
      return {
        ...state,
        dataHorarios: action.payload.data,
        loadingHorarios: false,
        statusHorarios: action.payload.status,
      };
    case GET_HORARIOS_FAILURE:
      return {
        ...state,
        dataHorarios: {},
        loadingHorarios: false,
        statusHorarios: 501,
      };
    case RESET_HORARIOS_VALIDO:
      return {
        ...state,
        dataHorariosValido: {},
        loadingHorariosValido: false,
        statusHorariosValido: 0,
      };
    case GET_HORARIOS_VALIDO_INIT:
      return {
        ...state,
        loadingHorariosValido: true,
      };
    case GET_HORARIOS_VALIDO_SUCCESS:
      return {
        ...state,
        dataHorariosValido: action.payload.data,
        loadingHorariosValido: false,
        statusHorariosValido: action.payload.status,
      };
    case GET_HORARIOS_VALIDO_FAILURE:
      return {
        ...state,
        dataCalendario: {},
        loadingCalendario: false,
        statusCalendario: 501,
      };
    case RESET_CALENDARIO:
      return {
        ...state,
        dataCalendario: {},
        loadingCalendario: false,
        statusCalendario: 0,
      };
    case GET_CALENDARIO_INIT:
      return {
        ...state,
        loadingCalendario: true,
      };
    case GET_CALENDARIO_SUCCESS:
      return {
        ...state,
        dataCalendario: action.payload.data,
        loadingCalendario: false,
        statusCalendario: action.payload.status,
      };
    case GET_CALENDARIO_FAILURE:
      return {
        ...state,
        dataCalendario: {},
        loadingCalendario: false,
        statusCalendario: 501,
      };
    /* MARCO */
    case RESET_MARCO:
      return {
        ...state,
        dataMarco: {},
        loadingMarco: false,
        statusMarco: 0,
      };
    case GET_MARCO_INIT:
      return {
        ...state,
        loadingMarco: true,
      };
    case GET_MARCO_SUCCESS:
      return {
        ...state,
        dataMarco: action.payload.data,
        loadingMarco: false,
        statusMarco: action.payload.status,
      };
    case GET_MARCO_FAILURE:
      return {
        ...state,
        dataMarco: {},
        loadingMarco: false,
        statusMarco: 501,
      };
    /* WEEK */
    case RESET_WEEK:
      return {
        ...state,
        dataWeek: {
          data0: {},
          data1: {},
          data2: {},
          data3: {},
          data4: {},
          data5: {},
          data6: {},
        },

        loadingWeek: {
          data0: true,
          data1: true,
          data2: true,
          data3: true,
          data4: true,
          data5: true,
          data6: true,
        },

        statusWeek: {
          data0: 0,
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
          data6: 0,
        },
      };
    case GET_WEEK_INIT:
      return {
        ...state,
      };
    case GET_WEEK_SUCCESS:
      let myState = { ...state };

      myState.dataWeek[`data${action.index}`] = action.payload.data;
      myState.loadingWeek[`data${action.index}`] = false;
      myState.statusWeek[`data${action.index}`] = action.payload.status;
      return {
        ...myState,
      };
    case GET_WEEK_FAILURE:
      return {
        ...state,
        dataWeek: {
          data0: {},
          data1: {},
          data2: {},
          data3: {},
          data4: {},
          data5: {},
          data6: {},
        },

        loadingWeek: {
          data0: true,
          data1: true,
          data2: true,
          data3: true,
          data4: true,
          data5: true,
          data6: true,
        },

        statusWeek: {
          data0: 0,
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
          data6: 0,
        },
      };
    case RESET_EDIFICIO:
      return {
        ...state,
        dataEdificio: {},
        loadingEdificio: true,
        statusEdificio: 0,
      };
    case GET_EDIFICIO_INIT:
      return {
        ...state,
        loadingEdificio: true,
      };
    case GET_EDIFICIO_SUCCESS:
      return {
        ...state,
        dataEdificio: action.payload.data,
        loadingEdificio: false,
        statusEdificio: action.payload.status,
      };
    case GET_EDIFICIO_FAILURE:
      return {
        ...state,
        dataEdificio: {},
        loadingEdificio: false,
        statusEdificio: 501,
      };
    case RESET_DESEABLE:
      return {
        ...state,
        dataDeseable: {},
        loadingDeseable: true,
        statusDeseable: 0,
      };
    case GET_DESEABLE_INIT:
      return {
        ...state,
        loadingDeseable: true,
      };
    case GET_DESEABLE_SUCCESS:
      return {
        ...state,
        dataDeseable: action.payload.data,
        loadingDeseable: false,
        statusDeseable: action.payload.status,
      };
    case GET_DESEABLE_FAILURE:
      return {
        ...state,
        dataDeseable: {},
        loadingDeseable: false,
        statusDeseable: 501,
      };
    case RESET_NODO1:
      return {
        ...state,
        dataNodo1: {},
        loadingNodo1: true,
        statusNodo1: 0,
      };
    case GET_NODO1_INIT:
      return {
        ...state,
        loadingNodo1: true,
      };
    case GET_NODO1_SUCCESS:
      return {
        ...state,
        dataNodo1: action.payload.data,
        loadingNodo1: false,
        statusNodo1: action.payload.status,
      };
    case GET_NODO1_FAILURE:
      return {
        ...state,
        dataNodo1: {},
        loadingNodo1: false,
        statusNodo1: 501,
      };
    case RESET_NODO_HIJO:
      return {
        ...state,
        dataNodoHijo: {},
        loadingNodoHijo: false,
        statusNodoHijo: 0,
      };
    case GET_NODO_HIJO_INIT:
      return {
        ...state,
        loadingNodoHijo: true,
      };
    case GET_NODO_HIJO_SUCCESS:
      return {
        ...state,
        dataNodoHijo: action.payload.data,
        loadingNodoHijo: false,
        statusNodoHijo: action.payload.status,
      };
    case GET_NODO_HIJO_FAILURE:
      return {
        ...state,
        dataNodoHijo: {},
        loadingNodoHijo: false,
        statusNodoHijo: 501,
      };
    case RESET_NODO_FINAL:
      return {
        ...state,
        dataNodoFinal: {},
        loadingNodoFinal: true,
        statusNodoFinal: 0,
      };
    case GET_NODO_FINAL_INIT:
      return {
        ...state,
        loadingNodoFinal: true,
      };
    case GET_NODO_FINAL_SUCCESS:
      return {
        ...state,
        dataNodoFinal: action.payload.data,
        loadingNodoFinal: false,
        statusNodoFinal: action.payload.status,
      };
    case GET_NODO_FINAL_FAILURE:
      return {
        ...state,
        dataNodoFinal: {},
        loadingNodoFinal: false,
        statusNodoFinal: 501,
      };
    case RESET_IMAGEN:
      return {
        ...state,
        dataImagen: {},
        loadingImagen: true,
        statusImagen: 0,
      };
    case GET_IMAGEN_INIT:
      return {
        ...state,
        loadingImagen: true,
      };
    case GET_IMAGEN_SUCCESS:
      return {
        ...state,
        dataImagen: action.payload.data,
        loadingImagen: false,
        statusImagen: action.payload.status,
      };
    case GET_IMAGEN_FAILURE:
      return {
        ...state,
        dataImagen: {},
        loadingImagen: false,
        statusImagen: 501,
      };
    case RESET_RECURSOS:
      return {
        ...state,
        dataRecursos: {},
        loadingRecursos: false,
        statusRecursos: 0,
      };
    case GET_RECURSOS_INIT:
      return {
        ...state,
        loadingRecursos: true,
      };
    case GET_RECURSOS_SUCCESS:
      state.dataRecursos[action.index] = action.payload.data;
      return {
        ...state,
        dataRecursos: state.dataRecursos,
        loadingRecursos: false,
        statusRecursos: action.payload.status,
      };
    case GET_RECURSOS_FAILURE:
      return {
        ...state,
        dataRecursos: {},
        loadingRecursos: false,
        statusRecursos: 501,
      };
    /* Reserva  */
    case RESET_RESERVA:
      return {
        ...state,
        dataReserva: {},
        loadingReserva: false,
        statusReserva: 0,
      };
    case GET_RESERVA_INIT:
      return {
        ...state,
        loadingReserva: true,
      };
    case GET_RESERVA_SUCCESS:
      return {
        ...state,
        dataReserva: action.payload.data,
        loadingReserva: false,
        statusReserva: action.payload.status,
      };
    case GET_RESERVA_FAILURE:
      return {
        ...state,
        dataReserva: {},
        loadingReserva: false,
        statusReserva: 501,
      };
    /* Reserva  Externa */
    case RESET_RESERVA_EXTERNA:
      return {
        ...state,
        dataReservaExterna: {},
        loadingReservaExterna: false,
        statusReservaExterna: 0,
      };
    case GET_RESERVA_EXTERNA_INIT:
      return {
        ...state,
        loadingReservaExterna: true,
      };
    case GET_RESERVA_EXTERNA_SUCCESS:
      return {
        ...state,
        dataReservaExterna: action.payload.data,
        loadingReservaExterna: false,
        statusReservaExterna: action.payload.status,
      };
    case GET_RESERVA_EXTERNA_FAILURE:
      return {
        ...state,
        dataReservaExterna: {},
        loadingReservaExterna: false,
        statusReservaExterna: 501,
      };
    /* Reserva accept  Externa */
    case RESET_ACCEPT_RESERVA_EXTERNA:
      return {
        ...state,
        dataAcceptReservaExterna: {},
        loadingAcceptReservaExterna: false,
        statusAcceptReservaExterna: 0,
      };
    case GET_ACCEPT_RESERVA_EXTERNA_INIT:
      return {
        ...state,
        loadingAcceptReservaExterna: true,
      };
    case GET_ACCEPT_RESERVA_EXTERNA_SUCCESS:
      return {
        ...state,
        dataAcceptReservaExterna: action.payload.data,
        loadingAcceptReservaExterna: false,
        statusAcceptReservaExterna: action.payload.status,
      };
    case GET_ACCEPT_RESERVA_EXTERNA_FAILURE:
      return {
        ...state,
        dataAcceptReservaExterna: {},
        loadingAcceptReservaExterna: false,
        statusAcceptReservaExterna: 501,
      };
    /* Reserva  Externa list */
    case RESET_RESERVA_EXTERNA_LIST:
      return {
        ...state,
        dataReservaExternaList: {},
        loadingReservaExternaList: true,
        statusReservaExternaList: 0,
      };
    case GET_RESERVA_EXTERNA_LIST_INIT:
      return {
        ...state,
        loadingReservaExternaList: true,
      };
    case GET_RESERVA_EXTERNA_LIST_SUCCESS:
      return {
        ...state,
        dataReservaExternaList: action.payload.data,
        loadingReservaExternaList: false,
        statusReservaExternaList: action.payload.status,
      };
    case GET_RESERVA_EXTERNA_LIST_FAILURE:
      return {
        ...state,
        dataReservaExternaList: {},
        loadingReservaExternaList: false,
        statusReservaExternaList: 501,
      };
    /* Reserva all externa list */
    case RESET_RESERVA_ALL_EXTERNA_LIST:
      return {
        ...state,
        dataReservaAllExternaList: {},
        loadingReservaAllExternaList: true,
        statusReservaAllExternaList: 0,
      };
    case GET_RESERVA_ALL_EXTERNA_LIST_INIT:
      return {
        ...state,
        loadingReservaAllExternaList: true,
      };
    case GET_RESERVA_ALL_EXTERNA_LIST_SUCCESS:
      return {
        ...state,
        dataReservaAllExternaList: action.payload.data,
        loadingReservaAllExternaList: false,
        statusReservaAllExternaList: action.payload.status,
      };
    case GET_RESERVA_ALL_EXTERNA_LIST_FAILURE:
      return {
        ...state,
        dataReservaAllExternaList: {},
        loadingReservaAllExternaList: false,
        statusReservaAllExternaList: 501,
      };
    /* Reserva options reservation*/
    case RESET_OPTIONS_RESERVATION:
      return {
        ...state,
        dataOptionsReservation: {},
        loadingOptionsReservation: true,
        statusOptionsReservation: 0,
      };
    case GET_OPTIONS_RESERVATION_INIT:
      return {
        ...state,
        loadingOptionsReservation: true,
      };
    case GET_OPTIONS_RESERVATION_SUCCESS:
      return {
        ...state,
        dataOptionsReservation: action.payload.data,
        loadingOptionsReservation: false,
        statusOptionsReservation: action.payload.status,
      };
    case GET_OPTIONS_RESERVATION_FAILURE:
      return {
        ...state,
        dataOptionsReservation: {},
        loadingOptionsReservation: false,
        statusOptionsReservation: 501,
      };
    case RESET_ALL_RESERVATION:
      return {
        ...state,
        dataCompanyContrato: {},
        loadingCompanyContrato: true,
        statusCompanyContrato: 0,
        dataCompanySinContrato: {},
        loadingCompanySinContrato: true,
        statusCompanySinContrato: 0,
        dataLabels: {},
        loadingLabels: true,
        statusLabels: 0,
        dataDivision: {},
        loadingDivision: true,
        statusDivision: 0,
        dataSubDivision: {},
        loadingSubDivision: false,
        statusSubDivision: 0,
        dataSubSubDivision: {},
        loadingSubSubDivision: false,
        statusSubSubDivision: 0,
        dataBolsas: {},
        loadingBolsas: true,
        statusBolsas: 0,
        dataPreguntas: {},
        loadingPreguntas: true,
        statusPreguntas: 0,
        dataAlternativas: {},
        loadingAlternativas: true,
        statusAlternativas: 0,
        dataHorarios: {},
        loadingHorarios: true,
        statusHorarios: 0,
        dataHorariosValido: {},
        loadingHorariosValido: false,
        statusHorariosValido: 0,
        dataCalendario: {},
        loadingCalendario: true,
        statusCalendario: 0,
        dataEdificio: {},
        loadingEdificio: true,
        statusEdificio: 0,
        dataDeseable: {},
        loadingDeseable: true,
        statusDeseable: 0,
        dataNodo1: {},
        loadingNodo1: true,
        statusNodo1: 0,
        dataNodoHijo: {},
        loadingNodoHijo: false,
        statusNodoHijo: 0,
        dataNodoFinal: {},
        loadingNodoFinal: true,
        statusNodoFinal: 0,
        dataImagen: {},
        loadingImagen: true,
        statusImagen: 0,
        dataRecursos: {},
        loadingRecursos: false,
        statusRecursos: 0,
        dataReserva: {},
        loadingReserva: false,
        statusReserva: 0,
        dataReservaExterna: {},
        loadingReservaExterna: false,
        statusReservaExterna: 0,
      };

    default:
      return state;
  }
}
