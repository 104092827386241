import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

/* Component */
import { Form, Button, Alert } from "react-bootstrap";

/* Assets */
import Logo from "../../assets/images/logo.png";

/* Actions  */
import { getLoginValidate, resetLoginValidate } from "../../actions/validate";

const FormLogin = (props) => {
  /*  Use  states  */

  const [form, setForm] = useState({
    user: "",
    pass: "",
  });

  /*  User Select from redux  */
  const state = useSelector((state) => state.validate);
  const dispatch = useDispatch();

  /* Reset status error */
  useEffect(() => {
    return () => {
      if (state.status === 401 || state.status === 501) {
        dispatch(resetLoginValidate());
      }
    };
  }, [dispatch]);

  /*  Function for inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /*  Post  Login  */
  const postLogin = (event) => {
    event.preventDefault();

    const obj = {
      provider: "kantoor",
      loginCompany: props.company,
      google: { uid: "", name: "", token: "" },
      facebook: { uid: "", name: "", token: "" },
      office365: { uid: "", name: "", token: "" },
      kantoor: {
        email: form.user.toLowerCase(),
        password: form.pass,
      },
    };
    dispatch(getLoginValidate(obj));
  };

  /* Local store */

  return (
    <section data-testid="form" className={props.style.container__login}>
      <div className={props.style.box}>
        {props.cliente === "" ? (
          <div className={props.style.box__img}>
            <img src={Logo} alt="Logo" />{" "}
            <h6>
              <FormattedMessage id="Reception" />
            </h6>
          </div>
        ) : (
          <div className={props.myStyle.box__logomix}>
            <div className={props.myStyle[`logo_${props.cliente}`]}></div>
            <div></div>
          </div>
        )}

        <Form onSubmit={!state.loading ? postLogin : null}>
          <div id="testupload" className={props.style.box__form}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <FormattedMessage id="WriteYourEmail">
                {(msg) => (
                  <Form.Control
                    value={form.user}
                    onChange={(e) => handleChange(e)}
                    name={"user"}
                    autoComplete="username"
                    type="text"
                    placeholder={msg}
                    className={props.myStyle[`input_${props.cliente}`]}
                  />
                )}
              </FormattedMessage>
            </Form.Group>
          </div>
          <div className={props.style.box__form}>
            <Form.Group controlId="pass">
              <Form.Label>
                <FormattedMessage id="Password" />
              </Form.Label>
              <FormattedMessage id="WriteYourPass">
                {(msg) => (
                  <Form.Control
                    value={form.pass}
                    onChange={(e) => handleChange(e)}
                    name={"pass"}
                    autoComplete="current-password"
                    type="password"
                    placeholder={msg}
                    className={props.myStyle[`input_${props.cliente}`]}
                  />
                )}
              </FormattedMessage>
            </Form.Group>
          </div>

          <div className={props.style.box__form}>
            <div className={props.style.box__form__link}>
              {props.validateCompanies(
                props.company,
                props.companies,
                "recover"
              ) ? (
                <Link to={`/recover-company/${props.company}`}>
                  <FormattedMessage id="RecoverPassword" />
                </Link>
              ) : (
                <Link to="/recover">
                  <FormattedMessage id="RecoverPassword" />
                </Link>
              )}
            </div>
          </div>
          <div className={props.style.box__form}>
            <Button
              variant="main"
              disabled={state.loading}
              type="submit"
              className={props.myStyle[`btn_${props.cliente}`]}
            >
              <div className={props.style.box__button}>
                <span>
                  {state.loading ? (
                    <FormattedMessage id="Loading" />
                  ) : (
                    <FormattedMessage id="LogIn" />
                  )}
                </span>
                <span>
                  <i className="far fa-arrow-alt-circle-right"></i>
                </span>
              </div>
            </Button>
          </div>

          {state.status === 401 && (
            <div className={props.style.box__error}>
              <Alert variant={"danger"} className="top center">
                <span>
                  <FormattedMessage id="WrongUsernameOrPassword" />
                </span>
              </Alert>
            </div>
          )}
          {state.status === 501 && (
            <div className={props.style.box__error}>
              <Alert variant={"danger"} className="top center">
                <span>
                  <FormattedMessage id="WrongUsernameOrPassword" />
                </span>
              </Alert>
            </div>
          )}

          {state.errorMessage === "HaveNoTheRole" && (
            <div className={props.style.box__error}>
              <Alert variant={"danger"} className="top center">
                <span>
                  <FormattedMessage id="DoesNotHaveNecessaryPermissions" />
                </span>
              </Alert>
            </div>
          )}

          <div className={props.style.box__footer}>
            <div></div>
            <div className={props.style.box__footer__icon}></div>
          </div>
        </Form>
      </div>
    </section>
  );
};

FormLogin.propTypes = {
  state: PropTypes.object,
};

export default FormLogin;
