import initialState from "./initialState";
import {
  GET_HISTORY_INIT,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  RESET_HISTORY,
  GET_ANULAR_INIT,
  GET_ANULAR_SUCCESS,
  GET_ANULAR_FAILURE,
  RESET_ANULAR,
  GET_NIVELES_INIT,
  GET_NIVELES_SUCCESS,
  GET_NIVELES_FAILURE,
  RESET_NIVELES,
  GET_NOTA_INIT,
  GET_NOTA_SUCCESS,
  GET_NOTA_FAILURE,
  RESET_NOTA,
  GET_CALENDARTWO_INIT,
  GET_CALENDARTWO_SUCCESS,
  GET_CALENDARTWO_FAILURE,
  RESET_CALENDARTWO,
} from "../../actions/history/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_HISTORY:
      return {
        ...state,
        dataHistory: {},
        loadingHistory: true,
        statusHistory: 0,
      };
    case GET_HISTORY_INIT:
      return {
        ...state,
        loadingHistory: true,
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        dataHistory: action.payload.data,
        loadingHistory: false,
        statusHistory: action.payload.status,
      };
    case GET_HISTORY_FAILURE:
      return {
        ...state,
        dataHistory: {},
        loadingHistory: false,
        statusHistory: 501,
      };
    case RESET_ANULAR:
      return {
        ...state,
        dataAnular: {},
        loadingAnular: false,
        statusAnular: 0,
      };
    case GET_ANULAR_INIT:
      return {
        ...state,
        loadingAnular: true,
      };
    case GET_ANULAR_SUCCESS:
      return {
        ...state,
        dataAnular: action.payload.data,
        loadingAnular: false,
        statusAnular: action.payload.status,
      };
    case GET_ANULAR_FAILURE:
      return {
        ...state,
        dataAnular: {},
        loadingAnular: false,
        statusAnular: 501,
      };

    case RESET_NIVELES:
      return {
        ...state,
        dataNiveles: {},
        loadingNiveles: true,
        statusNiveles: 0,
      };
    case GET_NIVELES_INIT:
      return {
        ...state,
        loadingNiveles: true,
      };
    case GET_NIVELES_SUCCESS:
      return {
        ...state,
        dataNiveles: action.payload.data,
        loadingNiveles: false,
        statusNiveles: action.payload.status,
      };
    case GET_NIVELES_FAILURE:
      return {
        ...state,
        dataNiveles: {},
        loadingNiveles: false,
        statusNiveles: 501,
      };

    case RESET_NOTA:
      return {
        ...state,
        dataNota: {},
        loadingNota: false,
        statusNota: 0,
      };
    case GET_NOTA_INIT:
      return {
        ...state,
        loadingNota: true,
      };
    case GET_NOTA_SUCCESS:
      return {
        ...state,
        dataNota: action.payload.data,
        loadingNota: false,
        statusNota: action.payload.status,
      };
    case GET_NOTA_FAILURE:
      return {
        ...state,
        dataNota: {},
        loadingNota: false,
        statusNota: 501,
      };

    case RESET_CALENDARTWO:
      return {
        ...state,
        dataCalendarTwo: {},
        loadingCalendarTwo: true,
        statusCalendarTwo: 0,
      };
    case GET_CALENDARTWO_INIT:
      return {
        ...state,
        loadingCalendarTwo: true,
      };
    case GET_CALENDARTWO_SUCCESS:
      return {
        ...state,
        dataCalendarTwo: action.payload.data,
        loadingCalendarTwo: false,
        statusCalendarTwo: action.payload.status,
      };
    case GET_CALENDARTWO_FAILURE:
      return {
        ...state,
        dataCalendarTwo: {},
        loadingCalendarTwo: false,
        statusCalendarTwo: 501,
      };

    default:
      return state;
  }
}
