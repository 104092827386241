import React from "react";
import { compose, withProps } from "recompose";
import Config from "../../server/controllers/config";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  OverlayView,
} from "react-google-maps";

/* FontAwesome */

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const MyMarker = (props) => {
  return (
    <div className={props.style.box__marker}>
      <section>
        <div className={props.style.box__marker__img}>
          <img
            src={`${Config.api}${props.item.informacion_padre.vista_general.archivo_imagen}`}
          />
        </div>
        <h3>{props.item.edificacion.nombre}</h3>
        <h5>{`${props.item.informacion_padre.nombre_padre} / ${props.item.edi_division_reservada.nombre}`}</h5>
        <h5>{props.item.direccion.forma_larga}</h5>
      </section>
    </div>
  );
};

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDf9RfH8mWT94Vj0ypGJnYK5gWOJpPR238&v=3.exp",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const latitud =
    props.data.length > 0 ? props.data[0].direccion_cruda.latitud : -33.6167;

  const longitud =
    props.data.length > 0 ? props.data[0].direccion_cruda.longitud : -33.6167;
  return (
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: latitud, lng: longitud }}>
      {props.data.map((item) => {
        return (
          <OverlayView
            key={item.reserva.id}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
            position={{
              lat: item.direccion_cruda.latitud,
              lng: item.direccion_cruda.longitud,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <MyMarker item={item} style={props.style} />
          </OverlayView>
        );
      })}
    </GoogleMap>
  );
});

export default MyMapComponent;
