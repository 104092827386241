import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Form, Button } from "react-bootstrap";
import moment from "moment";

/* Actions */
import { getRecursos, resetRecursos } from "../../actions/reservation";

/* Components */
import Expired from "../common/Expired";
import { max, set } from "lodash";

const StepGuests = (props) => {
  const [error, setError] = useState(false);
  const [ready, setReady] = useState(false);
  const [reservaOtro, setReservaOtro] = useState(false);
  const inputEl = useRef(null);

  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  const totalGuests = parseInt(props.form.guests, 10) - 1;

  useEffect(() => {
    if (totalGuests <= 0) {
      const obj = {
        edi_division_id: props.form.nodos_final[0].id,
        clave_granularidad: props.form.formato_tiempo,
        fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
        hora_inicio: props.form.inicio,
        duracion: props.form.duracion,
      };

      const arr = [...props.form.emails];
      arr[0]["booking"] = props.form.nodos_final[0].id.toString();

      props.handleChange({
        target: { name: "emails", value: arr },
      });
      dispatch(getRecursos(obj, "index0"));
    } else {
      if (props.form.emails.length === 1) {
        const arr = [...props.form.emails];

        if (props.form.nodos_final.length === 1) {
          arr[0]["booking"] = props.form.nodos_final[0].id.toString();
          const obj = {
            edi_division_id: props.form.nodos_final[0].id,
            clave_granularidad: props.form.formato_tiempo,
            fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
            hora_inicio: props.form.inicio,
            duracion: props.form.duracion,
          };
          dispatch(getRecursos(obj, "index0"));
        }

        for (let i = 0; i < totalGuests; i++) {
          arr.push({
            index: `index${i + 1}`,
            email: "",
            booking: "",
            editable: "",
          });
        }
        setReady(true);
        props.handleChange({
          target: { name: "emails", value: arr },
        });
      }
    }
  }, [totalGuests]);

  useEffect(() => {
    if (localStorage.getItem("permite_reserva_para_otro")) {
      setReservaOtro(
        JSON.parse(localStorage.getItem("permite_reserva_para_otro"))
      );
    }
  }, []);

  /* Reset  recursos */
  useEffect(() => {
    const elem = document.getElementById("recursoEditable0");
    if (elem) {
      elem.click();
    }
  }, [state]);

  const handleChange = (event) => {
    setError(false);
    event.preventDefault();
    const element = event.target;
    const arr = [...props.form.emails];

    let index = arr.findIndex((x) => x.index === element.dataset.index);

    arr[index][element.name] = element.value;

    if (element.value === "" && element.name === "booking") {
      arr[index] = {
        booking: element.value,
        email: arr[index].email,
        editable: "",
        index: `index${index}`,
      };
    }

    props.handleChange({
      target: { name: "emails", value: arr },
    });
  };

  const handleChangeBox = (obj) => {
    const clave = obj.clave.split("--");

    setError(false);
    const arr = [...props.form.emails];
    let index = arr.findIndex((x) => x.index === obj.index);

    if (clave[1] && obj.value !== "") {
      if (arr[index]["clave"]) {
        let index1 = arr[index]["clave"].findIndex((x) => x.id === clave[1]);
        if (index1 < 0) {
          arr[index]["clave"].push({ id: clave[1], value: obj.name });
        } else {
          arr[index][arr[index]["clave"][index1].value] = "";
          arr[index]["clave"].splice(index1, 1);
          arr[index]["clave"].push({ id: clave[1], value: obj.name });
        }
      } else {
        arr[index]["clave"] = [{ id: clave[1], value: obj.name }];
      }
    }
    arr[index][obj.name] = obj.value;

    props.handleChange({
      target: { name: "emails", value: arr },
    });
  };

  const filterRecursos = (arr, exist, field) => {
    const result = arr.filter((o) => {
      if (exist) {
        return o.clave.includes(field);
      } else {
        return !o.clave.includes(field);
      }
    });
    return result;
  };

  const handleSubmit = (event) => {
    const arr = [...props.form.emails];
    const nodos_final = [...props.form.nodos_final];
    const count =
      parseInt(props.form.guests, 10) === 0
        ? 0
        : parseInt(props.form.guests, 10) - 1;
    let isWrong = false;
    const arrNoRepeat = [];

    for (let i = 0; i <= count; i++) {
      if (props.form.nodos_final.length === 1) {
        if (arrNoRepeat.includes(arr[i].email)) {
          if (arr[i].email !== "") {
            isWrong = "TheEmailsShouldNotBeTheSame";
          }
        }
        arrNoRepeat.push(arr[i].email);
        if (i === 0) {
          if (arr[i].email === "" || arr[i].booking === "") {
            isWrong = "YouHaveToCompleteEmailAndReservation";
          }
        }
      } else {
        if (arrNoRepeat.includes(arr[i].email)) {
          isWrong = "TheEmailsShouldNotBeTheSame";
        }
        arrNoRepeat.push(arr[i].email);
        if (arr[i].email === "" || arr[i].booking === "") {
          isWrong = "YouHaveToCompleteEmailAndReservation";
        }
      }

      if (
        state.statusRecursos === 200 &&
        state.dataRecursos[`index${i}`] &&
        state.dataRecursos[`index${i}`].datos &&
        state.dataRecursos[`index${i}`].datos.recursos.length > 0
      ) {
        if (
          filterRecursos(
            state.dataRecursos[`index${i}`].datos.recursos,
            true,
            "campo_editable_obligatorio"
          ).length > 0
        ) {
          const clave = filterRecursos(
            state.dataRecursos[`index${i}`].datos.recursos,
            true,
            "campo_editable_obligatorio"
          )[0].clave.split("_");
          if (arr[i].editable === "") {
            isWrong = "YouMustTheRequiredFields";
          } else if (arr[i].editable.length !== parseInt(clave[4], 10)) {
            isWrong = `MustCompleteThe${clave[3]}`;
          }
        }
      }
    }

    let index;
    nodos_final.forEach((item) => {
      index = arr.findIndex((x) => {
        return x.booking === item.id.toString();
      });
      if (index < 0) {
        isWrong = "AllBookingsMustBeSelected";
      }
    });

    if (!isWrong) {
      props.handleCount(1);
      props.handleMemory({ name: "step11" });
      props.nextStep();
      setError(false);
    } else {
      setError(isWrong);
    }
  };

  const handleRecursos = (element) => {
    const obj = {
      edi_division_id: element.id,
      clave_granularidad: props.form.formato_tiempo,
      fecha_inicio: moment(props.form.fecha_inicio).format("DD-MM-YYYY"),
      hora_inicio: props.form.inicio,
      duracion: props.form.duracion,
    };

    dispatch(getRecursos(obj, element.index));
  };

  const drawOption = (disponible) => {
    let options = [];
    for (let i = 1; i <= disponible; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const controlAforo = (id) => {
    let count = 0;
    props.form.emails.forEach((item) => {
      if (item.booking === id.toString()) {
        count++;
      }
    });
    return count;
  };

  const totalDisponibles = (disponibles, index, id) => {
    let count = 0;
    props.form.emails.forEach((item) => {
      if (item.index !== index) {
        if (item[id]) {
          count += parseInt(item[id], 10);
        }
      }
    });

    return disponibles - count;
  };

  const validationEmail = (arr, email) => {
    let index;
    let count = 0;
    arr.forEach((item) => {
      index = item.exclusivo_para.findIndex((x) => x === email);
      if (item.exclusivo_para.length === 0) {
        count++;
      }
      if (index > -1) {
        count++;
      }
    });
    return count > 0 ? true : false;
  };

  /* Recursos */
  const mode = (array) => {
    const arr = [];
    const arr1 = [];
    let div, div1;

    array.forEach((element) => {
      div = element.nombre.split(" ");
      div1 = element.clave.split("_");
      arr.push(div[0]);
      arr1.push(div1[0]);
    });

    let valor = arr
      .sort((a, b) => {
        arr.filter((v) => v === a).length - arr.filter((v) => v === b).length;
      })
      .pop();

    let valor1 = arr1
      .sort((a, b) => {
        arr1.filter((v) => v === a).length - arr1.filter((v) => v === b).length;
      })
      .pop();

    if (valor1 === "Recursos") {
      return <FormattedMessage id="Resources" />;
    } else {
      return valor;
    }
  };

  const handleGuest = (type) => {
    const arr = [...props.form.emails];
    if (type === "add") {
      arr.push({
        index: `index${arr.length}`,
        email: "",
        booking: "",
        editable: "",
      });
    } else {
      arr.pop();
    }
    props.handleChange({
      target: { name: "emails", value: arr },
    });
  };

  const maximumCapacity = useMemo(() => {
    return props.form.nodos_final.reduce(
      (accumulator, current) => accumulator + current.aforo,
      0
    );
  }, [props.form.nodos_final]);

  if (
    /* When the token is expired*/
    state.statusRecursos === 501
  ) {
    return <Expired />;
  }

  return (
    <>
      <section className={props.style.container__content}>
        <h2 className={props.style.start}>
          <FormattedMessage id="SendTheInvitation" />
        </h2>
        <div className={`${props.style.box__footer}`}>
          <section className={`${props.style.box__guests}`}>
            <div className={`${props.style.box__guests__title}`}>
              <h2>
                <FormattedMessage id="WriteYourPreferences" />:
              </h2>
            </div>

            <article>
              <div>
                <Form.Group controlId={`text-0`}>
                  <Form.Label>
                    <FormattedMessage id="Email" />
                  </Form.Label>
                  <Form.Control
                    name="email"
                    className={props.myStyle[`inputall_${props.cliente}`]}
                    data-index={`index0`}
                    value={props.form.emails[0].email}
                    onChange={handleChange}
                    type="email"
                    placeholder="john@gmail.com"
                    disabled={reservaOtro ? false : true}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group controlId={`text1-0`}>
                  <Form.Label>
                    <FormattedMessage id="Booking" />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={props.form.emails[0].booking}
                    className={props.myStyle[`select_${props.cliente}`]}
                    data-index={`index0`}
                    name="booking"
                    onChange={(event) => {
                      handleChange(event);
                      handleRecursos({
                        id: event.target.value,
                        index: "index0",
                      });
                    }}
                    type="email"
                    placeholder="john@gmail.com"
                  >
                    <FormattedMessage id="Select">
                      {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    {/* {totalGuests > 0 && reservaOtro && (
                      <FormattedMessage id="IamAnOrganizer">
                        {(message) => <option value="empty">{message}</option>}
                      </FormattedMessage>
                    )} */}
                    {props.form.nodos_final.map((item) => {
                      let index = -1;
                      if (!item.permite_multiples) {
                        index = props.form.emails.findIndex((x) => {
                          return x.booking.toString() === item.id.toString();
                        });
                      }
                      const control = controlAforo(item.id);
                      return (
                        <option
                          disabled={
                            index >= 0 || control >= item.aforo ? true : false
                          }
                          key={item.id}
                          value={item.id}
                        >
                          {item.label} capacidad: {item.aforo}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </div>
              {state.statusRecursos === 200 &&
                state.dataRecursos.index0 &&
                state.dataRecursos.index0.datos &&
                state.dataRecursos.index0.datos.recursos.length > 0 &&
                validationEmail(
                  state.dataRecursos.index0.datos.recursos,
                  props.form.emails[0].email.trim().toLowerCase()
                ) && (
                  <>
                    {filterRecursos(
                      state.dataRecursos.index0.datos.recursos,
                      false,
                      "campo_editable"
                    ).length > 0 && (
                      <section className={`${props.style.box__recursos}`}>
                        <h6
                          className={props.myStyle[`recursos_${props.cliente}`]}
                        >
                          {mode(state.dataRecursos.index0.datos.recursos)}
                        </h6>{" "}
                        <i
                          className={`fas fa-chevron-down icon-recurso ${props.style.icon__recursos}`}
                        ></i>
                        <section
                          className={`${props.style.item__recursos} ${
                            props.myStyle[`recursos_${props.cliente}`]
                          }`}
                        >
                          {state.dataRecursos.index0.datos.recursos.map(
                            (item, i) => {
                              const disponible = totalDisponibles(
                                item.qdisponible,
                                "index0",
                                `recurso-${item.recurso_id}`
                              );

                              const myValue = props.form.emails[0][
                                `recurso-${item.recurso_id}`
                              ]
                                ? props.form.emails[0][
                                    `recurso-${item.recurso_id}`
                                  ]
                                : "";

                              let validaEmail = true;

                              if (item.exclusivo_para.length > 0) {
                                const puedo = item.exclusivo_para.includes(
                                  props.form.emails[0].email
                                    .trim()
                                    .toLowerCase()
                                );
                                if (puedo) {
                                  validaEmail = true;
                                } else {
                                  validaEmail = false;
                                }
                              }

                              if (disponible > 0 && validaEmail) {
                                return (
                                  <div
                                    onClick={() => {
                                      if (!item.seleccion_multiple) {
                                        handleChangeBox({
                                          index: "index0",
                                          name: `recurso-${item.recurso_id}`,
                                          value: myValue === "" ? 1 : "",
                                          clave: item.clave,
                                        });
                                      }
                                    }}
                                    key={item.recurso_id}
                                    className={
                                      myValue !== ""
                                        ? `${
                                            props.style
                                              .item__list__recursos__active
                                          } ${
                                            props.myStyle[
                                              `recursos_active_${props.cliente}`
                                            ]
                                          }`
                                        : ``
                                    }
                                  >
                                    <h5>{item.nombre}</h5>
                                    {item.seleccion_multiple && (
                                      <Form.Control
                                        as="select"
                                        value={myValue}
                                        data-index={`index0`}
                                        name={`recurso-${item.recurso_id}`}
                                        className={
                                          props.myStyle[
                                            `select_${props.cliente}`
                                          ]
                                        }
                                        onChange={(event) => {
                                          handleChangeBox({
                                            index: "index0",
                                            name: `recurso-${item.recurso_id}`,
                                            value: myValue === "" ? 1 : "",
                                            clave: item.clave,
                                          });
                                        }}
                                        type="text"
                                      >
                                        <FormattedMessage id="No">
                                          {(message) => (
                                            <option value="">0</option>
                                          )}
                                        </FormattedMessage>
                                        {item.seleccion_multiple && (
                                          <React.Fragment>
                                            {drawOption(disponible)}
                                          </React.Fragment>
                                        )}
                                      </Form.Control>
                                    )}
                                  </div>
                                );
                              }
                            }
                          )}
                        </section>
                      </section>
                    )}

                    {/* Campo editable */}
                    {filterRecursos(
                      state.dataRecursos.index0.datos.recursos,
                      true,
                      "campo_editable"
                    ).length > 0 && (
                      <>
                        <section className={`${props.style.box__recursos} `}>
                          <div>
                            <Form.Group controlId={`editable-0`}>
                              <Form.Label>
                                {filterRecursos(
                                  state.dataRecursos.index0.datos.recursos,
                                  true,
                                  "campo_editable"
                                )[0].clave.split("_")[2] === "obligatorio"
                                  ? "(*)"
                                  : ""}{" "}
                                <FormattedMessage id="WriteYour" />{" "}
                                {
                                  filterRecursos(
                                    state.dataRecursos.index0.datos.recursos,
                                    true,
                                    "campo_editable"
                                  )[0].clave.split("_")[3]
                                }{" "}
                              </Form.Label>

                              <Form.Control
                                name="editable"
                                maxLength={
                                  filterRecursos(
                                    state.dataRecursos.index0.datos.recursos,
                                    true,
                                    "campo_editable"
                                  )[0].clave.split("_")[4]
                                }
                                className={
                                  props.myStyle[`inputall_${props.cliente}`]
                                }
                                id="recursoEditable0"
                                ref={inputEl}
                                data-index={`index0`}
                                value={props.form.emails[0].editable}
                                onChange={(event) => {
                                  localStorage.setItem(
                                    "memoriaRecurso",
                                    JSON.stringify({
                                      value: event.target.value,
                                      name: filterRecursos(
                                        state.dataRecursos.index0.datos
                                          .recursos,
                                        true,
                                        "campo_editable"
                                      )[0].clave.split("_")[3],
                                    })
                                  );
                                  handleChange(event);
                                  handleChangeBox({
                                    index: "index0",
                                    name: `recurso-${
                                      filterRecursos(
                                        state.dataRecursos.index0.datos
                                          .recursos,
                                        true,
                                        "campo_editable"
                                      )[0].recurso_id
                                    }`,
                                    value:
                                      props.form.emails[0].editable !== ""
                                        ? 1
                                        : 0,
                                    clave: filterRecursos(
                                      state.dataRecursos.index0.datos.recursos,
                                      true,
                                      "campo_editable"
                                    )[0].clave,
                                  });

                                  handleChangeBox({
                                    index: "index0",
                                    name: `edititem-${
                                      filterRecursos(
                                        state.dataRecursos.index0.datos
                                          .recursos,
                                        true,
                                        "campo_editable"
                                      )[0].recurso_id
                                    }`,
                                    value:
                                      props.form.emails[0].editable !== ""
                                        ? state.dataRecursos.index0.datos
                                            .recursos[0]
                                        : "",
                                    clave: filterRecursos(
                                      state.dataRecursos.index0.datos.recursos,
                                      true,
                                      "campo_editable"
                                    )[0].clave,
                                  });
                                }}
                                onClick={(event) => {
                                  localStorage.setItem(
                                    "memoriaRecurso",
                                    JSON.stringify({
                                      value: event.target.value,
                                      name: filterRecursos(
                                        state.dataRecursos.index0.datos
                                          .recursos,
                                        true,
                                        "campo_editable"
                                      )[0].clave.split("_")[3],
                                    })
                                  );
                                  handleChange(event);
                                  handleChangeBox({
                                    index: "index0",
                                    name: `recurso-${
                                      filterRecursos(
                                        state.dataRecursos.index0.datos
                                          .recursos,
                                        true,
                                        "campo_editable"
                                      )[0].recurso_id
                                    }`,
                                    value:
                                      props.form.emails[0].editable !== ""
                                        ? 1
                                        : 0,
                                    clave: filterRecursos(
                                      state.dataRecursos.index0.datos.recursos,
                                      true,
                                      "campo_editable"
                                    )[0].clave,
                                  });

                                  handleChangeBox({
                                    index: "index0",
                                    name: `edititem-${
                                      filterRecursos(
                                        state.dataRecursos.index0.datos
                                          .recursos,
                                        true,
                                        "campo_editable"
                                      )[0].recurso_id
                                    }`,
                                    value:
                                      props.form.emails[0].editable !== ""
                                        ? state.dataRecursos.index0.datos
                                            .recursos[0]
                                        : "",
                                    clave: filterRecursos(
                                      state.dataRecursos.index0.datos.recursos,
                                      true,
                                      "campo_editable"
                                    )[0].clave,
                                  });
                                }}
                              />
                            </Form.Group>
                          </div>
                        </section>
                      </>
                    )}
                  </>
                )}
            </article>
            {maximumCapacity > 1 && (
              <div className={`${props.style.box__guests__buttons}`}>
                <div>
                  <h2>
                    <FormattedMessage id="AddOrRemoveGuests" />
                  </h2>
                  <p>
                    (
                    <FormattedMessage id="YouCannotAddMoreUsersThanTheAllowedCapacity" />
                    )
                  </p>
                </div>

                <Button
                  size="sm"
                  disabled={props.form.emails.length === 1 ? true : false}
                  onClick={() => handleGuest("delete")}
                  variant="outline-dark"
                >
                  -
                </Button>
                <Button
                  disabled={
                    props.form.emails.filter((o) => o.booking !== "empty")
                      .length >= maximumCapacity
                      ? true
                      : false
                  }
                  size="sm"
                  onClick={() => handleGuest("add")}
                  variant="outline-dark"
                >
                  +
                </Button>
              </div>
            )}
            {props.form.emails.map((item) => {
              if (item.index !== "index0") {
                return (
                  <article key={`box-${item.index}`}>
                    <div>
                      <Form.Group controlId={`text-${item.index}`}>
                        <Form.Label>
                          <FormattedMessage id="Email" />
                        </Form.Label>
                        <Form.Control
                          value={item.email}
                          className={props.myStyle[`inputall_${props.cliente}`]}
                          name="email"
                          data-index={`${item.index}`}
                          onChange={handleChange}
                          type="email"
                          placeholder="john@gmail.com"
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group controlId={`text1-${item.index}`}>
                        <Form.Label>
                          <FormattedMessage id="Booking" />
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={item.booking}
                          className={props.myStyle[`select_${props.cliente}`]}
                          name="booking"
                          data-index={`${item.index}`}
                          onChange={(event) => {
                            handleChange(event);
                            handleRecursos({
                              id: event.target.value,
                              index: item.index,
                            });
                          }}
                        >
                          <FormattedMessage id="Select">
                            {(message) => <option value="">{message}</option>}
                          </FormattedMessage>
                          {props.form.nodos_final.map((item) => {
                            let index = -1;
                            if (!item.permite_multiples) {
                              index = props.form.emails.findIndex((x) => {
                                return (
                                  x.booking.toString() === item.id.toString()
                                );
                              });
                            }
                            const control = controlAforo(item.id);
                            return (
                              <option
                                disabled={
                                  index >= 0 || control >= item.aforo
                                    ? true
                                    : false
                                }
                                key={item.id}
                                value={item.id}
                              >
                                {item.label} capacidad: {item.aforo}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </div>
                    {state.statusRecursos === 200 &&
                      state.dataRecursos[item.index] &&
                      state.dataRecursos[item.index].datos &&
                      state.dataRecursos[item.index].datos.recursos.length >
                        0 &&
                      validationEmail(
                        state.dataRecursos[item.index].datos.recursos,
                        item.email.trim().toLowerCase()
                      ) && (
                        <>
                          {filterRecursos(
                            state.dataRecursos[item.index].datos.recursos,
                            false,
                            "campo_editable"
                          ).length > 0 && (
                            <section className={`${props.style.box__recursos}`}>
                              <h6
                                className={
                                  props.myStyle[`recursos_${props.cliente}`]
                                }
                              >
                                {mode(
                                  state.dataRecursos[item.index].datos.recursos
                                )}
                              </h6>
                              <i
                                className={`fas fa-chevron-down icon-recurso ${props.style.icon__recursos}`}
                              ></i>
                              <section
                                className={`${props.style.item__recursos} ${
                                  props.myStyle[`recursos_${props.cliente}`]
                                }`}
                              >
                                {state.dataRecursos[
                                  item.index
                                ].datos.recursos.map((item1, i) => {
                                  const disponible = totalDisponibles(
                                    item1.qdisponible,
                                    item.index,
                                    `recurso-${item1.recurso_id}`
                                  );
                                  const myValue1 = item[
                                    `recurso-${item1.recurso_id}`
                                  ]
                                    ? item[`recurso-${item1.recurso_id}`]
                                    : "";

                                  let validaEmail = true;
                                  if (item1.exclusivo_para.length > 0) {
                                    const puedo = item1.exclusivo_para.includes(
                                      item.email.trim().toLowerCase()
                                    );
                                    if (puedo) {
                                      validaEmail = true;
                                    } else {
                                      validaEmail = false;
                                    }
                                  }

                                  if (disponible > 0 && validaEmail) {
                                    return (
                                      <div
                                        key={item1.recurso_id}
                                        onClick={() => {
                                          if (!item1.seleccion_multiple) {
                                            handleChangeBox({
                                              index: item.index,
                                              name: `recurso-${item1.recurso_id}`,
                                              value: myValue1 === "" ? 1 : "",
                                              clave: item1.clave,
                                            });
                                          }
                                        }}
                                        className={
                                          myValue1 !== ""
                                            ? `${
                                                props.style
                                                  .item__list__recursos__active
                                              } ${
                                                props.myStyle[
                                                  `recursos_active_${props.cliente}`
                                                ]
                                              }`
                                            : ``
                                        }
                                      >
                                        <h5>{item1.nombre}</h5>
                                        {item1.seleccion_multiple && (
                                          <Form.Control
                                            as="select"
                                            className={
                                              props.myStyle[
                                                `select_${props.cliente}`
                                              ]
                                            }
                                            value={
                                              item[
                                                `recurso-${item1.recurso_id}`
                                              ]
                                                ? item[
                                                    `recurso-${item1.recurso_id}`
                                                  ]
                                                : ""
                                            }
                                            data-index={item.index}
                                            name={`recurso-${item1.recurso_id}`}
                                            onChange={(event) => {
                                              handleChangeBox({
                                                index: item.index,
                                                name: `recurso-${item1.recurso_id}`,
                                                value: myValue1 === "" ? 1 : "",
                                                clave: item1.clave,
                                              });
                                            }}
                                            type="text"
                                          >
                                            <FormattedMessage id="No">
                                              {(message) => (
                                                <option value="">0</option>
                                              )}
                                            </FormattedMessage>
                                            {item1.seleccion_multiple && (
                                              <React.Fragment>
                                                {drawOption(disponible)}
                                              </React.Fragment>
                                            )}
                                          </Form.Control>
                                        )}
                                      </div>
                                    );
                                  }
                                })}
                              </section>
                            </section>
                          )}

                          {/* Campo editable */}
                          {filterRecursos(
                            state.dataRecursos[item.index].datos.recursos,
                            true,
                            "campo_editable"
                          ).length > 0 &&
                            filterRecursos(
                              state.dataRecursos[item.index].datos.recursos,
                              true,
                              "campo_editable"
                            )[item.index.replace("index", "")] && (
                              <>
                                <section
                                  className={`${props.style.box__recursos} `}
                                >
                                  <div>
                                    <Form.Group>
                                      <Form.Label>
                                        {filterRecursos(
                                          state.dataRecursos[item.index].datos
                                            .recursos,
                                          true,
                                          "campo_editable"
                                        )[
                                          item.index.replace("index", "")
                                        ].clave.split("_")[2] === "obligatorio"
                                          ? "(*)"
                                          : ""}{" "}
                                        <FormattedMessage id="WriteYour" />{" "}
                                        {
                                          filterRecursos(
                                            state.dataRecursos[item.index].datos
                                              .recursos,
                                            true,
                                            "campo_editable"
                                          )[
                                            item.index.replace("index", "")
                                          ].clave.split("_")[3]
                                        }{" "}
                                      </Form.Label>

                                      <Form.Control
                                        name="editable"
                                        maxLength={
                                          filterRecursos(
                                            state.dataRecursos[item.index].datos
                                              .recursos,
                                            true,
                                            "campo_editable"
                                          )[
                                            item.index.replace("index", "")
                                          ].clave.split("_")[4]
                                        }
                                        className={
                                          props.myStyle[
                                            `inputall_${props.cliente}`
                                          ]
                                        }
                                        data-index={`${item.index}`}
                                        value={item.editable}
                                        onChange={(event) => {
                                          handleChange(event);
                                          handleChangeBox({
                                            index: item.index,
                                            name: `recurso-${
                                              filterRecursos(
                                                state.dataRecursos[item.index]
                                                  .datos.recursos,
                                                true,
                                                "campo_editable"
                                              )[item.index.replace("index", "")]
                                                .recurso_id
                                            }`,
                                            value:
                                              item.editable !== ""
                                                ? 1
                                                : item.index.replace(
                                                    "index",
                                                    ""
                                                  ),
                                            clave: filterRecursos(
                                              state.dataRecursos[item.index]
                                                .datos.recursos,
                                              true,
                                              "campo_editable"
                                            )[item.index.replace("index", "")]
                                              .clave,
                                          });

                                          handleChangeBox({
                                            index: item.index,
                                            name: `edititem-${
                                              filterRecursos(
                                                state.dataRecursos[item.index]
                                                  .datos.recursos,
                                                true,
                                                "campo_editable"
                                              )[item.index.replace("index", "")]
                                                .recurso_id
                                            }`,
                                            value:
                                              item.editable !== ""
                                                ? state.dataRecursos[item.index]
                                                    .datos.recursos[
                                                    item.index.replace(
                                                      "index",
                                                      ""
                                                    )
                                                  ]
                                                : "",
                                            clave: filterRecursos(
                                              state.dataRecursos[item.index]
                                                .datos.recursos,
                                              true,
                                              "campo_editable"
                                            )[item.index.replace("index", "")]
                                              .clave,
                                          });
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                </section>
                              </>
                            )}
                        </>
                      )}
                  </article>
                );
              }
            })}

            <article className={props.style.justify__flex__end}>
              {error && (
                <span className={props.style.error}>
                  <p>
                    <FormattedMessage id={error} />
                  </p>
                </span>
              )}

              <Button
                className={props.myStyle[`btn_${props.cliente}`]}
                variant="main"
                disabled={
                  state.statusRecursos === 200 && state.loadingRecursos
                    ? true
                    : false
                }
                onClick={handleSubmit}
              >
                <div className={props.style.box__button}>
                  {state.loadingRecursos === false ? (
                    <>
                      <span>
                        <FormattedMessage id="Next" />
                      </span>
                      <span>
                        <i className="far fa-arrow-alt-circle-right"></i>
                      </span>
                    </>
                  ) : (
                    <FormattedMessage id="Loading" />
                  )}
                </div>
              </Button>
            </article>
          </section>
        </div>
      </section>

      <div className={props.style.box__arrow}>
        <span
          onClick={() => {
            props.handleChange({
              target: {
                name: "emails",
                value: [
                  {
                    index: "index0",
                    email: props.myEmail,
                    booking: "",
                    editable: "",
                  },
                ],
              },
            });
            props.handleMemory({ name: `step${props.step + 1}` }, false);
            props.backStep();
          }}
        >
          <i className="far fa-arrow-alt-circle-left"></i>
        </span>
        {props.memory[`step${props.step + 1}`] && (
          <span
            onClick={() => {
              props.nextStep();
            }}
          >
            <i className="far fa-arrow-alt-circle-right"></i>
          </span>
        )}
      </div>
    </>
  );
};

export default StepGuests;
