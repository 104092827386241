import React from "react";
import Header from "./Header";
import Days from "./Days";
import { FormattedMessage } from "react-intl";

const Schedule = (props) => {
  return (
    <>
      <h3 className={props.style.calendar__header__title}>
        {props.data.datos.marco.encabezado}
      </h3>
      <Header
        next={props.next}
        back={props.back}
        cliente={props.cliente}
        myStyle={props.myStyle}
        data={props.data.datos.marco.eje_x}
        style={props.style}
      />
      <Days
        id="id"
        handleSelect={props.handleSelect}
        data={props.data.datos.casillas}
        style={props.style}
      />
    </>
  );
};

export default Schedule;
