import React from "react";
import Example from "../../assets/images/example.png";

const Information = (props) => {
  return (
    <section className={`${props.style.container__information}`}>
      <ul>
        {props.data.map((item) => {
          if (item.img !== "false" && item.img !== false) {
            return (
              <li
                key={item.id}
                className={props.myStyle[`icon_${props.cliente}`]}
              >
                <div>
                  <img src={item.img} alt="example" />
                </div>
              </li>
            );
          }
        })}
      </ul>
    </section>
  );
};

export default Information;
