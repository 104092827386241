import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import Days from "./Days";

import { getWeek, resetWeek } from "../../../actions/reservation";

const OneComponent = ({
  index,
  query,
  inicio,
  style,
  message,
  handleSelect,
  data,
}) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  useEffect(() => {
    const callsAPI = async () => {
      query.fecha_inicio = inicio;
      dispatch(getWeek(query, index));
    };
    if (query.empresa_id !== undefined) {
      callsAPI();
    }
  }, [dispatch, query]);

  useEffect(() => {
    return () => {
      dispatch(resetWeek());
    };
  }, [dispatch]);

  const renderDay = () => {
    if (state.loadingWeek[`data${index}`]) {
      return <Loading style={style} />;
    } else if (state.statusWeek[`data${index}`] === 200) {
      if (state.dataWeek[`data${index}`].ejecucion.estado) {
        const largo = state.dataWeek[`data${index}`].datos.marco.eje_y.length;

        return (
          <Days
            id={`id${index}`}
            handleSelect={handleSelect}
            style={style}
            message={message}
            data={state.dataWeek[`data${index}`].datos.casillas.filter(
              (o) => o.date === data.datos.fechas_a_llamar[index]
            )}
          />
        );
      } else {
        return <Loading style={style} />;
      }
    }

    return <Loading style={style} />;
  };

  return <>{renderDay()}</>;
};

export default OneComponent;
