import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

/* Component */
import { Form, Button, Alert } from "react-bootstrap";

/* Assets */
import Logo from "../../assets/images/logo.png";

const Code = (props) => {
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(props.goBack);
  };

  return (
    <section data-testid="form" className={props.style.container__code}>
      <section className={props.style.box}>
        {props.cliente === "" ? (
          <div className={props.style.box__img}>
            <img src={Logo} alt="Logo" />
          </div>
        ) : (
          <div
            className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
          >
            <div className={props.myStyle[`logo_${props.cliente}`]}></div>
            <div></div>
          </div>
        )}
        <h2>
          <FormattedMessage id="RecoverPass" />
        </h2>

        <h3>
          <FormattedMessage id="PasswordRecoveredCheckYourEmail" />
        </h3>

        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <section className={props.style.box__footer}>
            <Button
              variant="main"
              type="submit"
              className={props.myStyle[`btn_${props.cliente}`]}
            >
              <div className={props.style.box__button}>
                <span>
                  {!true ? (
                    <FormattedMessage id="Loading" />
                  ) : (
                    <FormattedMessage id="Next" />
                  )}
                </span>
                <span>
                  <i className="far fa-arrow-alt-circle-right"></i>
                </span>
              </div>
            </Button>
          </section>
        </Form>
      </section>
    </section>
  );
};

export default Code;
