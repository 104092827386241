import React from "react";

const includeDays = (info, item, disponibility) => {
  let inicioObjeto1 = new Date(
    info.desde.split(" ")[0].split("-").reverse().join("-") +
      " " +
      info.desde.split(" ")[1]
  );
  let finObjeto1 = new Date(
    info.hasta.split(" ")[0].split("-").reverse().join("-") +
      " " +
      info.hasta.split(" ")[1]
  );
  let inicioObjeto2 = new Date(
    item.date.split("-").reverse().join("-") + " " + item.comienza
  );
  let finObjeto2 = new Date(
    item.date.split("-").reverse().join("-") + " " + item.termina
  );

  let seSolapa = inicioObjeto2 >= inicioObjeto1 && finObjeto2 <= finObjeto1;

  if (seSolapa && !disponibility) {
    return true;
  } else if (item.disponibilidad && item.seleccionable) {
    return true;
  }

  return false;
};
const Days = (props) => {
  return (
    <section>
      {props.data.map((item) => {
        return (
          <div key={`prueba-${props.id}${item.id}`}>
            {includeDays(props.infoReserva, item, props.disponibility) && (
              <span>Disponible</span>
            )}
            <div
              data-date={item.date}
              data-father={"ok"}
              id={`father-${props.id}${item.id}`}
              className={`${props.style.container__message__calendar}`}
              onClick={() => {
                if (item.disponibilidad && item.seleccionable) {
                  props.handleSelect({
                    message: `message-${props.id}${item.id}`,
                    id: `father-${props.id}${item.id}`,
                    data: props.data,
                    num: item.id,
                    item: item,
                    key: props.id,
                  });
                }
              }}
            >
              <h4 data-ok={"ok"} id={`message-${props.id}${item.id}`}></h4>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Days;
