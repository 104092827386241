import {
  GET_COMPANY_CONTRATO_INIT,
  GET_COMPANY_CONTRATO_SUCCESS,
  GET_COMPANY_CONTRATO_FAILURE,
  GET_COMPANY_SIN_CONTRATO_INIT,
  GET_COMPANY_SIN_CONTRATO_SUCCESS,
  GET_COMPANY_SIN_CONTRATO_FAILURE,
  GET_LABELS_INIT,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  GET_DIVISION_INIT,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAILURE,
  GET_SUBDIVISION_INIT,
  GET_SUBDIVISION_SUCCESS,
  GET_SUBDIVISION_FAILURE,
  GET_SUBSUBDIVISION_INIT,
  GET_SUBSUBDIVISION_SUCCESS,
  GET_SUBSUBDIVISION_FAILURE,
  GET_BOLSAS_INIT,
  GET_BOLSAS_SUCCESS,
  GET_BOLSAS_FAILURE,
  GET_PREGUNTAS_INIT,
  GET_PREGUNTAS_SUCCESS,
  GET_PREGUNTAS_FAILURE,
  GET_ALTERNATIVAS_INIT,
  GET_ALTERNATIVAS_SUCCESS,
  GET_ALTERNATIVAS_FAILURE,
  GET_HORARIOS_INIT,
  GET_HORARIOS_SUCCESS,
  GET_HORARIOS_FAILURE,
  GET_HORARIOS_VALIDO_INIT,
  GET_HORARIOS_VALIDO_SUCCESS,
  GET_HORARIOS_VALIDO_FAILURE,
  GET_CALENDARIO_INIT,
  GET_CALENDARIO_SUCCESS,
  GET_CALENDARIO_FAILURE,
  GET_MARCO_INIT,
  GET_MARCO_SUCCESS,
  GET_MARCO_FAILURE,
  GET_WEEK_INIT,
  GET_WEEK_SUCCESS,
  GET_WEEK_FAILURE,
  GET_EDIFICIO_INIT,
  GET_EDIFICIO_SUCCESS,
  GET_EDIFICIO_FAILURE,
  GET_DESEABLE_INIT,
  GET_DESEABLE_SUCCESS,
  GET_DESEABLE_FAILURE,
  GET_NODO1_INIT,
  GET_NODO1_SUCCESS,
  GET_NODO1_FAILURE,
  GET_NODO_HIJO_INIT,
  GET_NODO_HIJO_SUCCESS,
  GET_NODO_HIJO_FAILURE,
  GET_NODO_FINAL_INIT,
  GET_NODO_FINAL_SUCCESS,
  GET_NODO_FINAL_FAILURE,
  GET_IMAGEN_INIT,
  GET_IMAGEN_SUCCESS,
  GET_IMAGEN_FAILURE,
  GET_RECURSOS_INIT,
  GET_RECURSOS_SUCCESS,
  GET_RECURSOS_FAILURE,
  GET_RESERVA_INIT,
  GET_RESERVA_SUCCESS,
  GET_RESERVA_FAILURE,
  GET_RESERVA_EXTERNA_INIT,
  GET_RESERVA_EXTERNA_SUCCESS,
  GET_RESERVA_EXTERNA_FAILURE,
  GET_ACCEPT_RESERVA_EXTERNA_INIT,
  GET_ACCEPT_RESERVA_EXTERNA_SUCCESS,
  GET_ACCEPT_RESERVA_EXTERNA_FAILURE,
  GET_RESERVA_EXTERNA_LIST_INIT,
  GET_RESERVA_EXTERNA_LIST_SUCCESS,
  GET_RESERVA_EXTERNA_LIST_FAILURE,
  GET_RESERVA_ALL_EXTERNA_LIST_INIT,
  GET_RESERVA_ALL_EXTERNA_LIST_SUCCESS,
  GET_RESERVA_ALL_EXTERNA_LIST_FAILURE,
  GET_OPTIONS_RESERVATION_INIT,
  GET_OPTIONS_RESERVATION_SUCCESS,
  GET_OPTIONS_RESERVATION_FAILURE,
  RESET_COMPANY_CONTRATO,
  RESET_COMPANY_SIN_CONTRATO,
  RESET_LABELS,
  RESET_DIVISION,
  RESET_SUBDIVISION,
  RESET_SUBSUBDIVISION,
  RESET_BOLSAS,
  RESET_PREGUNTAS,
  RESET_ALTERNATIVAS,
  RESET_HORARIOS,
  RESET_HORARIOS_VALIDO,
  RESET_CALENDARIO,
  RESET_MARCO,
  RESET_WEEK,
  RESET_EDIFICIO,
  RESET_DESEABLE,
  RESET_NODO1,
  RESET_NODO_HIJO,
  RESET_NODO_FINAL,
  RESET_IMAGEN,
  RESET_RECURSOS,
  RESET_RESERVA,
  RESET_RESERVA_EXTERNA,
  RESET_ACCEPT_RESERVA_EXTERNA,
  RESET_RESERVA_EXTERNA_LIST,
  RESET_RESERVA_ALL_EXTERNA_LIST,
  RESET_OPTIONS_RESERVATION,
  RESET_ALL_RESERVATION,
} from "./types";
import API from "./api";

/* Resets */

export function resetAllReservation() {
  return {
    type: RESET_ALL_RESERVATION,
  };
}

export function resetCompanyContrato() {
  return {
    type: RESET_COMPANY_CONTRATO,
  };
}

export function resetCompanySinContrato() {
  return {
    type: RESET_COMPANY_SIN_CONTRATO,
  };
}

export function resetLabels() {
  return {
    type: RESET_LABELS,
  };
}

export function resetDivision() {
  return {
    type: RESET_DIVISION,
  };
}

export function resetSubDivision() {
  return {
    type: RESET_SUBDIVISION,
  };
}

export function resetSubSubDivision() {
  return {
    type: RESET_SUBSUBDIVISION,
  };
}

export function resetBolsas() {
  return {
    type: RESET_BOLSAS,
  };
}

export function resetPreguntas() {
  return {
    type: RESET_PREGUNTAS,
  };
}

export function resetAlternativas() {
  return {
    type: RESET_ALTERNATIVAS,
  };
}

export function resetHorarios() {
  return {
    type: RESET_HORARIOS,
  };
}

export function resetHorariosValido() {
  return {
    type: RESET_HORARIOS_VALIDO,
  };
}

export function resetCalendario() {
  return {
    type: RESET_CALENDARIO,
  };
}

export function resetMarco() {
  return {
    type: RESET_MARCO,
  };
}

export function resetWeek() {
  return {
    type: RESET_WEEK,
  };
}

export function resetEdificio() {
  return {
    type: RESET_EDIFICIO,
  };
}

export function resetDeseable() {
  return {
    type: RESET_DESEABLE,
  };
}

export function resetNodo1() {
  return {
    type: RESET_NODO1,
  };
}

export function resetNodoHijo() {
  return {
    type: RESET_NODO_HIJO,
  };
}

export function resetNodoFinal() {
  return {
    type: RESET_NODO_FINAL,
  };
}

export function resetImagen() {
  return {
    type: RESET_IMAGEN,
  };
}

export function resetRecursos() {
  return {
    type: RESET_RECURSOS,
  };
}

export function resetReserva() {
  return {
    type: RESET_RESERVA,
  };
}

export function resetReservaExterna() {
  return {
    type: RESET_RESERVA_EXTERNA,
  };
}

export function resetAcceptReservaExterna() {
  return {
    type: RESET_ACCEPT_RESERVA_EXTERNA,
  };
}

export function resetReservaExternaList() {
  return {
    type: RESET_RESERVA_EXTERNA_LIST,
  };
}

export function resetReservaAllExternaList() {
  return {
    type: RESET_RESERVA_ALL_EXTERNA_LIST,
  };
}

export function resetOptionsReservation() {
  return {
    type: RESET_OPTIONS_RESERVATION,
  };
}

/* Get company with contract (Async) */

function getCompanyContratoSuccess(data) {
  return {
    type: GET_COMPANY_CONTRATO_SUCCESS,
    payload: data,
  };
}

function getCompanyContratoFailure(error) {
  console.log(error);
  return {
    type: GET_COMPANY_CONTRATO_FAILURE,
    payload: error,
  };
}

function getCompanyContratoInit(data) {
  return {
    type: GET_COMPANY_CONTRATO_INIT,
    payload: data.loading,
  };
}

export function getCompanyContrato(data) {
  return async (dispatch) => {
    dispatch(getCompanyContratoInit(data));
    try {
      const resp = await API.data.getCompanyContrato(data);
      return dispatch(getCompanyContratoSuccess(resp));
    } catch (error) {
      return dispatch(getCompanyContratoFailure(error));
    }
  };
}

/* Get company without contract (Async) */

function getCompanySinContratoSuccess(data) {
  return {
    type: GET_COMPANY_SIN_CONTRATO_SUCCESS,
    payload: data,
  };
}

function getCompanySinContratoFailure(error) {
  console.log(error);
  return {
    type: GET_COMPANY_SIN_CONTRATO_FAILURE,
    payload: error,
  };
}

function getCompanySinContratoInit(data) {
  return {
    type: GET_COMPANY_SIN_CONTRATO_INIT,
    payload: data.loading,
  };
}

export function getCompanySinContrato(data) {
  return async (dispatch) => {
    dispatch(getCompanySinContratoInit(data));
    try {
      const resp = await API.data.getCompanySinContrato(data);
      return dispatch(getCompanySinContratoSuccess(resp));
    } catch (error) {
      return dispatch(getCompanySinContratoFailure(error));
    }
  };
}

/* Get labels div, sub and subsub (Async) */

function getLabelsSuccess(data) {
  return {
    type: GET_LABELS_SUCCESS,
    payload: data,
  };
}

function getLabelsFailure(error) {
  return {
    type: GET_LABELS_FAILURE,
    payload: error,
  };
}

function getLabelsInit(data) {
  return {
    type: GET_LABELS_INIT,
    payload: data.loading,
  };
}

export function getLabels(data) {
  return async (dispatch) => {
    dispatch(getLabelsInit(data));
    try {
      const resp = await API.data.getLabels(data);
      return dispatch(getLabelsSuccess(resp));
    } catch (error) {
      return dispatch(getLabelsFailure(error));
    }
  };
}

/* Get division (Async) */

function getDivisionSuccess(data) {
  return {
    type: GET_DIVISION_SUCCESS,
    payload: data,
  };
}

function getDivisionFailure(error) {
  return {
    type: GET_DIVISION_FAILURE,
    payload: error,
  };
}

function getDivisionInit(data) {
  return {
    type: GET_DIVISION_INIT,
    payload: data.loading,
  };
}

export function getDivision(data) {
  return async (dispatch) => {
    dispatch(getDivisionInit(data));
    try {
      const resp = await API.data.getDivision(data);
      return dispatch(getDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getDivisionFailure(error));
    }
  };
}

/* Get sub division (Async) */

function getSubDivisionSuccess(data) {
  return {
    type: GET_SUBDIVISION_SUCCESS,
    payload: data,
  };
}

function getSubDivisionFailure(error) {
  return {
    type: GET_SUBDIVISION_FAILURE,
    payload: error,
  };
}

function getSubDivisionInit(data) {
  return {
    type: GET_SUBDIVISION_INIT,
    payload: data.loading,
  };
}

export function getSubDivision(data) {
  return async (dispatch) => {
    dispatch(getSubDivisionInit(data));
    try {
      const resp = await API.data.getSubDivision(data);
      return dispatch(getSubDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getSubDivisionFailure(error));
    }
  };
}

/* Get sub sub division (Async) */

function getSubSubDivisionSuccess(data) {
  return {
    type: GET_SUBSUBDIVISION_SUCCESS,
    payload: data,
  };
}

function getSubSubDivisionFailure(error) {
  return {
    type: GET_SUBSUBDIVISION_FAILURE,
    payload: error,
  };
}

function getSubSubDivisionInit(data) {
  return {
    type: GET_SUBSUBDIVISION_INIT,
    payload: data.loading,
  };
}

export function getSubSubDivision(data) {
  return async (dispatch) => {
    dispatch(getSubSubDivisionInit(data));
    try {
      const resp = await API.data.getSubSubDivision(data);
      return dispatch(getSubSubDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getSubSubDivisionFailure(error));
    }
  };
}

/* Get bolsas (Async) */

function getBolsasSuccess(data) {
  return {
    type: GET_BOLSAS_SUCCESS,
    payload: data,
  };
}

function getBolsasFailure(error) {
  return {
    type: GET_BOLSAS_FAILURE,
    payload: error,
  };
}

function getBolsasInit(data) {
  return {
    type: GET_BOLSAS_INIT,
    payload: data.loading,
  };
}

export function getBolsas(data) {
  return async (dispatch) => {
    dispatch(getBolsasInit(data));
    try {
      const resp = await API.data.getBolsas(data);
      return dispatch(getBolsasSuccess(resp));
    } catch (error) {
      return dispatch(getBolsasFailure(error));
    }
  };
}

/* Get preguntas (Async) */

function getPreguntasSuccess(data) {
  return {
    type: GET_PREGUNTAS_SUCCESS,
    payload: data,
  };
}

function getPreguntasFailure(error) {
  return {
    type: GET_PREGUNTAS_FAILURE,
    payload: error,
  };
}

function getPreguntasInit(data) {
  return {
    type: GET_PREGUNTAS_INIT,
    payload: data.loading,
  };
}

export function getPreguntas(data) {
  return async (dispatch) => {
    dispatch(getPreguntasInit(data));
    try {
      const resp = await API.data.getPreguntas(data);
      return dispatch(getPreguntasSuccess(resp));
    } catch (error) {
      return dispatch(getPreguntasFailure(error));
    }
  };
}

/* Get alternativas (Async) */

function getAlternativasSuccess(data) {
  return {
    type: GET_ALTERNATIVAS_SUCCESS,
    payload: data,
  };
}

function getAlternativasFailure(error) {
  return {
    type: GET_ALTERNATIVAS_FAILURE,
    payload: error,
  };
}

function getAlternativasInit(data) {
  return {
    type: GET_ALTERNATIVAS_INIT,
    payload: data.loading,
  };
}

export function getAlternativas(data) {
  return async (dispatch) => {
    dispatch(getAlternativasInit(data));
    try {
      const resp = await API.data.getAlternativas(data);
      return dispatch(getAlternativasSuccess(resp));
    } catch (error) {
      return dispatch(getAlternativasFailure(error));
    }
  };
}

/* Get horarios (Async) */

function getHorariosSuccess(data) {
  return {
    type: GET_HORARIOS_SUCCESS,
    payload: data,
  };
}

function getHorariosFailure(error) {
  return {
    type: GET_HORARIOS_FAILURE,
    payload: error,
  };
}

function getHorariosInit(data) {
  return {
    type: GET_HORARIOS_INIT,
    payload: data.loading,
  };
}

export function getHorarios(data) {
  return async (dispatch) => {
    dispatch(getHorariosInit(data));
    try {
      const resp = await API.data.getHorarios(data);
      return dispatch(getHorariosSuccess(resp));
    } catch (error) {
      return dispatch(getHorariosFailure(error));
    }
  };
}

/* Get horarios valido (Async) */

function getHorariosValidoSuccess(data) {
  return {
    type: GET_HORARIOS_VALIDO_SUCCESS,
    payload: data,
  };
}

function getHorariosValidoFailure(error) {
  return {
    type: GET_HORARIOS_VALIDO_FAILURE,
    payload: error,
  };
}

function getHorariosValidoInit(data) {
  return {
    type: GET_HORARIOS_VALIDO_INIT,
    payload: data.loading,
  };
}

export function getHorariosValido(data) {
  return async (dispatch) => {
    dispatch(getHorariosValidoInit(data));
    try {
      const resp = await API.data.getHorariosValido(data);
      return dispatch(getHorariosValidoSuccess(resp));
    } catch (error) {
      return dispatch(getHorariosValidoFailure(error));
    }
  };
}

/* Get calendario (Async) */

function getCalendarioSuccess(data) {
  return {
    type: GET_CALENDARIO_SUCCESS,
    payload: data,
  };
}

function getCalendarioFailure(error) {
  return {
    type: GET_CALENDARIO_FAILURE,
    payload: error,
  };
}

function getCalendarioInit(data) {
  return {
    type: GET_CALENDARIO_INIT,
    payload: data.loading,
  };
}

export function getCalendario(data) {
  return async (dispatch) => {
    dispatch(getCalendarioInit(data));
    try {
      const resp = await API.data.getCalendario(data);
      return dispatch(getCalendarioSuccess(resp));
    } catch (error) {
      return dispatch(getCalendarioFailure(error));
    }
  };
}

/* Get marco (Async) */

function getMarcoSuccess(data) {
  return {
    type: GET_MARCO_SUCCESS,
    payload: data,
  };
}

function getMarcoFailure(error) {
  return {
    type: GET_MARCO_FAILURE,
    payload: error,
  };
}

function getMarcoInit(data) {
  return {
    type: GET_MARCO_INIT,
    payload: data.loading,
  };
}

export function getMarco(data) {
  return async (dispatch) => {
    dispatch(getMarcoInit(data));
    try {
      const resp = await API.data.getMarco(data);
      return dispatch(getMarcoSuccess(resp));
    } catch (error) {
      return dispatch(getMarcoFailure(error));
    }
  };
}

/* Get week(Async) */

function getWeekSuccess(data, index) {
  return {
    type: GET_WEEK_SUCCESS,
    payload: data,
    index,
  };
}

function getWeekFailure(error) {
  console.log("error", error);
  return {
    type: GET_WEEK_FAILURE,
    payload: error,
  };
}

function getWeekInit(data) {
  return {
    type: GET_WEEK_INIT,
    payload: data.loading,
  };
}

export function getWeek(data, index) {
  return async (dispatch) => {
    dispatch(getWeekInit(data));
    try {
      const resp = await API.data.getWeek(data);
      return dispatch(getWeekSuccess(resp, index));
    } catch (error) {
      return dispatch(getWeekFailure(error));
    }
  };
}

/* Get edificio (Async) */

function getEdificioSuccess(data) {
  return {
    type: GET_EDIFICIO_SUCCESS,
    payload: data,
  };
}

function getEdificioFailure(error) {
  return {
    type: GET_EDIFICIO_FAILURE,
    payload: error,
  };
}

function getEdificioInit(data) {
  return {
    type: GET_EDIFICIO_INIT,
    payload: data.loading,
  };
}

export function getEdificio(data) {
  return async (dispatch) => {
    dispatch(getEdificioInit(data));
    try {
      const resp = await API.data.getEdificio(data);
      return dispatch(getEdificioSuccess(resp));
    } catch (error) {
      return dispatch(getEdificioFailure(error));
    }
  };
}

/* Get deseable (Async) */

function getDeseableSuccess(data) {
  return {
    type: GET_DESEABLE_SUCCESS,
    payload: data,
  };
}

function getDeseableFailure(error) {
  return {
    type: GET_DESEABLE_FAILURE,
    payload: error,
  };
}

function getDeseableInit(data) {
  return {
    type: GET_DESEABLE_INIT,
    payload: data.loading,
  };
}

export function getDeseable(data) {
  return async (dispatch) => {
    dispatch(getDeseableInit(data));
    try {
      const resp = await API.data.getDeseable(data);
      return dispatch(getDeseableSuccess(resp));
    } catch (error) {
      return dispatch(getDeseableFailure(error));
    }
  };
}

/* Get nodo1 (Async) */

function getNodo1Success(data) {
  return {
    type: GET_NODO1_SUCCESS,
    payload: data,
  };
}

function getNodo1Failure(error) {
  return {
    type: GET_NODO1_FAILURE,
    payload: error,
  };
}

function getNodo1Init(data) {
  return {
    type: GET_NODO1_INIT,
    payload: data.loading,
  };
}

export function getNodo1(data) {
  return async (dispatch) => {
    dispatch(getNodo1Init(data));
    try {
      const resp = await API.data.getNodo1(data);
      return dispatch(getNodo1Success(resp));
    } catch (error) {
      return dispatch(getNodo1Failure(error));
    }
  };
}

/* Get nodohijo (Async) */

function getNodoHijoSuccess(data) {
  return {
    type: GET_NODO_HIJO_SUCCESS,
    payload: data,
  };
}

function getNodoHijoFailure(error) {
  return {
    type: GET_NODO_HIJO_FAILURE,
    payload: error,
  };
}

function getNodoHijoInit(data) {
  return {
    type: GET_NODO_HIJO_INIT,
    payload: data.loading,
  };
}

export function getNodoHijo(data) {
  return async (dispatch) => {
    dispatch(getNodoHijoInit(data));
    try {
      const resp = await API.data.getNodoHijo(data);
      return dispatch(getNodoHijoSuccess(resp));
    } catch (error) {
      return dispatch(getNodoHijoFailure(error));
    }
  };
}

/* Get nodo final (Async) */

function getNodoFinalSuccess(data) {
  return {
    type: GET_NODO_FINAL_SUCCESS,
    payload: data,
  };
}

function getNodoFinalFailure(error) {
  return {
    type: GET_NODO_FINAL_FAILURE,
    payload: error,
  };
}

function getNodoFinalInit(data) {
  return {
    type: GET_NODO_FINAL_INIT,
    payload: data.loading,
  };
}

export function getNodoFinal(data) {
  return async (dispatch) => {
    dispatch(getNodoFinalInit(data));
    try {
      const resp = await API.data.getNodoFinal(data);
      return dispatch(getNodoFinalSuccess(resp));
    } catch (error) {
      return dispatch(getNodoFinalFailure(error));
    }
  };
}

/* Get imagen (Async) */

function getImagenSuccess(data) {
  return {
    type: GET_IMAGEN_SUCCESS,
    payload: data,
  };
}

function getImagenFailure(error) {
  return {
    type: GET_IMAGEN_FAILURE,
    payload: error,
  };
}

function getImagenInit(data) {
  return {
    type: GET_IMAGEN_INIT,
    payload: data.loading,
  };
}

export function getImagen(data) {
  return async (dispatch) => {
    dispatch(getImagenInit(data));
    try {
      const resp = await API.data.getImagen(data);
      return dispatch(getImagenSuccess(resp));
    } catch (error) {
      return dispatch(getImagenFailure(error));
    }
  };
}

/* Get recursos(Async) */

function getRecursosSuccess(data, index) {
  return {
    type: GET_RECURSOS_SUCCESS,
    payload: data,
    index,
  };
}

function getRecursosFailure(error) {
  return {
    type: GET_RECURSOS_FAILURE,
    payload: error,
  };
}

function getRecursosInit(data) {
  return {
    type: GET_RECURSOS_INIT,
    payload: data.loading,
  };
}

export function getRecursos(data, index) {
  return async (dispatch) => {
    dispatch(getRecursosInit(data));
    try {
      if (data.edi_division_id === "" || data.edi_division_id === "empty") {
        const resp = { status: 200, data: {} };
        return dispatch(getRecursosSuccess(resp, index));
      } else {
        const resp = await API.data.getRecursos(data);
        return dispatch(getRecursosSuccess(resp, index));
      }
    } catch (error) {
      return dispatch(getRecursosFailure(error));
    }
  };
}

/* Get reserva(Async) */

function getReservaSuccess(data, index) {
  return {
    type: GET_RESERVA_SUCCESS,
    payload: data,
    index,
  };
}

function getReservaFailure(error) {
  console.log(error);
  return {
    type: GET_RESERVA_FAILURE,
    payload: error,
  };
}

function getReservaInit(data) {
  return {
    type: GET_RESERVA_INIT,
    payload: data.loading,
  };
}

export function getReserva(data) {
  return async (dispatch) => {
    dispatch(getReservaInit(data));
    try {
      const resp = await API.data.getReserva(data);
      return dispatch(getReservaSuccess(resp));
    } catch (error) {
      return dispatch(getReservaFailure(error));
    }
  };
}

/* Get reserva externa */

function getReservaExternaSuccess(data, index) {
  return {
    type: GET_RESERVA_EXTERNA_SUCCESS,
    payload: data,
    index,
  };
}

function getReservaExternaFailure(error) {
  return {
    type: GET_RESERVA_EXTERNA_FAILURE,
    payload: error,
  };
}

function getReservaExternaInit(data) {
  return {
    type: GET_RESERVA_EXTERNA_INIT,
    payload: data.loading,
  };
}

export function getReservaExterna(data) {
  return async (dispatch) => {
    dispatch(getReservaExternaInit(data));
    try {
      const resp = await API.data.getReservaExterna(data);
      return dispatch(getReservaExternaSuccess(resp));
    } catch (error) {
      return dispatch(getReservaExternaFailure(error));
    }
  };
}

/* Get accept reserva externa */

function getAcceptReservaExternaSuccess(data, index) {
  return {
    type: GET_ACCEPT_RESERVA_EXTERNA_SUCCESS,
    payload: data,
    index,
  };
}

function getAcceptReservaExternaFailure(error) {
  return {
    type: GET_ACCEPT_RESERVA_EXTERNA_FAILURE,
    payload: error,
  };
}

function getAcceptReservaExternaInit(data) {
  return {
    type: GET_ACCEPT_RESERVA_EXTERNA_INIT,
    payload: data.loading,
  };
}

export function getAcceptReservaExterna(data) {
  return async (dispatch) => {
    dispatch(getAcceptReservaExternaInit(data));
    try {
      const resp = await API.data.getAcceptReservaExterna(data);
      return dispatch(getAcceptReservaExternaSuccess(resp));
    } catch (error) {
      return dispatch(getAcceptReservaExternaFailure(error));
    }
  };
}

/* Get reserva externa list */

function getReservaExternaListSuccess(data, index) {
  return {
    type: GET_RESERVA_EXTERNA_LIST_SUCCESS,
    payload: data,
    index,
  };
}

function getReservaExternaListFailure(error) {
  return {
    type: GET_RESERVA_EXTERNA_LIST_FAILURE,
    payload: error,
  };
}

function getReservaExternaListInit(data) {
  return {
    type: GET_RESERVA_EXTERNA_LIST_INIT,
    payload: data.loading,
  };
}

export function getReservaExternaList(data) {
  return async (dispatch) => {
    dispatch(getReservaExternaListInit(data));
    try {
      const resp = await API.data.getReservaExternaList(data);
      return dispatch(getReservaExternaListSuccess(resp));
    } catch (error) {
      return dispatch(getReservaExternaListFailure(error));
    }
  };
}

/* Get reserva externa list */

function getReservaAllExternaListSuccess(data, index) {
  return {
    type: GET_RESERVA_ALL_EXTERNA_LIST_SUCCESS,
    payload: data,
    index,
  };
}

function getReservaAllExternaListFailure(error) {
  return {
    type: GET_RESERVA_ALL_EXTERNA_LIST_FAILURE,
    payload: error,
  };
}

function getReservaAllExternaListInit(data) {
  return {
    type: GET_RESERVA_ALL_EXTERNA_LIST_INIT,
    payload: data.loading,
  };
}

export function getReservaAllExternaList(data) {
  return async (dispatch) => {
    dispatch(getReservaAllExternaListInit(data));
    try {
      const resp = await API.data.getReservaAllExternaList(data);
      return dispatch(getReservaAllExternaListSuccess(resp));
    } catch (error) {
      return dispatch(getReservaAllExternaListFailure(error));
    }
  };
}

/* Get  options reservation */

function getOptionsReservationSuccess(data, index) {
  return {
    type: GET_OPTIONS_RESERVATION_SUCCESS,
    payload: data,
    index,
  };
}

function getOptionsReservationFailure(error) {
  return {
    type: GET_OPTIONS_RESERVATION_FAILURE,
    payload: error,
  };
}

function getOptionsReservationInit(data) {
  return {
    type: GET_OPTIONS_RESERVATION_INIT,
    payload: data.loading,
  };
}

export function getOptionsReservation(data) {
  return async (dispatch) => {
    dispatch(getOptionsReservationInit(data));
    try {
      const resp = await API.data.getOptionsReservation(data);
      return dispatch(getOptionsReservationSuccess(resp));
    } catch (error) {
      return dispatch(getOptionsReservationFailure(error));
    }
  };
}
