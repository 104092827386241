import React from "react";
import { FormattedMessage } from "react-intl";
const Loading = (props) => {
  return (
    <section className={`${props.style.loading__calendar}`}>
      <div className={`${props.style.loading__calendar__box}`}>
        <p>
          <FormattedMessage id="DeterminingAvailability" />
          {"   "}
          <i className="fas fa-spinner fa-spin"></i>
        </p>
      </div>
    </section>
  );
};

export default Loading;
