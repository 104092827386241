import React from "react";
import { FormattedMessage } from "react-intl";

const Step1 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Sobre nosotros</h1>

      <p>
        Kantoor es la plataforma que permite la gestión y administración del
        proceso de agendamiento y reserva de los puestos y escritorios, salas de
        reuniones y espacios comunes de tu lugar de trabajo.
      </p>
    </section>
  );
};

export default Step1;
