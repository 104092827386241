import initialState from "./initialState";
import {
  GET_LOGIN_VALIDATE_INIT,
  GET_LOGIN_VALIDATE_SUCCESS,
  GET_LOGIN_VALIDATE_FAILURE,
  RESET_LOGIN_VALIDATE,
  GET_RESERVA_VALIDATE_INIT,
  GET_RESERVA_VALIDATE_SUCCESS,
  GET_RESERVA_VALIDATE_FAILURE,
  RESET_RESERVA_VALIDATE,
  LOGOUT_VALIDATE,
  GET_CHECK_VALIDATE_INIT,
  GET_CHECK_VALIDATE_SUCCESS,
  GET_CHECK_VALIDATE_FAILURE,
  RESET_CHECK_VALIDATE,
} from "../../actions/validate/types";

export default function login(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_VALIDATE:
      localStorage.clear();
      return {
        ...state,
        data: false,
        email: false,
        loading: false,

        errorMessage: "",

        dataReserva: false,
        loadingReserva: true,
        statusReserva: 0,
      };
    /* Setting s*/
    case RESET_LOGIN_VALIDATE:
      return {
        ...state,
        data: false,
        loading: false,
        errorMessage: "",
        status: 0,
        email: false,
      };
    case GET_LOGIN_VALIDATE_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGIN_VALIDATE_SUCCESS:
      const auth = action.payload.status === 200 ? true : false;
      const index = action.payload.data.roles.findIndex(
        (o) => o.codigo === "recepcionista"
      );
      let error = "";
      if (auth && index > -1) {
        localStorage.setItem("authValidate", auth);
      } else {
        error = "HaveNoTheRole";
      }

      return {
        ...state,
        data: action.payload.data,
        email: action.info.email,
        loading: false,
        status: action.payload.status,
        errorMessage: error,
      };
    case GET_LOGIN_VALIDATE_FAILURE:
      return {
        ...state,
        data: true,
        loading: false,
        status: 501,
      };
    /* Data reserva */
    case RESET_RESERVA_VALIDATE:
      return {
        ...state,
        dataReserva: false,
        loadingReserva: false,
        statusReserva: 0,
      };
    case GET_RESERVA_VALIDATE_INIT:
      return {
        ...state,
        loadingReserva: action.loading,
      };
    case GET_RESERVA_VALIDATE_SUCCESS:
      return {
        ...state,
        dataReserva: action.payload.data,
        loadingReserva: false,
        statusReserva: action.payload.status,
      };
    case GET_RESERVA_VALIDATE_FAILURE:
      return {
        ...state,
        dataReserva: true,
        loadingReserva: false,
        statusReserva: 501,
      };
    /* Data reserva */
    case RESET_CHECK_VALIDATE:
      return {
        ...state,
        dataCheck: false,
        loadingCheck: false,
        statusCheck: 0,
      };
    case GET_CHECK_VALIDATE_INIT:
      return {
        ...state,
        loadingCheck: true,
      };
    case GET_CHECK_VALIDATE_SUCCESS:
      return {
        ...state,
        dataCheck: action.payload.data,
        loadingCheck: false,
        statusCheck: action.payload.status,
      };
    case GET_CHECK_VALIDATE_FAILURE:
      return {
        ...state,
        dataCheck: true,
        loadingCheck: false,
        statusCheck: 501,
      };

    default:
      return state;
  }
}
