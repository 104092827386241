import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

/* Actions  */
import { getLanguages, resetLanguages } from "../../actions/login";

const ModalLanguajes = (props) => {
  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    language: "",
  });
  const [ok, setOk] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (state.statusLanguages !== 0) {
      if (state.statusLanguages === 200) {
        if (state.dataLanguages.ejecucion.estado) {
          setOk(true);
          setError(false);
        }
      } else {
        setError("ThereWasAnError");
      }
    }
  }, [state.loadingLanguages]);

  /* Reset forget pass*/
  useEffect(() => {
    return () => {
      dispatch(resetLanguages());
      setOk(false);
    };
  }, [dispatch]);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    if (form.language === "") {
      setError("CompleteTheRequiredFields");
    } else {
      localStorage.setItem("language", form.language);
      dispatch(getLanguages({ lenguaje: form.language }));
    }
  };

  return (
    <>
      <Modal
        onHide={() => {
          props.setModal(false);
          dispatch(resetLanguages());
          setOk(false);
        }}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="Languages" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          {!ok ? (
            <Modal.Body>
              <div className={props.style.box__input}>
                <label className={props.style.box__input__label}>
                  <FormattedMessage id="Languages" />
                </label>
                <FormattedMessage id="WriteYourPass">
                  {(msg) => (
                    <Form.Control
                      value={form.language}
                      onChange={(e) => handleChange(e)}
                      name={"language"}
                      as="select"
                    >
                      <FormattedMessage id="Select">
                        {(message) => <option value="">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="Spanish">
                        {(message) => <option value="es">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="English">
                        {(message) => <option value="en">{message}</option>}
                      </FormattedMessage>
                    </Form.Control>
                  )}
                </FormattedMessage>
              </div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <p>
                <FormattedMessage id="LanguageUpdatedSuccessfully" />
              </p>
            </Modal.Body>
          )}
          <Modal.Footer>
            {error && (
              <span className={props.style.error}>
                <p className={props.style.center}>
                  <FormattedMessage id={error} />
                </p>
              </span>
            )}
            {!ok ? (
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                disabled={state.loadingLanguages}
                type="button"
                onClick={handleSubmit}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    {state.loadingLanguages ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Ok" />
                    )}
                  </span>
                  <span>
                    <i className="fas fa-globe-americas"></i>
                  </span>
                </div>
              </Button>
            ) : (
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                disabled={state.loadingLanguages}
                type="button"
                onClick={() => {
                  props.setModal(false);
                  dispatch(resetLanguages());
                  setOk(false);
                }}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    <FormattedMessage id="Ok" />
                  </span>
                  <span>
                    <i className="fas fa-globe-americas"></i>
                  </span>
                </div>
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalLanguajes;
