import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import { hydrate, render } from "react-dom";

import Main from "./containers/main";
import store from "./store/index";

import "./assets/styles/Index.scss";
import "./assets/styles/general/minimal.css";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";

const renderMethod = module.hot ? render : hydrate;

renderMethod(
  <Provider store={store}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
