import initialState from "./initialState";
import {
  GET_LOGIN_CHECK_INIT,
  GET_LOGIN_CHECK_SUCCESS,
  GET_LOGIN_CHECK_FAILURE,
  RESET_LOGIN_CHECK,
  GET_RESERVA_CHECK_INIT,
  GET_RESERVA_CHECK_SUCCESS,
  GET_RESERVA_CHECK_FAILURE,
  RESET_RESERVA_CHECK,
  GET_CHECK_INIT,
  GET_CHECK_SUCCESS,
  GET_CHECK_FAILURE,
  RESET_CHECK,
  GET_COVID_INIT,
  GET_COVID_SUCCESS,
  GET_COVID_FAILURE,
  RESET_COVID,
  GET_COVID_REGISTER_INIT,
  GET_COVID_REGISTER_SUCCESS,
  GET_COVID_REGISTER_FAILURE,
  RESET_COVID_REGISTER,
  GET_COVID_REGISTER_UPDATE_INIT,
  GET_COVID_REGISTER_UPDATE_SUCCESS,
  GET_COVID_REGISTER_UPDATE_FAILURE,
  RESET_COVID_REGISTER_UPDATE,
  GET_JORNADA_INIT,
  GET_JORNADA_SUCCESS,
  GET_JORNADA_FAILURE,
  RESET_JORNADA,
} from "../../actions/check/types";

export default function login(state = initialState, action) {
  switch (action.type) {
    /* Setting s*/
    case RESET_LOGIN_CHECK:
      return {
        ...state,
        data: false,
        loading: false,
        status: 0,
        email: false,
      };
    case GET_LOGIN_CHECK_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGIN_CHECK_SUCCESS:
      if (!action.info.invitado) {
        const auth = action.payload.status === 200 ? true : false;
        if (auth) {
          localStorage.setItem("auth", auth);
          if (action.info.facebook.uid !== "") {
            localStorage.setItem("type", "facebook");
            localStorage.setItem("names", action.info.facebook.name);
            localStorage.setItem("img", action.info.facebook.img);
            localStorage.setItem("email", action.payload.data.usuario.email);
            localStorage.setItem(
              "external_booking",
              action.payload.data.usuario.reserva_por_fuera
            );
            localStorage.setItem(
              "roles",
              JSON.stringify(action.payload.data.roles)
            );
            localStorage.setItem(
              "resource_name",
              action.payload.data.usuario.nombre_recurso
            );
            localStorage.setItem(
              "language",
              action.payload.data.usuario.lenguaje
            );
          } else if (action.info.office365.uid !== "") {
            localStorage.setItem("type", "microsoft");
            if (action.payload.data.persona) {
              localStorage.setItem(
                "names",
                `${action.payload.data.persona.nombres} ${action.payload.data.persona.apellido_paterno}`
              );
            } else {
              localStorage.setItem("names", action.payload.data.usuario.email);
            }
            localStorage.setItem(
              "img",
              "https://www.nicepng.com/png/detail/621-6215336_rocket-launch-spd-out-of-this-world-clip.png"
            );
            localStorage.setItem("email", action.payload.data.usuario.email);
            localStorage.setItem(
              "language",
              action.payload.data.usuario.lenguaje
            );
            localStorage.setItem(
              "external_booking",
              action.payload.data.usuario.reserva_por_fuera
            );
            localStorage.setItem(
              "resource_name",
              action.payload.data.usuario.nombre_recurso
            );
            localStorage.setItem(
              "roles",
              JSON.stringify(action.payload.data.roles)
            );
            localStorage.setItem(
              "logo",
              action.payload.data.avatar1.avatar1.archivo_imagen
            );
          } else if (action.info.google.uid !== "") {
            localStorage.setItem("type", "google");
            if (action.payload.data.persona) {
              localStorage.setItem(
                "names",
                `${action.payload.data.persona.nombres} ${action.payload.data.persona.apellido_paterno}`
              );
            } else {
              localStorage.setItem("names", action.payload.data.usuario.email);
            }
            localStorage.setItem(
              "img",
              "https://www.nicepng.com/png/detail/621-6215336_rocket-launch-spd-out-of-this-world-clip.png"
            );
            localStorage.setItem("email", action.payload.data.usuario.email);
            localStorage.setItem(
              "external_booking",
              action.payload.data.usuario.reserva_por_fuera
            );
            localStorage.setItem(
              "resource_name",
              action.payload.data.usuario.nombre_recurso
            );
            localStorage.setItem(
              "roles",
              JSON.stringify(action.payload.data.roles)
            );

            localStorage.setItem(
              "language",
              action.payload.data.usuario.lenguaje
            );

            localStorage.setItem(
              "logo",
              action.payload.data.avatar1.avatar1.archivo_imagen
            );
          } else {
            localStorage.setItem("type", "normal");
            localStorage.setItem(
              "img",
              "https://www.nicepng.com/png/detail/621-6215336_rocket-launch-spd-out-of-this-world-clip.png"
            );
            if (action.payload.data.persona) {
              localStorage.setItem(
                "names",
                `${action.payload.data.persona.nombres} ${action.payload.data.persona.apellido_paterno}`
              );
            } else {
              localStorage.setItem("names", action.payload.data.usuario.email);
            }
            localStorage.setItem(
              "language",
              action.payload.data.usuario.lenguaje
            );
            localStorage.setItem("email", action.payload.data.usuario.email);
            localStorage.setItem(
              "external_booking",
              action.payload.data.usuario.reserva_por_fuera
            );
            localStorage.setItem(
              "resource_name",
              action.payload.data.usuario.nombre_recurso
            );
            localStorage.setItem(
              "roles",
              JSON.stringify(action.payload.data.roles)
            );
            localStorage.setItem(
              "logo",
              action.payload.data.avatar1.avatar1.archivo_imagen
            );
          }
        }
      }
      return {
        ...state,
        data: action.payload.data,
        email: action.info.email,
        loading: false,
        status: action.payload.status,
      };
    case GET_LOGIN_CHECK_FAILURE:
      return {
        ...state,
        data: true,
        loading: false,
        status: 501,
      };
    /* Data reserva */
    case RESET_RESERVA_CHECK:
      return {
        ...state,
        dataReserva: false,
        loadingReserva: false,
        statusReserva: 0,
      };
    case GET_RESERVA_CHECK_INIT:
      return {
        ...state,
        loadingReserva: true,
      };
    case GET_RESERVA_CHECK_SUCCESS:
      return {
        ...state,
        dataReserva: action.payload.data,
        loadingReserva: false,
        statusReserva: action.payload.status,
      };
    case GET_RESERVA_CHECK_FAILURE:
      return {
        ...state,
        dataReserva: true,
        loadingReserva: false,
        statusReserva: 501,
      };
    /* Data reserva */
    case RESET_CHECK:
      return {
        ...state,
        dataCheck: false,
        loadingCheck: false,
        statusCheck: 0,
      };
    case GET_CHECK_INIT:
      return {
        ...state,
        loadingCheck: true,
      };
    case GET_CHECK_SUCCESS:
      return {
        ...state,
        dataCheck: action.payload.data,
        loadingCheck: false,
        statusCheck: action.payload.status,
      };
    case GET_CHECK_FAILURE:
      return {
        ...state,
        dataCheck: true,
        loadingCheck: false,
        statusCheck: 501,
      };

    /* Data covid */
    case RESET_COVID:
      return {
        ...state,
        dataCovid: false,
        loadingCovid: false,
        statusCovid: 0,
      };
    case GET_COVID_INIT:
      return {
        ...state,
        loadingCovid: true,
      };
    case GET_COVID_SUCCESS:
      return {
        ...state,
        dataCovid: action.payload.data,
        loadingCovid: false,
        statusCovid: action.payload.status,
      };
    case GET_COVID_FAILURE:
      return {
        ...state,
        dataCovid: true,
        loadingCovid: false,
        statusCovid: 501,
      };

    /* Data covid register */
    case RESET_COVID_REGISTER:
      return {
        ...state,
        dataCovidRegister: false,
        loadingCovidRegister: false,
        statusCovidRegister: 0,
      };
    case GET_COVID_REGISTER_INIT:
      return {
        ...state,
        loadingCovidRegister: true,
      };
    case GET_COVID_REGISTER_SUCCESS:
      return {
        ...state,
        dataCovidRegister: action.payload.data,
        loadingCovidRegister: false,
        statusCovidRegister: action.payload.status,
      };
    case GET_COVID_REGISTER_FAILURE:
      return {
        ...state,
        dataCovidRegister: true,
        loadingCovidRegister: false,
        statusCovidRegister: 501,
      };
    /* Data covid register update */
    case RESET_COVID_REGISTER_UPDATE:
      return {
        ...state,
        dataCovidRegisteUpdate: false,
        loadingCovidRegisteUpdate: false,
        statusCovidRegisteUpdater: 0,
      };
    case GET_COVID_REGISTER_UPDATE_INIT:
      return {
        ...state,
        loadingCovidRegisteUpdate: true,
      };
    case GET_COVID_REGISTER_UPDATE_SUCCESS:
      return {
        ...state,
        dataCovidRegisteUpdate: action.payload.data,
        loadingCovidRegisteUpdate: false,
        statusCovidRegisteUpdate: action.payload.status,
      };
    case GET_COVID_REGISTER_UPDATE_FAILURE:
      return {
        ...state,
        dataCovidRegisteUpdate: true,
        loadingCovidRegisteUpdate: false,
        statusCovidRegisteUpdate: 501,
      };

    /* Data Jornada*/
    case RESET_JORNADA:
      return {
        ...state,
        dataJornada: false,
        loadingJornada: false,
        statusJornada: 0,
      };
    case GET_JORNADA_INIT:
      return {
        ...state,
        loadingJornada: true,
      };
    case GET_JORNADA_SUCCESS:
      return {
        ...state,
        dataJornada: action.payload.data,
        loadingJornada: false,
        statusJornada: action.payload.status,
      };
    case GET_JORNADA_FAILURE:
      return {
        ...state,
        dataJornada: true,
        loadingJornada: false,
        statusJornada: 501,
      };

    default:
      return state;
  }
}
