import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const ModalGuests = (props) => {
  const handleGuests = () => {
    props.setQuestion(props.question + 1);
    props.setModal(false);
    if (props.question + 1 === props.lengthData) {
      props.nextStep();
      props.handleMemory({ name: "step6" });
      props.handleCount(1);
    }
  };

  const enterKeyPressed = (event) => {
    if (event.key === "Enter") {
      handleGuests();
    }
  };

  return (
    <>
      <Modal
        onHide={handleGuests}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>
              <FormattedMessage id="Guests" />
            </h4>
            <span>
              <i
                onClick={() => {
                  props.setModal(false);
                }}
                className="fas fa-times"
              ></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className={props.style.box__input__label}>
            <FormattedMessage id="HowManyReservationsDoYouWantToMake" />
          </label>
          <div className={props.style.box__input}>
            <FormattedMessage id="HowManyReservationsDoYouWantToMake">
              {(msg) => (
                <Form.Control
                  name="guests"
                  onChange={props.handleChange}
                  value={props.form.guests}
                  type="number"
                  placeholder={msg}
                  onKeyDown={enterKeyPressed}
                />
              )}
            </FormattedMessage>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={props.myStyle[`btn_${props.cliente}`]}
            variant="main"
            disabled={false}
            onClick={(e) => {
              e.preventDefault();
              handleGuests(e);
            }}
          >
            <div className={props.style.box__button__modal}>
              <span>
                {false ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Ok" />
                )}
              </span>
              <span>
                <i className="fas fa-search"></i>
              </span>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalGuests;
