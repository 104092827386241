import React, { useState } from "react";

/* Component */
import StepGuests from "./StepGuests";
import StepSummary from "./StepSummary";
import StepDone from "./StepDone";

const Content = (props) => {
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState(false);
  const [count, setCount] = useState(1);
  const [finalBooking, setFinalBooking] = useState([[], []]);

  let myEmail = "test";
  if (typeof localStorage !== "undefined") {
    myEmail = localStorage.getItem("email");
  }

  const [form, setForm] = useState({
    empresa_id: props.mydata.empresa_id,
    fecha_inicio: props.mydata.fecha_inicio,
    duracion: props.mydata.duracion,
    inicio: props.mydata.inicio,
    guests: props.mydata.guests,
    emails: props.mydata.emails,
    nodos_final: props.mydata.nodos_final,
    formato_tiempo: props.mydata.formato_tiempo,
    disponibility: props.mydata.disponibility,
    reserva_id: props.mydata.reserva_id,
  });

  const [memory, setMemory] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  /* This function is for to down a step */
  const backStep = () => {
    setDirection("left");
    setStep(step - 1);
  };

  /* This function is for to up a step */
  const nextStep = () => {
    setDirection("right");
    setStep(step + 1);
  };

  /*  Function changue inputs  */
  const handleChange = (event) => {
    if (event.persist) {
      event.persist();
    }

    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleMemory = (obj) => {
    setMemory((prev) => ({ ...prev, [obj.name]: true }));
  };

  /* This counter is representative for the user */
  const handleCount = (value) => {
    setCount(count + value);
  };

  let container;

  switch (step) {
    case 1:
      container = (
        <StepGuests
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={props.cliente}
          myStyle={props.myStyle}
        />
      );
      break;
    case 2:
      container = (
        <StepSummary
          filters={props.filters}
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          setFinalBooking={setFinalBooking}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={props.cliente}
          myStyle={props.myStyle}
        />
      );
      break;
    case 3:
      container = (
        <StepDone
          setStep={setStep}
          setMemory={setMemory}
          setCount={setCount}
          setForm={setForm}
          style={props.style}
          cliente={props.cliente}
          myStyle={props.myStyle}
          setFinalBooking={setFinalBooking}
          finalBooking={finalBooking}
        />
      );
      break;
    default:
      container = <h1>Test</h1>;
      break;
  }
  return (
    <section className={props.style.container}>
      {container}
      {step !== 5 && step !== 6 && step !== 8 && step !== 12 && (
        <div className={props.style.box__arrow}>
          {step > 1 && (
            <span
              onClick={() => {
                backStep();
                setDirection("left");
                handleCount(-1);
              }}
            >
              <i className="far fa-arrow-alt-circle-left"></i>
            </span>
          )}
          {memory[`step${step + 1}`] && (
            <span
              onClick={() => {
                nextStep();
                setDirection("right");
                handleCount(1);
              }}
            >
              <i className="far fa-arrow-alt-circle-right"></i>
            </span>
          )}
        </div>
      )}
    </section>
  );
};

export default Content;
