import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

/* Img */
import Logo from "../../assets/images/logo.png";
import Modal from "./Modal";
import Expired from "../common/ExpiredCheck";

/* Actions  */
import {
  getCheck,
  resetCheck,
  getReservaCheck,
  resetLoginCheck,
  resetReservaCheck,
  getJornada,
  getCovid,
  getCovidRegister,
  getCovidRegisterUpdate,
  resetCovidRegister,
  resetCovidRegisterUpdate,
  resetCovid,
  resetJornada,
} from "../../actions/check";

/* Actions */
import { getReserva, resetReserva } from "../../actions/reservation";

const Content = (props) => {
  const [covid, setCovid] = useState({
    respuesta1: "",
    respuesta2: "",
    respuesta3: "",
    acepto: "",
  });
  const [modal, setModal] = useState(false);

  const [covidOk, setCovidOk] = useState(false);
  const [horario, setHorario] = useState([]);

  const state = useSelector((state) => state.check);
  const [email, setEmail] = useState(false);
  const [emailSave, setEmailSave] = useState(false);

  const stateReserva = useSelector((state) => state.reservation);

  const dispatch = useDispatch();

  /* Set email when the user  is logined */
  useEffect(() => {
    if (state.email) {
      setEmail(state.email);
    }
  }, [state]);

  useEffect(() => {
    const emailLocal = localStorage.getItem("email");
    if (emailLocal) {
      setEmailSave(emailLocal);
      setEmail(emailLocal);
    }
  });

  useEffect(() => {
    if (state.statusCovidRegister === 200 && state.statusCheck === 0) {
      if (state.dataCovidRegister.ejecucion.estado) {
        setCovidOk(true);
      }
    }
  }, [state]);

  const myBookings = () => {
    setEmail(emailSave);
    const div = props.id.split("=");
    dispatch(
      getReservaCheck({
        edi_division_id: parseInt(div[1], 10),
        email: emailSave,
      })
    );
    dispatch(
      getJornada({
        edi_division_id: parseInt(div[1], 10),
        fecha: moment(new Date()).format("DD-MM-YYYY"),
      })
    );
    dispatch(
      getCovid({
        edi_division_id: parseInt(div[1], 10),
        email: emailSave,
      })
    );
  };

  const handleCheck = (obj) => {
    dispatch(
      getCheck({
        reserva_id: obj.reserva_id,
        email: email.toLowerCase(),
        type: obj.type,
      })
    );

    if (covidOk) {
      dispatch(
        getCovidRegisterUpdate({
          reserva_id: obj.reserva_id,
          encuesta_id: state.dataCovidRegister.datos.id,
        })
      );
    }
  };

  const makeBooking = () => {
    if (horario.length > 0) {
      let diff = Math.abs(
        new Date(`${moment(new Date()).format("YYYY/MM/DD")} ${horario[0]}`) -
          new Date(`${moment(new Date()).format("YYYY/MM/DD")} ${horario[1]}`)
      );
      let minutes = Math.floor(diff / 1000 / 60);

      const div = props.id.split("=");
      const obj = {
        editables: [],
        reservas: [
          {
            empresa_id: state.dataJornada.datos.empresa_id,
            edi_division_id: parseInt(div[1], 10),
            clave_granularidad: state.dataJornada.datos.granularidad,
            fecha_inicio: moment(new Date()).format("DD-MM-YYYY"),
            hora_inicio: horario[0],
            duracion: minutes,
            arr_recursos_escasos: [],
            arr_invitados: [{ email: email.toLowerCase() }],
            tipo_iteracion: 1,
          },
        ],
      };

      dispatch(getReserva(obj));
    }
  };

  const renderButton = (cin, cout, reserva_id) => {
    if (cout === true) {
      return <h6>Ya fue realizado el checkout</h6>;
    } else if (cin === false && cout === false) {
      return (
        <button
          onClick={() => {
            handleCheck({ reserva_id, type: "checkin" });
          }}
        >
          {state.loadingCheck ? <FormattedMessage id="Loading" /> : "check in"}
        </button>
      );
    } else if (cin === true && cout === false) {
      return (
        <button
          onClick={() => {
            handleCheck({ reserva_id, type: "checkout" });
          }}
        >
          {state.loadingCheck ? <FormattedMessage id="Loading" /> : "check out"}
        </button>
      );
    }
  };

  const reset = () => {
    dispatch(resetCheck());
    dispatch(resetLoginCheck());
    dispatch(resetReservaCheck());
    dispatch(resetCovidRegister());
    dispatch(resetCovidRegisterUpdate());
    dispatch(resetCovid());
  };

  const resetCheckState = () => {
    const div = props.id.split("=");
    dispatch(
      getReservaCheck({
        edi_division_id: parseInt(div[1], 10),
        email: email,
      })
    );
    dispatch(resetJornada());
    dispatch(resetCheck());
    dispatch(resetReserva());
  };

  /*  Function covid  */
  const handleCovid = (obj) => {
    setCovid((prev) => ({ ...prev, [obj.name]: obj.value }));
  };

  /*  Function covid  */
  const handleRegisterCovid = (obj) => {
    const div = props.id.split("=");
    dispatch(
      getCovidRegister({
        edi_division_id: parseInt(div[1], 10),
        reservador_id: state.dataCovid.datos.reservador_id,
        respuesta1: covid.respuesta1,
        respuesta2: covid.respuesta2,
        respuesta3: covid.respuesta3,
      })
    );
  };

  const handleHorario = (arr, id, comienzo, termino) => {
    let myHorario = [...horario];

    if (myHorario.length > 0) {
      let clean = false;
      let menor = id <= myHorario[2] ? id : myHorario[2];
      let mayor = id >= myHorario[2] ? id : myHorario[2];

      arr.forEach((element) => {
        if (element.id >= menor && element.id <= mayor) {
          if (!element.seleccionable || !element.disponibilidad) {
            clean = true;
          }
        }
      });
      if (clean) {
        myHorario = [comienzo, termino, id, id];
      } else {
        if (id === myHorario[2]) {
          myHorario = [comienzo, termino, id, id];
        } else if (id <= myHorario[2]) {
          myHorario = [comienzo, myHorario[1], id, myHorario[2]];
        } else {
          myHorario = [myHorario[0], termino, id, myHorario[2]];
        }
      }
    } else {
      myHorario = [comienzo, termino, id, id];
    }

    setHorario(myHorario);
  };

  const renderContent = () => {
    if (state.loadingReserva || state.loadingCovid || state.loadingJornada) {
      return (
        <div className={`${props.style.container__loading}`}>
          <h1>Cargando</h1>
        </div>
      );
    }

    if (state.statusJornada === 501 || state.statusCovid === 501) {
      return <Expired />;
    }

    /* Render  */
    if (
      state.statusReserva === 200 &&
      state.statusCovid === 200 &&
      state.statusCheck === 0
    ) {
      if (
        (state.dataReserva.ejecucion.estado &&
          !state.dataCovid.ejecucion.estado) ||
        covidOk
      ) {
        return (
          <React.Fragment>
            {state.dataReserva.datos.map((item) => {
              return (
                <section
                  key={item.reserva.id}
                  className={props.style.container__check}
                >
                  <article>
                    <div>
                      <h3>{item.reserva.edi_division_nombre}</h3>
                    </div>
                    <div>
                      <h3>
                        <FormattedMessage id="From" />:{" "}
                        {moment(item.reserva.desde.substr(0, 16)).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </h3>
                    </div>
                    <div>
                      <h3>
                        <FormattedMessage id="To" />:{" "}
                        {moment(item.reserva.hasta.substr(0, 16)).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </h3>
                    </div>
                  </article>
                  <article>
                    {renderButton(
                      item.chequeo.check_in,
                      item.chequeo.check_out,
                      item.reserva.id
                    )}
                  </article>
                </section>
              );
            })}
          </React.Fragment>
        );
      } else if (
        state.dataCovid.ejecucion.estado &&
        state.dataReserva.ejecucion.estado
      ) {
        return (
          <section className={props.style.container__question}>
            <h2>{state.dataCovid.datos.encuesta}</h2>
            <h3>
              {state.dataCovid.datos.declaracion}
              <section>
                <div
                  className={covid.acepto === true ? props.style.active : ""}
                  onClick={() => handleCovid({ name: "acepto", value: true })}
                >
                  <FormattedMessage id="Ok" />
                </div>
              </section>
            </h3>
            <ul>
              <li>
                1 - {state.dataCovid.datos.pregunta1}
                <section>
                  <div
                    className={
                      covid.respuesta1 === true ? props.style.active : ""
                    }
                    onClick={() =>
                      handleCovid({ name: "respuesta1", value: true })
                    }
                  >
                    <FormattedMessage id="Yes" />
                  </div>
                  <div
                    className={
                      covid.respuesta1 === false ? props.style.active : ""
                    }
                    onClick={() =>
                      handleCovid({ name: "respuesta1", value: false })
                    }
                  >
                    <FormattedMessage id="No" />
                  </div>
                </section>
              </li>
              <li>
                2 - {state.dataCovid.datos.pregunta2}
                <section>
                  <div
                    className={
                      covid.respuesta2 === true ? props.style.active : ""
                    }
                    onClick={() =>
                      handleCovid({ name: "respuesta2", value: true })
                    }
                  >
                    <FormattedMessage id="Yes" />
                  </div>
                  <div
                    className={
                      covid.respuesta2 === false ? props.style.active : ""
                    }
                    onClick={() =>
                      handleCovid({ name: "respuesta2", value: false })
                    }
                  >
                    <FormattedMessage id="No" />
                  </div>
                </section>
              </li>
              <li>
                3 - {state.dataCovid.datos.pregunta3}
                <section>
                  <div
                    className={
                      covid.respuesta3 === true ? props.style.active : ""
                    }
                    onClick={() =>
                      handleCovid({ name: "respuesta3", value: true })
                    }
                  >
                    <FormattedMessage id="Yes" />
                  </div>
                  <div
                    className={
                      covid.respuesta3 === false ? props.style.active : ""
                    }
                    onClick={() =>
                      handleCovid({ name: "respuesta3", value: false })
                    }
                  >
                    <FormattedMessage id="No" />
                  </div>
                </section>
              </li>
            </ul>
            {covid.respuesta1 === false &&
              covid.respuesta2 === false &&
              covid.respuesta3 === false &&
              covid.acepto !== "" && (
                <Button
                  variant="main"
                  className={props.myStyle[`btn_${props.cliente}`]}
                  onClick={() => handleRegisterCovid({})}
                >
                  <div className={props.style.box__button}>
                    <span>
                      {state.loadingCovidRegister ? (
                        <FormattedMessage id="Loading" />
                      ) : (
                        <FormattedMessage id="Register" />
                      )}
                    </span>
                    <span>
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                </Button>
              )}

            {(covid.respuesta1 === true ||
              covid.respuesta2 === true ||
              covid.respuesta3 === true) && (
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
              >
                <div className={props.style.box__button}>
                  <span>
                    {state.loadingCovidRegister ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Cannotbook" />
                    )}
                  </span>
                </div>
              </Button>
            )}
          </section>
        );
      } else if (stateReserva.statusReserva === 200) {
        if (stateReserva.dataReserva.ejecucion.estado) {
          return (
            <div className={props.style.container__message}>
              <h1>
                <FormattedMessage id="ReservationMadeSuccessfully" />
              </h1>
              <p>
                <FormattedMessage id="YouCanSeeIfYourCheckInIsAvailable" />.
              </p>
              <p>
                <FormattedMessage id="MessageSeeCheckin" />.
              </p>
              <Button
                className={props.myStyle[`btn_${props.cliente}`]}
                variant="main"
                onClick={() => resetCheckState()}
              >
                <div className={props.style.box__button}>
                  <span>
                    <FormattedMessage id="Next" />
                  </span>
                  <span>
                    <i className="fas fa-eye"></i>
                  </span>
                </div>
              </Button>
            </div>
          );
        } else {
          return (
            <div className={props.style.container__message}>
              <h1>
                <FormattedMessage id="ReservationCouldNotBeMade" />
              </h1>
            </div>
          );
        }
      } else if (state.dataJornada) {
        if (state.dataJornada.ejecucion.estado) {
          return (
            <div className={props.style.container__booking__schedule}>
              <h1>
                <FormattedMessage id="SelectATime" />
              </h1>
              <section>
                {state.dataJornada.datos.calendario_del_dia.casillas.map(
                  (item) => {
                    if (item.seleccionable && item.disponibilidad) {
                      let mayor = 0;
                      let menor = 0;

                      if (horario.length > 0) {
                        mayor =
                          horario[2] >= horario[3] ? horario[2] : horario[3];
                        menor =
                          horario[2] <= horario[3] ? horario[2] : horario[3];
                      }

                      return (
                        <div
                          key={item.id}
                          className={
                            item.id >= menor && item.id <= mayor
                              ? props.style.green
                              : ""
                          }
                          onClick={() =>
                            handleHorario(
                              state.dataJornada.datos.calendario_del_dia
                                .casillas,
                              item.id,
                              item.comienza,
                              item.termina
                            )
                          }
                        >
                          <span>{item.comienza}</span>
                          <span>{item.termina}</span>
                        </div>
                      );
                    }
                    return (
                      <div key={item.id} className={props.style.red}>
                        <span>{item.comienza}</span>
                        <span>{item.termina}</span>
                      </div>
                    );
                  }
                )}
              </section>
              <Button
                className={props.myStyle[`btn_${props.cliente}`]}
                variant="main"
                onClick={() => makeBooking()}
              >
                <div className={props.style.box__button}>
                  <span>
                    {stateReserva.loadingReserva ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="MakeABooking" />
                    )}
                  </span>
                  <span>
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </span>
                </div>
              </Button>
            </div>
          );
        } else {
          return (
            <div className={props.style.container__message}>
              <h1>
                <FormattedMessage id="YouDoNotHaveCheckInAvailable" />
              </h1>
            </div>
          );
        }
      } else {
        return (
          <div className={props.style.container__message}>
            <h1>
              <FormattedMessage id="YouDoNotHaveCheckInAvailable" />
            </h1>
          </div>
        );
      }
    }
  };

  return (
    <section className={props.style.container}>
      <Modal
        setEmail={setEmail}
        id={props.id}
        style={props.style}
        modal={modal}
        setModal={setModal}
        cliente={props.cliente}
        myStyle={props.myStyle}
      />
      {props.cliente === "" ? (
        <h1>
          <FormattedMessage id="WelcomeToKantoor" />
        </h1>
      ) : (
        <h1>
          <FormattedMessage id="WelcomeTo" /> {props.cliente} y Kantoor
        </h1>
      )}
      {props.cliente === "" ? (
        <div className={props.style.box__img}>
          <img src={Logo} alt="Logo" />
        </div>
      ) : (
        <div
          className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
        >
          <div className={props.myStyle[`logo_${props.cliente}`]}></div>
          <div></div>
        </div>
      )}
      <div className={props.style.box__text}>
        <p>
          <FormattedMessage id="PleaseConfirmYourEntryOrExitOfTheReservation" />
        </p>
      </div>
      {state.statusReserva === 0 && (
        <React.Fragment>
          {emailSave ? (
            <div className={props.style.container__buttons}>
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                onClick={() => myBookings()}
              >
                <div className={props.style.box__button}>
                  <span>
                    <FormattedMessage id="MyBookings" />
                  </span>
                  <span>
                    <i className="fas fa-eye"></i>
                  </span>
                </div>
              </Button>
            </div>
          ) : (
            <div className={props.style.container__buttons}>
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                onClick={() => setModal({ edi_division: 6, invitado: false })}
              >
                <div className={props.style.box__button}>
                  <span>
                    <FormattedMessage id="LogIn" />
                  </span>
                  <span>
                    <i className="fas fa-user"></i>
                  </span>
                </div>
              </Button>
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                onClick={() => setModal({ edi_division: 6, invitado: true })}
              >
                <div className={props.style.box__button}>
                  <span>
                    <FormattedMessage id="Guests" />
                  </span>
                  <span>
                    <i className="fas fa-users"></i>
                  </span>
                </div>
              </Button>
            </div>
          )}
        </React.Fragment>
      )}

      {/* Condicionamos que vamos a renderizar */}
      {renderContent()}

      {state.statusReserva === 200 && state.statusCheck === 200 && (
        <div className={props.style.container__message}>
          {state.dataCheck.ejecucion ? (
            <h1>
              <FormattedMessage id="SuccessfulAction" />
            </h1>
          ) : (
            <h1>
              <FormattedMessage id="ThereWasAnError" />
            </h1>
          )}

          <Button
            className={props.myStyle[`btn_${props.cliente}`]}
            variant="main"
            onClick={() => resetCheckState()}
          >
            <div className={props.style.box__button}>
              <span>
                <FormattedMessage id="GoBack" />
              </span>
              <span>
                <i className="fas fa-undo"></i>
              </span>
            </div>
          </Button>
        </div>
      )}
    </section>
  );
};

export default Content;
