import React from "react";
import { FormattedMessage } from "react-intl";

const headerDay = (props) => {
  return (
    <section
      className={`${props.style.container__schedule__header} ${
        props.myStyle[`header_agenda_${props.cliente}`]
      }`}
    >
      {props.data.map((item, i) => {
        let button;
        if (i === 0) {
          button = (
            <button
              onClick={props.back}
              className={`${props.style.btn__left} ${props.style.btn__top}`}
            >
              <i className="fas fa-arrow-circle-left"></i>
            </button>
          );
        } else if (i + 1 === props.data.length) {
          button = (
            <button
              onClick={props.next}
              className={`${props.style.btn__top} ${props.style.btn__right}`}
            >
              <i className="fas fa-arrow-circle-right"></i>
            </button>
          );
        }

        return (
          <div key={item.id}>
            {button}
            <h3>{item.label_corto}</h3>
          </div>
        );
      })}
    </section>
  );
};

export default headerDay;
