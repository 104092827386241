import axios from "axios";

const API = {
  data: {
    async getLogin(obj) {
      const response = await axios.post(`/api/login`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getForgetPass(obj) {
      const response = await axios.post(`/api/forgetPass`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getChangePass(obj) {
      const response = await axios.post(`/api/changePass`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getLanguages(obj) {
      const response = await axios.post(`/api/languages`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getCreateUser(obj) {
      const response = await axios.post(`/api/createUser`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getArea(obj) {
      const response = await axios.get(
        `/api/createUser/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async postCreateUserCompany(obj) {
      const response = await axios.post(`/api/createUser/company`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getCreateUserCode(obj) {
      const response = await axios.post(`/api/createUserCode`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
