import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse } from "date-fns";
import { FormattedMessage } from "react-intl";

import enUS from "date-fns/locale/en-US";
import esEs from "date-fns/locale/es";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarMenu.css";

import { getHistory } from "../../../actions/history";

const locales = {
  "en-US": enUS,
  es: esEs,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  locales,
});

const CalendarMenu = (props) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.history);
  const stateReserva = useSelector((state) => state.reservation);

  const [dataCalendar, setDataCalendar] = useState([]);

  // GET DATA
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getHistory({ filtrar_evaluadas: false }));
    };
    callsAPI();
  }, [dispatch, stateReserva.statusReserva]);

  // CARGAR DATOS EN EL CALENDARIO
  useEffect(() => {
    const data = state.dataHistory.datos;
    const arr = [];

    if (state.statusHistory === 200) {
      for (let i = 0; i < data.length; i++) {
        const fechaParseadaInicio = parse(
          data[i].reserva.desde,
          "dd-MM-yyyy HH:mm",
          new Date()
        );

        const fechaParseadaFin = parse(
          data[i].reserva.hasta,
          "dd-MM-yyyy HH:mm",
          new Date()
        );

        const titleEvent = `${data[i].edi_division_reservada.nombre}`;

        arr.push({
          id: data[i].reserva.id,
          title: titleEvent,
          start: fechaParseadaInicio,
          end: fechaParseadaFin,
          vigente: data[i].reserva.vigente,
        });
      }

      setDataCalendar(arr);
    }
  }, [state.statusHistory]);

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.vigente ? "#367CF7" : "#FA5858",
      color: "white",
      borderRadius: "5px",
      border: "none",
    };

    return {
      style,
    };
  };

  const availableViews = {
    day: true,
  };

  let language =
    typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : "es";

  if (language !== "es" && language !== "en") {
    language = "es";
  }

  return (
    <div
      className={
        props.expand && props.select === "calendar"
          ? `${props.style.box} `
          : `${props.style.box} ${props.style.hide}`
      }
    >
      <article>
        <span className={props.style.box__close}>
          <i
            onClick={() => {
              props.setExpand(false);
              props.setSelect("");
            }}
            className="fas fa-times"
          />
        </span>
        <div className="calendar-container">
          <div className="calendar-container-estados">
            <strong>
              <FormattedMessage id="Current" />
            </strong>
            <div className="calendar-circle-vigente"></div>

            <strong>
              <FormattedMessage id="Expired" />
            </strong>
            <div className="calendar-circle-expirada"></div>
          </div>
          <div className="calendar-wrapper">
            <Calendar
              localizer={localizer}
              events={dataCalendar}
              startAccessor="start"
              culture={language}
              endAccessor="end"
              selectable
              views={availableViews}
              defaultView="day"
              style={{ height: "calc(100vh - 90px)" }}
              eventPropGetter={eventStyleGetter}
              messages={language === "es" && getMessagesES()}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default CalendarMenu;

// Mensajes que se muestran en el calendario cuando es en español
export const getMessagesES = () => {
  return {
    allDay: "Todo el día",
    previous: "<",
    next: ">",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "No hay eventos en este rango",
    showMore: (total) => `+ Ver más (${total})`,
  };
};
