import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Component */
import { Button } from "react-bootstrap";

/* Actions */
import { resetReserva } from "../../actions/reservation";

const StepDone = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let myEmail = "test";
  if (typeof localStorage !== "undefined") {
    myEmail = localStorage.getItem("names");
  }

  useEffect(() => {
    return () => {
      dispatch(resetReserva());
    };
  }, [dispatch]);

  return (
    <section
      className={`${props.style.container__content} ${props.style.container__content__center}`}
    >
      <h1>
        <FormattedMessage id="BookingReady" />
      </h1>
      <h2>
        <FormattedMessage id="Summary1" />
      </h2>
      <section className={`${props.style.container__content__booking}`}>
        <article>
          <h4>
            <FormattedMessage id="BookingsMade" />
          </h4>
          <section>
            {props.finalBooking[0].map((item, i) => {
              let returned_endate = moment(
                `${item.fecha_inicio} ${item.hora_inicio}:00`,
                "DD-MM-YYYY HH:mm:ss"
              )
                .add(item.duracion, "minutes")
                .format("HH:mm");

              let returned_day = moment(
                `${item.fecha_inicio} ${item.hora_inicio}:00`,
                "DD-MM-YYYY HH:mm:ss"
              ).day();

              return (
                <main key={i}>
                  <div>
                    <FormattedMessage id={`Day${returned_day}`} />{" "}
                    {item.fecha_inicio}
                  </div>
                  <div>
                    <FormattedMessage id="Schedule1" /> {item.hora_inicio} -{" "}
                    {returned_endate}
                  </div>
                </main>
              );
            })}
          </section>
        </article>
        {props.finalBooking[1].length > 0 && (
          <article>
            <h4>
              <FormattedMessage id="BookingsNotMadeDue" />
            </h4>
            <section>
              {props.finalBooking[1].map((item, i) => {
                let returned_endate = moment(
                  `${item.fecha_inicio} ${item.hora_inicio}:00`,
                  "DD-MM-YYYY HH:mm:ss"
                )
                  .add(item.duracion, "minutes")
                  .format("HH:mm");

                let returned_day = moment(
                  `${item.fecha_inicio} ${item.hora_inicio}:00`,
                  "DD-MM-YYYY HH:mm:ss"
                ).day();

                return (
                  <main key={i}>
                    <div>
                      <FormattedMessage id={`Day${returned_day}`} />{" "}
                      {item.fecha_inicio}
                    </div>
                    <div>
                      <FormattedMessage id="Schedule1" /> {item.hora_inicio} -{" "}
                      {returned_endate}
                    </div>
                  </main>
                );
              })}
            </section>
          </article>
        )}
      </section>
      <div className={props.style.box__button__center}>
        <Button
          variant="main"
          className={props.myStyle[`btn_${props.cliente}`]}
          onClick={() => {
            history.push("/home/history");
          }}
        >
          <div className={props.style.box__button}>
            <span>
              <FormattedMessage id="Next" />
            </span>
            <span>
              <i className="far fa-arrow-alt-circle-right"></i>
            </span>
          </div>
        </Button>
      </div>
    </section>
  );
};

export default StepDone;
