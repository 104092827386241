import React, { useEffect, useState } from "react";

/* Compnents */
import Content from "../../components/checkin/Content";
import Footer from "../../components/footer";

/* Style */
import Style from "../../assets/styles/Login.module.scss";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
  if (
    window.location.protocol !== "https:" &&
    window.location.host === "app.kantoor.cl"
  ) {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }
}

const Checkin = (props) => {
  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  return (
    <>
      <Content
        cliente={cliente}
        myStyle={myStyle}
        style={Style}
        id={props.match.params.data}
      />
      <Footer />
    </>
  );
};

export default Checkin;
