import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

/* Img */
import Logo from "../../assets/images/logo.png";

const Welcome = (props) => {
  return (
    <section className={props.style.container}>
      {props.cliente === "" ? (
        <h1>
          <FormattedMessage id="WelcomeToKantoor" />
        </h1>
      ) : (
        <h1>
          <FormattedMessage id="WelcomeTo" /> {props.cliente} y Kantoor
        </h1>
      )}
      {props.cliente === "" ? (
        <div className={props.style.box__img}>
          <img src={Logo} alt="Logo kantor" />
        </div>
      ) : (
        <div
          className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
        >
          <div className={props.myStyle[`logo_${props.cliente}`]}></div>
          <div></div>
        </div>
      )}

      <div className={props.style.box__text}>
        <p>
          <FormattedMessage id="YourOwnSpaceYourOwnDeskWith" />{" "}
          {props.cliente === "" ? (
            <strong>Kantoor </strong>
          ) : (
            <strong>{props.cliente} y Kantoor </strong>
          )}
          <FormattedMessage id="reserveYourDesk" />
        </p>
      </div>
      <div>
        <Button
          variant="main"
          onClick={() => props.setLogin(true)}
          className={props.myStyle[`btn_${props.cliente}`]}
        >
          <div className={props.style.box__button}>
            <span>
              <FormattedMessage id="Start" />
            </span>
            <span>
              <i className="far fa-arrow-alt-circle-right"></i>
            </span>
          </div>
        </Button>
      </div>
    </section>
  );
};

export default Welcome;
