import React from "react";
import { FormattedMessage } from "react-intl";

const Step2 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>Recopilación de los Datos Personales y de la Información Personal</h2>
      <p>
        Para utilizar la Plataforma, los Usuarios deben completar un registro
        (en adelante el “Registro”) con Datos Personales tales como nombre
        completo, dirección de correo electrónico, número de teléfono, número de
        cédula nacional de identidad o rol único tributario (RUT), dirección
        particular y otros que le puedan ser solicitados y sean necesarios para
        el correcto uso de la Plataforma. Podría ocurrir que la plataforma no le
        solicite todos estos datos. Una vez completado el Registro, Kantoor
        otorgará al Usuario una cuenta personal para acceder a la Plataforma con
        la contraseña que dicho Usuario elija (en adelante la “Cuenta”).
      </p>
      <p>
        Los Usuarios asumen el compromiso de indicar en el Registro sus Datos
        Personales de manera exacta, precisa y verdadera, así como también la
        obligación de actualizar sus Datos Personales cada vez que ocurra una
        modificación de los mismos.
      </p>
      <p>
        Los Datos Personales introducidos por el Usuario en el Registro deberán
        ser exactos, actuales y veraces en todo momento. Kantoor se reserva el
        derecho a solicitar algún comprobante y/o dato adicional a efectos de
        corroborar los Datos Personales indicados en el Registro y de suspender,
        temporal y/o definitivamente, a aquel Usuario cuyos datos no hayan
        podido ser confirmados. No obstante lo anterior, Kantoor no se
        responsabiliza por la certeza de los datos consignados en el Registro,
        toda vez que los mismos son suministrados por el Usuario y son de su
        única y exclusiva responsabilidad. En este sentido el Usuario garantiza
        y responde, en cualquier caso, de la veracidad, exactitud, vigencia y
        autenticidad de sus Datos Personales.
      </p>
      <p>
        Los Datos Personales que el Usuario proporcione se integrarán en una
        base de datos personales de la que es responsable Kantoor. Asimismo, la
        Información Personal de los Usuarios será recopilada y utilizada en la
        forma descrita en estas Políticas de Privacidad. Asimismo, durante el
        uso de la Plataforma, los Usuarios proporcionarán a Kantoor información
        Personal relativa a sus intereses, gustos, contactos y otros, los que
        también serán registrados en la base de datos.
      </p>
      <p>
        El Usuario accederá a su Cuenta mediante el ingreso de su apodo de
        usuario y clave de seguridad personal elegida. El Usuario será el único
        responsable por el cuidado y buen uso de su Cuenta, así como de mantener
        su clave de acceso protegida. Si por cualquier razón un Usuario creyera
        que alguien puede conocer su clave de acceso, deberá modificarla
        ingresando a la opción de modificación dispuesta para tal fin en la
        Plataforma.
      </p>
      <p>
        El Usuario que se registre en Kantoor y mantenga una Cuenta, consiente
        expresamente que Kantoor tenga acceso, en cualquier momento, a la
        totalidad de la información contenida en su Cuenta, incluyendo, en
        particular pero sin limitación alguna, a sus Datos Personales, e
        Información Personal sobre sus intereses, gustos, contactos y/o
        cualquier otro contenido alojado en su Cuenta y Registro.
      </p>
    </section>
  );
};

export default Step2;
