import React from "react";

const Tramo = (props) => {
  return (
    <article>
      {props.data.map((item) => {
        return (
          <div key={item.id}>
            <span>{item.comienza}</span>
          </div>
        );
      })}
    </article>
  );
};

export default Tramo;
