export const RESET_LOGIN_VALIDATE = "RESET_LOGIN_VALIDATE";
export const GET_LOGIN_VALIDATE_INIT = "GET_LOGIN_VALIDATE_INIT";
export const GET_LOGIN_VALIDATE_SUCCESS = "GET_LOGIN_VALIDATE_SUCCESS";
export const GET_LOGIN_VALIDATE_FAILURE = "GET_LOGIN_VALIDATE_FAILURE";

export const RESET_RESERVA_VALIDATE = "RESET_RESERVA_VALIDATE";
export const GET_RESERVA_VALIDATE_INIT = "GET_RESERVA_VALIDATE_INIT";
export const GET_RESERVA_VALIDATE_SUCCESS = "GET_RESERVA_VALIDATE_SUCCESS";
export const GET_RESERVA_VALIDATE_FAILURE = "GET_RESERVA_VALIDATE_FAILURE";

export const RESET_CHECK_VALIDATE = "RESET_CHECK_VALIDATE";
export const GET_CHECK_VALIDATE_INIT = "GET_CHECK_VALIDATE_INIT";
export const GET_CHECK_VALIDATE_SUCCESS = "GET_CHECK_VALIDATE_SUCCESS";
export const GET_CHECK_VALIDATE_FAILURE = "GET_CHECK_VALIDATE_FAILURE";

export const LOGOUT_VALIDATE = "LOGOUT_VALIDATE";
