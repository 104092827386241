/* (ASYNC) */
export const RESET_HOME = "RESET_HOME";
export const GET_HOME_INIT = "GET_HOME_INIT";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_HOME_FAILURE = "GET_HOME_FAILURE";

export const RESET_CREDITO = "RESET_CREDITO";
export const GET_CREDITO_INIT = "GET_CREDITO_INIT";
export const GET_CREDITO_SUCCESS = "GET_CREDITO_SUCCESS";
export const GET_CREDITO_FAILURE = "GET_CREDITO_FAILURE";
