import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Config from "../../server/controllers/config";

import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/* Actions */
import {
  getHistory,
  resetHistory,
  getNiveles,
  resetNiveles,
} from "../../actions/history";

/* Components */
import Loading from "../common/Loading";
import ErrorComponent from "../common/Error";
import Expired from "../common/Expired";
import ModalCancel from "./ModalCancel";
import ModalStar from "./ModalStar";
import ModalSchedule from "./ModalSchedule";
import Mapa from "./Mapa";
import FilterSelect from "./FilterSelect";

const List = (props) => {
  const state = useSelector((state) => state.history);
  const dispatch = useDispatch();

  /* Use state para los modales */
  const [modalCancel, setModalCancel] = useState(false);
  const [modalStar, setModalStar] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [data, setData] = useState(false);

  const [search, setSearch] = useState([]);
  const [combinedFilter, setCombinedFilter] = useState([]);

  const [disponibility, setDisponibility] = useState("");

  const [form, setForm] = useState({
    fecha_inicio: new Date(),
    minuto_comienzo: "",
    minuto_final: "",
    hora_comienzo: "",
    hora_final: "",
    dias_comienzo: 1,
    dias_final: "",
    semana_comienzo: "",
    semana_final: "",
    duracion: "",
  });

  /* Use state para query calendan y tabs mapa y listado */
  const [query, setQuery] = useState({});
  const [list, setList] = useState(true);

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getHistory({ filtrar_evaluadas: props.evaluada }));
      dispatch(getNiveles({}));
    };
    callsAPI();
  }, [dispatch]);

  /* Reset  Data  */
  useEffect(() => {
    return () => {
      dispatch(resetHistory());
      dispatch(resetNiveles());
    };
  }, [dispatch]);

  /* Controla el modal anular */
  const handleSelect = (obj) => {
    setData(obj.id);
    setModalCancel(obj.state);
  };

  /* Controla el modal evaluacion */
  const handleSelectStar = (obj) => {
    setData(obj.id);
    setModalStar(obj.state);
  };

  /* Controla el modal calendario */
  const handleCalendar = (id, clave, item, reserva) => {
    setData(item);

    setQuery({
      edi_division_id: id,
      reserva_id: reserva,
      clave_granularidad: clave,
      fecha_inicio: moment().format("DD-MM-YYYY"),
      hora_inicio: "08:00",
      duracion: "1",
    });
    setModalSchedule(true);
  };

  /* Controla los cambios del modal calendario */
  const handleChange = (event) => {
    if (event.persist) {
      event.persist();
    }

    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  if (state.loadingHistory || state.loadingNiveles) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusHistory === 501 ||
    state.loadingNiveles === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusHistory === 200
  ) {
    if (!state.dataHistory.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  let filter = state.dataHistory.datos;
  if (props.search) {
    filter = state.dataHistory.datos.filter((o) => {
      return (
        o.edificacion.nombre.toLowerCase().indexOf(props.search.toLowerCase()) >
          -1 ||
        o.informacion_padre.nombre_padre
          .toLowerCase()
          .indexOf(props.search.toLowerCase()) > -1 ||
        o.edi_division_reservada.nombre
          .toLowerCase()
          .indexOf(props.search.toLowerCase()) > -1
      );
    });
  }

  const filteredData = () => {
    if (search.length === 0 && combinedFilter.length === 0) {
      return filter;
    }

    const filtered = filter.filter((o) => {
      const input = o.edi_division_reservada.nombre
        .toLowerCase()
        .includes(search);

      const estado = o.reserva.vencida
        ? "Anulada"
        : o.reserva.vigente
        ? "Vigente"
        : "Expirada";

      const selector =
        combinedFilter.length === 0 ||
        combinedFilter.some((selected) => selected.value === estado);

      return input && selector;
    });

    return filtered;
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Fin de filtro

  if (filter.length === 0) {
    return (
      <section className={`${props.style.container} ${props.style.center}`}>
        <h1>
          <FormattedMessage id={props.title} />
        </h1>
        <h2>
          <FormattedMessage id="ThereIsCurrentlyNoDataInTheHistory" />
        </h2>
      </section>
    );
  }

  const renderMessageBox = (vigente, vencida) => {
    if (!vigente) {
      return (
        <span className={`${props.style.box__vigente}`}>
          <h3>
            <FormattedMessage id="NonCurrentReservation" />{" "}
          </h3>
        </span>
      );
    } else if (vencida) {
      return (
        <span className={`${props.style.box__vigente}`}>
          <h3>
            <FormattedMessage id="ExpiredReservation" />
          </h3>
        </span>
      );
    }
  };

  const renderIconBox = (vigente, vencida, item) => {
    if (vigente && !vencida) {
      return (
        <OverlayTrigger overlay={<Tooltip>Anular reserva</Tooltip>}>
          <i
            className="fas fa-trash"
            onClick={() => {
              handleSelect({ id: item.reserva.id, state: true });
            }}
          />
        </OverlayTrigger>
      );
    }
  };

  return (
    <section className={props.style.container}>
      <h1>
        <FormattedMessage id="History" />
      </h1>
      {props.disabled && (
        <p>
          <FormattedMessage id="MeesageCredit" />
        </p>
      )}
      <div className={props.style.box__selbutton}>
        <ul>
          <li
            onClick={() => setList(true)}
            className={
              list
                ? `${props.myStyle[`list_${props.cliente}`]}`
                : `${props.style.box__selbutton__active} ${
                    props.myStyle[`list_active_${props.cliente}`]
                  }`
            }
          >
            <FormattedMessage id="List" />
          </li>
          <li
            onClick={() => setList(false)}
            className={
              !list
                ? `${props.myStyle[`list_${props.cliente}`]}`
                : `${props.style.box__selbutton__active} ${
                    props.myStyle[`list_active_${props.cliente}`]
                  }`
            }
          >
            <FormattedMessage id="Map" />
          </li>
        </ul>
      </div>

      {/* FILTRO */}
      <div className={props.style.box__selbutton}>
        <h3>
          <FormattedMessage id="Filter" />
        </h3>

        <div className={props.style.main__container__filter}>
          <div className={props.style.input__container}>
            <input
              type="text"
              placeholder="Buscar por nombre"
              value={search}
              onChange={onSearchChange}
              className={props.style.input__search}
            />

            <i className={`fas fa-search ${props.style.input__icon}`} />
          </div>

          <FilterSelect filter={filter} setCombinedFilter={setCombinedFilter} />
        </div>
      </div>

      <ModalCancel
        data={data}
        modal={modalCancel}
        setModal={setModalCancel}
        style={props.style}
        cliente={props.cliente}
        myStyle={props.myStyle}
      />
      <ModalStar
        data={data}
        modal={modalStar}
        setModal={setModalStar}
        style={props.style}
        niveles={state.dataNiveles}
        cliente={props.cliente}
        myStyle={props.myStyle}
      />
      <ModalSchedule
        query={query}
        style={props.reservationStyle}
        modal={modalSchedule}
        data={data}
        form={form}
        handleChange={handleChange}
        setQuery={setQuery}
        getHorariosValido={() => {}}
        setModal={setModalSchedule}
        cliente={props.cliente}
        myStyle={props.myStyle}
        disponibility={disponibility}
      />
      {list ? (
        <section className={`${props.style.box__img}`}>
          {filteredData().length > 0 ? (
            filteredData().map((item) => {
              const time1 = item.reserva.desde.split(" ");
              const time2 = item.reserva.hasta.split(" ");

              let img = false;

              if (item.edi_division_reservada.vista_general) {
                if (item.edi_division_reservada.vista_general.vista_general) {
                  img = true;
                }
              }

              return (
                <article key={item.reserva.id}>
                  {renderMessageBox(item.reserva.vigente, item.reserva.vencida)}

                  <div className={`${props.style.box__imgelement}`}>
                    {img ? (
                      <img
                        src={`${Config.api}${item.edi_division_reservada.vista_general.vista_general.archivo_imagen}`}
                      />
                    ) : (
                      <img
                        src={`${Config.api}${item.informacion_padre.vista_general.archivo_imagen}`}
                      />
                    )}
                  </div>
                  <div className={`${props.style.box__reserva}`}>
                    {!props.disabled && (
                      <OverlayTrigger
                        overlay={<Tooltip>Copiar reserva</Tooltip>}
                        placement="top"
                      >
                        <i
                          className="fas fa-plus-circle"
                          onClick={() => {
                            handleCalendar(
                              item.edi_division_reservada.id,
                              item.reserva.clave_granularidad,
                              item,
                              item.reserva.id
                            );
                            setDisponibility(true);
                          }}
                        />
                      </OverlayTrigger>
                    )}

                    {item.reserva.vigente && (
                      <OverlayTrigger
                        overlay={<Tooltip>Editar reserva</Tooltip>}
                        placement="top"
                      >
                        <i
                          className="fas fa-pencil-alt"
                          onClick={() => {
                            handleCalendar(
                              item.edi_division_reservada.id,
                              item.reserva.clave_granularidad,
                              item,
                              item.reserva.id
                            );
                            setDisponibility(false);
                          }}
                        />
                      </OverlayTrigger>
                    )}

                    <OverlayTrigger
                      overlay={<Tooltip>Agregar a favoritos</Tooltip>}
                      placement="top"
                    >
                      <i
                        className="fas fa-star"
                        onClick={() => {
                          handleSelectStar({
                            id: item.reserva.id,
                            state: true,
                          });
                        }}
                      />
                    </OverlayTrigger>

                    {renderIconBox(
                      item.reserva.vigente,
                      item.reserva.vencida,
                      item
                    )}
                  </div>
                  <h2>{item.edificacion.nombre}</h2>
                  <h3>{`${item.informacion_padre.nombre_padre} / ${item.edi_division_reservada.nombre}`}</h3>
                  <ul>
                    <li>
                      <i className="fas fa-user"></i>{" "}
                      <FormattedMessage id="Creater" />:{" "}
                      {`${item.gente.quien_hace_la_reserva.nombres} ${item.gente.quien_hace_la_reserva.apellido_paterno}`}
                    </li>
                    {item.gente.involucrados.length > 0 && (
                      <li>
                        <i className="fas fa-user-friends"></i>{" "}
                        <FormattedMessage id="Guests" />:{" "}
                        <div className={props.style.box__list}>
                          {item.gente.involucrados.map((item) => (
                            <span key={item.email}>{item.email}</span>
                          ))}
                        </div>
                      </li>
                    )}
                    <li>
                      <i className="far fa-calendar"></i>{" "}
                      <FormattedMessage id="Day" />: {time1[0]}
                    </li>
                    <li>
                      <i className="far fa-clock"></i>{" "}
                      {`${time1[1]} - ${time2[1]} hrs`}
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {item.direccion.forma_larga}
                    </li>
                  </ul>
                </article>
              );
            })
          ) : (
            <h2>
              <FormattedMessage id="ThereIsNoDataWithThisFilter" />
            </h2>
          )}
        </section>
      ) : (
        <Mapa data={state.dataHistory.datos} style={props.style} />
      )}
    </section>
  );
};

export default List;
