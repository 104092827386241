const initialState = {
  dataCompanyContrato: {},
  loadingCompanyContrato: true,
  statusCompanyContrato: 0,
  dataCompanySinContrato: {},
  loadingCompanySinContrato: true,
  statusCompanySinContrato: 0,
  dataLabels: {},
  loadingLabels: true,
  statusLabels: 0,
  dataDivision: {},
  loadingDivision: true,
  statusDivision: 0,
  dataSubDivision: {},
  loadingSubDivision: false,
  statusSubDivision: 0,
  dataSubSubDivision: {},
  loadingSubSubDivision: false,
  statusSubSubDivision: 0,
  dataBolsas: {},
  loadingBolsas: true,
  statusBolsas: 0,
  dataPreguntas: {},
  loadingPreguntas: true,
  statusPreguntas: 0,
  dataAlternativas: {},
  loadingAlternativas: true,
  statusAlternativas: 0,
  dataHorarios: {},
  loadingHorarios: true,
  statusHorarios: 0,
  dataHorariosValido: {},
  loadingHorariosValido: false,
  statusHorariosValido: 0,
  dataCalendario: {},
  loadingCalendario: true,
  statusCalendario: 0,
  dataMarco: {},
  loadingMarco: true,
  statusMarco: 0,
  dataEdificio: {},
  loadingEdificio: true,
  statusEdificio: 0,
  dataDeseable: {},
  loadingDeseable: true,
  statusDeseable: 0,
  dataNodo1: {},
  loadingNodo1: true,
  statusNodo1: 0,
  dataNodoHijo: {},
  loadingNodoHijo: false,
  statusNodoHijo: 0,
  dataNodoFinal: {},
  loadingNodoFinal: true,
  statusNodoFinal: 0,
  dataImagen: {},
  loadingImagen: true,
  statusImagen: 0,
  dataRecursos: {},
  loadingRecursos: false,
  statusRecursos: 0,
  dataReserva: {},
  loadingReserva: false,
  statusReserva: 0,
  dataReservaExterna: {},
  loadingReservaExterna: false,
  statusReservaExterna: 0,
  dataAcceptReservaExterna: {},
  loadingAcceptReservaExterna: false,
  statusAcceptReservaExterna: 0,
  dataReservaExternaList: {},
  loadingReservaExternaList: true,
  statusReservaExternaList: 0,
  dataReservaAllExternaList: {},
  loadingReservaAllExternaList: true,
  statusReservaAllExternaList: 0,
  dataOptionsReservation: {},
  loadingOptionsReservation: true,
  statusOptionsReservation: 0,

  // Data week
  dataWeek: {
    data0: {},
    data1: {},
    data2: {},
    data3: {},
    data4: {},
    data5: {},
    data6: {},
  },

  loadingWeek: {
    data0: true,
    data1: true,
    data2: true,
    data3: true,
    data4: true,
    data5: true,
    data6: true,
  },

  statusWeek: {
    data0: 0,
    data1: 0,
    data2: 0,
    data3: 0,
    data4: 0,
    data5: 0,
    data6: 0,
  },
};

export default initialState;
