import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import { FormattedMessage } from "react-intl";

/* Actions */
import {
  getReservaExternaList,
  resetReservaExternaList,
} from "../../actions/reservation";

/* Components */
import Loading from "../common/Loading";
import ErrorComponent from "../common/Error";
import Expired from "../common/Expired";

const List = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getReservaExternaList({}));
    };
    callsAPI();
  }, [dispatch]);

  /* Reset  Data  */
  useEffect(() => {
    return () => {
      dispatch(resetReservaExternaList());
    };
  }, [dispatch]);

  if (state.loadingReservaExternaList) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusReservaExternaList === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusReservaExternaList === 200
  ) {
    if (!state.dataReservaExternaList.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <section className={`${props.style.container__module}`} variant="dark">
      <h1>
        <FormattedMessage id="MyRequests" />
      </h1>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>#</th>
            <th>
              <FormattedMessage id="Description" />
            </th>
            <th>
              <FormattedMessage id="Request" />
            </th>
            <th>
              <FormattedMessage id="AnswerDate" />
            </th>
            <th>
              <FormattedMessage id="AssignedRoom" />
            </th>
          </tr>
        </thead>
        <tbody>
          {state.dataReservaExternaList.datos.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.descripcion}</td>
              <td>
                {item.feedback === null ? (
                  <FormattedMessage id="WaitingForAnswer" />
                ) : item.feedback === true ? (
                  <FormattedMessage id="RequestAccepted" />
                ) : (
                  <FormattedMessage id="RequestRejected" />
                )}
              </td>
              <td>{item.fecha_feedback ? item.fecha_feedback : "-"}</td>
              <td>{item.recurso_asginado ? item.recurso_asginado : "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
};

export default List;
