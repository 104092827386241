import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Config from "../../server/controllers/config";

import {
  getPreguntas,
  resetPreguntas,
  getAlternativas,
  resetAlternativas,
} from "../../actions/reservation";

/* Component */
import { Form } from "react-bootstrap";

/* Components */
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";
import Information from "./Information";
import Example from "../../assets/images/example.png";
import Modal from "./ModalGuests";

const leters = ["a", "b", "c", "d", "e", "f", "g", "h"];

const StepAttribute = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [question, setQuestion] = useState(props.form.question);

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getPreguntas({
          bolsa_id: props.form.bolsa_id,
        })
      );
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    if (state.statusPreguntas === 200) {
      if (state.dataPreguntas.ejecucion.estado) {
        /* clean attributes */
        const arr = [...props.form.atributos];
        const total = state.dataPreguntas.datos.length;
        if (arr[question]) {
          arr.splice(question, total);
        }

        dispatch(
          getAlternativas({
            ciudad: props.form.ciudad,
            provincia: props.form.provincia,
            comuna: props.form.comuna,
            empresa_id: props.form.empresa_id,
            atributos: arr,
            pregunta_id: state.dataPreguntas.datos[question].id,
          })
        );
      }
    }
  }, [state.statusPreguntas, question]);

  /* Reset  Data  */
  useEffect(() => {
    return () => {
      dispatch(resetPreguntas());
    };
  }, [dispatch]);

  const handleChange = (event) => {
    if (event.target.value !== "") {
      const arr = [...props.form.atributos];

      if (arr[question]) {
        arr[question] = event.target.value;
      } else {
        arr.push(event.target.value);
      }

      /* Add  attribute */
      props.handleChange({
        target: {
          name: event.target.name,
          value: arr,
        },
      });

      /* Set Question  -> do to work of step */
      props.handleChange({
        target: {
          name: "question",
          value: question,
        },
      });

      /* Add image */
      props.handleChangeImage(
        {
          id: event.target.value,
          img: event.target.img,
        },
        question
      );

      if (event.target.invitado) {
        setModal(true);
        props.handleChange({
          target: {
            name: "is_guests",
            value: question,
          },
        });
      } else {
        setQuestion(question + 1);

        if (props.form.is_guests === question) {
          props.handleChange({
            target: {
              name: "guests",
              value: "0",
            },
          });
        }
        if (question + 1 === state.dataPreguntas.datos.length) {
          props.nextStep();
          props.handleMemory({ name: "step6" });
          props.handleCount(1);
        }
      }

      /* Plus a question */
    } else {
      /* In case if the value is empty */
      const arrDelete = [...props.form.atributos];
      arrDelete.splice(question, 1);

      /* Add  attribute */
      props.handleChange({
        target: {
          name: event.target.name,
          value: arrDelete,
        },
      });

      /* Add image */
      props.handleChangeImage(false, question);
    }
  };

  const backStep = () => {
    //props.deleteAtribute(question);
    //props.deleteImage(question);
    setQuestion(question - 1);
    if (question - 1 < 0) {
      props.backStep();
      props.handleChange({
        target: {
          name: "question",
          value: 0,
        },
      });
      props.handleCount(-1);
    }
  };

  const nextStep = () => {
    setQuestion(question + 1);
    if (question + 1 === state.dataPreguntas.datos.length) {
      props.nextStep();
      props.handleChange({
        target: {
          name: "question",
          value: question,
        },
      });
    }
  };

  if (state.loadingPreguntas || state.loadingAlternativas) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusPreguntas === 501 ||
    state.statusAlternativas === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusPreguntas === 200
  ) {
    if (!state.dataPreguntas.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }
  const type = state.dataPreguntas.datos[question].tipo_despliegue.nombre;
  const glosa = state.dataPreguntas.datos[question].glosa;

  return (
    <>
      <section className={props.style.container__content}>
        <Information
          cliente={props.cliente}
          myStyle={props.myStyle}
          data={props.form.imagenes}
          style={props.style}
        />
        <h2 className={props.style.start}>{glosa}</h2>
        <p>
          <FormattedMessage id="SelectYourPreference" />.
        </p>
        <div
          className={`${props.style.box__footer} ${props.style.box__footer_alcenter}`}
        >
          {type === "combo_box" && (
            <div className={props.style.box__footer__select}>
              <Form.Label>{glosa}</Form.Label>
              <Form.Control
                as="select"
                className={props.myStyle[`select_${props.cliente}`]}
                value={props.form.atributos[question]}
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: event.target.name,
                      value: event.target.value,
                      invitado:
                        event.target.selectedOptions[0].getAttribute(
                          "data-invitados"
                        ) === "true"
                          ? true
                          : false,
                      img: event.target.selectedOptions[0].getAttribute(
                        "data-route"
                      ),
                    },
                  });
                }}
                name="atributos"
                data-number="ok"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                {state.dataAlternativas.datos.map((item) => {
                  let route = false;
                  if (item.icono.icono) {
                    route = `${Config.api}${item.icono.icono.archivo_icono}`;
                  }
                  return (
                    <option
                      data-invitados={item.pide_invitados}
                      data-route={route}
                      key={item.id}
                      value={item.id}
                    >
                      {item.glosa}
                    </option>
                  );
                })}
              </Form.Control>
            </div>
          )}
          {type === "imagenes" && (
            <div className={props.style.box__footer__icon}>
              <ul>
                {state.dataAlternativas.datos.map((item) => {
                  let img = <img src={Example} alt="example" />;
                  let route = false;
                  if (item.icono.icono) {
                    img = (
                      <img
                        src={`${Config.api}${item.icono.icono.archivo_icono}`}
                      />
                    );
                    route = `${Config.api}${item.icono.icono.archivo_icono}`;
                  }
                  return (
                    <li
                      key={item.id}
                      title={item.glosa}
                      name="atributos"
                      className={props.myStyle[`icon_select_${props.cliente}`]}
                      onClick={(event) => {
                        handleChange({
                          target: {
                            name: "atributos",
                            value: item.id,
                            img: route,
                            invitado: item.pide_invitados,
                          },
                        });
                      }}
                    >
                      <div>{img}</div>
                      <span>{item.glosa}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </section>
      <div className={props.style.box__arrow}>
        <span
          onClick={() => {
            backStep();
          }}
        >
          <i className="far fa-arrow-alt-circle-left"></i>
        </span>
        {props.form.atributos.length > question && (
          <span
            onClick={() => {
              nextStep();
            }}
          >
            <i className="far fa-arrow-alt-circle-right"></i>
          </span>
        )}
      </div>
      <Modal
        nextStep={props.nextStep}
        handleMemory={props.handleMemory}
        handleCount={props.handleCount}
        lengthData={state.dataPreguntas.datos.length}
        question={question}
        setQuestion={setQuestion}
        form={props.form}
        handleChange={props.handleChange}
        style={props.style}
        modal={modal}
        setModal={setModal}
        cliente={props.cliente}
        myStyle={props.myStyle}
      />
    </>
  );
};

export default StepAttribute;
