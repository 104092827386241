export const RESET_LOGIN = "RESET_LOGIN";
export const GET_LOGIN_INIT = "GET_LOGIN_INIT";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";

export const RESET_FORGET_PASS = "RESET_FORGET_PASS";
export const GET_FORGET_PASS_INIT = "GET_FORGET_PASS_INIT";
export const GET_FORGET_PASS_SUCCESS = "GET_FORGET_PASS_SUCCESS";
export const GET_FORGET_PASS_FAILURE = "GET_FORGET_PASS_FAILURE";

export const RESET_CHANGE_PASS = "RESET_CHANGE_PASS";
export const GET_CHANGE_PASS_INIT = "GET_CHANGE_PASS_INIT";
export const GET_CHANGE_PASS_SUCCESS = "GET_CHANGE_PASS_SUCCESS";
export const GET_CHANGE_PASS_FAILURE = "GET_CHANGE_PASS_FAILURE";

export const RESET_LANGUAGES = "RESET_LANGUAGES";
export const GET_LANGUAGES_INIT = "GET_LANGUAGES_INIT";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAILURE = "GET_LANGUAGES_FAILURE";

export const RESET_CREATE_USER = "RESET_CREATE_USER";
export const GET_CREATE_USER_INIT = "GET_CREATE_USER_INIT";
export const GET_CREATE_USER_SUCCESS = "GET_CREATE_USER_SUCCESS";
export const GET_CREATE_USER_FAILURE = "GET_CREATE_USER_FAILURE";

export const RESET_CREATE_USER_CODE = "RESET_CREATE_USER_CODE";
export const GET_CREATE_USER_CODE_INIT = "GET_CREATE_USER_CODE_INIT";
export const GET_CREATE_USER_CODE_SUCCESS = "GET_CREATE_USER_CODE_SUCCESS";
export const GET_CREATE_USER_CODE_FAILURE = "GET_CREATE_USER_CODE_FAILURE";

export const RESET_AREA = "RESET_AREA";
export const GET_AREA_INIT = "GET_AREA_INIT";
export const GET_AREA_SUCCESS = "GET_AREA_SUCCESS";
export const GET_AREA_FAILURE = "GET_AREA_FAILURE";

export const RESET_CREATE_USER_COMPANY = "RESET_CREATE_USER_COMPANY";
export const POST_CREATE_USER_COMPANY_INIT = "POST_CREATE_USER_COMPANY_INIT";
export const POST_CREATE_USER_COMPANY_SUCCESS =
  "POST_CREATE_USER_COMPANY_SUCCESS";
export const POST_CREATE_USER_COMPANY_FAILURE =
  "POST_CREATE_USER_COMPANY_FAILURE";
