const initialState = {
  dataHistory: {},
  loadingHistory: true,
  statusHistory: 0,
  dataAnular: {},
  loadingAnular: false,
  statusAnular: 0,
  dataNiveles: {},
  loadingNiveles: true,
  statusNiveles: 0,
  dataNota: {},
  loadingNota: false,
  statusNota: 0,
  dataCalendarTwo: {},
  loadingCalendarTwo: true,
  statusCalendarTwo: 0,
};

export default initialState;
