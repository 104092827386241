import React, { useState, useEffect } from "react";

/* Compnents */
import Form from "../../components/login/Form";
import Welcome from "../../components/login/Welcome";
import Footer from "../../components/footer";

/* Style */
import myStyle from "../../components/style_client/cliente.module.scss";
import Style from "../../assets/styles/Login.module.scss";

import ConfigCliente from "../../components/style_client/cliente.js";
import companies from "../config";
import validateCompanies from "../config/functions";

/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
  if (
    window.location.protocol !== "https:" &&
    window.location.host === "app.kantoor.cl"
  ) {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }
}

const Login = (props) => {
  const [login, setLogin] = useState(true);
  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  return (
    <>
      {login ? (
        <Form
          validateCompanies={validateCompanies}
          companies={companies}
          company={props.match.params.id}
          cliente={cliente}
          myStyle={myStyle}
          style={Style}
        />
      ) : (
        <Welcome
          cliente={cliente}
          myStyle={myStyle}
          setLogin={setLogin}
          style={Style}
        />
      )}
      <Footer />
    </>
  );
};

export default Login;
