import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

const headerDay = (props) => {
  const day = useMemo(() => {
    let arr = props.fecha_inicio.split("-");
    return parseInt(arr[0]);
  }, [props.fecha_inicio]);

  return (
    <section
      className={`${props.style.container__schedule__header} ${
        props.myStyle[`header_agenda_${props.cliente}`]
      } ${
        props.showType === "show_day"
          ? props.style.container__schedule__header_day
          : ""
      }`}
    >
      <article></article>

      {props.data.map((item, i) => {
        let button;
        if (i === 0) {
          button = (
            <button onClick={props.back} className={props.style.btn__left}>
              <i className="fas fa-arrow-circle-left"></i>
            </button>
          );
        } else if (i + 1 === props.data.length) {
          button = (
            <button onClick={props.next} className={props.style.btn__right}>
              <i className="fas fa-arrow-circle-right"></i>
            </button>
          );
        }

        if (item.numero_dia !== day && props.showType === "show_day") {
          return null;
        }

        return (
          <div key={item.id}>
            {button}
            {props.showType === "show_day" && (
              <>
                <button onClick={props.back} className={props.style.btn__left}>
                  <i className="fas fa-arrow-circle-left"></i>
                </button>
                <button onClick={props.next} className={props.style.btn__right}>
                  <i className="fas fa-arrow-circle-right"></i>
                </button>
              </>
            )}

            <h3>{item.label_corto}</h3>
            <h4>{item.numero_dia}</h4>
          </div>
        );
      })}
    </section>
  );
};

export default headerDay;
