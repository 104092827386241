import React from "react";

/* Components */
import Content from "../../components/terms/Content";

/* Style */
import Style from "../../assets/styles/Terms.module.scss";

const About = (props) => {
  return (
    <>
      <Content style={Style} />
    </>
  );
};

export default About;
