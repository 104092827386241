import React from "react";
import { FormattedMessage } from "react-intl";
import Img from "../../assets/images/loading.gif";

const Loading = (props) => {
  return (
    <section className={props.style.container__content}>
      <div className={`${props.style.box__footer}`}>
        <div className={`${props.style.box__loading}`}>
          <img src={Img} alt="Logo" />
        </div>
      </div>
    </section>
  );
};

export default Loading;
