import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

/* Redux */
import { useSelector, useDispatch } from "react-redux";

/* Components */
import Menu from "../../components/home/Menu";
import MenuLeft from "../../components/home/MenuLeft";
import Footer from "../../components/footer";

import Expired from "../../components/common/Expired";

/* Components routers */
import Reservation from "../reservation";
import CopyReservation from "../copy_reservation";
import HistoryComponent from "../history";
import PreferenceComponent from "../preference";
import ProfileComponent from "../profile";
import AboutComponent from "../about";
import TermsComponent from "../terms";
import Request from "../request";
import AllRequest from "../all_request";

/* Actions */
import { resetLogin } from "../../actions/login";

/* Style */
import Style from "../../assets/styles/Home.module.scss";

/*  Language */
import { IntlProvider } from "react-intl";

import messages_es from "../../translate/es";
import messages_en from "../../translate/en";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

let cliente = "";
if (currentLocation.host === ConfigCliente.factotal.url) {
  cliente = ConfigCliente.factotal.name;
}

const messages = {
  es: messages_es,
  en: messages_en,
};

const Home = (props) => {
  /* Login validation */
  const dispatch = useDispatch();
  const state = useSelector((state) => state.login);
  const homeState = useSelector((state) => state.home);
  const reservation = useSelector((state) => state.reservation);

  const [deseable, setDeseable] = useState([]);
  const [count, setCount] = useState([]);
  const [language, setLanguage] = useState(
    typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : "es"
  );
  const [cliente, setCliente] = useState("");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  useEffect(() => {
    let auth = localStorage.getItem("auth");
    auth = auth === "true";
    if (!auth) {
      props.history.push("/");
      dispatch(resetLogin());
    }
  });

  useEffect(() => {
    if (
      localStorage.getItem("language") === "es" ||
      localStorage.getItem("language") === "en"
    ) {
      setLanguage(localStorage.getItem("language"));
    } else if (language !== "es" && language !== "en") {
      setLanguage("es");
    }
  }, [state.loadingLanguages]);

  if (
    /* When the token is expired*/
    homeState.statusCredito === 501 ||
    homeState.statusCredito === 401
  ) {
    return <Expired />;
  }

  return (
    <IntlProvider locale="en" messages={messages[language]}>
      <section>
        <Menu
          homeState={homeState}
          cliente={cliente}
          myStyle={myStyle}
          setCount={setCount}
          setDeseable={setDeseable}
          style={Style}
        />

        <>
          <section className={Style.container__main}>
            <MenuLeft cliente={cliente} myStyle={myStyle} style={Style} />
            <Route exact path={`${props.match.path}/`}>
              <Reservation countDeseable={count} deseable={deseable} />
            </Route>
            <Route
              path={`${props.match.path}/history`}
              component={HistoryComponent}
            />
            <Route
              path={`${props.match.path}/preference`}
              component={PreferenceComponent}
            />
            <Route
              path={`${props.match.path}/profile`}
              component={ProfileComponent}
            />
            <Route
              path={`${props.match.path}/about`}
              component={AboutComponent}
            />
            <Route
              path={`${props.match.path}/terms`}
              component={TermsComponent}
            />
            <Route path={`${props.match.path}/requests`} component={Request} />
            <Route
              path={`${props.match.path}/all_requests`}
              component={AllRequest}
            />
            <Route
              path={`${props.match.path}/copy_reservation/:id`}
              component={CopyReservation}
            />
          </section>
        </>
      </section>
      <Footer />
    </IntlProvider>
  );
};

export default Home;
