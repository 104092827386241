import React from "react";
import { FormattedMessage } from "react-intl";

const Step8 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>Menores de Edad</h2>
      <p>
        Existen ciertos servicios en la Plataforma, los cuales solo pueden ser
        solicitados por mayores de 18 años. FIFO se reserva el derecho de
        verificar, por los medios que considere más oportunos, la edad real de
        cualquier Usuario.
      </p>
      <p>
        Bajo sospecha o constatación de que un Usuario sea menor de 18 años y de
        que ha falseado los datos que se requieren para utilizar los servicios
        que requieran de mayoría de edad, FIFO podrá denegar dicho servicio, sin
        derecho a reclamo alguno por parte del Usuario afectado.
      </p>
    </section>
  );
};

export default Step8;
