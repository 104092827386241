import React, { useState, useEffect } from "react";
import Select from "react-select";

const FilterSelect = ({ filter, setCombinedFilter }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [initialData, setInitialData] = useState([]);

  const options = [
    { value: "Vigente", label: "Vigente" },
    { value: "Expirada", label: "Expirada" },
    { value: "Anulada", label: "Anulada" },
  ];

  useEffect(() => {
    const arr = [];

    filter.map((item) => {
      const estado = item.reserva.vencida
        ? "Anulada"
        : item.reserva.vigente
        ? "Vigente"
        : "Expirada";

      arr.push({ id: item.reserva.id, estado: estado });
    });
    setInitialData(arr);
  }, [filter]);

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setCombinedFilter(selectedValues);
  };

  return (
    <Select
      placeholder="Filtrar por estado"
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleSelectChange}
      menuPlacement="top"
    />
  );
};

export default FilterSelect;
