import initialState from "./initialState";
import {
  GET_LOGIN_INIT,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  RESET_LOGIN,
  GET_FORGET_PASS_INIT,
  GET_FORGET_PASS_SUCCESS,
  GET_FORGET_PASS_FAILURE,
  RESET_FORGET_PASS,
  GET_CHANGE_PASS_INIT,
  GET_CHANGE_PASS_SUCCESS,
  GET_CHANGE_PASS_FAILURE,
  RESET_CHANGE_PASS,
  GET_LANGUAGES_INIT,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILURE,
  RESET_LANGUAGES,
  GET_CREATE_USER_INIT,
  GET_CREATE_USER_SUCCESS,
  GET_CREATE_USER_FAILURE,
  RESET_CREATE_USER,
  GET_CREATE_USER_CODE_INIT,
  GET_CREATE_USER_CODE_SUCCESS,
  GET_CREATE_USER_CODE_FAILURE,
  RESET_CREATE_USER_CODE,
  GET_AREA_INIT,
  GET_AREA_SUCCESS,
  GET_AREA_FAILURE,
  RESET_AREA,
  POST_CREATE_USER_COMPANY_INIT,
  POST_CREATE_USER_COMPANY_SUCCESS,
  POST_CREATE_USER_COMPANY_FAILURE,
  RESET_CREATE_USER_COMPANY,
} from "../../actions/login/types";

export default function login(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_LOGIN:
      localStorage.clear();
      return {
        ...state,
        data: false,
        loading: false,
        status: 0,
      };
    case GET_LOGIN_INIT:
      return {
        ...state,
        data: false,
        loading: true,
        status: 0,
      };
    case GET_LOGIN_SUCCESS:
      const auth = action.payload.status === 200 ? true : false;
      if (auth) {
        localStorage.setItem("auth", auth);
        if (action.info.facebook.uid !== "") {
          localStorage.setItem("type", "facebook");
          localStorage.setItem("names", action.info.facebook.name);
          localStorage.setItem("img", action.info.facebook.img);
          localStorage.setItem("email", action.payload.data.usuario.email);
          localStorage.setItem(
            "permite_reserva_para_otro",
            action.payload.data.usuario.permite_reserva_para_otro
          );

          localStorage.setItem(
            "login_company",
            action.info.loginCompany ? action.info.loginCompany : "false"
          );
          localStorage.setItem(
            "external_booking",
            action.payload.data.usuario.reserva_por_fuera
          );
          localStorage.setItem(
            "roles",
            JSON.stringify(action.payload.data.roles)
          );
          localStorage.setItem(
            "resource_name",
            action.payload.data.usuario.nombre_recurso
          );
          localStorage.setItem(
            "language",
            action.payload.data.usuario.lenguaje
          );
        } else if (action.info.office365.uid !== "") {
          localStorage.setItem("type", "microsoft");
          if (action.payload.data.persona) {
            localStorage.setItem(
              "names",
              `${action.payload.data.persona.nombres} ${action.payload.data.persona.apellido_paterno}`
            );
          } else {
            localStorage.setItem("names", action.payload.data.usuario.email);
          }
          localStorage.setItem(
            "img",
            "https://www.nicepng.com/png/detail/621-6215336_rocket-launch-spd-out-of-this-world-clip.png"
          );
          localStorage.setItem(
            "login_company",
            action.info.loginCompany ? action.info.loginCompany : "false"
          );
          localStorage.setItem("email", action.payload.data.usuario.email);
          localStorage.setItem(
            "permite_reserva_para_otro",
            action.payload.data.usuario.permite_reserva_para_otro
          );
          localStorage.setItem(
            "language",
            action.payload.data.usuario.lenguaje
          );
          localStorage.setItem(
            "external_booking",
            action.payload.data.usuario.reserva_por_fuera
          );
          localStorage.setItem(
            "resource_name",
            action.payload.data.usuario.nombre_recurso
          );
          localStorage.setItem(
            "roles",
            JSON.stringify(action.payload.data.roles)
          );
          localStorage.setItem(
            "logo",
            action.payload.data.avatar1.avatar1.archivo_imagen
          );
        } else if (action.info.google.uid !== "") {
          localStorage.setItem("type", "google");
          if (action.payload.data.persona) {
            localStorage.setItem(
              "names",
              `${action.payload.data.persona.nombres} ${action.payload.data.persona.apellido_paterno}`
            );
          } else {
            localStorage.setItem("names", action.payload.data.usuario.email);
          }
          localStorage.setItem(
            "img",
            "https://www.nicepng.com/png/detail/621-6215336_rocket-launch-spd-out-of-this-world-clip.png"
          );
          localStorage.setItem(
            "login_company",
            action.info.loginCompany ? action.info.loginCompany : "false"
          );
          localStorage.setItem("email", action.payload.data.usuario.email);
          localStorage.setItem(
            "permite_reserva_para_otro",
            action.payload.data.usuario.permite_reserva_para_otro
          );
          localStorage.setItem(
            "external_booking",
            action.payload.data.usuario.reserva_por_fuera
          );
          localStorage.setItem(
            "resource_name",
            action.payload.data.usuario.nombre_recurso
          );
          localStorage.setItem(
            "roles",
            JSON.stringify(action.payload.data.roles)
          );

          localStorage.setItem(
            "language",
            action.payload.data.usuario.lenguaje
          );

          localStorage.setItem(
            "logo",
            action.payload.data.avatar1.avatar1.archivo_imagen
          );
        } else {
          localStorage.setItem("type", "normal");
          localStorage.setItem(
            "img",
            "https://www.nicepng.com/png/detail/621-6215336_rocket-launch-spd-out-of-this-world-clip.png"
          );
          if (action.payload.data.persona) {
            localStorage.setItem(
              "names",
              `${action.payload.data.persona.nombres} ${action.payload.data.persona.apellido_paterno}`
            );
          } else {
            localStorage.setItem("names", action.payload.data.usuario.email);
          }
          localStorage.setItem(
            "language",
            action.payload.data.usuario.lenguaje
          );
          localStorage.setItem(
            "login_company",
            action.info.loginCompany ? action.info.loginCompany : "false"
          );
          localStorage.setItem("email", action.payload.data.usuario.email);
          localStorage.setItem(
            "permite_reserva_para_otro",
            action.payload.data.usuario.permite_reserva_para_otro
          );
          localStorage.setItem(
            "external_booking",
            action.payload.data.usuario.reserva_por_fuera
          );
          localStorage.setItem(
            "resource_name",
            action.payload.data.usuario.nombre_recurso
          );
          localStorage.setItem(
            "roles",
            JSON.stringify(action.payload.data.roles)
          );
          localStorage.setItem(
            "logo",
            action.payload.data.avatar1.avatar1.archivo_imagen
          );
        }
      }

      return {
        ...state,
        data: auth,
        loading: false,
        status: action.payload.status,
      };
    case GET_LOGIN_FAILURE:
      return {
        ...state,
        data: true,
        loading: false,
        status: 501,
      };
    case RESET_FORGET_PASS:
      return {
        ...state,
        dataForgetPass: false,
        loadingForgetPass: false,
        statusForgetPass: 0,
      };
    case GET_FORGET_PASS_INIT:
      return {
        ...state,
        loadingForgetPass: true,
      };
    case GET_FORGET_PASS_SUCCESS:
      return {
        ...state,
        dataForgetPass: action.payload.data,
        loadingForgetPass: false,
        statusForgetPass: action.payload.status,
      };
    case GET_FORGET_PASS_FAILURE:
      return {
        ...state,
        dataForgetPass: true,
        loadingForgetPass: false,
        statusForgetPass: 501,
      };
    case RESET_CHANGE_PASS:
      return {
        ...state,
        dataChangePass: false,
        loadingChangePass: false,
        statusChangePass: 0,
      };
    case GET_CHANGE_PASS_INIT:
      return {
        ...state,
        loadingChangePass: true,
      };
    case GET_CHANGE_PASS_SUCCESS:
      return {
        ...state,
        dataChangePass: action.payload.data,
        loadingChangePass: false,
        statusChangePass: action.payload.status,
      };
    case GET_CHANGE_PASS_FAILURE:
      return {
        ...state,
        dataChangePass: true,
        loadingChangePass: false,
        statusChangePass: 501,
      };
    case RESET_LANGUAGES:
      return {
        ...state,
        dataLanguages: false,
        loadingLanguages: false,
        statusLanguages: 0,
      };
    case GET_LANGUAGES_INIT:
      return {
        ...state,
        loadingLanguages: true,
      };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        dataLanguages: action.payload.data,
        loadingLanguages: false,
        statusLanguages: action.payload.status,
      };
    case GET_LANGUAGES_FAILURE:
      return {
        ...state,
        dataLanguages: true,
        loadingLanguages: false,
        statusLanguages: 501,
      };
    case RESET_CREATE_USER:
      return {
        ...state,
        dataCreateUser: false,
        loadingCreateUser: false,
        statusCreateUser: 0,
      };
    case GET_CREATE_USER_INIT:
      return {
        ...state,
        loadingCreateUser: true,
      };
    case GET_CREATE_USER_SUCCESS:
      return {
        ...state,
        dataCreateUser: action.payload.data,
        loadingCreateUser: false,
        statusCreateUser: action.payload.status,
      };
    case GET_CREATE_USER_FAILURE:
      return {
        ...state,
        dataCreateUser: true,
        loadingCreateUser: false,
        statusCreateUser: 501,
      };
    //code
    case RESET_CREATE_USER_CODE:
      return {
        ...state,
        dataCreateUserCode: false,
        loadingCreateUserCode: false,
        statusCreateUserCode: 0,
      };
    case GET_CREATE_USER_CODE_INIT:
      return {
        ...state,
        loadingCreateUserCode: true,
      };
    case GET_CREATE_USER_CODE_SUCCESS:
      return {
        ...state,
        dataCreateUserCode: action.payload.data,
        loadingCreateUserCode: false,
        statusCreateUserCode: action.payload.status,
      };
    case GET_CREATE_USER_CODE_FAILURE:
      return {
        ...state,
        dataCreateUserCode: true,
        loadingCreateUserCode: false,
        statusCreateUserCode: 501,
      };
    //Area
    case RESET_AREA:
      return {
        ...state,
        dataArea: false,
        loadingArea: true,
        statusArea: 0,
      };
    case GET_AREA_INIT:
      return {
        ...state,
        loadingArea: true,
      };
    case GET_AREA_SUCCESS:
      return {
        ...state,
        dataArea: action.payload.data,
        loadingArea: false,
        statusArea: action.payload.status,
      };
    case GET_AREA_FAILURE:
      return {
        ...state,
        dataArea: true,
        loadingArea: false,
        statusArea: 501,
      };
    //create user company
    case RESET_CREATE_USER_COMPANY:
      return {
        ...state,
        dataCreateUserCompany: false,
        loadingCreateUserCompany: false,
        statusCreateUserCompany: 0,
      };
    case POST_CREATE_USER_COMPANY_INIT:
      return {
        ...state,
        loadingCreateUserCompany: true,
      };
    case POST_CREATE_USER_COMPANY_SUCCESS:
      return {
        ...state,
        dataCreateUserCompany: action.payload.data,
        loadingCreateUserCompany: false,
        statusCreateUserCompany: action.payload.status,
      };
    case POST_CREATE_USER_COMPANY_FAILURE:
      return {
        ...state,
        dataCreateUserCompany: true,
        loadingCreateUserCompany: false,
        statusCreateUserCompany: 501,
      };
    default:
      return state;
  }
}
