import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

/* Actions  */
import { getChangePass, resetChangePass } from "../../actions/login";

const ModalPassword = (props) => {
  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    email: "",
    password_antigua: "",
    password_nueva: "",
    repeat_nueva: "",
  });
  const [ok, setOk] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (state.statusChangePass !== 0) {
      if (state.statusChangePass === 200) {
        setOk(true);
        setError(false);
      } else {
        setError("ThereWasAnError");
      }
    }
  }, [state.loadingChangePass]);

  /* Reset forget pass*/
  useEffect(() => {
    return () => {
      dispatch(resetChangePass());
      setOk(false);
    };
  }, [dispatch]);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      email: props.email,
    }));
  };

  const handleSubmit = () => {
    if (
      form.email === "" ||
      form.password_antigua === "" ||
      form.password_nueva === ""
    ) {
      setError("CompleteTheRequiredFields");
    } else if (form.repeat_nueva !== form.password_nueva) {
      setError("PasswordsDoNotMatch");
    } else {
      dispatch(getChangePass(form));
    }
  };

  return (
    <>
      <Modal
        onHide={() => {
          props.setModal(false);
          dispatch(resetChangePass());
          setOk(false);
        }}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="Password" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          {!ok ? (
            <Modal.Body>
              <div className={props.style.box__input}>
                <label className={props.style.box__input__label}>
                  Contraseña
                </label>
                <FormattedMessage id="WriteYourPass">
                  {(msg) => (
                    <Form.Control
                      value={form.password_antigua}
                      onChange={(e) => handleChange(e)}
                      name={"password_antigua"}
                      type="password"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className={props.style.box__input}>
                <label className={props.style.box__input__label}>
                  Nueva Contraseña
                </label>
                <FormattedMessage id="WriteYourNewPass">
                  {(msg) => (
                    <Form.Control
                      value={form.password_nueva}
                      onChange={(e) => handleChange(e)}
                      name={"password_nueva"}
                      type="password"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className={props.style.box__input}>
                <label className={props.style.box__input__label}>
                  Confirma contraseña
                </label>
                <FormattedMessage id="WriteYourPass">
                  {(msg) => (
                    <Form.Control
                      value={form.repeat_nueva}
                      onChange={(e) => handleChange(e)}
                      name={"repeat_nueva"}
                      type="password"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <p>
                <FormattedMessage id="PasswordUpdatedSuccessfully" />
              </p>
            </Modal.Body>
          )}
          <Modal.Footer>
            {error && (
              <span className={props.style.error}>
                <p className={props.style.center}>
                  <FormattedMessage id={error} />
                </p>
              </span>
            )}
            {!ok ? (
              <Button
                variant="main"
                className={props.myStyle[`btn_${props.cliente}`]}
                disabled={state.loadingChangePass}
                type="button"
                onClick={handleSubmit}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    {state.loadingChangePass ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Ok" />
                    )}
                  </span>
                  <span>
                    <i className="fas fa-key"></i>
                  </span>
                </div>
              </Button>
            ) : (
              <Button
                variant="main"
                disabled={state.loadingChangePass}
                className={props.myStyle[`btn_${props.cliente}`]}
                type="button"
                onClick={() => {
                  props.setModal(false);
                  dispatch(resetChangePass());
                  setOk(false);
                }}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    <FormattedMessage id="Ok" />
                  </span>
                  <span>
                    <i className="fas fa-smile"></i>
                  </span>
                </div>
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalPassword;
