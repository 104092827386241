const companies = {
  "hive-cowork": {
    id: 113,
    createAccount: true,
    recover: true,
    microsoftLogin: true,
    googleLogin: true,
    textLoginWith: true,
    option1: true,
    option2: true,
    option3: true,
    option4: true,
    option5: true,
  },
  "co-crea-lab": {
    id: 109,
    createAccount: true,
    recover: true,
    microsoftLogin: true,
    googleLogin: true,
    textLoginWith: true,
    option1: true,
    option2: true,
    option3: true,
    option4: true,
    option5: true,
  },
  "tna-group": {
    id: 115,
    createAccount: true,
    recover: true,
    microsoftLogin: true,
    googleLogin: true,
    textLoginWith: true,
    option1: true,
    option2: true,
    option3: true,
    option4: true,
    option5: true,
  },
  progreso: {
    id: 114,
    createAccount: false,
    recover: true,
    microsoftLogin: false,
    googleLogin: false,
    textLoginWith: false,
    option1: true,
    option2: false,
    option3: true,
    option4: false,
    option5: false,
  },
};

export default companies;
