import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { getAnular, getHistory, resetAnular } from "../../actions/history";

const ModalCancel = (props) => {
  const [error, setError] = useState(false);
  const state = useSelector((state) => state.history);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.statusAnular === 200) {
      if (state.dataAnular.ejecucion.estado) {
        props.setModal(false);
        dispatch(getHistory({}));
        dispatch(resetAnular());
        setError(false);
      } else {
        if (state.dataAnular.ejecucion.codigo_error === 500) {
          setError("Error500");
        } else {
          setError("ThereWasAnError");
        }
      }
    }
  }, [state.loadingAnular]);

  const handleAnular = () => {
    dispatch(getAnular({ reserva_id: props.data }));
  };

  return (
    <>
      <Modal
        onHide={() => {
          setError(false);
          dispatch(resetAnular());
          props.setModal(false);
        }}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="Reservation" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    setError(false);
                    dispatch(resetAnular());
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="AreYouSureToCancelTheReservation" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            {error && (
              <div className={props.style.box__error}>
                <p>
                  <FormattedMessage id={error} />
                </p>
              </div>
            )}
            <Button
              variant="main1"
              className={props.myStyle[`btn_cancel_${props.cliente}`]}
              disabled={false}
              onClick={() => {
                props.setModal(false);
                setError(false);
                dispatch(resetAnular());
              }}
            >
              {!error ? (
                <FormattedMessage id="Cancel" />
              ) : (
                <FormattedMessage id="Close" />
              )}
            </Button>
            {!error && (
              <Button
                className={props.myStyle[`btn_${props.cliente}`]}
                variant="main"
                disabled={false}
                onClick={handleAnular}
              >
                {state.loadingAnular ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Ok" />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCancel;
