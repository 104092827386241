import React from "react";
import { FormattedMessage } from "react-intl";

const Days = (props) => {
  return (
    <section>
      {props.data.map((item) => {
        return (
          <div key={`prueba-${props.id}${item.id}`}>
            {item.disponibilidad && item.seleccionable && (
              <span>
                <FormattedMessage id="Available" />
              </span>
            )}
            <div
              data-date={item.date}
              data-father={"ok"}
              id={`father-${props.id}${item.id}`}
              className={`${props.style.container__message__calendar}`}
              onClick={() => {
                if (item.disponibilidad && item.seleccionable) {
                  props.handleSelect({
                    message: `message-${props.id}${item.id}`,
                    id: `father-${props.id}${item.id}`,
                    data: props.data,
                    num: item.id,
                    item: item,
                    key: props.id,
                  });
                }
              }}
            >
              <h4 data-ok={"ok"} id={`message-${props.id}${item.id}`}></h4>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Days;
