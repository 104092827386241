import React from "react";
import { Route, Switch } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../../components/login/microsoftConfig";

import Login from "../login";
import Home from "../home";
import Checkin from "../../containers/checkin";
import Register from "../../containers/register";
import RegisterCompany from "../../containers/register_company";
import Recover from "../../containers/recover";
import Validation from "../../containers/validation";
import Validate from "../../containers/validate";

/*  Language */
import { IntlProvider } from "react-intl";

import messages_es from "../../translate/es";
import messages_en from "../../translate/en";

const messages = {
  es: messages_es,
  en: messages_en,
};
let language =
  typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : "es";

if (language !== "es" && language !== "en") {
  language = "es";
}
const msalInstance = new PublicClientApplication(msalConfig);
const App = () => (
  <IntlProvider locale="en" messages={messages[language]}>
    <MsalProvider instance={msalInstance}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/company/:id" component={Login} />

        <Route path="/home" component={Home} />
        <Route path="/gcheck/:data" component={Checkin} />
        <Route path="/recepcion/:data" component={Validate} />
        <Route path="/register/:code" component={Register} />
        <Route path="/register-company/:id" component={RegisterCompany} />
        <Route path="/recover" component={Recover} />
        <Route path="/recover-company/:id" component={Recover} />
        <Route path="/validation" component={Validation} />
      </Switch>
    </MsalProvider>
  </IntlProvider>
);

export default App;
