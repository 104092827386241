import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const ModalSearch = (props) => {
  const [search, setSearch] = useState("");
  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="Search" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={props.style.box__input}>
              <FormattedMessage id="Search">
                {(msg) => (
                  <Form.Control
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder={msg}
                  />
                )}
              </FormattedMessage>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={props.myStyle[`btn_${props.cliente}`]}
              variant="main"
              disabled={false}
              type="button"
            >
              <Link
                onClick={() => {
                  props.setModal(false);
                }}
                to={{
                  pathname: `/home/history`,
                  state: {
                    search,
                  },
                }}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    {false ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Search" />
                    )}
                  </span>
                </div>
              </Link>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalSearch;
