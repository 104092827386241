import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
/* Component */
import { Button } from "react-bootstrap";

/* Actions */
import {
  resetDeseable,
  resetRecursos,
  resetReserva,
} from "../../actions/reservation";

const StepDone = (props) => {
  const dispatch = useDispatch();
  let myEmail = "test";
  if (typeof localStorage !== "undefined") {
    myEmail = localStorage.getItem("names");
  }

  useEffect(() => {
    return () => {
      props.setForm({
        ciudad: "",
        provincia: "",
        comuna: "",
        empresa_id: "",
        bolsa_id: 3,
        atributos: [],
        imagenes: [],
        question: 0,
        formato_tiempo: "",
        fecha_inicio: new Date(),
        minuto_comienzo: moment().hour(0).minute(0),
        minuto_final: moment().hour(1).minute(0),
        hora_comienzo: moment().hour(0).minute(0),
        hora_final: "1",
        dias_comienzo: 1,
        dias_final: "",
        semana_comienzo: "",
        semana_final: "",
        duracion: "",
        inicio: "",
        showType: "show_week",
        edificio_id: "",
        guests: "0",
        showWeekend: false,
        filterType: "1,2,3,4,5",
        nodos: [],
        nodosCount: 0,
        emails: [
          { index: "index0", email: myEmail, booking: "", editable: "" },
        ],
        nodos_final: [],
      });
      props.setCount(0);
      props.setStep(1);
      props.setMemory({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
        step7: false,
        step8: false,
        step9: false,
        step10: false,
        step11: false,
      });
      dispatch(resetRecursos());
      dispatch(resetDeseable());
      dispatch(resetReserva());
    };
  }, [dispatch]);

  return (
    <section
      className={`${props.style.container__content} ${props.style.container__content__center}`}
    >
      <h1>
        <FormattedMessage id="BookingReady" />
      </h1>
      <h2>
        <FormattedMessage id="Summary1" />
      </h2>
      <section className={`${props.style.container__content__booking}`}>
        <article>
          <h4>
            <FormattedMessage id="BookingsMade" />
          </h4>
          <section>
            {props.finalBooking[0].map((item, i) => {
              let returned_endate = moment(
                `${item.fecha_inicio} ${item.hora_inicio}:00`,
                "DD-MM-YYYY HH:mm:ss"
              )
                .add(item.duracion, "minutes")
                .format("HH:mm");

              let returned_day = moment(
                `${item.fecha_inicio} ${item.hora_inicio}:00`,
                "DD-MM-YYYY HH:mm:ss"
              ).day();

              return (
                <main key={i}>
                  <div>
                    <FormattedMessage id={`Day${returned_day}`} />{" "}
                    {item.fecha_inicio}
                  </div>
                  <div>
                    <FormattedMessage id="Schedule1" /> {item.hora_inicio} -{" "}
                    {returned_endate}
                  </div>
                </main>
              );
            })}
          </section>
        </article>
        {props.finalBooking[1].length > 0 && (
          <article>
            <h4>
              <FormattedMessage id="BookingsNotMadeDue" />
            </h4>
            <section>
              {props.finalBooking[1].map((item, i) => {
                let returned_endate = moment(
                  `${item.fecha_inicio} ${item.hora_inicio}:00`,
                  "DD-MM-YYYY HH:mm:ss"
                )
                  .add(item.duracion, "minutes")
                  .format("HH:mm");

                let returned_day = moment(
                  `${item.fecha_inicio} ${item.hora_inicio}:00`,
                  "DD-MM-YYYY HH:mm:ss"
                ).day();

                return (
                  <main key={i}>
                    <div>
                      <FormattedMessage id={`Day${returned_day}`} />{" "}
                      {item.fecha_inicio}
                    </div>
                    <div>
                      <FormattedMessage id="Schedule1" /> {item.hora_inicio} -{" "}
                      {returned_endate}
                    </div>
                    <article>
                      {item.mensaje !== "" && item.mensaje ? (
                        <FormattedMessage id={item.mensaje} />
                      ) : (
                        ""
                      )}
                    </article>
                  </main>
                );
              })}
            </section>
          </article>
        )}
      </section>
      <div className={props.style.box__button__center}>
        <Button
          variant="main"
          className={props.myStyle[`btn_${props.cliente}`]}
          onClick={() => {
            props.setForm({
              ciudad: "",
              provincia: "",
              comuna: "",
              empresa_id: "",
              bolsa_id: 3,
              atributos: [],
              imagenes: [],
              question: 0,
              formato_tiempo: "",
              fecha_inicio: new Date(),
              minuto_comienzo: moment().hour(0).minute(0),
              minuto_final: moment().hour(1).minute(0),
              hora_comienzo: moment().hour(0).minute(0),
              hora_final: "1",
              dias_comienzo: 1,
              dias_final: "",
              semana_comienzo: "",
              semana_final: "",
              duracion: "",
              inicio: "",
              edificio_id: "",
              guests: "0",
              nodos: [],
              nodosCount: 0,
              emails: [
                { index: "index0", email: myEmail, booking: "", editable: "" },
              ],
              nodos_final: [],
            });
            props.setCount(0);
            props.setStep(1);
            props.setFinalBooking([[], []]);
            props.setMemory({
              step1: false,
              step2: false,
              step3: false,
              step4: false,
              step5: false,
              step6: false,
              step7: false,
              step8: false,
              step9: false,
              step10: false,
              step11: false,
            });
          }}
        >
          <div className={props.style.box__button}>
            <span>
              <FormattedMessage id="Next" />
            </span>
            <span>
              <i className="far fa-arrow-alt-circle-right"></i>
            </span>
          </div>
        </Button>
      </div>
    </section>
  );
};

export default StepDone;
