import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import Expired from "../common/Expired";

/* Img */
import Logo from "../../assets/images/logo.png";

/* Actions  */
import {
  getCheckValidate,
  resetCheckValidate,
  getReservaValidate,
  //resetReservaValidate,
  logOutValidate,
} from "../../actions/validate";

const Content = (props) => {
  const [id, setId] = useState(false);

  const state = useSelector((state) => state.validate);

  const email = props.id.split("=");

  const dispatch = useDispatch();

  useEffect(() => {
    if (state.statusCheck === 200) {
      if (state.dataCheck.ejecucion.estado) {
        dispatch(resetCheckValidate({}));
        dispatch(
          getReservaValidate({
            email: email[1],
            loading: false,
          })
        );
      }
    }
  }, [state.loadingCheck]);

  useEffect(() => {
    dispatch(
      getReservaValidate({
        email: email[1],
        loading: true,
      })
    );
  }, []);

  const handleCheck = (obj) => {
    dispatch(
      getCheckValidate({
        reserva_id: obj.reserva_id,
        email: email[1],
        type: obj.type,
      })
    );
  };

  const renderButton = (cin, cout, reserva_id) => {
    if (cout === true) {
      return (
        <span>
          <FormattedMessage id="Validated" />
        </span>
      );
    } else if (cin === false && cout === false) {
      return (
        <button
          onClick={() => {
            if (id !== reserva_id) {
              setId(reserva_id);
              handleCheck({ reserva_id, type: "checkin" });
            }
          }}
        >
          {(state.loadingReserva || state.loadingCheck) && reserva_id === id ? (
            <FormattedMessage id="Loading" />
          ) : (
            <FormattedMessage id="Validate" />
          )}
        </button>
      );
    } else if (cin === true && cout === false) {
      return (
        <span>
          <FormattedMessage id="Validated" />
        </span>
      );
    }
  };

  if (state.loadingReserva) {
    return (
      <section className={props.style.container}>
        {props.cliente === "" ? (
          <h1>
            <FormattedMessage id="WelcomeToKantoor" />
          </h1>
        ) : (
          <h1>
            <FormattedMessage id="WelcomeTo" /> {props.cliente} y Kantoor
          </h1>
        )}
        {props.cliente === "" ? (
          <div className={props.style.box__img}>
            <img src={Logo} alt="Logo" />
          </div>
        ) : (
          <div
            className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
          >
            <div className={props.myStyle[`logo_${props.cliente}`]}></div>
            <div></div>
          </div>
        )}
        <div className={props.style.box__text}>
          <h4>
            <FormattedMessage id="Loading" /> ...
          </h4>
        </div>
      </section>
    );
  }

  if (state.statusReserva === 200) {
    const filter1 = state.dataReserva.datos.filter((o) => o.reserva.vigente);

    if (state.dataReserva.datos === null || filter1.length === 0) {
      return (
        <section className={props.style.container}>
          {props.cliente === "" ? (
            <h1>
              <FormattedMessage id="WelcomeToKantoor" />
            </h1>
          ) : (
            <h1>
              <FormattedMessage id="WelcomeTo" /> {props.cliente} y Kantoor
            </h1>
          )}
          {props.cliente === "" ? (
            <div className={props.style.box__img}>
              <img src={Logo} alt="Logo" />
            </div>
          ) : (
            <div
              className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
            >
              <div className={props.myStyle[`logo_${props.cliente}`]}></div>
              <div></div>
            </div>
          )}
          <div className={props.style.box__text}>
            <h4>
              <FormattedMessage id="MessageReceptionNoReservation" />.
            </h4>
          </div>
          <div className={props.style.box__filter}>
            <button
              onClick={() => {
                props.setLogin(false);
                dispatch(logOutValidate());
              }}
            >
              <FormattedMessage id="LogOut" />
            </button>
          </div>
        </section>
      );
    }
  }

  if (state.statusReserva === 501) {
    return <Expired />;
  }

  const filter = state.dataReserva.datos.filter((o) => o.reserva.vigente);

  return (
    <section className={props.style.container}>
      {props.cliente === "" ? (
        <h1>
          <FormattedMessage id="WelcomeToKantoor" />
        </h1>
      ) : (
        <h1>
          <FormattedMessage id="WelcomeTo" /> {props.cliente} y Kantoor
        </h1>
      )}
      {props.cliente === "" ? (
        <div className={props.style.box__img}>
          <img src={Logo} alt="Logo" />
        </div>
      ) : (
        <div
          className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
        >
          <div className={props.myStyle[`logo_${props.cliente}`]}></div>
          <div></div>
        </div>
      )}

      <div className={props.style.box__filter}>
        <div>
          <button
            onClick={() => {
              props.setLogin(false);
              dispatch(logOutValidate());
            }}
          >
            <FormattedMessage id="LogOut" />
          </button>
        </div>

        {/*  <div>
          <button>
            <FormattedMessage id="ValidateAllReservations" />
          </button>
        </div> */}
      </div>

      {filter.map((item) => {
        return (
          <section
            key={item.reserva.id}
            className={props.style.container__check}
          >
            <article>
              <div>
                <h3>{item.edi_division_reservada.label}</h3>
              </div>
              <div>
                <h3>
                  <FormattedMessage id="From" />: {item.reserva.desde}
                </h3>
              </div>
              <div>
                <h3>
                  <FormattedMessage id="To" />: {item.reserva.hasta}
                </h3>
              </div>
              <div>
                {item.gente.involucrados.map((gente) => (
                  <h3 key={gente.email}>{gente.email}</h3>
                ))}
              </div>
              <div>
                <h3>{item.direccion.forma_larga}</h3>
              </div>
            </article>
            <article>
              {renderButton(
                item.chequeo.check_in,
                item.chequeo.check_out,
                item.chequeo.reserva_id
              )}{" "}
            </article>
          </section>
        );
      })}
    </section>
  );
};

export default Content;
