const initialState = {
  data: false,
  email: false,
  loading: false,
  status: 0,
  dataReserva: false,
  loadingReserva: false,
  statusReserva: 0,
  dataCheck: false,
  loadingCheck: false,
  statusCheck: 0,
  dataCovid: false,
  loadingCovid: false,
  statusCovid: 0,
  dataCovidRegister: false,
  loadingCovidRegister: false,
  statusCovidRegister: 0,
  dataCovidRegisterUpdate: false,
  loadingCovidRegisterUpdate: false,
  statusCovidRegisterUpdate: 0,
  dataJornada: false,
  loadingJornada: false,
  statusJornada: 0,
};

export default initialState;
