import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { resetLogin } from "../../actions/login";
import { resetAllReservation } from "../../actions/reservation";

const Expired = (props) => {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const handleLogout = (instance) => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  let history = useHistory();
  /* Close session */
  useEffect(() => {
    if (localStorage.getItem("type") === "microsoft") {
      //handleLogout(instance);
    }

    dispatch(resetLogin());
    dispatch(resetAllReservation());
    location.reload();
  }, [dispatch]);

  return (
    <section>
      <h2>Expired</h2>
    </section>
  );
};

export default Expired;
