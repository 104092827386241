import React, { useState, useEffect } from "react";
import moment from "moment";

/* Component */
import Step1 from "./StepOne";
import StepCompany from "./StepCompany";
import StepDivision from "./StepDivision";
import StepBags from "./StepBags";
import StepAttribute from "./StepAttribute";
import StepTime from "./StepTime";
import StepBuilding from "./StepBuilding";
import StepBlocks from "./StepBlocks";
import StepGuests from "./StepGuests";
import StepFinal from "./StepFinal";
import StepSummary from "./StepSummary";
import StepDone from "./StepDone";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const testData = [
  {
    id: 49,
    img: "http://k2.dataon.cl:71//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBLZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--49f0f32332635e78653c8de05a90fe184ef16ca3/icono_individual.png",
  },
  {
    id: 51,
    img: "false",
  },
  {
    id: 61,
    img: "http://k2.dataon.cl:71//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBLZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--49f0f32332635e78653c8de05a90fe184ef16ca3/icono_individual.png",
  },
];

const Content = (props) => {
  const [step, setStep] = useState(1); // First step is 1
  const [direction, setDirection] = useState(false);
  const [count, setCount] = useState(1);
  const [cliente, setCliente] = useState("");
  const [finalBooking, setFinalBooking] = useState([[], []]);

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  let myEmail = "test";
  if (typeof localStorage !== "undefined") {
    myEmail = localStorage.getItem("email");
  }

  /* Form  */

  /* const [form, setForm] = useState({
    ciudad: "7",
    provincia: "22",
    comuna: "102",
    empresa_id: "91",
    bolsa_id: 32,
    atributos: ["103", "108"],
    imagenes: testData,
    question: 1,
    is_guests: false,
    formato_tiempo: "minutos",
    fecha_inicio: new Date("2023-09-13"),
    minuto_comienzo: moment().hour(0).minute(0),
    minuto_final: moment().hour(1).minute(0),
    hora_comienzo: moment().hour(0).minute(0),
    hora_final: "1",
    dias_comienzo: 1,
    showWeekend: false,
    showType: "show_week",
    days_allowed: 30,
    filterType: "1,2,3,4,5",
    dias_final: "",
    semana_comienzo: "",
    semana_final: "",
    duracion: "300",
    inicio: "12:30",
    edificio_id: 25,
    guests: "2",
    nodos: [440, 441, 444],
    nodosCount: 0,
    emails: [
      {
        index: "index0",
        email: "cri.maturana@gmail.com",
        booking: "",
        editable: "",
      },
    ],
    nodos_final: [],
  }); */

  const [form, setForm] = useState({
    ciudad: "",
    provincia: "",
    comuna: "",
    empresa_id: "",
    bolsa_id: "",
    atributos: [],
    imagenes: [],
    question: 0,
    formato_tiempo: "",
    fecha_inicio: new Date(),
    minuto_comienzo: moment().hour(1).minute(0),
    minuto_final: moment().hour(1).minute(0),
    hora_comienzo: moment().hour(0).minute(0),
    hora_final: "1",
    dias_comienzo: 1,
    dias_final: "",
    semana_comienzo: "",
    daysAllowed: 0,
    semana_final: "",
    duracion: "",
    inicio: "",
    edificio_id: "",
    guests: "0",
    filterType: "1,2,3,4,5",
    showWeekend: false,
    showType: "show_week",
    nodos: [],
    nodosCount: 0,
    emails: [
      {
        index: "index0",
        email: myEmail,
        booking: "",
        editable: "",
      },
    ],
    nodos_final: [],
    is_guests: false,
  });

  const [memory, setMemory] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
    step8: false,
    step9: false,
    step10: false,
    step11: false,
  });

  /* This function is for to down a step */
  const backStep = () => {
    setDirection("left");
    setStep(step - 1);
  };

  const backStepSpecific = (num) => {
    setDirection("left");
    setStep(num);
  };

  /* This function is for to up a step */
  const nextStep = () => {
    setDirection("right");
    setStep(step + 1);
  };

  /*  Function changue inputs  */
  const handleChange = (event) => {
    if (event.persist) {
      event.persist();
    }

    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleMemory = (obj, state = true) => {
    setMemory((prev) => ({ ...prev, [obj.name]: state }));
  };

  /*  Function delete atribute */
  const deleteAtribute = (index) => {
    if (index > -1) {
      const arr = form.atributos;
      arr.splice(index, 1);
      setForm((prev) => ({ ...prev, atributos: arr }));
    }
  };

  /*  Function add image icon  */
  const handleChangeImage = (data, question) => {
    if (data) {
      const arrImg = [...form.imagenes];
      if (arrImg[question]) {
        arrImg[question] = data;
      } else {
        arrImg.push(data);
      }
      setForm((prev) => ({ ...prev, imagenes: arrImg }));
    } else {
      /* if  select empty, we have the element of array */
      const arrImg = [...form.imagenes];
      arrImg.splice(question, 1);
      setForm((prev) => ({ ...prev, imagenes: arrImg }));
    }
  };

  /*  Function delete imagenes */
  const deleteImage = (index, id) => {
    if (index > -1) {
      const arr = form.imagenes;
      arr.splice(index, 1);
      setForm((prev) => ({ ...prev, imagenes: arr }));
    }
  };

  /* This counter is representative for the user */
  const handleCount = (value) => {
    setCount(count + value);
  };

  let container;

  //console.log(form);

  switch (step) {
    case 2:
      container = (
        <StepCompany
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 3:
      container = (
        <StepDivision
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 4:
      container = (
        <StepBags
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 5:
      container = (
        <StepAttribute
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          deleteAtribute={deleteAtribute}
          handleChangeImage={handleChangeImage}
          deleteImage={deleteImage}
          handleMemory={handleMemory}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 6:
      container = (
        <StepTime
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          handleChangeImage={handleChangeImage}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 7:
      container = (
        <StepBuilding
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          handleChangeImage={handleChangeImage}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 8:
      container = (
        <StepBlocks
          myEmail={myEmail}
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          handleChangeImage={handleChangeImage}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 9:
      container = (
        <StepFinal
          backStepSpecific={backStepSpecific}
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          handleChangeImage={handleChangeImage}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          deseable={props.deseable}
          countDeseable={props.countDeseable}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 10:
      container = (
        <StepGuests
          myEmail={myEmail}
          nextStep={nextStep}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          handleChangeImage={handleChangeImage}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 11:
      container = (
        <StepSummary
          nextStep={nextStep}
          backStepSpecific={backStepSpecific}
          backStep={backStep}
          setDirection={setDirection}
          handleChange={handleChange}
          handleCount={handleCount}
          handleMemory={handleMemory}
          handleChangeImage={handleChangeImage}
          setFinalBooking={setFinalBooking}
          count={count}
          form={form}
          style={props.style}
          direction={direction}
          memory={memory}
          step={step}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
    case 12:
      container = (
        <StepDone
          setStep={setStep}
          setMemory={setMemory}
          setCount={setCount}
          setForm={setForm}
          style={props.style}
          cliente={cliente}
          myStyle={myStyle}
          setFinalBooking={setFinalBooking}
          finalBooking={finalBooking}
        />
      );
      break;
    default:
      container = (
        <Step1
          handleCount={handleCount}
          nextStep={nextStep}
          style={props.style}
          cliente={cliente}
          myStyle={myStyle}
        />
      );
      break;
  }

  return (
    <section className={props.style.container}>
      {container}
      {step !== 5 && step !== 6 && step !== 10 && step !== 12 && (
        <div className={props.style.box__arrow}>
          {step > 1 && (
            <span
              onClick={() => {
                setDirection("left");
                if (step === 9) {
                  handleCount(-2);
                  setStep(step - 2);
                } else {
                  backStep();
                  handleCount(-1);
                }
              }}
            >
              <i className="far fa-arrow-alt-circle-left"></i>
            </span>
          )}
          {memory[`step${step + 1}`] && step !== 10 && step !== 8 && (
            <span
              onClick={() => {
                nextStep();
                setDirection("right");
                handleCount(1);
              }}
            >
              <i className="far fa-arrow-alt-circle-right"></i>
            </span>
          )}
        </div>
      )}
    </section>
  );
};

export default Content;
