import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";

const Filter = (props) => {
  const [select, setSelect] = useState([]);
  const [count, setCount] = useState(0);

  const handleFilter = (e) => {
    let arr = select;
    const index = arr.indexOf(e.target.dataset.num);

    if (index < 0) {
      arr.push(e.target.dataset.num);
    } else {
      arr.splice(index, 1);
    }

    setSelect(arr);
    props.setDeseable(arr);
    props.setCount(count + 1);
    setCount(count + 1);
  };

  return (
    <div
      className={
        props.expand && props.select === "filter"
          ? `${props.style.box} `
          : `${props.style.box} ${props.style.hide}`
      }
    >
      <article>
        <span className={props.style.box__close}>
          <i
            onClick={() => {
              props.setExpand(false);
              props.setSelect("");
            }}
            className="fas fa-times"
          ></i>
        </span>
        <ol>
          {props.data.map((item) => {
            return (
              <li key={item.id}>
                <div>
                  <i
                    onClick={(e) => handleFilter(e)}
                    data-num={item.id}
                    className={
                      !select.includes(item.id.toString())
                        ? "far fa-square"
                        : "fas fa-check-square"
                    }
                  ></i>
                </div>
                <div>{item.glosa}</div>
              </li>
            );
          })}
        </ol>
        <div className={props.style.box__footer}>
          <Button
            variant="main"
            className={props.myStyle[`btn_${props.cliente}`]}
            type="submit"
            onClick={() => props.setExpand(false)}
          >
            <FormattedMessage id="Ok" />
          </Button>
        </div>
      </article>
    </div>
  );
};

export default Filter;
