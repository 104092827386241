import React from "react";
import { FormattedMessage } from "react-intl";

/* Component */
import { Button } from "react-bootstrap";

const Step1 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>
        <FormattedMessage id="Reservation" />
      </h1>
      <h2>
        <FormattedMessage id="DoYouWantToReserveASpace" />
      </h2>
      <p>
        <FormattedMessage id="Step1Text1" />
      </p>
      <div className={props.style.box__footer}>
        <Button
          className={props.myStyle[`btn_${props.cliente}`]}
          variant="main"
          onClick={() => {
            props.handleCount(1);
            props.nextStep();
          }}
        >
          <div className={props.style.box__button}>
            <span>
              <FormattedMessage id="Next" />
            </span>
            <span>
              <i className="far fa-arrow-alt-circle-right"></i>
            </span>
          </div>
        </Button>
        <h6>
          <FormattedMessage id="PressNextToContinue" />
        </h6>
      </div>
    </section>
  );
};

export default Step1;
