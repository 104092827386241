import React from "react";
import moment from "moment";

const Schedule = (props) => {
  return (
    <section className={props.style.container__schedule__bodydays}>
      {props.data.map((item, i) => {
        const divition = (i + 1) % 7;
        let keySpecial = parseInt(
          moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );
        return (
          <div
            className={divition === 0 ? props.style.no__boder : ""}
            key={item.id}
          >
            <span>{moment(item.comienza, "DD-MM-YYYY").format("DD")}</span>
            {item.disponibilidad && item.seleccionable && (
              <span className={props.style.active}>Disponible</span>
            )}
            <div
              data-date={item.date}
              data-father={"ok"}
              id={`father-${props.id}${item.id}${keySpecial}`}
              className={`${props.style.container__message__calendar} ${props.style.container__message__calendartop}`}
              onClick={() => {
                if (item.disponibilidad && item.seleccionable) {
                  props.handleSelect({
                    message: `message-${props.id}${item.id}${keySpecial}`,
                    id: `father-${props.id}${item.id}${keySpecial}`,
                    data: props.data,
                    num: keySpecial,
                    item: item,
                    key: props.id,
                  });
                }
              }}
            >
              <h4
                data-ok={"ok"}
                id={`message-${props.id}${item.id}${keySpecial}`}
              ></h4>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Schedule;
