import React, { useEffect, useState } from "react";

/* Components */
import Content from "../../components/copy_reservation/Content";

/* Style */
import Style from "../../assets/styles/Reservation.module.scss";

import myStyle from "../../components/style_client/cliente.module.scss";
import ConfigCliente from "../../components/style_client/cliente.js";
/* Clients */
let currentLocation = "";
if (typeof window !== "undefined") {
  currentLocation = window.location;
}

const Reservation = (props) => {
  const [mydata, setMydata] = useState({});
  const [cliente, setCliente] = useState("");
  const [filters, setFilters] = useState("1,2,3,4,5");

  useEffect(() => {
    if (currentLocation.host === ConfigCliente.factotal.url) {
      setCliente(ConfigCliente.factotal.name);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (!props.location.state) {
      props.history.push("/home");
    } else {
      const emails = [];
      let memoriaRecurso = "";
      let memoriaRecursoObj = localStorage.getItem("memoriaRecurso");
      if (memoriaRecursoObj) {
        memoriaRecursoObj = JSON.parse(memoriaRecursoObj);
        memoriaRecurso = memoriaRecursoObj.value;
      }
      props.location.state.data.gente.involucrados.forEach((item, i) => {
        emails.push({
          index: `index${i}`,
          email: item.email,
          editable: i === 0 ? memoriaRecurso : "",
          booking: props.location.state.data.edi_division_reservada.id,
        });
      });

      const regex1 = /\(\((.*?)\)\)/;
      const resultado = regex1.exec(props.location.state.data.empresa.uid); // "((0,3))"

      if (resultado) {
        setFilters(resultado[1]);
      }

      setMydata({
        empresa_id: props.location.state.data.empresa.id,
        fecha_inicio: props.location.state.form.fecha_inicio,
        duracion: props.location.state.form.duracion,
        inicio: props.location.state.form.hora_inicio,
        emails: emails,
        guests: props.location.state.data.gente.involucrados.length - 1,
        nodos_final: [props.location.state.data.edi_division_reservada],
        formato_tiempo: props.location.state.query.clave_granularidad,
        disponibility: props.location.state.form.disponibility,
        reserva_id: props.location.state.query.reserva_id,
      });
    }
  }, []);

  return (
    <>
      {mydata.empresa_id ? (
        <Content
          filters={filters}
          cliente={cliente}
          myStyle={myStyle}
          mydata={mydata}
          style={Style}
        />
      ) : (
        <h1>Loading</h1>
      )}
    </>
  );
};

export default Reservation;
