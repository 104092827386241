import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

/* Component */
import { Form, Button, Alert } from "react-bootstrap";

/* Assets */
import Logo from "../../assets/images/logo.png";

/* Actions  */
import { getCreateUserCode, resetCreateUserCode } from "../../actions/login";

const Code = (props) => {
  let history = useHistory();
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  /*  Use  states  */
  const [form, setForm] = useState({
    code1: props.code[0],
    code2: props.code[1],
    code3: props.code[2],
    code4: props.code[3],
  });

  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (state.statusCreateUserCode !== 0) {
      if (state.statusCreateUserCode === 200) {
        setStep(step + 1);
      } else {
        setError("ThereWasAnError");
      }
    }
  }, [state.loadingCreateUserCode]);

  /* Reset create user */
  useEffect(() => {
    return () => {
      dispatch(resetCreateUserCode());
    };
  }, [dispatch]);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.name === "code1") {
      document.getElementById("code2").focus();
    } else if (event.target.name === "code2") {
      document.getElementById("code3").focus();
    } else if (event.target.name === "code3") {
      document.getElementById("code4").focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      if (
        form.code1 !== "" &&
        form.code2 !== "" &&
        form.code3 !== "" &&
        form.code4 !== ""
      ) {
        const obj = props.form;
        obj.code = `${form.code1}${form.code2}${form.code3}${form.code4}`;
        console.log(obj);
        dispatch(getCreateUserCode(obj));
      } else {
        setError("CompleteTheRequiredFields");
      }
    } else if (step === 2) {
      history.push("/");
    }
  };

  return (
    <section data-testid="form" className={props.style.container__code}>
      <section className={props.style.box}>
        <div className={props.style.box__img}>
          <img src={Logo} alt="Logo" />
        </div>
        <h2>
          <FormattedMessage id="Register" />
        </h2>
        {step === 1 && (
          <h3>
            <FormattedMessage id="WeSendAVerificationCodeToYourEmail" />
          </h3>
        )}
        {step === 2 && (
          <>
            <h3>
              <FormattedMessage id="ThanksForSigningUpFor" />{" "}
              <strong>Kantoor</strong>
            </h3>
            <h3>
              <FormattedMessage id="RememberToCompleteYourProfile" />
            </h3>
          </>
        )}
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {step === 1 && (
            <section className={props.style.box__buttons}>
              <div>
                <Form.Control
                  value={form.code1}
                  onChange={(e) => handleChange(e)}
                  name={"code1"}
                  type="text"
                  maxLength="1"
                  placeholder=""
                  className={props.myStyle[`inputall_${props.cliente}`]}
                />
              </div>
              <div>
                <Form.Control
                  value={form.code2}
                  onChange={(e) => handleChange(e)}
                  name={"code2"}
                  id={"code2"}
                  type="text"
                  maxLength="1"
                  placeholder=""
                  className={props.myStyle[`inputall_${props.cliente}`]}
                />
              </div>
              <div>
                <Form.Control
                  value={form.code3}
                  onChange={(e) => handleChange(e)}
                  name={"code3"}
                  id={"code3"}
                  type="text"
                  maxLength="1"
                  placeholder=""
                  className={props.myStyle[`inputall_${props.cliente}`]}
                />
              </div>
              <div>
                <Form.Control
                  value={form.code4}
                  onChange={(e) => handleChange(e)}
                  name={"code4"}
                  id={"code4"}
                  type="text"
                  maxLength="1"
                  placeholder=""
                  className={props.myStyle[`inputall_${props.cliente}`]}
                />
              </div>
            </section>
          )}
          <section className={props.style.box__footer}>
            {error && (
              <span className={props.style.error}>
                <p>
                  <FormattedMessage id={error} />
                </p>
              </span>
            )}
            <Button
              variant="main"
              type="submit"
              className={props.myStyle[`btn_${props.cliente}`]}
            >
              <div className={props.style.box__button}>
                <span>
                  {state.loadingCreateUserCode ? (
                    <FormattedMessage id="Loading" />
                  ) : (
                    <FormattedMessage id="Next" />
                  )}
                </span>
                <span>
                  <i className="far fa-arrow-alt-circle-right"></i>
                </span>
              </div>
            </Button>
          </section>
        </Form>
      </section>
    </section>
  );
};

export default Code;
