import React from "react";

/* Compnents */
import Form from "../../components/validation/Form";
import Footer from "../../components/footer";

/* Style */
import Style from "../../assets/styles/Register.module.scss";

const Register = (props) => {
  return (
    <>
      <Form style={Style} />
      <Footer />
    </>
  );
};

export default Register;
