import React from "react";
import { FormattedMessage } from "react-intl";

const Step1 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>POLÍTICA DE PRIVACIDAD</h2>
      <p>
        Estas políticas de privacidad (en adelante “Políticas de Privacidad”),
        regulan la forma en que Proyectos de Ingeniería DataOn Ltda., sociedad
        limitada creada bajo las leyes de la República de Chile, con domicilio
        legal en Luis Uribe 2925, depto. 308, comuna de Ñuñoa, Santiago de Chile
        (en adelante “DataOn”), tratará la información personal (en adelante la
        “Información Personal”) y los datos personales (en adelante los “Datos
        Personales”) de los usuarios de la Plataforma Kantoor en sus versiones
        web y móvil (en adelante “Kantoor”), entendiendo por tales a los
        usuarios, comercios, marcas, servicios y/o visitantes de la Plataforma
        (en adelante el “Usuario” o los “Usuarios”).
      </p>
      <p>
        Estas Políticas de Privacidad describen, de forma detallada, la manera
        en que Kantoor recoge, utiliza, protege y comparte los Datos Personales
        y la Información Personal de sus Usuarios, de conformidad con lo
        dispuesto en el artículo 19 N.º 4 de la Constitución Política de la
        República de Chile y a las normas pertinentes de la Ley Nº 19.628 sobre
        protección de la vida privada y sus modificaciones posteriores.
      </p>
      <p>
        Kantoor procurará que estas Políticas de Privacidad sean conocidas por
        todos quienes accedan y utilicen la Plataforma, garantizando su acceso.
      </p>
      <p>
        Kantoor podrá modificar estas Políticas de Privacidad en cualquier
        momento y sin previo aviso, a su entera discreción, de lo cual
        notificará a los Usuarios mediante el envío de e-mails, mensajes de
        texto (SMS) o de otra manera, adjuntando la versión actualizada de las
        Políticas de Privacidad. Asimismo, informará de la actualización de las
        Políticas de Privacidad en la página principal u otras secciones de la
        Plataforma, a fin de mantener a los Usuarios siempre actualizados.
        Conforme a la ley aplicable, al usar la Plataforma tras haber recibido
        dicha notificación, se entiende que el Usuario acepta las
        actualizaciones de las Políticas de Privacidad.
      </p>
      <p>
        Los Usuarios siempre podrán decidir si aceptan o no las modificaciones a
        las Políticas de Privacidad. En el caso que el Usuario no acepte las
        nuevas Políticas de Privacidad, el vínculo entre este y Kantoor quedará
        disuelto y la Información Personal y Datos Personales de dicho Usuario
        no será usada de otra forma que la que correspondía de acuerdo a las
        Políticas de Privacidad debidamente aceptadas. Asimismo, en caso de que
        el Usuario lo solicite, dicha información podrá ser eliminada de manera
        permanente de los registros de Kantoor.
      </p>
      <p>
        Lea detenidamente estas Políticas de Privacidad antes de acceder y/o
        utilizar a la Plataforma o de registrarse en ella.
      </p>
    </section>
  );
};

export default Step1;
