import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";

/* Actions */
import {
  getReservaExterna,
  resetReservaExterna,
  getReservaExternaList,
  getReservaAllExternaList,
} from "../../actions/reservation";

/* Inputs */
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";

const ModalMakeBooking = (props) => {
  const [date, setDate] = useState(new Date());
  const [timeStart, setTimeStart] = useState(moment().hour(8).minute(0));
  const [timeEnd, setTimeEnd] = useState(moment().hour(10).minute(0));
  const [request, setRequest] = useState(props.resourceName);
  const [people, setPeople] = useState("1");
  const [resourceName, setResourceName] = useState(false);

  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  const makeBooking = () => {
    const obj = {
      fecha: moment(date).format("DD-MM-YYYY"),
      desde: timeStart.format("HH:mm"),
      hasta: timeEnd.format("HH:mm"),
      cantidad_personas: parseInt(people, 10),
      nombre_recurso: request,
    };
    dispatch(getReservaExterna(obj));
  };

  const closeModal = (obj) => {
    if (obj.tryAgain) {
      dispatch(resetReservaExterna(obj));
    } else {
      if (state.statusReservaExterna === 200) {
        dispatch(getReservaAllExternaList({}));
        dispatch(getReservaExternaList({}));
      }
      dispatch(resetReservaExterna(obj));
      props.setModal(false);
    }
  };

  useEffect(() => {
    let resource_n = localStorage.getItem("resource_name");
    resource_n = resource_n !== "";

    if (resource_n) {
      setResourceName(true);
      setRequest(localStorage.getItem("resource_name"));
    }
  });

  return (
    <>
      <Modal
        onHide={() => closeModal({ tryAgain: false })}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="ExternalReservationRequest" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    closeModal({ tryAgain: false });
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          {state.statusReservaExterna === 0 && (
            <Modal.Body>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="Date" />
                </Form.Label>
                <DatePicker
                  className={props.style.input__full}
                  selected={date}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => {
                    setDate(date);
                  }}
                />
              </div>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="StartTime" />
                </Form.Label>
                <TimePicker
                  showSecond={false}
                  value={timeStart}
                  className="xxx"
                  onChange={(value) => {
                    setTimeStart(value);
                  }}
                  inputReadOnly
                />
              </div>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="EndTime" />
                </Form.Label>
                <TimePicker
                  showSecond={false}
                  value={timeEnd}
                  className="xxx"
                  onChange={(value) => {
                    setTimeEnd(value);
                  }}
                  inputReadOnly
                />
              </div>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="Request" />
                </Form.Label>
                <FormattedMessage id="Request">
                  {(msg) => (
                    <Form.Control
                      value={request}
                      readOnly={resourceName}
                      onChange={(e) => setRequest(e.target.value)}
                      type="text"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="People" />
                </Form.Label>
                <FormattedMessage id="People">
                  {(msg) => (
                    <Form.Control
                      value={people}
                      onChange={(e) => setPeople(e.target.value)}
                      type="number"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
            </Modal.Body>
          )}
          {state.statusReservaExterna === 200 &&
            state.dataReservaExterna.ejecucion.estado && (
              <Modal.Body>
                <div className={props.style.box__modal__feedback}>
                  <div>
                    <i className="fas fa-smile" />
                  </div>
                  <h4>
                    <FormattedMessage id="RequestSentSuccessfully" />
                  </h4>
                </div>
              </Modal.Body>
            )}
          {state.statusReservaExterna === 200 &&
            !state.dataReservaExterna.ejecucion.estado && (
              <Modal.Body>
                <div className={props.style.box__modal__feedback}>
                  <div>
                    <i className="fas fa-sad-tear" />
                  </div>
                  <h4>
                    <FormattedMessage id="RequestCouldNotBeSent" />
                  </h4>
                </div>
              </Modal.Body>
            )}
          <Modal.Footer>
            {state.statusReservaExterna === 0 && (
              <Button
                className={props.myStyle[`btn_${props.cliente}`]}
                variant="main"
                disabled={false}
                type="button"
                onClick={() => makeBooking()}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    {state.loadingReservaExterna ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Ok" />
                    )}
                  </span>
                </div>
              </Button>
            )}
            {state.statusReservaExterna === 200 &&
              state.dataReservaExterna.ejecucion.estado && (
                <Button
                  className={props.myStyle[`btn_${props.cliente}`]}
                  variant="main"
                  disabled={false}
                  type="button"
                  onClick={() => closeModal({ tryAgain: false })}
                >
                  <div className={props.style.box__button__modal}>
                    <span>
                      {state.loadingReservaExterna ? (
                        <FormattedMessage id="Loading" />
                      ) : (
                        <FormattedMessage id="Ok" />
                      )}
                    </span>
                  </div>
                </Button>
              )}
            {state.statusReservaExterna === 200 &&
              !state.dataReservaExterna.ejecucion.estado && (
                <Button
                  className={props.myStyle[`btn_${props.cliente}`]}
                  variant="main"
                  disabled={false}
                  type="button"
                  onClick={() => closeModal({ tryAgain: true })}
                >
                  <div className={props.style.box__button__modal}>
                    <span>
                      {state.loadingReservaExterna ? (
                        <FormattedMessage id="Loading" />
                      ) : (
                        <FormattedMessage id="TryAgain" />
                      )}
                    </span>
                  </div>
                </Button>
              )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalMakeBooking;
