const arr = [
  1670, // start progreso
  1682,
  1681,
  1680,
  1679,
  1678,
  1677,
  1676,
  1675,
  1674,
  1673,
  1672,
  1671,
  1669,
  1668,
  1667,
  1666,
  1665,
  1664,
  1663,
  1662,
  1661,
  1660,
  1659,
  1658,
  1657,
  1656,
  1655,
  1654,
  1734,
  1733,
  1732,
  1731,
  1730,
  1729,
  1728,
  1727,
  1726,
  1725,
  1724,
  1723,
  1722,
  1721,
  1720,
  1719,
  1718,
  1717,
  1716,
  1715,
  1714,
  1713,
  1712,
  1711,
  1710,
  1709,
  1708,
  1707,
  1706,
  1705,
  1704,
  1703,
  1702,
  1701,
  1700,
  1699,
  1698,
  1697,
  1696,
  1695,
  1694,
  1693,
  1692,
  1691,
  1690,
  1689,
  1688,
  1687,
  1686,
  1685,
  1684,
  1683,
  1653,
  1652,
  1651,
  1650,
  1649,
  1648,
  1637,
  1636,
  1635,
  1633,
  1632,
  1642,
  1641,
  1640,
  1639,
  1638,
  1745,
  1744,
  1743,
  1742,
  1741, // end progreso
];

export default arr;
