import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

/* Component */
import { Form, Button } from "react-bootstrap";
import Message from "./Message";

/* Assets */
import Logo from "../../assets/images/logo.png";

/* Actions  */
import { getCreateUser, resetCreateUser } from "../../actions/login";

let language =
  typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : "es";

if (language !== "es" && language !== "en") {
  language = "en";
}

const FormRegister = (props) => {
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  /*  Use  states  */
  const [form, setForm] = useState({
    email: "",
    lenguaje: language,
  });

  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (state.statusCreateUser !== 0) {
      if (state.statusCreateUser === 200) {
        setStep(step + 1);
      } else {
        setError("ThisEmailAlreadyExists");
      }
    }
  }, [state.loadingCreateUser]);

  /* Reset create user */
  useEffect(() => {
    return () => {
      dispatch(resetCreateUser());
    };
  }, [dispatch]);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setError(false);
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const nextStep = (e, step) => {
    e.preventDefault();

    if (form.email !== "") {
      if (step === 1) {
        dispatch(getCreateUser(form));
      }
    } else {
      setError("CompleteTheRequiredFields");
    }
  };

  /*  Code confirmation  */
  if (step === 2) {
    return <Message style={props.style} />;
  }

  return (
    <>
      <section data-testid="form" className={props.style.container}>
        <section className={props.style.box}>
          <div className={props.style.box__img}>
            <img src={Logo} alt="Logo" />
          </div>
          <h2>
            <FormattedMessage id="Register" />
          </h2>
          <Form
            onSubmit={(e) => {
              nextStep(e, step);
            }}
          >
            {step === 1 && (
              <div className={props.style.box__form}>
                <Form.Group>
                  <Form.Label>
                    1 - <FormattedMessage id="WriteYourEmail" />
                  </Form.Label>
                  <FormattedMessage id="WriteYourEmail">
                    {(msg) => (
                      <Form.Control
                        value={form.email}
                        onChange={(e) => handleChange(e)}
                        name={"email"}
                        type="text"
                        placeholder=""
                      />
                    )}
                  </FormattedMessage>
                </Form.Group>
              </div>
            )}
            <section className={props.style.box__footer}>
              {error && (
                <span className={props.style.error}>
                  <p>
                    <FormattedMessage id={error} />
                  </p>
                </span>
              )}
              <Button variant="main" type="submit">
                <div className={props.style.box__button}>
                  <span>
                    {state.loadingCreateUser ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Next" />
                    )}
                  </span>
                  <span>
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </span>
                </div>
              </Button>
              <div>
                <p>
                  <FormattedMessage id="PressEnterToContinue" />
                </p>
              </div>
            </section>
          </Form>
        </section>
      </section>
    </>
  );
};

export default FormRegister;
