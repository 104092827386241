import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

/* Actions */
import {
  getAcceptReservaExterna,
  resetAcceptReservaExterna,
  getReservaAllExternaList,
} from "../../actions/reservation";

const ModalMakeBookingAccept = (props) => {
  const [request, setRequest] = useState("1");
  const [id, setId] = useState("");

  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  const makeBooking = () => {
    let idFinal = null;

    if (id !== "" && request === "1") {
      idFinal = id === "" ? null : parseInt(id, 10);
    }
    const obj = {
      reserva_especial_id: props.id,
      feedback: request === "1" ? true : false,
      opcion_reserva_especial_id: idFinal,
    };

    dispatch(getAcceptReservaExterna(obj));
  };

  const closeModal = (obj) => {
    if (obj.tryAgain) {
      dispatch(resetAcceptReservaExterna(obj));
    } else {
      dispatch(resetAcceptReservaExterna(obj));
      props.setModal(false);
      if (state.statusAcceptReservaExterna === 200) {
        dispatch(getReservaAllExternaList({}));
      }
    }
  };

  return (
    <>
      <Modal
        onHide={() => closeModal({ tryAgain: false })}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="ExternalReservationRequest" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    closeModal({ tryAgain: false });
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          {state.statusAcceptReservaExterna === 0 && (
            <Modal.Body>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="AcceptRequest" />
                </Form.Label>

                <Form.Control
                  value={request}
                  onChange={(e) => {
                    setRequest(e.target.value);
                    if (e.target.value === "0") {
                      setId("");
                    }
                  }}
                  as="select"
                >
                  <FormattedMessage id="Yes">
                    {(msg) => <option value="1">{msg}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="No">
                    {(msg) => <option value="0">{msg}</option>}
                  </FormattedMessage>
                </Form.Control>
              </div>
              <div className={props.style.box__input}>
                <Form.Label>
                  <FormattedMessage id="AssignedDivision" />
                </Form.Label>

                <Form.Control
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  as="select"
                >
                  <FormattedMessage id="Select">
                    {(msg) => <option value="">{msg}</option>}
                  </FormattedMessage>
                  {props.data.map((item) => {
                    if (request === "1") {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.glosa}
                        </option>
                      );
                    }
                  })}
                </Form.Control>
              </div>
            </Modal.Body>
          )}
          {state.statusAcceptReservaExterna === 200 &&
            state.dataAcceptReservaExterna.ejecucion.estado && (
              <Modal.Body>
                <div className={props.style.box__modal__feedback}>
                  <div>
                    <i className="fas fa-smile" />
                  </div>
                  <h4>
                    <FormattedMessage id="RequestUpdated" />
                  </h4>
                </div>
              </Modal.Body>
            )}
          {state.statusAcceptReservaExterna === 200 &&
            !state.dataAcceptReservaExterna.ejecucion.estado && (
              <Modal.Body>
                <div className={props.style.box__modal__feedback}>
                  <div>
                    <i className="fas fa-sad-tear" />
                  </div>
                  <h4>
                    <FormattedMessage id="RequestRejected" />
                  </h4>
                </div>
              </Modal.Body>
            )}
          <Modal.Footer>
            {state.statusAcceptReservaExterna === 0 && (
              <Button
                className={props.myStyle[`btn_${props.cliente}`]}
                variant="main"
                disabled={false}
                type="button"
                onClick={() => makeBooking()}
              >
                <div className={props.style.box__button__modal}>
                  <span>
                    {state.loadingAcceptReservaExterna ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Ok" />
                    )}
                  </span>
                </div>
              </Button>
            )}
            {state.statusAcceptReservaExterna === 200 &&
              state.dataAcceptReservaExterna.ejecucion.estado && (
                <Button
                  className={props.myStyle[`btn_${props.cliente}`]}
                  variant="main"
                  disabled={false}
                  type="button"
                  onClick={() => closeModal({ tryAgain: false })}
                >
                  <div className={props.style.box__button__modal}>
                    <span>
                      {state.loadingAcceptReservaExterna ? (
                        <FormattedMessage id="Loading" />
                      ) : (
                        <FormattedMessage id="Ok" />
                      )}
                    </span>
                  </div>
                </Button>
              )}
            {state.statusAcceptReservaExterna === 200 &&
              !state.dataAcceptReservaExterna.ejecucion.estado && (
                <Button
                  className={props.myStyle[`btn_${props.cliente}`]}
                  variant="main"
                  disabled={false}
                  type="button"
                  onClick={() => closeModal({ tryAgain: true })}
                >
                  <div className={props.style.box__button__modal}>
                    <span>
                      {state.loadingAcceptReservaExterna ? (
                        <FormattedMessage id="Loading" />
                      ) : (
                        <FormattedMessage id="TryAgain" />
                      )}
                    </span>
                  </div>
                </Button>
              )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalMakeBookingAccept;
