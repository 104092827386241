const initialState = {
  data: false,
  loading: false,
  status: 0,
  dataForgetPass: {},
  loadingForgetPass: false,
  statusForgetPass: 0,
  dataChangePass: {},
  loadingChangePass: false,
  statusChangePass: 0,
  dataLanguages: {},
  loadingLanguages: false,
  statusLanguages: 0,
  dataCreateUser: {},
  loadingCreateUser: false,
  statusCreateUser: 0,
  dataCreateUserCode: {},
  loadingCreateUserCode: false,
  statusCreateUserCode: 0,
  dataArea: false,
  loadingArea: true,
  statusArea: 0,
  dataCreateUserCompany: false,
  loadingCreateUserCompany: false,
  statusCreateUserCompany: 0,
};

export default initialState;
