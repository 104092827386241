import React from "react";
import { FormattedMessage } from "react-intl";

const Step6 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>Cookies e Información Recogida Automáticamente</h2>
      <p>
        Una cookie se refiere a un fichero que es enviado con la finalidad de
        solicitar permiso para almacenarse en su ordenador, al aceptar dicho
        fichero se crea y la cookie sirve entonces para tener información
        respecto al tráfico web, y también facilita las futuras visitas a una
        web recurrente. Otra función que tienen las cookies es que con ellas las
        web pueden reconocerte individualmente y por tanto brindarte el mejor
        servicio personalizado de su web.
      </p>
      <p>
        La Plataforma emplea las cookies para poder identificar las páginas que
        son visitadas y su frecuencia. Esta información es empleada únicamente
        para análisis estadístico y después la información se elimina de forma
        permanente. Usted puede eliminar las cookies en cualquier momento desde
        su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor
        servicio de los sitios web, estás no dan acceso a información de su
        ordenador ni de usted, a menos de que usted así lo quiera y la
        proporcione directamente. El Usuario puede aceptar o negar el uso de
        cookies, sin embargo la mayoría de navegadores aceptan cookies
        automáticamente pues sirve para tener un mejor servicio web. El Usuario
        puede cambiar la configuración de su ordenador para declinar las
        cookies. Si se declinan es posible que no pueda utilizar algunos de los
        servicios.
      </p>
      <p>
        Para explorar qué configuración de Cookies está disponible para usted,
        busque en la sección "preferencias" u "opciones" del menú de su
        navegador. Para obtener más información sobre las Cookies, incluida
        información sobre cómo administrar y eliminar las Cookies, visite
        http://www.allaboutcookies.org/.
      </p>
    </section>
  );
};

export default Step6;
