import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

/* Component */
import { Form, Button } from "react-bootstrap";
import Message from "./Message";

/* Assets */
import Logo from "../../assets/images/logo.png";

/* Actions  */
import { getForgetPass, resetForgetPass } from "../../actions/login";

const FormRecover = (props) => {
  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  /*  Use  states  */
  const [form, setForm] = useState({
    email: "",
  });

  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (state.statusForgetPass !== 0) {
      if (state.statusForgetPass === 200) {
        setStep(step + 1);
      } else {
        setError("ThereWasAnError");
      }
    }
  }, [state.loadingForgetPass]);

  /* Reset forget pass*/
  useEffect(() => {
    return () => {
      dispatch(resetForgetPass());
    };
  }, [dispatch]);

  /*  Function changue inputs  */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const nextStep = (e, step) => {
    e.preventDefault();
    if (form.email !== "") {
      if (step === 1) {
        dispatch(getForgetPass({ email: form.email.toLowerCase() }));
      }
    } else {
      setError("CompleteTheRequiredFields");
    }
  };

  /* code confirmation  */
  if (step === 2) {
    return (
      <Message
        goBack={props.goBack}
        myStyle={props.myStyle}
        cliente={props.cliente}
        style={props.style}
      />
    );
  }

  return (
    <>
      <section data-testid="form" className={props.style.container}>
        <section className={props.style.box}>
          {props.cliente === "" ? (
            <div className={props.style.box__img}>
              <img src={Logo} alt="Logo" />
            </div>
          ) : (
            <div
              className={`${props.myStyle.box__logomix} ${props.myStyle.box__logomix__main}`}
            >
              <div className={props.myStyle[`logo_${props.cliente}`]}></div>
              <div></div>
            </div>
          )}
          <h2>
            <FormattedMessage id="RecoverPass" />
          </h2>
          <Form
            onSubmit={(e) => {
              nextStep(e, step);
            }}
          >
            {step === 1 && (
              <div className={props.style.box__form}>
                <Form.Group>
                  <Form.Label>
                    1 - <FormattedMessage id="WriteYourEmail" />
                  </Form.Label>
                  <FormattedMessage id="WriteYourEmail">
                    {(msg) => (
                      <Form.Control
                        value={form.email}
                        onChange={(e) => handleChange(e)}
                        name={"email"}
                        type="text"
                        placeholder=""
                        className={props.myStyle[`input_${props.cliente}`]}
                      />
                    )}
                  </FormattedMessage>
                </Form.Group>
              </div>
            )}

            <section className={props.style.box__footer}>
              {error && (
                <span className={props.style.error}>
                  <p>
                    <FormattedMessage id={error} />
                  </p>
                </span>
              )}
              <Button
                variant="main"
                type="submit"
                className={props.myStyle[`btn_${props.cliente}`]}
              >
                <div className={props.style.box__button}>
                  <span>
                    {state.loadingForgetPass ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      <FormattedMessage id="Next" />
                    )}
                  </span>
                  <span>
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </span>
                </div>
              </Button>
              <div>
                <p>
                  <FormattedMessage id="PressEnterToContinue" />
                </p>
              </div>
            </section>
          </Form>
        </section>
      </section>
    </>
  );
};

export default FormRecover;
