import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";

/*  Modals */

import ModalPassword from "./ModalPassword";
import ModalLanguages from "./ModalLanguages";
import QRCode from "react-qr-code";

const Filter = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [img, setImg] = useState("");

  /* mode use state */
  const [modalPassword, setModalPassword] = useState(false);
  const [modalLanguages, setModalLanguages] = useState(false);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      setEmail(localStorage.getItem("email"));
      setName(localStorage.getItem("names"));
      setImg(localStorage.getItem("img"));
    }
  }, []);

  return (
    <div
      className={
        props.expand && props.select === "user"
          ? `${props.style.box} `
          : `${props.style.box} ${props.style.hide}`
      }
    >
      <ModalPassword
        cliente={props.cliente}
        myStyle={props.myStyle}
        email={email}
        style={props.style}
        modal={modalPassword}
        setModal={setModalPassword}
      />
      <ModalLanguages
        cliente={props.cliente}
        myStyle={props.myStyle}
        email={email}
        style={props.style}
        modal={modalLanguages}
        setModal={setModalLanguages}
      />
      <article>
        <span className={props.style.box__close}>
          <i
            onClick={() => {
              props.setExpand(false);
              props.setSelect("");
            }}
            className="fas fa-times"
          ></i>
        </span>
        <div className={props.style.box__avatar}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`https://app.kantoor.cl/recepcion/email=${email}`}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div className={props.style.box__text}>
          <h4>{name}</h4>
          {false && (
            <h5>
              <i className="fas fa-map-marker-alt"></i> Santiago, Chile
            </h5>
          )}
          <p>{email}</p>
        </div>
        <div className={props.style.box__footer}>
          <div className={props.style.box__footer__column}>
            <Button
              variant="main"
              type="button"
              className={props.myStyle[`btn_${props.cliente}`]}
              onClick={() => {
                setModalPassword(true);
              }}
            >
              <i className="fas fa-unlock-alt"></i>{" "}
              <FormattedMessage id="ChangePassword" />
            </Button>
          </div>
          <div className={props.style.box__footer__column}>
            <Button
              variant="main"
              type="submit"
              className={props.myStyle[`btn_${props.cliente}`]}
              onClick={() => {
                setModalLanguages(true);
              }}
            >
              <i className="fas fa-globe-americas"></i>{" "}
              <FormattedMessage id="ChangeLanguage" />
            </Button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Filter;
