const validateCompanies = (
  company,
  companies,
  field,
  isCompanyMandatory = true
) => {
  if (!company && isCompanyMandatory) {
    return false;
  } else if (companies[company]) {
    return companies[company][field];
  }

  return true;
};

export default validateCompanies;
