import React from "react";
import { FormattedMessage } from "react-intl";

const Step1 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Sobre nosotros</h1>
      <p>
        El modelo de trabajo en las organizaciones ha cambiado, ejemplo de esto
        son los sistemas híbridos entre teletrabajo y presencial, que ha llegado
        para quedarse. Como consecuencia, los entornos de trabajo serán más
        flexibles, avanzando hacia sistemas colaborativos en donde las oficinas
        se convierten en centros de reunión destinados a socializar y trabajar
        en equipo.
      </p>
      <p>
        De esta manera, gestionar y administrar los espacios se vuelve una
        problema relevante para cualquier organización.
      </p>
    </section>
  );
};

export default Step1;
