export const RESET_LOGIN_CHECK = "RESET_LOGIN_CHECK";
export const GET_LOGIN_CHECK_INIT = "GET_LOGIN_CHECK_INIT";
export const GET_LOGIN_CHECK_SUCCESS = "GET_LOGIN_CHECK_SUCCESS";
export const GET_LOGIN_CHECK_FAILURE = "GET_LOGIN_CHECK_FAILURE";

export const RESET_RESERVA_CHECK = "RESET_RESERVA_CHECK";
export const GET_RESERVA_CHECK_INIT = "GET_RESERVA_CHECK_INIT";
export const GET_RESERVA_CHECK_SUCCESS = "GET_RESERVA_CHECK_SUCCESS";
export const GET_RESERVA_CHECK_FAILURE = "GET_RESERVA_CHECK_FAILURE";

export const RESET_CHECK = "RESET_CHECK";
export const GET_CHECK_INIT = "GET_CHECK_INIT";
export const GET_CHECK_SUCCESS = "GET_CHECK_SUCCESS";
export const GET_CHECK_FAILURE = "GET_CHECK_FAILURE";

export const RESET_COVID = "RESET_COVID";
export const GET_COVID_INIT = "GET_COVID_INIT";
export const GET_COVID_SUCCESS = "GET_COVID_SUCCESS";
export const GET_COVID_FAILURE = "GET_COVID_FAILURE";

export const RESET_COVID_REGISTER = "RESET_COVID_REGISTER";
export const GET_COVID_REGISTER_INIT = "GET_COVID_REGISTER_INIT";
export const GET_COVID_REGISTER_SUCCESS = "GET_COVID_REGISTER_SUCCESS";
export const GET_COVID_REGISTER_FAILURE = "GET_COVID_REGISTER_FAILURE";

export const RESET_COVID_REGISTER_UPDATE = "RESET_COVID_REGISTER_UPDATE";
export const GET_COVID_REGISTER_UPDATE_INIT = "GET_COVID_REGISTER_UPDATE_INIT";
export const GET_COVID_REGISTER_UPDATE_SUCCESS =
  "GET_COVID_REGISTER_UPDATE_SUCCESS";
export const GET_COVID_REGISTER_UPDATE_FAILURE =
  "GET_COVID_REGISTER_UPDATE_FAILURE";

export const RESET_JORNADA = "RESET_JORNADA";
export const GET_JORNADA_INIT = "GET_JORNADA_INIT";
export const GET_JORNADA_SUCCESS = "GET_JORNADA_SUCCESS";
export const GET_JORNADA_FAILURE = "GET_JORNADA_FAILURE";
