import React from "react";
import { FormattedMessage } from "react-intl";

const Step7 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>Enlaces a Terceros</h2>
      <p>
        La Plataforma pudiera contener enlaces a otros sitios que pudieran ser
        de su interés. Una vez que usted de click en estos enlaces y abandone la
        Plataforma, ya no tenemos control sobre al sitio al que es redirigido y
        por lo tanto no somos responsables de los términos o privacidad ni de la
        protección de sus datos en esos otros sitios terceros. Dichos sitios
        están sujetos a sus propias políticas de privacidad por lo cual es
        recomendable que los consulte para confirmar que usted está de acuerdo
        con estas.
      </p>
    </section>
  );
};

export default Step7;
