/* History*/
export const GET_HISTORY_INIT = "GET_HISTORY_INIT";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILURE = "GET_HISTORY_FAILURE";

export const RESET_HISTORY = "RESET_HISTORY";

/* Anular */
export const GET_ANULAR_INIT = "GET_ANULAR_INIT";
export const GET_ANULAR_SUCCESS = "GET_ANULAR_SUCCESS";
export const GET_ANULAR_FAILURE = "GET_ANULAR_FAILURE";

export const RESET_ANULAR = "RESET_ANULAR";

/* Niveles */
export const GET_NIVELES_INIT = "GET_NIVELES_INIT";
export const GET_NIVELES_SUCCESS = "GET_NIVELES_SUCCESS";
export const GET_NIVELES_FAILURE = "GET_NIVELES_FAILURE";

export const RESET_NIVELES = "RESET_NIVELES";

/* Nota */
export const GET_NOTA_INIT = "GET_NOTA_INIT";
export const GET_NOTA_SUCCESS = "GET_NOTA_SUCCESS";
export const GET_NOTA_FAILURE = "GET_NOTA_FAILURE";

export const RESET_NOTA = "RESET_NOTA";

/* Calendar two */
export const GET_CALENDARTWO_INIT = "GET_CALENDARTWO_INIT";
export const GET_CALENDARTWO_SUCCESS = "GET_CALENDARTWO_SUCCESS";
export const GET_CALENDARTWO_FAILURE = "GET_CALENDARTWO_FAILURE";

export const RESET_CALENDARTWO = "RESET_CALENDARTWO";
