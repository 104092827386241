import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

/* Action */
import { resetLogin } from "../../actions/login";
import { resetAllReservation } from "../../actions/reservation";

/* Modal */
import ModalMakeBooking from "./ModalMakeBooking";

/* Assets */
import Logo from "../../assets/images/switch.png";

const MenuLeft = (props) => {
  const [extend, setExtend] = useState(false);
  const [modal, setModal] = useState(false);
  const [externalBooking, setExternalBooking] = useState(false);
  const [roles, setRoles] = useState([]);
  const [path, setPath] = useState("false");

  const dispatch = useDispatch();
  // const { instance } = useMsal();
  let history = useHistory();

  useEffect(() => {
    let external_b = localStorage.getItem("external_booking");
    external_b = external_b === "true";
    if (external_b) {
      setExternalBooking(true);
    }

    //Path
    setPath(localStorage.getItem("login_company"));

    //Roles
    const arrRolesSave = [];
    let arrRoles = JSON.parse(localStorage.getItem("roles"))
      ? JSON.parse(localStorage.getItem("roles"))
      : [];
    arrRoles.forEach((element) => {
      arrRolesSave.push(element.codigo);
    });
    setRoles(arrRolesSave);
  }, []);

  const handleLogout = (instance) => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const logOut = () => {
    if (localStorage.getItem("type") === "microsoft") {
      //handleLogout(instance);
    }

    dispatch(resetLogin());
    dispatch(resetAllReservation());

    if (path === "false" || !path) {
      history.push("/");
    } else {
      history.push(`/company/${path}`);
    }
  };

  return (
    <nav>
      <ModalMakeBooking
        myStyle={props.myStyle}
        style={props.style}
        modal={modal}
        setModal={setModal}
      />
      <span
        onClick={() => {
          setExtend(true);
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          className={`${props.style.img__menu} ${
            props.myStyle[`imgmenu_${props.cliente}`]
          }`}
        />
        <h3>Menu</h3>
      </span>
      <div
        className={
          extend
            ? `${props.style.container__menu} ${
                props.myStyle[`menuleft_${props.cliente}`]
              }`
            : `${props.style.container__menu} ${props.style.hide} ${
                props.myStyle[`menuleft_${props.cliente}`]
              }`
        }
      >
        <article>
          <span className={props.style.box__close}>
            <i
              onClick={() => {
                setExtend(false);
              }}
              className="fas fa-times"
            ></i>
          </span>
          <ul>
            <li>
              <Link to="/home" onClick={() => setExtend(false)}>
                <FormattedMessage id="Home" />
              </Link>
            </li>
            <li>
              <Link to="/home/history" onClick={() => setExtend(false)}>
                <FormattedMessage id="History" />
              </Link>
            </li>
            {externalBooking && (
              <React.Fragment>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      setModal(true);
                      setExtend(false);
                    }}
                  >
                    <FormattedMessage id="ExternalReservationRequest" />
                  </a>
                </li>
                <li>
                  <Link to="/home/requests" onClick={() => setExtend(false)}>
                    <FormattedMessage id="MyRequests" />
                  </Link>
                </li>
                {roles.includes("buzon_correo_por_fuera") && (
                  <React.Fragment>
                    <li>
                      <Link
                        to="/home/all_requests"
                        onClick={() => setExtend(false)}
                      >
                        <FormattedMessage id="AllRequests" />
                      </Link>
                    </li>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <li>
              <Link to="/home/about" onClick={() => setExtend(false)}>
                <FormattedMessage id="About" />
              </Link>
            </li>

            {/*   <li>
              <Link to="/home/profile">
                <FormattedMessage id="Profile" />
              </Link>
            </li> */}
            {/*   <li>
              <a href="#" onClick={() => setExtend(false)}>
                <FormattedMessage id="Setting" />
              </a>
            </li> */}
            <li>
              <Link to="/home/terms" onClick={() => setExtend(false)}>
                <FormattedMessage id="TermsAndConditions" />
              </Link>
            </li>

            <li>
              <a onClick={logOut} href="">
                <FormattedMessage id="LogOut" />
              </a>
            </li>
          </ul>
        </article>
      </div>
    </nav>
  );
};

export default MenuLeft;
