import React from "react";
import { FormattedMessage } from "react-intl";

const Step5 = (props) => {
  return (
    <section className={props.style.container__content}>
      <h1>Terminos</h1>
      <h2>
        Derecho de Acceso, Modificación, Rectificación y Eliminación de los
        Datos Personales.
      </h2>
      <p>
        Los Usuarios podrán ejercitar los derechos de acceder, modificar,
        rectificar y eliminar sus Datos Personales, incluyendo su dirección de
        e-mail, así como a oponerse al tratamiento de los mismos y a ser
        informado de las cesiones y/o transferencias internacionales de sus
        Datos Personales, de conformidad a lo dispuesto en la Ley Nº 19.628
        sobre Protección de la Vida Privada.
      </p>
      <p>
        Conforme se establece en el artículo 12 de la Ley Nº 19.628, el Usuario
        titular de los Datos Personales y previa acreditación de su identidad,
        tiene la facultad de ejercer el derecho de acceder, modificar,
        rectificar y/o eliminar sus Datos Personales, en forma gratuita,
        solicitud que deberá ser atendida dentro de los 2 (dos) días hábiles a
        que se efectuado.
      </p>
      <p>
        La solicitud de acceso, modificación, rectificación y/o eliminación de
        Datos Personales deberá efectuarse mediante correo electrónico enviado a
        leonardo@kantoor.cl, incluyendo su nombre completo, cédula nacional de
        identidad o rol único tributario, y número de teléfono celular. Sin
        perjuicio de los cambios que se realicen, kantoor podrá conservar la
        información personal anterior por motivos de seguridad y control del
        fraude, en caso de tener dudas razonables. En caso de infracción a las
        disposiciones anteriores, los Usuarios podrán recurrir ante el juez de
        letras en lo civil de Santiago, solicitando amparo a los derechos
        consagrados en la Ley Nº 19.628.
      </p>
    </section>
  );
};

export default Step5;
