import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import GoogleLogin from "react-google-login";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../login/microsoftConfig";
import Divisiones from "../../containers/config/loginHideDivision";

/* Actions  */
//import { resetLogin } from "../../actions/login";

import {
  getLoginCheck,
  getReservaCheck,
  getCovid,
  getJornada,
} from "../../actions/check";

const ModalSearch = (props) => {
  const { instance } = useMsal();
  const [form, setForm] = useState({
    user: "",
    pass: "",
  });

  const state = useSelector((state) => state.check);

  const dispatch = useDispatch();

  const divLogin = props.id.split("="); // filter logiin with

  useEffect(() => {
    if (props.modal) {
      if (
        state.status === 200 &&
        props.modal.invitado === false &&
        state.email !== false
      ) {
        if (state.loading === false) {
          props.setModal(false);
          const div = props.id.split("=");
          props.setEmail(state.email);
          dispatch(
            getReservaCheck({
              edi_division_id: parseInt(div[1], 10),
              email: state.email.toLowerCase(),
            })
          );
          dispatch(
            getJornada({
              edi_division_id: parseInt(div[1], 10),
              fecha: moment(new Date()).format("DD-MM-YYYY"),
            })
          );
          dispatch(
            getCovid({
              edi_division_id: parseInt(div[1], 10),
              email: state.email.toLowerCase(),
            })
          );
        }
      } else if (state.status === 200 && props.modal.invitado) {
        if (state.loading === false) {
          if (state.data.ejecucion.estado) {
            props.setModal(false);
            const div = props.id.split("=");
            props.setEmail(form.user);
            dispatch(
              getReservaCheck({
                edi_division_id: parseInt(div[1], 10),
                email: form.user.toLowerCase(),
              })
            );
            dispatch(
              getCovid({
                edi_division_id: parseInt(div[1], 10),
                email: form.user.toLowerCase(),
              })
            );
          }
        }
      }
    }
  }, [state]);

  const postLogin = (event) => {
    event.preventDefault();

    const obj = {
      id: props.id,
      invitado: props.modal.invitado,
      provider: "kantoor",
      google: { uid: "", name: "", token: "" },
      office365: { uid: "", name: "", token: "" },
      facebook: { uid: "", name: "", token: "" },
      kantoor: { email: form.user.toLowerCase(), password: form.pass },
      email: form.user.toLowerCase(),
    };
    dispatch(getLoginCheck(obj));
  };

  const responseGoogle = (response) => {
    if (response.accessToken) {
      setForm((prev) => ({ ...prev, user: response.profileObj.email }));
      const obj = {
        id: props.id,
        invitado: props.modal.invitado,
        provider: "google",
        facebook: { uid: "", name: "", token: "" },
        office365: { uid: "", name: "", token: "" },
        kantoor: { uid: "", name: "", token: "" },
        google: {
          uid: response.profileObj.googleId,
          name: response.profileObj.name,
          usename: response.profileObj.email,
          token_access: response.accessToken,
        },
        email: response.profileObj.email,
      };
      dispatch(getLoginCheck(obj));
    }
  };

  /* Login microsoft */
  const handleLoginMicrosoft = async (instance) => {
    instance
      .loginPopup(loginRequest)
      .then((resp) => {
        const obj = {
          provider: "office365",
          google: { uid: "", name: "", token: "" },
          office365: {
            uid: resp.account.homeAccountId,
            name: resp.account.name,
            email: resp.account.username,
            token: resp.accessToken,
            img: "test",
          },
          facebook: { uid: "" },
          kantoor: { email: "", password: "" },
          email: resp.account.username,
        };
        dispatch(getLoginCheck(obj));
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  if (!props.modal) {
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal ? true : false}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      ></Modal>
    </>;
  }

  let error;
  if (state.status === 501) {
    error = (
      <div className={props.style.containercheck_error}>
        <p>
          <FormattedMessage id="WrongUsernameOrPassword" />
        </p>
      </div>
    );
  } else if (state.data.ejecucion) {
    if (!state.data.ejecucion.estado) {
      error = (
        <div className={props.style.containercheck_error}>
          <p>
            <FormattedMessage id="WrongUsernameOrPassword" />
          </p>
        </div>
      );
    }
  } else {
    error = "";
  }

  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal ? true : false}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>
              <h4>
                <FormattedMessage id="LogIn" />
              </h4>
              <span>
                <i
                  onClick={() => {
                    props.setModal(false);
                  }}
                  className="fas fa-times"
                ></i>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={props.style.container__input}>
              <FormattedMessage id="WriteYourEmail">
                {(msg) => (
                  <Form.Control
                    value={form.user}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name={"user"}
                    placeholder={msg}
                  />
                )}
              </FormattedMessage>
            </div>
            {!props.modal.invitado && (
              <div className={props.style.container__input}>
                <FormattedMessage id="WriteYourPass">
                  {(msg) => (
                    <Form.Control
                      value={form.pass}
                      onChange={(e) => handleChange(e)}
                      name={"pass"}
                      type="password"
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
            )}
            {error}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={props.myStyle[`btn_${props.cliente}`]}
              variant="main"
              disabled={state.loading}
              type="button"
              onClick={postLogin}
            >
              {state.loading ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="LogIn" />
              )}
            </Button>
            {!props.modal.invitado &&
              !Divisiones.includes(parseInt(divLogin[1], 10)) && (
                <div>
                  <h3>
                    <FormattedMessage id="Loginwith" />:
                  </h3>
                  <GoogleLogin
                    clientId="674973170963-vpqj81chgbd5lhg3bmbjqpr7qts648c7.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps) => (
                      <section
                        disabled={renderProps.disabled}
                        onClick={renderProps.onClick}
                        className={props.style.container__microsoft}
                      >
                        <span>
                          <i className="fab fa-google"></i> <span>Login</span>
                        </span>
                      </section>
                    )}
                  />
                  <section
                    className={props.style.container__microsoft}
                    onClick={() => handleLoginMicrosoft(instance)}
                  >
                    <span>
                      <i className="fab fa-microsoft"></i> <span>Login</span>
                    </span>
                  </section>
                </div>
              )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalSearch;
