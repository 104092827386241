import axios from "axios";

const API = {
  data: {
    async getLogin(obj) {
      const response = await axios.post(`/api/check`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getCheck(obj) {
      const response = await axios.post(`/api/checkPost`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getReserva(obj) {
      const response = await axios.get(
        `/api/check/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getCovid(obj) {
      const response = await axios.get(
        `/api/check/covid/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getCovidRegister(obj) {
      const response = await axios.post(`/api/check/covid`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getCovidRegisterUpdate(obj) {
      const response = await axios.post(`/api/check/covid/update`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getJornada(obj) {
      const response = await axios.get(
        `/api/check/jornada/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
