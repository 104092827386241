import {
  GET_LOGIN_INIT,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  RESET_LOGIN,
  GET_FORGET_PASS_INIT,
  GET_FORGET_PASS_SUCCESS,
  GET_FORGET_PASS_FAILURE,
  RESET_FORGET_PASS,
  GET_CHANGE_PASS_INIT,
  GET_CHANGE_PASS_SUCCESS,
  GET_CHANGE_PASS_FAILURE,
  RESET_CHANGE_PASS,
  GET_LANGUAGES_INIT,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILURE,
  RESET_LANGUAGES,
  GET_CREATE_USER_INIT,
  GET_CREATE_USER_SUCCESS,
  GET_CREATE_USER_FAILURE,
  RESET_CREATE_USER,
  GET_CREATE_USER_CODE_INIT,
  GET_CREATE_USER_CODE_SUCCESS,
  GET_CREATE_USER_CODE_FAILURE,
  RESET_CREATE_USER_CODE,
  GET_AREA_INIT,
  GET_AREA_SUCCESS,
  GET_AREA_FAILURE,
  RESET_AREA,
  POST_CREATE_USER_COMPANY_INIT,
  POST_CREATE_USER_COMPANY_SUCCESS,
  POST_CREATE_USER_COMPANY_FAILURE,
  RESET_CREATE_USER_COMPANY,
} from "./types";
import API from "./api";

/* Clean login Sync */

export function resetLogin() {
  return {
    type: RESET_LOGIN,
  };
}

/* Get Login (Async) */

function getLoginSuccess(data, info) {
  return {
    type: GET_LOGIN_SUCCESS,
    payload: data,
    info,
  };
}

function getLoginFailure(error) {
  console.log(error);
  return {
    type: GET_LOGIN_FAILURE,
    payload: error,
  };
}

function getLoginInit() {
  return {
    type: GET_LOGIN_INIT,
  };
}

export function getLogin(data) {
  return async (dispatch) => {
    dispatch(getLoginInit());
    try {
      const resp = await API.data.getLogin(data);
      return dispatch(getLoginSuccess(resp, data));
    } catch (error) {
      return dispatch(getLoginFailure(error));
    }
  };
}

/*  Forget pass */

export function resetForgetPass() {
  return {
    type: RESET_FORGET_PASS,
  };
}

function getForgetPassSuccess(data, info) {
  return {
    type: GET_FORGET_PASS_SUCCESS,
    payload: data,
    info,
  };
}

function getForgetPassFailure(error) {
  console.log(error);
  return {
    type: GET_FORGET_PASS_FAILURE,
    payload: error,
  };
}

function getForgetPassInit() {
  return {
    type: GET_FORGET_PASS_INIT,
  };
}

export function getForgetPass(data) {
  return async (dispatch) => {
    dispatch(getForgetPassInit());
    try {
      const resp = await API.data.getForgetPass(data);
      return dispatch(getForgetPassSuccess(resp, data));
    } catch (error) {
      return dispatch(getForgetPassFailure(error));
    }
  };
}

/* Change Pass */

export function resetChangePass() {
  return {
    type: RESET_CHANGE_PASS,
  };
}

function getChangePassSuccess(data, info) {
  return {
    type: GET_CHANGE_PASS_SUCCESS,
    payload: data,
    info,
  };
}

function getChangePassFailure(error) {
  console.log(error);
  return {
    type: GET_CHANGE_PASS_FAILURE,
    payload: error,
  };
}

function getChangePassInit() {
  return {
    type: GET_CHANGE_PASS_INIT,
  };
}

export function getChangePass(data) {
  return async (dispatch) => {
    dispatch(getChangePassInit());
    try {
      const resp = await API.data.getChangePass(data);
      return dispatch(getChangePassSuccess(resp, data));
    } catch (error) {
      return dispatch(getChangePassFailure(error));
    }
  };
}

/* language Pass */

export function resetLanguages() {
  return {
    type: RESET_LANGUAGES,
  };
}

function getLanguagesSuccess(data, info) {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: data,
    info,
  };
}

function getLanguagesFailure(error) {
  console.log(error);
  return {
    type: GET_LANGUAGES_FAILURE,
    payload: error,
  };
}

function getLanguagesInit() {
  return {
    type: GET_LANGUAGES_INIT,
  };
}

export function getLanguages(data) {
  return async (dispatch) => {
    dispatch(getLanguagesInit());
    try {
      const resp = await API.data.getLanguages(data);
      return dispatch(getLanguagesSuccess(resp, data));
    } catch (error) {
      return dispatch(getLanguagesFailure(error));
    }
  };
}

/*  create user */

export function resetCreateUser() {
  return {
    type: RESET_CREATE_USER,
  };
}

function getCreateUserSuccess(data, info) {
  return {
    type: GET_CREATE_USER_SUCCESS,
    payload: data,
    info,
  };
}

function getCreateUserFailure(error) {
  console.log(error);
  return {
    type: GET_CREATE_USER_FAILURE,
    payload: error,
  };
}

function getCreateUserInit() {
  return {
    type: GET_CREATE_USER_INIT,
  };
}

export function getCreateUser(data) {
  return async (dispatch) => {
    dispatch(getCreateUserInit());
    try {
      const resp = await API.data.getCreateUser(data);
      return dispatch(getCreateUserSuccess(resp, data));
    } catch (error) {
      return dispatch(getCreateUserFailure(error));
    }
  };
}

/*  create user pass */

export function resetCreateUserCode() {
  return {
    type: RESET_CREATE_USER_CODE,
  };
}

function getCreateUserCodeSuccess(data, info) {
  return {
    type: GET_CREATE_USER_CODE_SUCCESS,
    payload: data,
    info,
  };
}

function getCreateUserCodeFailure(error) {
  console.log(error);
  return {
    type: GET_CREATE_USER_CODE_FAILURE,
    payload: error,
  };
}

function getCreateUserCodeInit() {
  return {
    type: GET_CREATE_USER_CODE_INIT,
  };
}

export function getCreateUserCode(data) {
  return async (dispatch) => {
    dispatch(getCreateUserCodeInit());
    try {
      const resp = await API.data.getCreateUserCode(data);
      return dispatch(getCreateUserCodeSuccess(resp, data));
    } catch (error) {
      return dispatch(getCreateUserCodeFailure(error));
    }
  };
}

/*  Get area  */

export function resetArea() {
  return {
    type: RESET_AREA,
  };
}

function getAreaSuccess(data, info) {
  return {
    type: GET_AREA_SUCCESS,
    payload: data,
    info,
  };
}

function getAreaFailure(error) {
  console.log(error);
  return {
    type: GET_AREA_FAILURE,
    payload: error,
  };
}

function getAreaInit() {
  return {
    type: GET_AREA_INIT,
  };
}

export function getArea(data) {
  return async (dispatch) => {
    dispatch(getAreaInit());
    try {
      const resp = await API.data.getArea(data);
      return dispatch(getAreaSuccess(resp, data));
    } catch (error) {
      return dispatch(getAreaFailure(error));
    }
  };
}

/*  create user  company*/

export function resetCreateUserCompany() {
  return {
    type: RESET_CREATE_USER_COMPANY,
  };
}

function postCreateUserCompanySuccess(data, info) {
  return {
    type: POST_CREATE_USER_COMPANY_SUCCESS,
    payload: data,
    info,
  };
}

function postCreateUserCompanyFailure(error) {
  console.log(error);
  return {
    type: POST_CREATE_USER_COMPANY_FAILURE,
    payload: error,
  };
}

function postCreateUserCompanyInit() {
  return {
    type: POST_CREATE_USER_COMPANY_INIT,
  };
}

export function postCreateUserCompany(data) {
  return async (dispatch) => {
    dispatch(postCreateUserCompanyInit());
    try {
      const resp = await API.data.postCreateUserCompany(data);
      return dispatch(postCreateUserCompanySuccess(resp, data));
    } catch (error) {
      return dispatch(postCreateUserCompanyFailure(error));
    }
  };
}
