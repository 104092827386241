import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

/* Actions */
import { getBolsas, resetBolsas } from "../../actions/reservation";

/* Components */
import Loading from "./Loading";
import ErrorComponent from "./Error";
import Expired from "../common/Expired";

const StepBags = (props) => {
  const state = useSelector((state) => state.reservation);
  const dispatch = useDispatch();

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getBolsas({
          ciudad: props.form.ciudad,
          provincia: props.form.provincia,
          comuna: props.form.comuna,
          empresa_id: props.form.empresa_id,
        })
      );
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    if (state.statusBolsas === 200) {
      const total = state.dataBolsas.datos.length;
      if (total === 1) {
        props.handleChange({
          target: {
            name: "bolsa_id",
            value: state.dataBolsas.datos[0].id,
          },
        });
        if (props.direction === "right") {
          props.handleMemory({ name: "step5" });
          props.nextStep();
        } else {
          props.backStep();
        }
      }
    }
  }, [state]);

  /* Reset  Data  */
  useEffect(() => {
    return () => {
      dispatch(resetBolsas());
    };
  }, [dispatch]);

  if (state.loadingBolsas) {
    /* When the component is loading */
    return <Loading style={props.style} />;
  } else if (
    /* When the token is expired*/
    state.statusBolsas === 501
  ) {
    return <Expired />;
  } else if (
    /* When there is an error in the back */
    state.statusBolsas === 200
  ) {
    if (!state.dataBolsas.ejecucion.estado) {
      return <ErrorComponent style={props.style} />;
    }
  }

  return (
    <section className={props.style.container__content}>
      <h2 className={props.style.start}>
        <FormattedMessage id="WhatAreYouLookingFor" />
      </h2>
      <p>
        <FormattedMessage id="SelectYourPreference" />
      </p>
      <div className={`${props.style.box__footer}`}>
        <div className={props.style.box__footer__binary}>
          {state.dataBolsas.datos.map((item) => (
            <button
              className={props.myStyle[`btn_special_${props.cliente}`]}
              onClick={() => {
                props.handleChange({
                  target: { name: "bolsa_id", value: item.id },
                });
                props.handleCount(1);
                props.handleMemory({ name: "step5" });
                props.nextStep();
              }}
              key={item.id}
            >
              {" "}
              {item.glosa}{" "}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StepBags;
