import React from "react";
import Style from "../../assets/styles/Footer.module.scss";
import { FormattedMessage } from "react-intl";

const Footer = (props) => {
  return (
    <footer className={Style.container}>
      <h2>
        <FormattedMessage id="VisitOurNetworks" />{" "}
        <a href="https://www.facebook.com/kantoor.cl">
          <i className="fab fa-facebook-square"></i>
        </a>{" "}
        <FormattedMessage id="and" />{" "}
        <a href="https://www.instagram.com/kantoor.cl">
          <i className="fab fa-instagram"></i>{" "}
        </a>{" "}
        <a href="https://www.kantoor.cl/politicas.html">
          <FormattedMessage id="TermsAndConditions" />
        </a>
      </h2>
    </footer>
  );
};

export default Footer;
