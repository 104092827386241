import {
  GET_LOGIN_VALIDATE_INIT,
  GET_LOGIN_VALIDATE_SUCCESS,
  GET_LOGIN_VALIDATE_FAILURE,
  RESET_LOGIN_VALIDATE,
  GET_RESERVA_VALIDATE_INIT,
  GET_RESERVA_VALIDATE_SUCCESS,
  GET_RESERVA_VALIDATE_FAILURE,
  RESET_RESERVA_VALIDATE,
  LOGOUT_VALIDATE,
  GET_CHECK_VALIDATE_INIT,
  GET_CHECK_VALIDATE_SUCCESS,
  GET_CHECK_VALIDATE_FAILURE,
  RESET_CHECK_VALIDATE,
} from "./types";
import API from "./api";

export function logOutValidate() {
  return {
    type: LOGOUT_VALIDATE,
  };
}

export function resetLoginValidate() {
  return {
    type: RESET_LOGIN_VALIDATE,
  };
}

function getLoginSuccess(data, info) {
  return {
    type: GET_LOGIN_VALIDATE_SUCCESS,
    payload: data,
    info,
  };
}

function getLoginFailure(error) {
  console.log(error);
  return {
    type: GET_LOGIN_VALIDATE_FAILURE,
    payload: error,
  };
}

function getLoginInit() {
  return {
    type: GET_LOGIN_VALIDATE_INIT,
  };
}

export function getLoginValidate(data) {
  return async (dispatch) => {
    dispatch(getLoginInit());
    try {
      const resp = await API.data.getLoginValidate(data);
      return dispatch(getLoginSuccess(resp, data));
    } catch (error) {
      return dispatch(getLoginFailure(error));
    }
  };
}

// Get check

export function resetReservaValidate() {
  return {
    type: RESET_RESERVA_VALIDATE,
  };
}

function getReservaValidateSuccess(data, info) {
  return {
    type: GET_RESERVA_VALIDATE_SUCCESS,
    payload: data,
    info,
  };
}

function getReservaValidateFailure(error) {
  console.log(error);
  return {
    type: GET_RESERVA_VALIDATE_FAILURE,
    payload: error,
  };
}

function getReservaValidateInit(loading) {
  return {
    type: GET_RESERVA_VALIDATE_INIT,
    loading,
  };
}

export function getReservaValidate(data) {
  return async (dispatch) => {
    dispatch(getReservaValidateInit(data.loading));
    try {
      const resp = await API.data.getReservaValidate(data);
      return dispatch(getReservaValidateSuccess(resp, data));
    } catch (error) {
      return dispatch(getReservaValidateFailure(error));
    }
  };
}

/* Get post checkin checkout*/
export function resetCheckValidate() {
  return {
    type: RESET_CHECK_VALIDATE,
  };
}

function getCheckValidateSuccess(data, info) {
  return {
    type: GET_CHECK_VALIDATE_SUCCESS,
    payload: data,
    info,
  };
}

function getCheckValidateFailure(error) {
  console.log(error);
  return {
    type: GET_CHECK_VALIDATE_FAILURE,
    payload: error,
  };
}

function getCheckValidateInit() {
  return {
    type: GET_CHECK_VALIDATE_INIT,
  };
}

export function getCheckValidate(data) {
  return async (dispatch) => {
    dispatch(getCheckValidateInit());
    try {
      const resp = await API.data.getCheckValidate(data);
      return dispatch(getCheckValidateSuccess(resp, data));
    } catch (error) {
      return dispatch(getCheckValidateFailure(error));
    }
  };
}
