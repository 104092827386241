import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DaySchedule from "./Schedule_day";
import DaysSchedule from "./Schedule_days";

import { getCalendarTwo, resetCalendarTwo } from "../../actions/history";

const ModalSchedule = (props) => {
  let history = useHistory();
  const state = useSelector((state) => state.history);
  const dispatch = useDispatch();
  const [arrSelect, setArrSelect] = useState([]);

  const [error, setError] = useState(false);
  const [dataWeeks, setDataWeeks] = useState([]);

  /* Get Data */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getCalendarTwo(props.query));
    };
    callsAPI();
  }, [dispatch, props.query]);

  useEffect(() => {
    if (!state.loadingCalendarTwo) {
      if (
        props.query.clave_granularidad === "minutos" ||
        props.query.clave_granularidad === "horas"
      ) {
        if (arrSelect.length > 0) {
          const element1 = document.getElementById(
            `father-${arrSelect[0].key}${arrSelect[0].id}`
          );
          const text1 = document.getElementById(
            `message-${arrSelect[0].key}${arrSelect[0].id}`
          );
          let total = 1;
          if (arrSelect.length === 2) {
            total = arrSelect[1].id - arrSelect[0].id;
            total = total + 1;
          }

          if (element1.dataset.date === arrSelect[0].date) {
            element1.classList.add("message__green");
            element1.style.height = `${total * 50}px`;
            element1.style.zIndex = `2`;
            text1.innerHTML = "Ok";
          }
        }
      } else {
        if (arrSelect.length === 1) {
          let date1 = parseInt(
            moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          const element = document.getElementById(
            `father-id${arrSelect[0].id}${date1}`
          );
          const text = document.getElementById(
            `message-id${arrSelect[0].id}${date1}`
          );
          if (element) {
            element.classList.add("message__green");
            text.innerHTML = "Ok";
          }
        } else if (arrSelect.length === 2) {
          const arrW = dataWeeks.flat();
          let myData = [];

          arrW.forEach((item) => {
            myData = myData.concat(item.data);
          });

          let date1 = parseInt(
            moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          let date2 = parseInt(
            moment(arrSelect[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );

          let mayor = date1 > date2 ? date1 : date2;
          let menor = date1 < date2 ? date1 : date2;

          myData.forEach((item) => {
            let date3 = parseInt(
              moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
              10
            );
            if (date3 >= menor && date3 <= mayor) {
              const dataNum = parseInt(
                moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
                10
              );
              const element = document.getElementById(
                `father-id${item.id}${dataNum}`
              );
              const text = document.getElementById(
                `message-id${item.id}${dataNum}`
              );
              if (element) {
                element.classList.add("message__green");
                text.innerHTML = "ok";
              }
            }
          });
        }

        if (state.statusCalendarTwo === 200) {
          const dateQ = parseInt(
            moment(props.query.fecha_inicio, "DD-MM-YYYY").format("YYYYMM"),
            10
          );
          const arrW = dataWeeks.flat();
          const index = arrW.findIndex((x) => x.key === dateQ);
          if (index < 0) {
            dataWeeks.push([
              {
                data: state.dataCalendarTwo.datos.casillas,
                key: parseInt(
                  moment(props.query.fecha_inicio, "DD-MM-YYYY").format(
                    "YYYYMM"
                  ),
                  10
                ),
              },
            ]);
            setDataWeeks(dataWeeks);
          }
        }
      }
    }
  }, [state.loadingCalendarTwo]);

  useEffect(() => {
    return () => {
      dispatch(resetCalendarTwo());
    };
  }, [dispatch]);

  let container;

  /* back week or next month */
  const back = () => {
    if (
      props.query.clave_granularidad === "minutos" ||
      props.query.clave_granularidad === "horas"
    ) {
      const fecha = moment(props.query.fecha_inicio, "DD-MM-YYYY").add(
        -7,
        "days"
      );
      props.query.fecha_inicio = fecha.format("DD-MM-YYYY");

      dispatch(getCalendarTwo(props.query));
    } else {
      const fecha = moment(props.query.fecha_inicio, "DD-MM-YYYY").add(-1, "M");
      props.query.fecha_inicio = fecha.format("DD-MM-YYYY");
      dispatch(getCalendarTwo(props.query));
    }
  };

  /* Next week or next month */
  const next = () => {
    if (
      props.query.clave_granularidad === "minutos" ||
      props.query.clave_granularidad === "horas"
    ) {
      const fecha = moment(props.query.fecha_inicio, "DD-MM-YYYY").add(
        7,
        "days"
      );
      props.query.fecha_inicio = fecha.format("DD-MM-YYYY");

      dispatch(getCalendarTwo(props.query));
    } else {
      const fecha = moment(props.query.fecha_inicio, "DD-MM-YYYY").add(1, "M");
      props.query.fecha_inicio = fecha.format("DD-MM-YYYY");

      dispatch(getCalendarTwo(props.query));
    }
  };

  const message = () => {};

  /* Control days and hours */
  const handleSelect = (obj) => {
    setError(false);
    const arr = [...arrSelect];
    if (arr.length === 0) {
      obj.item.key = obj.key;
      arr.push(obj.item);
      setArrSelect(arr);
      const element = document.getElementById(obj.id);
      const text = document.getElementById(obj.message);
      element.classList.add("message__green");
      text.innerHTML = "Ok";
    } else if (obj.item.date !== arr[0].date) {
      if (arr[0]) {
        const element1 = document.getElementById(
          `father-${arr[0].key}${arr[0].id}`
        );
        const text1 = document.getElementById(
          `message-${arr[0].key}${arr[0].id}`
        );

        element1.classList.remove("message__green");
        element1.style.height = `${50}px`;
        element1.style.zIndex = `2`;
        text1.innerHTML = "";
      }

      if (arr[1]) {
        const element2 = document.getElementById(
          `father-${arr[0].key}${arr[1].id}`
        );
        const text2 = document.getElementById(
          `message-${arr[0].key}${arr[1].id}`
        );
        element2.classList.remove("message__green");
        element2.style.height = `${50}px`;
        element2.style.zIndex = `2`;
        text2.innerHTML = "";
      }

      obj.item.key = obj.key;
      let newArr = [obj.item];
      setArrSelect(newArr);
      const element = document.getElementById(obj.id);
      const text = document.getElementById(obj.message);
      element.classList.add("message__green");
      text.innerHTML = "Ok";
    } else if (arr.length === 1) {
      if (arr[0]) {
        const element1 = document.getElementById(
          `father-${obj.key}${arr[0].id}`
        );
        const text1 = document.getElementById(`message-${obj.key}${arr[0].id}`);

        element1.classList.remove("message__green");
        element1.style.height = `${50}px`;
        element1.style.zIndex = `2`;
        text1.innerHTML = "";
      }

      if (arr[1]) {
        const element2 = document.getElementById(
          `father-${obj.key}${arr[1].id}`
        );
        const text2 = document.getElementById(`message-${obj.key}${arr[1].id}`);
        element2.classList.remove("message__green");
        element2.style.height = `${50}px`;
        element2.style.zIndex = `2`;
        text2.innerHTML = "";
      }

      obj.item.key = obj.key;
      arr.push(obj.item);

      let verify = true;
      let count = 0;
      let mayor = arr[0].id > arr[1].id ? arr[0].id : arr[1].id;
      let menor = arr[0].id < arr[1].id ? arr[0].id : arr[1].id;

      obj.data.forEach((item) => {
        if (item.id >= menor && item.id <= mayor) {
          if (!item.disponibilidad && props.disponibility) {
            verify = false;
          } else {
            count++;
          }
        }
      });
      if (verify) {
        if (arr[0].id > arr[1].id) {
          setArrSelect([arr[1], arr[0]]);
        } else {
          setArrSelect(arr);
        }

        const element = document.getElementById(`father-${obj.key}${menor}`);
        const text = document.getElementById(`message-${obj.key}${menor}`);
        element.classList.add("message__green");
        element.style.height = `${count * 50}px`;
        element.style.zIndex = `1`;
        text.innerHTML = "Ok";
      } else {
        let newArr = [obj.item];
        setArrSelect(newArr);
        const element = document.getElementById(obj.id);
        const text = document.getElementById(obj.message);
        element.classList.add("message__green");
        text.innerHTML = "Ok";
      }
    } else if (obj.item.id === arr[0].id) {
      const element1 = document.getElementById(
        `father-${arr[0].key}${arr[0].id}`
      );
      const text1 = document.getElementById(
        `message-${arr[0].key}${arr[0].id}`
      );
      element1.classList.remove("message__green");
      element1.style.height = `${50}px`;
      element1.style.zIndex = `2`;
      text1.innerHTML = "";
      setArrSelect([]);
    } else if (arr.length === 2) {
      const element1 = document.getElementById(`father-${obj.key}${arr[0].id}`);
      const text1 = document.getElementById(`message-${obj.key}${arr[0].id}`);
      const element2 = document.getElementById(`father-${obj.key}${arr[1].id}`);
      const text2 = document.getElementById(`message-${obj.key}${arr[1].id}`);

      element1.classList.remove("message__green");
      element1.style.height = `${50}px`;
      element1.style.zIndex = `2`;
      text1.innerHTML = "";

      element2.classList.remove("message__green");
      element2.style.height = `${50}px`;
      element2.style.zIndex = `2`;
      text2.innerHTML = "";

      obj.item.key = obj.key;
      if (obj.num < arr[0].id) {
        arr.splice(0, 1, obj.item);
      } else if (obj.num >= arr[0].id && obj.num <= arr[1].id) {
        arr.splice(1, 1, obj.item);
      } else {
        arr.splice(1, 1, obj.item);
      }

      let verify = true;
      let count = 0;
      let mayor = arr[0].id > arr[1].id ? arr[0].id : arr[1].id;
      let menor = arr[0].id < arr[1].id ? arr[0].id : arr[1].id;

      obj.data.forEach((item) => {
        if (item.id >= menor && item.id <= mayor) {
          if (!item.disponibilidad && props.disponibility) {
            verify = false;
          } else {
            count++;
          }
        }
      });

      if (verify) {
        if (arr[0].id > arr[1].id) {
          setArrSelect([arr[1], arr[0]]);
        } else {
          setArrSelect(arr);
        }
        const element = document.getElementById(`father-${obj.key}${menor}`);
        const text = document.getElementById(`message-${obj.key}${menor}`);
        element.classList.add("message__green");
        element.style.height = `${count * 50}px`;
        element.style.zIndex = `1`;
        text.innerHTML = "Ok";
      } else {
        obj.item.key = obj.key;
        let newArr = [obj.item];
        setArrSelect(newArr);
        const element = document.getElementById(obj.id);
        const text = document.getElementById(obj.message);
        element.classList.add("message__green");
        text.innerHTML = "Ok";
      }
    }
  };

  /* Control days and times */
  const handleSelectDays = (obj) => {
    setError(false);
    const arr = [...arrSelect];
    const arrW = dataWeeks.flat();
    let myData = [];

    arrW.forEach((item) => {
      myData = myData.concat(item.data);
    });

    let clean;
    if (arr.length > 0) {
      clean = arr[0].comienza === obj.item.comienza;
    }

    if (clean) {
      myData.forEach((item) => {
        const dataNum = parseInt(
          moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );
        const element = document.getElementById(
          `father-${obj.key}${item.id}${dataNum}`
        );
        const text = document.getElementById(
          `message-${obj.key}${item.id}${dataNum}`
        );
        if (element) {
          element.classList.remove("message__green");
          text.innerHTML = "";
        }
      });
      setArrSelect([]);
    } else if (arr.length === 0) {
      obj.item.key = obj.key;
      arr.push(obj.item);
      setArrSelect(arr);

      const element = document.getElementById(obj.id);
      const text = document.getElementById(obj.message);
      element.classList.add("message__green");
      text.innerHTML = "Ok";
    } else if (arr.length === 1) {
      obj.item.key = obj.key;
      arr.push(obj.item);

      let verify = true;
      let count = 0;

      let date1 = parseInt(
        moment(arr[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );
      let date2 = parseInt(
        moment(arr[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );

      let mayor = date1 > date2 ? date1 : date2;
      let menor = date1 < date2 ? date1 : date2;

      myData.forEach((item) => {
        let date3 = parseInt(
          moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );
        if (date3 >= menor && date3 <= mayor) {
          if (!item.seleccionable) {
            verify = false;
          } else {
            count++;
          }
        }
      });
      if (verify) {
        if (date1 > date2) {
          setArrSelect([arr[1], arr[0]]);
        } else {
          setArrSelect(arr);
        }
        let index;
        myData.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          if (date3 >= menor && date3 <= mayor) {
            if (item.disponibilidad) {
              const dataNum = parseInt(
                moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
                10
              );
              const element = document.getElementById(
                `father-${obj.key}${item.id}${dataNum}`
              );
              const text = document.getElementById(
                `message-${obj.key}${item.id}${dataNum}`
              );
              if (element) {
                element.classList.add("message__green");
                text.innerHTML = "Ok";
              }
            }
          }
        });
      } else {
        arr.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          const dataNum = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          if (date3 >= menor && date3 <= mayor) {
            const element = document.getElementById(
              `father-${obj.key}${item.id}${dataNum}`
            );
            const text = document.getElementById(
              `message-${obj.key}${item.id}${dataNum}`
            );
            if (element) {
              element.classList.remove("message__green");
              text.innerHTML = "";
            }
          }
        });

        let newArr = [obj.item];
        setArrSelect(newArr);
        const element = document.getElementById(obj.id);
        const text = document.getElementById(obj.message);
        element.classList.add("message__green");
        text.innerHTML = "Ok";
      }
    } else if (arr.length === 2) {
      obj.item.key = obj.key;

      // console.log(obj.num, date1);
      let date1 = parseInt(
        moment(arr[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );
      let date2 = parseInt(
        moment(arr[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );
      let newArr;
      if (obj.num < date1) {
        newArr = [obj.item, arr[1]];
      } else if (obj.num >= date1 && obj.num <= date2) {
        newArr = [arr[1], obj.item];
      } else {
        newArr = [arr[0], obj.item];
      }
      date1 = parseInt(
        moment(newArr[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );
      date2 = parseInt(
        moment(newArr[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );

      let verify = true;
      let count = 0;

      let mayor = date1 > date2 ? date1 : date2;
      let menor = date1 < date2 ? date1 : date2;

      myData.forEach((item) => {
        let date3 = parseInt(
          moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );
        if (date3 >= menor && date3 <= mayor) {
          if (!item.seleccionable) {
            verify = false;
          } else {
            count++;
          }
        }
      });

      let date4 = parseInt(
        moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );

      let date5 = parseInt(
        moment(arrSelect[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
        10
      );

      let mayor1 = date4 > date5 ? date4 : date5;
      let menor1 = date4 < date5 ? date4 : date5;

      if (verify) {
        if (date1 > date2) {
          setArrSelect([newArr[1], newArr[0]]);
        } else {
          setArrSelect(newArr);
        }

        let index;
        myData.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          const dataNum = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          if (date3 >= menor1 && date3 <= mayor1) {
            if (item.disponibilidad) {
              const element = document.getElementById(
                `father-${obj.key}${item.id}${dataNum}`
              );
              const text = document.getElementById(
                `message-${obj.key}${item.id}${dataNum}`
              );
              if (element) {
                element.classList.remove("message__green");
                text.innerHTML = "";
              }
            }
          }
        });
        myData.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          const dataNum = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );

          if (date3 >= menor && date3 <= mayor) {
            if (item.disponibilidad) {
              const element = document.getElementById(
                `father-${obj.key}${item.id}${dataNum}`
              );
              const text = document.getElementById(
                `message-${obj.key}${item.id}${dataNum}`
              );
              if (element) {
                element.classList.add("message__green");
                text.innerHTML = "Ok";
              }
            }
          }
        });
      } else {
        newArr.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          const dataNum = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          if (date3 >= menor && date3 <= mayor) {
            const element = document.getElementById(
              `father-${obj.key}${item.id}${dataNum}`
            );
            const text = document.getElementById(
              `message-${obj.key}${item.id}${dataNum}`
            );
            if (element) {
              element.classList.remove("message__green");
              text.innerHTML = "";
            }
          }
        });

        let newArr1 = [obj.item];
        setArrSelect(newArr1);
        const element = document.getElementById(obj.id);
        const text = document.getElementById(obj.message);
        element.classList.add("message__green");
        text.innerHTML = "Ok";
      }
    }
  };

  const handleTime = (event) => {
    event.preventDefault();

    let someWrong = false;
    let obj = {};
    if (arrSelect.length === 0) {
      setError("CompleteTheRequiredFields");
    } else if (props.query.clave_granularidad === "minutos") {
      let timeStart;
      let timeEnd;
      let time1;
      let time2;
      if (arrSelect.length === 1) {
        timeStart = new Date("01/01/2007 " + arrSelect[0].comienza);
        timeEnd = new Date("01/01/2007 " + arrSelect[0].termina);
        time1 = arrSelect[0].comienza.split(":");
        time2 = arrSelect[0].termina.split(":");
      } else {
        timeStart = new Date("01/01/2007 " + arrSelect[0].comienza);
        timeEnd = new Date("01/01/2007 " + arrSelect[1].termina);
        time1 = arrSelect[0].comienza.split(":");
        time2 = arrSelect[1].termina.split(":");
      }

      props.handleChange({
        target: {
          name: "fecha_inicio",
          value: new Date(
            moment(arrSelect[0].date, "DD-MM-YYYY").format("YYYY/MM/DD")
          ),
        },
      });

      props.handleChange({
        target: {
          name: "minuto_comienzo",
          value: moment()
            .hour(parseInt(time1[0], 10))
            .minute(parseInt(time1[1], 10)),
        },
      });

      props.handleChange({
        target: {
          name: "minuto_final",
          value: moment()
            .hour(parseInt(time2[0], 10))
            .minute(parseInt(time2[1], 10)),
        },
      });

      let diff = Math.abs(timeStart - timeEnd);
      let minutes = Math.floor(diff / 1000 / 60);

      props.handleChange({
        target: {
          name: "duracion",
          value: minutes,
        },
      });

      props.handleChange({
        target: {
          name: "inicio",
          value: arrSelect[0].comienza,
        },
      });

      obj = {
        empresa_id: props.query.empresa_id,
        division_id: props.query.division_id,
        subdivision_id: props.query.subdivision_id,
        subsubdivision_id: props.query.subsubdivision_id,
        arr_alternativa_id: props.query.arr_alternativa_id, // []
        fecha_inicio: arrSelect[0].date,
        hora_inicio: arrSelect[0].comienza,
        clave_granularidad: "minutos",
        duracion: `${minutes}`,
        disponibility: props.disponibility,
      };
    } else if (props.query.clave_granularidad === "horas") {
      let timeStart;
      let timeEnd;
      let time1;
      let time2;
      if (arrSelect.length === 1) {
        timeStart = new Date("01/01/2007 " + arrSelect[0].comienza);
        timeEnd = new Date("01/01/2007 " + arrSelect[0].termina);
        time1 = arrSelect[0].comienza.split(":");
        time2 = arrSelect[0].termina.split(":");
      } else {
        timeStart = new Date("01/01/2007 " + arrSelect[0].comienza);
        timeEnd = new Date("01/01/2007 " + arrSelect[1].termina);
        time1 = arrSelect[0].comienza.split(":");
        time2 = arrSelect[1].termina.split(":");
      }

      let diff = Math.abs(timeStart - timeEnd);
      let minutes = Math.floor(diff / 1000 / 60);

      let hora = minutes / 60;
      props.handleChange({
        target: {
          name: "fecha_inicio",
          value: new Date(
            moment(arrSelect[0].date, "DD-MM-YYYY").format("YYYY/MM/DD")
          ),
        },
      });

      props.handleChange({
        target: {
          name: "hora_comienzo",
          value: moment()
            .hour(parseInt(time1[0], 10))
            .minute(parseInt(time1[1], 10)),
        },
      });

      props.handleChange({
        target: {
          name: "hora_final",
          value: hora,
        },
      });

      props.handleChange({
        target: {
          name: "duracion",
          value: hora,
        },
      });

      props.handleChange({
        target: {
          name: "inicio",
          value: arrSelect[0].comienza,
        },
      });

      obj = {
        empresa_id: props.query.empresa_id,
        division_id: props.query.division_id,
        subdivision_id: props.query.subdivision_id,
        subsubdivision_id: props.query.subsubdivision_id,
        arr_alternativa_id: props.query.arr_alternativa_id, // []
        fecha_inicio: arrSelect[0].date,
        hora_inicio: arrSelect[0].comienza,
        clave_granularidad: "horas",
        duracion: `${hora}`,
        disponibility: props.disponibility,
      };
    } else if (props.query.clave_granularidad === "dias") {
      let count = 0;
      if (arrSelect.length === 2) {
        const arrW = dataWeeks.flat();
        let myData = [];

        arrW.forEach((item) => {
          myData = myData.concat(item.data);
        });

        let date1 = parseInt(
          moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );
        let date2 = parseInt(
          moment(arrSelect[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );

        let mayor = date1 > date2 ? date1 : date2;
        let menor = date1 < date2 ? date1 : date2;
        myData.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          if (date3 >= menor && date3 <= mayor) {
            if (!item.disponibilidad) {
              verify = false;
            } else {
              count++;
            }
          }
        });

        props.handleChange({
          target: {
            name: "fecha_inicio",
            value: new Date(
              moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYY/MM/DD")
            ),
          },
        });

        props.handleChange({
          target: {
            name: "dias_comienzo",
            value: count,
          },
        });
      } else {
        count = 1;
      }

      props.handleChange({
        target: {
          name: "duracion",
          value: count,
        },
      });

      props.handleChange({
        target: {
          name: "inicio",
          value: arrSelect[0].comienza,
        },
      });

      obj = {
        empresa_id: props.query.empresa_id,
        division_id: props.query.division_id,
        subdivision_id: props.query.subdivision_id,
        subsubdivision_id: props.query.subsubdivision_id,
        arr_alternativa_id: props.query.arr_alternativa_id, // []
        fecha_inicio: arrSelect[0].comienza,
        hora_inicio: "12:00",
        clave_granularidad: "dias",
        duracion: `${count}`,
        disponibility: props.disponibility,
      };
    } else if (props.query.clave_granularidad === "semanas") {
      let count = 0;
      let total = 0;
      if (arrSelect.length === 2) {
        const arrW = dataWeeks.flat();
        let myData = [];

        arrW.forEach((item) => {
          myData = myData.concat(item.data);
        });

        let date1 = parseInt(
          moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );
        let date2 = parseInt(
          moment(arrSelect[1].comienza, "DD-MM-YYYY").format("YYYYMMDD"),
          10
        );

        let mayor = date1 > date2 ? date1 : date2;
        let menor = date1 < date2 ? date1 : date2;
        myData.forEach((item) => {
          let date3 = parseInt(
            moment(item.comienza, "DD-MM-YYYY").format("YYYYMMDD"),
            10
          );
          if (date3 >= menor && date3 <= mayor) {
            if (!item.disponibilidad) {
              verify = false;
            } else {
              count++;
            }
          }
        });

        props.handleChange({
          target: {
            name: "fecha_inicio",
            value: new Date(
              moment(arrSelect[0].comienza, "DD-MM-YYYY").format("YYYY/MM/DD")
            ),
          },
        });

        total = Math.ceil(count / 7);

        props.handleChange({
          target: {
            name: "semana_comienzo",
            value: total,
          },
        });
      } else {
        count = 1;
        total = Math.ceil(count / 7);
      }

      props.handleChange({
        target: {
          name: "duracion",
          value: total,
        },
      });

      props.handleChange({
        target: {
          name: "inicio",
          value: arrSelect[0].comienza,
        },
      });

      obj = {
        empresa_id: props.query.empresa_id,
        division_id: props.query.division_id,
        subdivision_id: props.query.subdivision_id,
        subsubdivision_id: props.query.subsubdivision_id,
        arr_alternativa_id: props.query.arr_alternativa_id, // []
        fecha_inicio: arrSelect[0].comienza,
        hora_inicio: "12:00",
        clave_granularidad: "semanas",
        duracion: `${total}`,
        disponibility: props.disponibility,
      };
    }

    if (!error) {
      history.push({
        pathname: `/home/copy_reservation/${props.data.reserva.id}`,
        state: { form: obj, data: props.data, query: props.query },
      });
    }
  };

  if (state.loadingCalendarTwo) {
    container = (
      <div className={props.style.container__loading__schedule}>
        <h1>
          Cargando <i className="fas fa-spinner fa-spin"></i>
        </h1>
      </div>
    );
  } else if (state.statusCalendarTwo === 200) {
    if (state.dataCalendarTwo.ejecucion.estado) {
      if (
        props.query.clave_granularidad === "minutos" ||
        props.query.clave_granularidad === "horas"
      ) {
        container = (
          <section className={props.style.container__schedule}>
            <DaySchedule
              next={next}
              back={back}
              cliente={props.cliente}
              myStyle={props.myStyle}
              data={state.dataCalendarTwo}
              style={props.style}
              message={message}
              handleSelect={handleSelect}
              disponibility={props.disponibility}
              infoReserva={props.data.reserva}
            />
          </section>
        );
      } else {
        container = (
          <section className={props.style.container__schedule}>
            <DaysSchedule
              next={next}
              back={back}
              cliente={props.cliente}
              myStyle={props.myStyle}
              data={state.dataCalendarTwo}
              style={props.style}
              handleSelect={handleSelectDays}
            />
          </section>
        );
      }
    } else {
      container = <h1>Error</h1>;
    }
  } else {
    container = <h1>Error</h1>;
  }

  return (
    <>
      <Modal
        onHide={() => {
          props.setModal(false);
          props.setQuery({});
          setArrSelect([]);
          dispatch(resetCalendarTwo());
        }}
        show={props.modal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>
              <FormattedMessage id="Schedule" />
            </h4>
            <span>
              <i
                onClick={() => {
                  setArrSelect([]);
                  setDataWeeks([]);
                  props.setModal(false);
                  dispatch(resetCalendarTwo());
                }}
                className="fas fa-times"
              ></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{container}</Modal.Body>
        <Modal.Footer>
          {error && (
            <span className={props.style.container__error__schedule}>
              <FormattedMessage id={error} />
            </span>
          )}
          <Button
            onClick={handleTime}
            variant="main"
            disabled={false}
            type="submit"
            className={props.myStyle[`btn_${props.cliente}`]}
          >
            <div className={props.style.box__button__modal}>
              <span>
                {state.loadingHorariosValido ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Next" />
                )}
              </span>
              <span>
                <i className="fas fa-search"></i>
              </span>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSchedule;
